import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { HapticsService } from '@app/core/service/haptics.service';
import { IonPopover } from '@ionic/angular';

@Component({
  selector: 'app-date-form-control',
  templateUrl: './date-form-control.component.html',
  styleUrls: ['./date-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateFormControlComponent
    }
  ]
})
export class DateFormControlComponent implements ControlValueAccessor {
  @ViewChild('popover')
  protected popover: IonPopover;
  @Input()
  public label: string;
  @Input()
  public dateTransformer: (value: Date) => Date = (value) => value;
  protected isOpen = false;

  protected value: string | null = '2023-07-01';

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private _onChange = (value: string) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouched = () => {};

  private _touched = false;

  protected disabled = false;

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _hapticsService: HapticsService
  ) {}

  public writeValue(value: string) {
    this.value = value;
    this._cdr.markForCheck();
  }

  public registerOnChange(onChange: (v: string) => void) {
    this._onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void) {
    this._onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  protected onTriggerClick(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  protected onDateChange({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    this._hapticsService.hapticsImpact();

    if (value === undefined) {
      return;
    }
    const [datePart] = value.split('T');
    this._touched = true;
    this.value = datePart;
    this._onChange(datePart);
    this._onTouched();
    this.isOpen = false;
  }

  protected hapticsImpact(): void {
    this._hapticsService.hapticsImpact();
  }
}
