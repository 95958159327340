import { endOfMonth, isExists } from 'date-fns';

export function setDayOfMonth(date: Date, day: number): Date {
  const year = date.getFullYear();
  const monthIndex = date.getMonth();
  if (isExists(year, monthIndex, day)) {
    return new Date(year, monthIndex, day);
  }
  return endOfMonth(new Date(year, monthIndex, 1));
}
