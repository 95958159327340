import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ActionSheetController,
  IonNav,
  LoadingController
} from '@ionic/angular';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { TaskFolderListService } from '@app/core/service/task-folder-list.service';
import { ToastService } from '@app/core/service/toast.service';
import { TaskListService } from '@app/core/service/task-list.service';

@Component({
  selector: 'app-task-folder-create',
  templateUrl: './task-folder-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFolderCreateComponent {
  @Input()
  public taskFolder: MongoStoredObject<ITaskFolder> | null = null;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _taskFolderListService: TaskFolderListService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _taskListService: TaskListService
  ) {}

  public onTaskFolderSaved(): void {
    this._ionNav.pop();
  }

  protected async onDeleteButtonClick() {
    const actionSheet = await this._actionSheetCtrl.create({
      header: `Delete ${this.taskFolder.title}?`,
      buttons: [
        {
          text: 'Yes',
          role: 'destructive'
        },
        {
          text: 'No',
          role: 'cancel'
        }
      ]
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    if (role === 'destructive') {
      const loading = await this._loadingCtrl.create({
        message: 'Loading...'
      });
      loading.present();

      this._taskFolderListService
        .deleteTaskFolder(this.taskFolder._id)
        .subscribe({
          next: () => {
            this._taskListService.update();
            this._toastService.presentToast('The folder deleted successfully');
            this._ionNav.pop();
          },
          error: () => {
            loading.dismiss();
            this._toastService.presentToast('Unable to delete the folder');
          }
        });
    }
  }
}
