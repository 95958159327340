import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ImageViewerModalComponent } from './image-viewer-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [CommonModule, IonicModule, DirectivesModule],
  exports: [ImageViewerModalComponent],
  declarations: [ImageViewerModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ImageViewerModalModule {}
