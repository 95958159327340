import { NgModule } from '@angular/core';
import { AnnouncementThreadComponent } from './announcement-thread.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WysiwygEditorComponent } from '../wysiwyg-editor/wysiwyg-editor.component';

@NgModule({
  imports: [CommonModule, IonicModule, WysiwygEditorComponent],
  exports: [AnnouncementThreadComponent],
  declarations: [AnnouncementThreadComponent]
})
export class AnnouncementThreadModule {}
