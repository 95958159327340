<ion-item
  class="group-title"
  lines="none"
  [button]="!forceOpen"
  (click)="onFolderClick()"
  [detail]="false"
  appHaptics
  [disabled]="forceOpen"
  [disableHaptics]="forceOpen"
>
  <ion-icon slot="start" class="folder-icon" name="folder-open-outline" />
  <span class="ion-text-capitalize">{{ taskFolderTitle.toLowerCase() }}</span>
  <span slot="end">{{ formattedCountText }}</span>
</ion-item>

<div *ngIf="isOpen || forceOpen" class="group-tasks">
  <app-checklist-item
    *ngFor="let item of items; let i = index; trackBy: listItemTrackBy"
    [item]="item"
    [isOverdue]="item | isOverdue : time"
    (click)="itemClick.emit(item)"
    appHaptics
    [animationOrder]="i"
  ></app-checklist-item>
</div>
