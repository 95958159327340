<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Valchemy</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="goToLogin()" appHaptics>
        <ion-icon name="log-out"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <h3 class="heading ion-text-center">Enter Passcode</h3>
      <div class="dots">
        <div
          class="dot"
          [ngClass]="pinCode.length > 0 ? 'dot--active' : ''"
        ></div>
        <div
          class="dot"
          [ngClass]="pinCode.length > 1 ? 'dot--active' : ''"
        ></div>
        <div
          class="dot"
          [ngClass]="pinCode.length > 2 ? 'dot--active' : ''"
        ></div>
        <div
          class="dot"
          [ngClass]="pinCode.length > 3 ? 'dot--active' : ''"
        ></div>
      </div>
    </div>

    <div class="middle-content">
      <ion-grid class="numpad">
        <ion-row>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('1')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">1</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('2')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">2</div>
              <div class="number-letters">ABC</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('3')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">3</div>
              <div class="number-letters">DEF</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('4')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">4</div>
              <div class="number-letters">GHI</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('5')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">5</div>
              <div class="number-letters">JKL</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('6')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">6</div>
              <div class="number-letters">MNO</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('7')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">7</div>
              <div class="number-letters">PQRS</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('8')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">8</div>
              <div class="number-letters">TUV</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('9')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">9</div>
              <div class="number-letters">WXYZ</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col></ion-col>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onNumberPress('0')"
              appHaptics
              class="number-btn"
            >
              <div class="number-digit" slot="icon-only">0</div>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button
              fill="clear"
              size="large"
              expand="full"
              (click)="onBackspacePress()"
              appHaptics
              class="number-btn"
            >
              <ion-icon slot="icon-only" name="backspace"></ion-icon>
              <ion-ripple-effect type="unbounded"></ion-ripple-effect>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col class="ion-text-center">
            <ion-button
              (click)="forgotPin()"
              appHaptics
              fill="outline"
              color="danger"
              size="small"
            >
              Forgot Passcode
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Select User"
      class="ion-page"
      [users]="users"
      (selectionChange)="onUserSelect($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>
