import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActionSheetController, IonNav } from '@ionic/angular';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskListService } from '@app/core/service/task-list.service';
import { from, of, switchMap } from 'rxjs';
import { TaskDto } from '@app/types/task-dto';
import { ToastService } from '@app/core/service/toast.service';

@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskCreateComponent {
  @Input()
  public task: MongoStoredObject<TaskDto> | null = null;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _taskListService: TaskListService,
    private readonly _toastService: ToastService
  ) {}

  public onTaskSaved(): void {
    this._ionNav.pop();
  }

  protected onDeleteButtonClick() {
    from(
      this._actionSheetCtrl.create({
        header: `Archive ${this.task.title}?`,
        buttons: [
          {
            text: 'Yes',
            role: 'destructive'
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
    )
      .pipe(
        switchMap((actionSheet) =>
          from(actionSheet.present()).pipe(
            switchMap(() => actionSheet.onDidDismiss()),
            switchMap(({ role }) => {
              if (role === 'destructive') {
                this._taskListService.deleteTask(this.task._id);
                return of(1);
              }
              return of(0);
            })
          )
        )
      )
      .subscribe({
        next: (result) => {
          if (result === 1) {
            this._ionNav.pop().then(() => this._ionNav.pop());
          }
        },
        error: () => {
          this._toastService.presentToast('Cannot archive the task');
        }
      });
  }

  protected onRestoreButtonClick() {
    from(
      this._actionSheetCtrl.create({
        header: `Unarchive ${this.task.title}?`,
        buttons: [
          {
            text: 'Yes',
            role: 'confirm'
          },
          {
            text: 'No',
            role: 'cancel'
          }
        ]
      })
    )
      .pipe(
        switchMap((actionSheet) =>
          from(actionSheet.present()).pipe(
            switchMap(() => actionSheet.onDidDismiss()),
            switchMap(({ role }) => {
              if (role === 'confirm') {
                this._taskListService.restoreTask(this.task._id);
                return of(1);
              }
              return of(0);
            })
          )
        )
      )
      .subscribe({
        next: (result) => {
          if (result === 1) {
            this._ionNav.pop().then(() => this._ionNav.pop());
          }
        },
        error: () => {
          this._toastService.presentToast('Cannot unarchive the task');
        }
      });
  }
}
