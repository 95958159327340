import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskFolderListService } from '@app/core/service/task-folder-list.service';
import { ToastService } from '@app/core/service/toast.service';
import { UserListService } from '@app/core/service/user-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ObjectId } from '@app/types/object-id';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { IonModal, LoadingController } from '@ionic/angular';
import { map } from 'rxjs';

@Component({
  selector: 'app-task-folder-form',
  templateUrl: './task-folder-form.component.html',
  styleUrls: ['./task-folder-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFolderFormComponent implements OnInit {
  @Input()
  public taskFolder: MongoStoredObject<ITaskFolder> | null = null;

  @Output()
  public taskFolderSaved = new EventEmitter<MongoStoredObject<ITaskFolder>>();

  @Output()
  public delete = new EventEmitter<void>();

  @ViewChild('notifyUsersOnAllCriticalTasksCompletedModal', { static: true })
  public notifyUsersOnAllCriticalTasksCompletedModal!: IonModal;

  protected mainFormGroup: FormGroup = new FormGroup({
    title: new FormControl<string>('', [Validators.required]),
    notifyUsersOnAllCriticalTasksCompleted: new FormControl<ObjectId[]>([]),
    emailUsersOnAllCriticalTasksCompleted: new FormControl<boolean>(false)
  });

  protected allUsers$ = this._userListService.users$.pipe(
    map((list) => list.filter(({ isDeleted }) => !isDeleted))
  );

  public constructor(
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _taskFolderListService: TaskFolderListService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _userListService: UserListService
  ) {}

  public ngOnInit(): void {
    if (this.taskFolder) {
      this.mainFormGroup.reset({
        ...this.taskFolder,
        notifyUsersOnAllCriticalTasksCompleted:
          this.taskFolder.notifyUsersOnAllCriticalTasksCompleted || [],
        emailUsersOnAllCriticalTasksCompleted:
          this.taskFolder.emailUsersOnAllCriticalTasksCompleted || false
      });
    }
  }

  protected get hasChanges() {
    if (!this.taskFolder) {
      return true;
    }

    return this.mainFormGroup.get('title').value !== this.taskFolder.title;
  }

  protected async onFormSubmit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const newValue = this.mainFormGroup.value;

    const saveOperation = this.taskFolder
      ? this._taskFolderListService.updateTaskFolder(
          this.taskFolder._id,
          newValue
        )
      : this._taskFolderListService.createTaskFolder(newValue);

    saveOperation.subscribe({
      next: (result) => {
        this.taskFolderSaved.emit(result);
      },
      error: () => {
        loading.dismiss();
        this._toastService.presentToast(
          this.taskFolder
            ? 'Unable to update the folder'
            : 'Unable to create the folder'
        );
        this._cdr.markForCheck();
      }
    });
  }

  protected onDeleteClick(): void {
    this.delete.emit();
  }

  protected get notifyUsersOnAllCriticalTasksCompleted(): ObjectId[] {
    return this.mainFormGroup.controls.notifyUsersOnAllCriticalTasksCompleted
      .value;
  }

  protected notifyUsersOnAllCriticalTasksCompletedChanged(
    selection: ObjectId[]
  ): void {
    this.notifyUsersOnAllCriticalTasksCompletedModal.dismiss();
    this.mainFormGroup.patchValue({
      notifyUsersOnAllCriticalTasksCompleted: selection
    });
    this.mainFormGroup.controls.notifyUsersOnAllCriticalTasksCompleted.markAsDirty();
    this.mainFormGroup.updateValueAndValidity();
  }
}
