<div class="avatar">
  <ng-container *ngIf="item | hasCompleted">
    <div class="diagram">
      <div class="user-name">{{ item.performances | userInitials }}</div>
    </div>
  </ng-container>
</div>
<div
  class="title ion-text-capitalize"
  [class.alert-text]="isOverdue"
  [class.muted-text]="isCompleted"
>
  <span
    [ngClass]="
      item.isCrossed ? 'title-content title-content--deleted' : 'title-content'
    "
  >
    {{ item.task.title }}
    <span *ngIf="count > 1" class="count">x{{ count }}</span>
  </span>
</div>
<div class="points">
  {{ item.task.points }}
  <div class="note" *ngIf="item.task.pointsEarns === taskPointsEarns.PER_UNIT">
    ea
  </div>
</div>
