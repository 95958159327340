import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { localizedWeekDayName } from '@app/utils/localized-week-day-name';

@Component({
  selector: 'app-schedule-weekly',
  templateUrl: './schedule-weekly.component.html',
  styleUrls: ['./schedule-weekly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleWeeklyComponent {
  @Input()
  public form!: any; // FormGroup<{ weekDays?: FormArray<FormControl<boolean>>; }>;

  protected get dayArray(): FormArray<FormControl<boolean>> {
    return this.form.controls.weekDays;
  }

  protected weekDays = [0, 1, 2, 3, 4, 5, 6].map((i) =>
    localizedWeekDayName(i, 'short').substring(0, 2)
  );
}
