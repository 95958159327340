import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-dice',
  templateUrl: './dice.component.html',
  styleUrls: ['./dice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiceComponent {
  @ViewChild('dice')
  public dice!: ElementRef<HTMLElement>;

  public angleX = 0;
  public angleY = 0;
  public delay = 0;
  public rollMax = 8;

  private getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  public roll() {
    let result = 1;

    const xTurn = 4 + this.getRandomInt(this.rollMax),
      yTurn = 4 + this.getRandomInt(this.rollMax);

    this.delay = Math.max(xTurn, yTurn) * 250;

    this.angleX += 90 * xTurn;
    this.angleY += 90 * yTurn;
    // balancing the results
    if (this.angleX % 180) {
      this.getRandomInt(3) > 1 && (this.angleX += 90);
    }

    this.dice.nativeElement.style.transform =
      'rotateX(' + this.angleX + 'deg) rotateY(' + this.angleY + 'deg)';
    this.dice.nativeElement.style.transitionDuration = this.delay + 'ms';

    const x = this.angleX % 360,
      y = this.angleY % 360;

    if (x === 0 || x === 180) {
      switch ((x + y) % 360) {
        case 0:
          result = 1;
          break;
        case 90:
          result = 5;
          break;
        case 180:
          result = 6;
          break;
        case 270:
          result = 2;
          break;
        default:
          console.error(123456);
      }
    } else if (x === 90) {
      result = 4;
    } else if (x === 270) {
      result = 3;
    }

    return result;
  }
}
