import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { ChecklistItem } from '../../types/checklist-item';
import { TaskPointsEarns } from '@backend/models/types/task';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'checklist-item'
  }
})
export class ChecklistItemComponent {
  public readonly taskPointsEarns = TaskPointsEarns;

  @Input()
  public item: ChecklistItem;

  @Input()
  public count?: number;

  @Input()
  public isOverdue?: boolean = false;

  @Input()
  public isCompleted?: boolean = false;

  @Input()
  public animationOrder?: number = -1;

  @HostBinding('style')
  public get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.checklist-item-animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  @HostBinding('class.checklist-item-critical')
  public get isCritical() {
    return this.item.task.isCritical;
  }

  public constructor(private readonly _sanitizer: DomSanitizer) {}
}
