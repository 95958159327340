<ion-button
  color="light"
  expand="block"
  (click)="onTriggerClick($event)"
  [disabled]="disabled"
  appHaptics
  [disableHaptics]="disabled"
  class="ion-no-margin"
>
  <div class="button-content-wrapper">
    <div class="label">{{ label }}</div>
    <div class="time">{{ value | formatTime : 'Select time' }}</div>
  </div>
</ion-button>

<ion-popover
  [keepContentsMounted]="true"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
  #popover
>
  <ng-template>
    <ion-datetime
      presentation="time"
      [minuteValues]="minuteValues"
      #datetime
      hourCycle="h12"
    >
      <div slot="buttons">
        <ion-buttons class="ion-justify-content-between">
          <ion-button
            color="primary"
            fill="clear"
            (click)="isOpen = false"
            appHaptics
          >
            Cancel
          </ion-button>
          <ion-button
            color="primary"
            fill="clear"
            (click)="onCalendarDoneClick()"
            appHaptics
          >
            Done
          </ion-button>
        </ion-buttons>
      </div>
    </ion-datetime>
  </ng-template>
</ion-popover>
