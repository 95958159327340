import { Duration, formatDuration } from 'date-fns';

export const formatDurationShort = (duration: Duration) => {
  return formatDuration(duration, {
    format: ['days', 'hours', 'minutes', 'seconds']
  })
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' minutes', 'm')
    .replace(' minute', 'm')
    .replace(' seconds', 's')
    .replace(' second', 's');
};

export const valueOfDuration = (duration: Duration) => {
  return Number(
    formatDuration(duration, {
      format: ['seconds']
    }).replace(/[^0-9]/g, '')
  );
};
