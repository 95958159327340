import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-external',
  templateUrl: './externalauth.component.html',
  styleUrls: ['./externalauth.component.scss']
})
export class ExternalauthComponent implements OnInit {
  userid: any;
  accesstoken: any;
  authToken: any;
  isLoading = false;

  constructor(public router: Router, private route: ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      console.log({ params });
      this.userid = params['userid'];
      this.accesstoken = params['accesstoken'];
      this.authToken = params['authToken'];
    });

    console.log('UserId', this.userid);
    console.log('accesstoken', this.accesstoken);
    console.log('authToken', this.authToken);
  }

  ngOnInit() {
    if (this.userid && this.accesstoken) {
      // ?????????????????????????????????????????????
      // this.authenticationService.???({
      //   user: this.userid,
      //   accessToken: this.accesstoken,
      //   session: null
      //   // authToken: this.authToken
      // });
      this.router.navigate(['/i/recognition']);
    } else {
      this.router.navigate(['/login']);
    }
  }

  login() {
    this.isLoading = true;
  }

  resetPassword() {
    // TODO: Implement Reset Password
  }
}
