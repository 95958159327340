export const API_ENDPOINT: any = [];

export const ERROR_OBJECTS: Record<string, string> = {
  required: '{{field}} is required',
  email: 'Please enter valid email',
  minlength: 'Too short'
};

export enum ROLES {
  adminName = 'Admin',
  managerName = 'Manager'
}
