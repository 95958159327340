import { Pipe, PipeTransform } from '@angular/core';
import { ROLES } from '@app/core';
import { UserModel } from '@app/core/model/user.model';

@Pipe({
  name: 'appNormalUsers'
})
export class NormalUsersPipe implements PipeTransform {
  public transform(allUsers: UserModel[]): UserModel[] {
    return allUsers.filter(
      ({ roles }) =>
        !roles.includes(ROLES.managerName) && !roles.includes(ROLES.adminName)
    );
  }
}
