import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';

@Pipe({
  name: 'appReceiveDailyReports'
})
export class ReceiveDailyRepotsPipe implements PipeTransform {
  public transform(allUsers: UserModel[]): ObjectId[] {
    return allUsers
      .filter(({ receiveDailyReports }) => receiveDailyReports)
      .map(({ _id }) => _id);
  }
}
