import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { IonNav } from '@ionic/angular';
import { ObjectId } from '@app/types/object-id';
import { ILotteryMachineTicket } from '@app/modules/lottery-machine/lottery-machine.component';

@Component({
  selector: 'app-lottery',
  templateUrl: './lottery.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotteryComponent implements OnInit {
  @Input()
  public readonly users: {
    _id: ObjectId;
    firstName: string;
    lastName: string;
    totalPoints: number;
  }[] = [];

  @Input()
  public readonly rangeStr: string;

  protected lotteryTickets: ILotteryMachineTicket[] = [];

  public constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _ionNav: IonNav
  ) {}

  public ngOnInit(): void {
    this.lotteryTickets = this.users.map((user) => ({
      id: user._id.toString(),
      name: `${user.firstName} ${user.lastName}`,
      tickets: user.totalPoints
    }));
    this._cdr.markForCheck();
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }
}
