<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>{{ libraryDoc ? 'Update Document' : 'Add Document' }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <ion-grid [formGroup]="mainFormGroup">
        <ion-row>
          <ion-col>
            <ion-input
              label="Title"
              class="app-w100 input-with-background"
              formControlName="title"
              labelPlacement="floating"
              fill="solid"
              appHaptics
              [spellcheck]="true"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <app-wysiwyg-editor
              [(value)]="body"
              [disableAttachments]="disableAttachments"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6" *ngIf="libraryDoc">
            <ion-button
              type="button"
              color="danger"
              expand="block"
              fill="outline"
              (click)="onDeleteClick()"
              appHaptics
            >
              Delete
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button
              color="dark"
              expand="block"
              [disabled]="mainFormGroup.invalid || !body"
              [disableHaptics]="mainFormGroup.invalid || !body"
              appHaptics
              (click)="onFormSubmit()"
            >
              {{ libraryDoc ? 'Save Revision' : 'Submit' }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>
