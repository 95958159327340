import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  ViewChild
} from '@angular/core';
import { ObjectId } from '@app/types/object-id';
import { ModalController, IonModal, IonInput } from '@ionic/angular';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import fastDeepEqual from 'fast-deep-equal';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TaskListService } from '@app/core/service/task-list.service';
import { TaskDto } from '@app/types/task-dto';
import { add, startOfDay } from 'date-fns';

export interface IEditTaskPerformanceModalResult {
  userId: string;
  dateCompleted?: Date;
  quantity?: number;
  timeAttackBonusPoints?: number;
  isDeleted?: boolean;
}

@Component({
  selector: 'app-edit-task-performance-modal',
  templateUrl: './edit-task-performance-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditTaskPerformanceModalComponent implements OnInit {
  @Input()
  public readonly taskId: ObjectId;

  @Input()
  public readonly initialData: {
    userId: ObjectId;
    dateCompleted: Date;
    quantity: number;
    isDeleted: boolean;
    timeAttackBonusPoints: number;
  };

  @Input()
  public readonly createMode?: boolean = false;

  @Input()
  public readonly showTimeAttackBonusPoints?: boolean = false;

  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  @ViewChild('quantityInput', { static: true })
  public quantityInput!: IonInput;

  @ViewChild('quantityInput', { static: true })
  public timeAttackBonusPointsInput!: IonInput;

  protected form = new FormGroup({
    userId: new FormControl<ObjectId>(null, Validators.required),
    dateCompleted: new FormControl<Date>(null, Validators.required),
    quantity: new FormControl<string>('1', Validators.required),
    timeAttackBonusPoints: new FormControl<string>('0', Validators.required)
  });
  protected allUsers: UserModel[] = [];
  protected task?: TaskDto = null;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _userListService: UserListService,
    private readonly _taskListService: TaskListService
  ) {}

  public ngOnInit(): void {
    this.form.reset({
      userId: this.initialData.userId,
      dateCompleted: this.initialData.dateCompleted,
      quantity: this.initialData.quantity.toString(),
      timeAttackBonusPoints: this.initialData.timeAttackBonusPoints.toString()
    });
    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });
    this._taskListService.list$.subscribe((tasks) => {
      this.task = tasks.find((t) => t._id === this.taskId);
    });
  }

  protected get taskTitle() {
    return this.task?.title || '';
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.form.get('userId').value];
  }

  protected get currentUser(): UserModel {
    return this.allUsers.find(
      (user) => user._id === this.form.get('userId').value
    );
  }

  protected get canSave() {
    if (this.createMode) {
      return Number(this.form.get('quantity').value);
    } else {
      return (
        this.form.get('userId').value &&
        this.form.get('dateCompleted').value &&
        Number(this.form.get('quantity').value) > 0 &&
        !fastDeepEqual(this.initialData, {
          userId: this.form.get('userId').value,
          dateCompleted: this.form.get('dateCompleted').value,
          quantity: Number(this.form.get('quantity').value),
          timeAttackBonusPoints: Number(
            this.form.get('timeAttackBonusPoints').value
          ),
          isDeleted: this.initialData.isDeleted
        })
      );
    }
  }

  protected onUserChange(event: ObjectId[]): void {
    this.form.controls.userId.setValue(event[0]);
    this.userSelectorModal.dismiss();
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'edit-task-performance-modal');
  }

  protected onDeleteClick(): void {
    const result: IEditTaskPerformanceModalResult = {
      userId: this.initialData.userId,
      dateCompleted: this.initialData.dateCompleted,
      quantity: this.initialData.quantity,
      isDeleted: true
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-task-performance-modal');
  }

  protected onRestoreClick(): void {
    const result: IEditTaskPerformanceModalResult = {
      userId: this.initialData.userId,
      dateCompleted: this.initialData.dateCompleted,
      quantity: this.initialData.quantity,
      isDeleted: false
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-task-performance-modal');
  }

  protected onSaveClick(): void {
    const result: IEditTaskPerformanceModalResult = {
      userId: this.form.get('userId').value,
      dateCompleted: add(startOfDay(this.form.get('dateCompleted').value), {
        hours: 12
      }),
      quantity: Number(this.form.get('quantity').value),
      timeAttackBonusPoints: Number(
        this.form.get('timeAttackBonusPoints').value
      ),
      isDeleted: this.initialData.isDeleted
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-task-performance-modal');
  }
}
