import { NgModule } from '@angular/core';
import { CompensationFieldModalComponent } from './compensation-field-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSelectorComponent } from '../user-selector/user-selector.component';
import { WideButtonComponent } from '../wide-button/wide-button.component';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    UserSelectorComponent,
    WideButtonComponent,
    DirectivesModule,
    UserSelectorComponent
  ],
  exports: [CompensationFieldModalComponent],
  declarations: [CompensationFieldModalComponent]
})
export class CompensationFieldModalModule {}
