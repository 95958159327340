import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  AngularEditorComponent,
  AngularEditorConfig,
  AngularEditorModule
} from '@kolkov/angular-editor';
import { AfterContentInit } from '@angular/core';

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AngularEditorModule, FormsModule]
})
export class WysiwygEditorComponent
  implements OnInit, OnChanges, AfterContentInit
{
  @Input()
  public readonly mode?: 'view' | 'edit' = 'edit';

  @Input()
  public readonly value?: string = '';

  @Input()
  public readonly bodyPlaceholder?: string = 'Body';

  @Input()
  public readonly minHeight?: string = '100px';

  @Input()
  public readonly disableAttachments?: boolean = false;

  @Input()
  public readonly toolbarMode?: 'full' | 'image-only' = 'full';

  @Input()
  public readonly dark?: boolean = false;

  @Output()
  public valueChange = new EventEmitter<string>();

  @ViewChild('wysiwygEditor', { static: true })
  public wysiwygEditor!: AngularEditorComponent;

  protected body = '';
  protected editorConfig: AngularEditorConfig = {
    editable: false,
    showToolbar: false,
    spellcheck: true,
    height: 'auto',
    maxHeight: 'auto',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'fontName',
        'toggleEditorMode',
        'backgroundColor',
        'customClasses',
        'insertVideo',
        'justifyRight',
        'justifyFull',
        'justifyLeft',
        'justifyCenter',
        'textColor',
        'strikeThrough',
        'insertHorizontalRule',
        'undo',
        'redo',
        'heading'
      ]
    ]
  };

  public ngAfterContentInit(): void {
    setTimeout(() => {
      const contenteditableDiv = document.querySelector(
        `#wysiwyg-editor .angular-editor-textarea`
      );

      contenteditableDiv.addEventListener('paste', function (e: any) {
        // cancel paste
        e.preventDefault();

        // get text representation of clipboard
        const text = (e.originalEvent || e).clipboardData.getData('text/plain');

        // insert text manually
        document.execCommand('insertHTML', false, text);
      });

      const isSafari =
        navigator.userAgent.includes('Safari/') &&
        navigator.userAgent.includes('Version/');

      if (isSafari) {
        contenteditableDiv.addEventListener(
          'keydown',
          (e: any) => {
            // bold
            if (e.metaKey) {
              if (e.keyCode === 66 || e.keyCode === 73 || e.keyCode === 85) {
                e.preventDefault();
              }

              switch (e.keyCode) {
                // bold
                case 66:
                  document.execCommand('Bold', false, '');
                  break;
                // italic
                case 73:
                  document.execCommand('Italic', false, '');
                  break;
                // underline
                case 85:
                  document.execCommand('Underline', false, '');
                  break;
              }
            }
          },
          true
        );
      }
    });
  }

  public ngOnInit(): void {
    this.editorConfig.placeholder = this.bodyPlaceholder;
    this.editorConfig.minHeight = this.minHeight;

    if (this.mode === 'edit') {
      this.editorConfig.editable = true;
      this.editorConfig.showToolbar = true;
    }

    if (this.disableAttachments) {
      this.editorConfig.toolbarHiddenButtons[0].push('insertImage');
    }

    this.wysiwygEditor.onPaste = (e) => {
      const pastedData = e.clipboardData.getData('text');

      if (!pastedData) {
        e.preventDefault();
      }

      return '';
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.value && changes.value.currentValue) {
      this.body = this.value;
    }
  }

  protected onChange(e: string) {
    this.body = e;
    this.valueChange.emit(e);
  }

  protected setFocus() {
    this.wysiwygEditor.focus();
  }

  protected get currentFontSize() {
    return this.wysiwygEditor && this.wysiwygEditor.editorToolbar
      ? this.wysiwygEditor.editorToolbar.fontSize
      : '';
  }
}
