<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{ compensationField ? 'Update Column' : 'Create Column' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<div [formGroup]="form">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-input
          label="Column Title"
          class="app-w100 input-with-background"
          formControlName="name"
          labelPlacement="floating"
          fill="solid"
          autocapitalize="on"
          [spellcheck]="true"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding">
      <ion-col size="4">
        <ion-button
          color="dark"
          expand="block"
          [disabled]="form.invalid"
          appHaptics
          [disableHaptics]="form.invalid"
          (click)="save()"
        >
          {{ compensationField ? 'Update' : 'Create' }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
