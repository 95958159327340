import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ILibraryDoc } from '@backend/models/types/library-doc';
import { LibraryDocModel } from '../model/library-doc.model';

@Injectable()
export class LibraryApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getAllLibraryDocs(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<
    {
      originalDoc: LibraryDocModel;
      versions: LibraryDocModel[];
      latestVersion: LibraryDocModel | null;
    }[]
  > {
    return this._httpClient.get<
      {
        originalDoc: LibraryDocModel;
        versions: LibraryDocModel[];
        latestVersion: LibraryDocModel | null;
      }[]
    >('/library/docs', { params: { page, limit, searchTerm } });
  }

  public getLibraryDoc(libraryDocId: string): Observable<LibraryDocModel> {
    return this._httpClient.get<LibraryDocModel>(
      `/library/docs/${libraryDocId}`
    );
  }

  public addLibraryDoc(
    libraryDoc: Pick<ILibraryDoc, 'title' | 'body' | 'attachments'>
  ): Observable<LibraryDocModel> {
    return this._httpClient.post<LibraryDocModel>('/library/docs', libraryDoc);
  }

  public updateLibraryDoc(
    libraryDocId: string,
    libraryDoc: Pick<ILibraryDoc, 'title' | 'body' | 'attachments'>
  ): Observable<LibraryDocModel> {
    return this._httpClient.put<LibraryDocModel>(
      `/library/docs/${libraryDocId}`,
      libraryDoc
    );
  }

  public saveAsNewVersion(
    libraryDocId: string,
    libraryDoc: Pick<ILibraryDoc, 'title' | 'body' | 'attachments'>
  ): Observable<LibraryDocModel> {
    return this._httpClient.post<LibraryDocModel>(
      `/library/docs/${libraryDocId}/save-as-new-version`,
      libraryDoc
    );
  }

  public softDeleteLibraryDoc(libraryDocId: string) {
    return this._httpClient.delete(`/library/docs/${libraryDocId}`);
  }

  public signLibraryDoc(libraryDocId: string): Observable<LibraryDocModel> {
    return this._httpClient.post<LibraryDocModel>(
      `/library/docs/${libraryDocId}/sign`,
      {}
    );
  }

  public requestUsersToSignLibraryDoc(libraryDocId: string, users: string[]) {
    return this._httpClient.post(
      `/library/docs/${libraryDocId}/request-to-sign`,
      { users }
    );
  }
}
