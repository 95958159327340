import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { EUserScheduleType } from '@backend/models/types/user';

export const defaultScheduleFormGroup = {
  scheduleType: new FormControl<string>(
    EUserScheduleType.FIXED,
    Validators.required
  ),
  weekDays: new FormArray<FormControl<boolean>>(
    [
      new FormControl<boolean>(false),
      new FormControl<boolean>(true),
      new FormControl<boolean>(true),
      new FormControl<boolean>(true),
      new FormControl<boolean>(true),
      new FormControl<boolean>(true),
      new FormControl<boolean>(false)
    ],
    Validators.required
  )
};

export const defaultWeekTimeFormGroup = {
  0: new FormGroup<{
    from: FormControl<string>;
    to: FormControl<string>;
  }>({
    from: new FormControl('8:00'),
    to: new FormControl('16:30')
  }),
  1: new FormGroup<{
    from: FormControl<string>;
    to: FormControl<string>;
  }>({
    from: new FormControl('8:00'),
    to: new FormControl('16:30')
  }),
  2: new FormGroup<{
    from: FormControl<string>;
    to: FormControl<string>;
  }>({
    from: new FormControl('8:00'),
    to: new FormControl('16:30')
  }),
  3: new FormGroup<{
    from: FormControl<string>;
    to: FormControl<string>;
  }>({
    from: new FormControl('8:00'),
    to: new FormControl('16:30')
  }),
  4: new FormGroup<{
    from: FormControl<string>;
    to: FormControl<string>;
  }>({
    from: new FormControl('8:00'),
    to: new FormControl('16:30')
  }),
  5: new FormGroup<{
    from: FormControl<string>;
    to: FormControl<string>;
  }>({
    from: new FormControl('8:00'),
    to: new FormControl('16:30')
  }),
  6: new FormGroup<{
    from: FormControl<string>;
    to: FormControl<string>;
  }>({
    from: new FormControl('8:00'),
    to: new FormControl('16:30')
  })
};
