import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { CheckTaskCriteriaModalComponent } from './check-task-criteria-modal.component';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';
import { WysiwygEditorComponent } from '../wysiwyg-editor/wysiwyg-editor.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    WidthRestrictedContainerModule,
    WysiwygEditorComponent
  ],
  exports: [CheckTaskCriteriaModalComponent],
  declarations: [CheckTaskCriteriaModalComponent]
})
export class CheckTaskCriteriaModalModule {}
