import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication';
import { ObjectId } from '../../types/object-id';
import { BehaviorModel } from '../model/behavior.model';
import { ROLES } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class BehaviorApiService {
  private _tenantId: ObjectId;

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _authenticationService: AuthenticationService
  ) {
    this._tenantId = this._authenticationService.user.tenant;
  }

  public getAllBehaviors(): Observable<BehaviorModel[]> {
    return this._httpClient.get<BehaviorModel[]>(
      `/tenants/${this._tenantId}/behaviors`
    );
  }

  public getNonSysGenBehaviors(): Observable<BehaviorModel[]> {
    return this._httpClient.get<BehaviorModel[]>(
      `/tenants/${this._tenantId}/non-sys-gen-behaviors`
    );
  }

  public getValueBehaviors(): Observable<BehaviorModel[]> {
    return this._httpClient.get<BehaviorModel[]>(
      `/tenants/${this._tenantId}/ValueBehaviors`
    );
  }

  public getBehaviorById(id: any): Observable<any> {
    return this._httpClient.get(`/tenants/${this._tenantId}/behavior/${id}`);
  }

  public createBehavior(body: {
    name: string;
    points: number;
    isValue: boolean;
    description: boolean;
    eligible: ROLES[];
  }): Observable<any> {
    const option = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    const data = {
      ...body,
      tenantId: this._tenantId
    };
    return this._httpClient.post(`/behaviors`, JSON.stringify(data), option);
  }

  public updateBehavior(
    id: string,
    body: {
      name: string;
      points: number;
      isValue: boolean;
      description: string;
      eligible: ROLES[];
    }
  ): Observable<any> {
    const option = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this._httpClient.patch(
      `/tenants/${this._tenantId}/behavior/${id}`,
      JSON.stringify(body),
      option
    );
  }

  public deleteBehavior(id: string): Observable<any> {
    return this._httpClient.delete(`/behavior/${id}`);
  }

  public moveBehavior(from: number, to: number): Observable<any> {
    return this._httpClient.patch(
      `/tenants/${this._tenantId}/behavior/move/${from}/${to}`,
      {}
    );
  }
}
