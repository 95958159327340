import { ChecklistItem } from '@app/types/checklist-item';
import { DateInterval } from '@backend/types/date-interval';
import { createChecklistItemId } from '@app/utils/create-checklist-item-id';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskDto } from '@app/types/task-dto';
import { endOfDay, isBefore, startOfDay } from 'date-fns';

export abstract class TaskRenderer {
  public constructor(
    protected readonly task: MongoStoredObject<TaskDto>,
    protected readonly allowTasksToBeDisplayedOnlyDuringCertainHours: boolean
  ) {}

  public render(
    interval: DateInterval,
    extraAppearDate: string
  ): ChecklistItem[] {
    const matchingIntervals = this.createMatchingIntervals(interval);

    if (
      extraAppearDate &&
      !isBefore(endOfDay(new Date(extraAppearDate)), new Date())
    ) {
      matchingIntervals.push({
        start: startOfDay(new Date(extraAppearDate)),
        end: endOfDay(new Date(extraAppearDate))
      });
    }

    const checklistItems = matchingIntervals.map(
      (itemInterval) =>
        new ChecklistItem(
          createChecklistItemId(this.task._id, itemInterval.start),
          this.task,
          itemInterval
        )
    );
    return checklistItems;
  }

  protected abstract createMatchingIntervals(
    interval: DateInterval
  ): DateInterval[];
}
