import {
  Directive,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  NgZone,
  HostListener
} from '@angular/core';
import { HapticsService } from '@app/core/service/haptics.service';
import { ImpactStyle } from '@capacitor/haptics';
import { GestureController } from '@ionic/angular';

@Directive({
  selector: '[appHaptics]'
})
export class HapticsDirective implements AfterViewInit {
  @Input()
  public disableHaptics: boolean;

  @Input()
  public enableLongPress: boolean;

  @Input()
  public longPressDelay = 300;

  @Output()
  public tap = new EventEmitter();

  @Output()
  public longPress = new EventEmitter();

  @HostListener('click')
  protected touchstart(): void {
    if (!this.disableHaptics) {
      this._hapticsService.hapticsImpact();
    }
  }

  action: any; //not stacking actions

  private positions = {
    start: {
      x: undefined as number,
      y: undefined as number
    },
    current: {
      x: undefined as number,
      y: undefined as number
    }
  };
  private longPressActive = false;

  public constructor(
    private readonly _hapticsService: HapticsService,
    private el: ElementRef,
    private gestureCtrl: GestureController,
    private zone: NgZone
  ) {}

  public ngAfterViewInit() {
    this.loadLongPressOnElement();
  }

  private loadLongPressOnElement() {
    const gesture = this.gestureCtrl.create({
      el: this.el.nativeElement,
      threshold: 0,
      gestureName: 'long-press',
      onStart: (ev) => {
        this.longPressActive = true;
        this.longPressAction();

        this.positions = {
          start: { x: ev.startX, y: ev.startY },
          current: { x: ev.currentX, y: ev.currentY }
        };
      },
      onMove: (ev) => {
        this.positions.current = { x: ev.currentX, y: ev.currentY };
      },
      onEnd: (_ev) => {
        this.longPressActive = false;
      }
    });
    gesture.enable(true);
  }

  private longPressAction() {
    if (!this.disableHaptics) {
      if (this.action) {
        clearInterval(this.action);
      }

      this.action = setTimeout(() => {
        this.zone.run(() => {
          // Check distance
          const xDistance = Math.abs(
            this.positions.start.x - this.positions.current.x
          );
          const yDistance = Math.abs(
            this.positions.start.y - this.positions.current.y
          );
          if (xDistance > 15 || yDistance > 15)
            // User dragged finger
            return;

          if (!this.disableHaptics) {
            if (this.longPressActive === true) {
              this.longPressActive = false;
              if (this.enableLongPress) {
                this.longPress.emit();
                this._hapticsService.hapticsImpact(ImpactStyle.Medium);
              }
            } else {
              this.tap.emit();
            }
          } else if (this.longPressActive === true) {
            this.longPressActive = false;
          }
        });
      }, this.longPressDelay);
    }
  }
}
