import { Pipe, PipeTransform } from '@angular/core';
import { format, parse } from 'date-fns';

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
  public transform(value: string, placeholder: string): string {
    if (!value) {
      return placeholder;
    }
    return format(parse(value, 'HH:mm', new Date()), 'p');
  }
}
