import { TaskPointsEarns } from '@backend/models/types/task';
import { DateInterval } from '@backend/types/date-interval';
import { MongoStoredObject } from './mongo-stored-object';
import { TaskPerformanceStatus } from '@backend/models/types/task-performance';
import { PerformanceWithUser } from './performance-with-user';
import { TaskDto } from './task-dto';

export class ChecklistItem {
  public performances: PerformanceWithUser[] = [];
  public constructor(
    public id: string, // used to identify same object when rendering dom elements
    public task: MongoStoredObject<TaskDto>,
    public interval: DateInterval,
    public isCrossed?: boolean
  ) {}

  public isCompleted(): boolean {
    if (this.task.pointsEarns === TaskPointsEarns.PER_COMPLETION) {
      return this.performances.some(
        (perf) => perf.status === TaskPerformanceStatus.COMPLETE
      );
    }
    // PER_UNIT tasks considered not completed always
    return false;
  }

  public isCompletedAtLeastOnce() {
    return this.performances.some(
      (perf) => perf.status === TaskPerformanceStatus.COMPLETE
    );
  }

  public cloneWithoutPerformances(): ChecklistItem {
    return new ChecklistItem(this.id, this.task, this.interval);
  }
}

export interface ChecklistItemWithCount {
  item: ChecklistItem;
  count: number;
}

export interface ChecklistWithTime {
  checklist: ChecklistItem[];
  time: Date;
}
