import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';

@Pipe({
  name: 'appAllowedInsights'
})
export class AllowedInsightsPipe implements PipeTransform {
  public transform(allUsers: UserModel[]): ObjectId[] {
    return allUsers
      .filter(({ isInsightsHidden }) => !isInsightsHidden)
      .map(({ _id }) => _id);
  }
}
