import { NgModule } from '@angular/core';
import { EditRecognitionModalComponent } from './edit-recognition-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateSelectorModule } from '@app/modules/date-selector/date-selector.module';
import { UserSelectorComponent } from '../user-selector/user-selector.component';
import { WideButtonComponent } from '../wide-button/wide-button.component';
import { BehaviorSelectorComponent } from '../behavior-selector/behavior-selector.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DateSelectorModule,
    UserSelectorComponent,
    WideButtonComponent,
    BehaviorSelectorComponent,
    DirectivesModule,
    WidthRestrictedContainerModule
  ],
  exports: [EditRecognitionModalComponent],
  declarations: [EditRecognitionModalComponent]
})
export class EditRecognitionModalModule {}
