export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function base64Resize(
  sourceBase64: string,
  maxWidthHeightSize: number,
  callBack: (newBase64: string) => void
) {
  const img = document.createElement('img');
  img.setAttribute('src', sourceBase64);

  img.onload = () => {
    const aspectRatio = img.width / img.height;

    const newWidth =
      aspectRatio > 1 ? maxWidthHeightSize : maxWidthHeightSize * aspectRatio;
    const newHeight =
      aspectRatio > 1 ? maxWidthHeightSize / aspectRatio : maxWidthHeightSize;

    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, newWidth, newHeight);

    const newBase64 = canvas.toDataURL('image/jpeg');

    callBack(newBase64);
  };
}
