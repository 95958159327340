import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Announcement } from './announcement';

@Injectable()
export class AnnouncementApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getUnacknowledgedCount(): Observable<{
    unacknowledgedCount: number;
  }> {
    return this._httpClient.get<{ unacknowledgedCount: number }>(
      '/announcements/unacknowledged-count'
    );
  }

  public getCriticalUnacknowledgedAnnouncements(): Observable<{
    items: Announcement[];
    count: number;
    totalCount: number;
  }> {
    return this._httpClient.get<{
      items: Announcement[];
      count: number;
      totalCount: number;
    }>('/announcements/critical-unacknowledged');
  }

  public getAllAnnouncements(
    page: number,
    limit: number,
    filters: {
      showRewardReports: boolean;
    },
    searchTerm?: string
  ): Observable<Announcement[]> {
    return this._httpClient.get<Announcement[]>('/announcements', {
      params: {
        page,
        limit,
        ...filters,
        searchTerm
      }
    });
  }

  public getAnnouncementById(id: string): Observable<Announcement> {
    return this._httpClient.get<Announcement>(`/announcements/${id}`).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  public publishAnnouncement(data: {
    title: string;
    body: string;
    recipients: string[];
    attachments: string[];
    isCritical: boolean;
  }) {
    return this._httpClient
      .post('/announcements', {
        title: data.title,
        body: data.body,
        recipients: data.recipients.map((recipientId) => {
          return {
            id: recipientId
          };
        }),
        attachments: data.attachments,
        isCritical: data.isCritical
      })
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  public editAnnouncement(
    id: string,
    data: {
      title: string;
      body: string;
      isCritical: boolean;
      recipients: string[];
      attachments: string[];
    },
    keepStats?: boolean
  ) {
    return this._httpClient
      .patch(`/announcements/${id}`, {
        title: data.title,
        body: data.body,
        recipients: data.recipients.map((recipientId) => {
          return {
            id: recipientId
          };
        }),
        attachments: data.attachments,
        keepStats,
        isCritical: data.isCritical
      })
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  public readAnnouncement(announcementId: string) {
    return this._httpClient
      .post(`/announcements/${announcementId}/read`, {})
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  public acknowledgeAnnouncement(announcementId: string) {
    return this._httpClient
      .post(`/announcements/${announcementId}/acknowledge`, {})
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  public deleteAnnouncement(announcementId: string) {
    return this._httpClient.delete(`/announcements/${announcementId}`).pipe(
      tap((res) => {
        return res;
      })
    );
  }

  public respondToAnnouncement(
    announcementId: string,
    data: { body: string }
  ): Observable<Announcement> {
    return this._httpClient
      .post<Announcement>(`/announcements/${announcementId}/respond`, data)
      .pipe(
        tap((res) => {
          return res;
        })
      );
  }

  public getThreadOfAnnouncement(
    announcementId: string
  ): Observable<Announcement[]> {
    return this._httpClient.get<Announcement[]>(
      `/announcements/${announcementId}/thread`
    );
  }
}
