import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-backdoor-info-link-modal',
  templateUrl: './add-backdoor-info-link-modal.component.html'
})
export class AddBackdoorInfoLinkModalComponent {
  protected mainFormGroup: FormGroup = new FormGroup({
    text: new FormControl<string>('', Validators.required),
    url: new FormControl<string>('', Validators.required)
  });

  public constructor(private readonly _modalCtrl: ModalController) {}

  protected onSaveClick(): void {
    this._modalCtrl.dismiss(
      this.mainFormGroup.value,
      'confirm',
      'add-backdoor-info-link-modal'
    );
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'add-backdoor-info-link-modal');
  }
}
