import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalSettingsService } from '@app/core/service/global-settings.service';
import { UserModel } from '@app/core/model/user.model';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalSettingsComponent {
  protected isLoading = true;
  protected mainFormGroup: FormGroup = new FormGroup({
    instantFeedback: new FormGroup({
      email: new FormControl<string>('', Validators.required),
      inactivityTime: new FormControl<string>('0', Validators.required)
    }),
    psychologicalSurvey: new FormGroup({
      emailForSuggestedOptions: new FormControl<string>('', Validators.required)
    }),
    aiPrompts: new FormGroup({
      suggestionTitle: new FormControl<string>('', Validators.required)
    })
  });
  protected superAdmins: Pick<UserModel, 'firstName' | 'lastName'>[] = [];

  public constructor(
    private readonly _loadingCtrl: LoadingController,
    private readonly _globalSettingsService: GlobalSettingsService
  ) {
    this._globalSettingsService.globalSettings$.subscribe((globalSettings) => {
      this.superAdmins = globalSettings.superAdmins;
      this.mainFormGroup.reset({
        ...globalSettings,
        instantFeedback: {
          ...globalSettings.instantFeedback,
          inactivityTime:
            globalSettings.instantFeedback.inactivityTime.toString()
        }
      });
    });
  }

  protected get canSave() {
    return !this.mainFormGroup.invalid;
  }

  protected async save() {
    const loading = await this._loadingCtrl.create({
      message: 'Saving...'
    });
    loading.present();

    const globalSettings = this.mainFormGroup.value;

    this._globalSettingsService
      .updateGlobalSettings({
        ...globalSettings,
        instantFeedback: {
          ...globalSettings.instantFeedback,
          inactivityTime:
            Number(globalSettings.instantFeedback.inactivityTime) || 0
        }
      })
      .subscribe(() => {
        loading.dismiss();
      });
  }
}
