<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> Values </ion-title>
    <ion-buttons slot="end" *ngIf="isAdmin">
      <ion-button appHaptics (click)="onSettingsClick()">
        <ion-icon slot="icon-only" name="settings-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="ion-padding">
    <app-values-accordion />
  </app-width-restricted-container>
</ion-content>
