import {
  Input,
  Component,
  ChangeDetectionStrategy,
  HostBinding,
  AfterViewInit,
  ChangeDetectorRef
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-feedback-single-bar',
  templateUrl: './feedback-single-bar.component.html',
  styleUrls: ['./feedback-single-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeedbackSingleBarComponent implements AfterViewInit {
  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  @Input()
  public readonly name: string;

  @Input()
  public readonly value: number;

  @Input()
  public readonly maxValue: number;

  @Input()
  public animationOrder?: number = -1;

  protected isMounted = false;

  public constructor(
    private readonly _sanitizer: DomSanitizer,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.isMounted = true;
      this._cdr.markForCheck();
    }, this.animationOrder * 50 + 250);
  }

  protected get val() {
    return this.maxValue ? `${(this.value / this.maxValue) * 100}%` : '0%';
  }
}
