import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { IonPopover } from '@ionic/angular';
import { extractDayPart } from '../../../../utils/extract-day-part';
import { DateFlags } from '../../types/date-flags.enum';
import { ScheduleFormBaseComponent } from '../shcedule-form-base.component';

@Component({
  selector: 'app-schedule-monthly',
  templateUrl: './schedule-monthly.component.html',
  styleUrls: ['./schedule-monthly.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleMonthlyComponent extends ScheduleFormBaseComponent {
  @ViewChild('popover')
  protected popover: IonPopover;
  protected isOpen = false;
  protected daysOfMonth: string[] = [];

  protected onDateButtonClick(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  protected onDoneClick(): void {
    this.daysOfMonth.forEach((dayOfMonth) => {
      this._addDayToList(dayOfMonth);
    });
    this.isOpen = false;
  }

  protected onPopovedDismiss(): void {
    this.isOpen = false;
    this.daysOfMonth = [];
  }

  private _addDayToList(dayOfMonth: string): void {
    if (this.form.controls.daysOfMonth.value.some((v) => v === dayOfMonth)) {
      return;
    }
    let i = 0;
    const day = extractDayPart(dayOfMonth);
    const values = this.form.controls.daysOfMonth.value.map((v) =>
      extractDayPart(v)
    );
    if (day === DateFlags.LAST_DAY) {
      i = values.length;
    } else {
      for (; i < values.length; i++) {
        if (values[i] === DateFlags.LAST_DAY) {
          break;
        }
        if (Number.parseInt(day) <= Number.parseInt(values[i])) {
          break;
        }
      }
    }
    this.form.controls.daysOfMonth.insert(
      i,
      new FormControl<string>(dayOfMonth)
    );
    this.form.controls.daysOfMonth.markAsDirty();
  }
}
