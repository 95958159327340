import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Announcement } from '@app/core/service/announcement';

@Component({
  selector: 'app-announcement-item',
  templateUrl: './announcement-item.component.html',
  styleUrls: ['./announcement-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementItemComponent implements OnInit {
  @Input()
  public readonly announcement: Announcement;

  @Input()
  public animationOrder?: number = -1;

  @Input()
  public showViewCount?: boolean = false;

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  @HostBinding('class.unacknowledged')
  public get unacknowledgedClass() {
    return this.announcement.unacknowledged;
  }

  protected prefix = 'Announcement';

  public constructor(private readonly _sanitizer: DomSanitizer) {}

  public ngOnInit(): void {
    if (this.announcement.title.startsWith('Message from')) {
      this.prefix = '';
    }
  }

  protected get acknowledged() {
    return this.announcement.recipients.filter((a) => a.acknowledged);
  }
}
