import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IInstantFeedback } from '@backend/models/types/instant-feedback';
import { Observable } from 'rxjs';

@Injectable()
export class InstantFeedbackApiService {
  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _router: Router
  ) {}

  public getInstantFeedbacksPaginated(
    page: number,
    pageSize: number
  ): Observable<MongoStoredObject<IInstantFeedback>[]> {
    return this._httpClient.get<MongoStoredObject<IInstantFeedback>[]>(
      `/instant-feedback?page=${page}&limit=${pageSize}`
    );
  }

  public sendInstantFeedback(message: string) {
    const appUrl = this._router.url;

    return this._httpClient.post('/instant-feedback', { message, appUrl });
  }

  public getUnreadCount(): Observable<{
    unreadCount: number;
  }> {
    return this._httpClient.get<{ unreadCount: number }>(
      '/instant-feedback/unread-count'
    );
  }

  public readInstantFeedback(instantFeedbackId: string) {
    return this._httpClient.post(
      `/instant-feedback/${instantFeedbackId}/read`,
      {}
    );
  }
}
