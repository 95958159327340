import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { BreadcrumbComponent } from './breadcrumb';
import { SpinnerComponent } from './spinner.component';
import { FullComponent } from './layouts/full/full.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from '@app/authentication/auth-interceptor';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    IonicModule,
    DirectivesModule
  ],
  declarations: [SpinnerComponent, BreadcrumbComponent, FullComponent],
  exports: [SpinnerComponent, BreadcrumbComponent, FullComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class SharedModule {}
