import { Directive, HostListener, Input } from '@angular/core';
import { IonMenu } from '@ionic/angular';

@Directive({ selector: '[appMenuClose]' })
export class AppMenuCloseDirective {
  @Input()
  public appMenuClose: IonMenu;

  @HostListener('click')
  protected onClick(): void {
    this.appMenuClose.close();
  }
}
