import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AuthenticationService } from '@app/core';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  IPositiveFeedbackParams,
  IPsychologicalSurveyQuestion
} from '@backend/models/types/psychological-survey-question';
import { IPositiveFeedbackResult } from '@backend/models/types/psychological-survey-answer';
import { ActionSheetController, IonModal, IonTextarea } from '@ionic/angular';
import { ObjectId } from '@app/types/object-id';
import { PsychologicalSurveyApiService } from '@app/core/service/psychological-survey-api.service';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';

@Component({
  selector: 'app-positive-feedback-question',
  templateUrl: './positive-feedback-question.component.html',
  styleUrls: ['./positive-feedback-question.component.scss']
})
export class PositiveFeedbackQuestionComponent implements OnInit {
  @Input()
  public readonly cancelButton: 'menu' | 'cancel' = 'menu';

  @Input()
  public readonly survey: MongoStoredObject<IPsychologicalSurvey>;

  @Input()
  public readonly question: MongoStoredObject<
    IPsychologicalSurveyQuestion & { params: IPositiveFeedbackParams }
  >;

  @Input()
  public readonly previousAnswer?: IPositiveFeedbackResult = null;

  @Input()
  public readonly questionTexting: 'TODAY' | 'LAST_SHIFT' = 'TODAY';

  @Input()
  public readonly initialAction: 'SELECT_USER' | 'SKIP_QUESTION' | 'NONE' =
    'NONE';

  @Output()
  public completed = new EventEmitter<{
    questionId: string;
    result: IPositiveFeedbackResult;
  }>();

  @Output()
  public goBack = new EventEmitter<void>();

  @Output()
  public cancelClicked = new EventEmitter<void>();

  @Output()
  public initialActionDone = new EventEmitter<void>();

  @ViewChild('selectIndividualsModal', { static: true })
  public selectIndividualsModal!: IonModal;

  @ViewChild('commentTextarea')
  public commentTextarea!: IonTextarea;

  @ViewChild('suggestionTextarea')
  public suggestionTextarea!: IonTextarea;

  protected allUsers: UserModel[] = [];
  protected result: IPositiveFeedbackResult = { usersFeedback: [] };
  protected selectedUser: UserModel | null = null;
  public selectedBehaviors: {
    id: ObjectId;
    name: string;
    checked: boolean;
  }[] = [];
  protected isRecognitionScreen = false;
  protected comment = '';
  protected isPrivateComment = true;
  protected isSuggestedOptionChecked = false;
  protected suggestedOption = '';

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _userListService: UserListService,
    private readonly _psychologicaSurveyApiService: PsychologicalSurveyApiService,
    private readonly _actionSheetCtrl: ActionSheetController
  ) {}

  public ngOnInit(): void {
    if (this.previousAnswer) {
      console.log(this.previousAnswer);
      this.result = this.previousAnswer;
    }

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users.filter(
        (user) => user._id.toString() !== this._authenticationService.user._id
      );

      if (this.initialAction === 'SELECT_USER') {
        this.selectIndividuals();
      } else if (this.initialAction === 'SKIP_QUESTION') {
        this.skipQuestion();
      }
      this.initialActionDone.emit();
    });

    this._psychologicaSurveyApiService
      .getPsychologicalSurveyBehaviors(this.survey._id)
      .subscribe((behaviors) => {
        this.selectedBehaviors = behaviors.map((behavior) => ({
          id: behavior._id,
          name: behavior.pastTense,
          checked: false
        }));
      });
  }

  protected get canGoToRecognitionScreen() {
    const hasCheckedBehaviors = !!this.selectedBehaviors.filter(
      (behavior) => behavior.checked
    ).length;

    return this.isSuggestedOptionChecked
      ? !!this.suggestedOption
      : hasCheckedBehaviors;
  }

  protected get hiddenUsers() {
    return this.result.usersFeedback.map((user) => user.userId);
  }

  protected get behaviorsSelectorTitle() {
    return this.question
      ? this.question.params.behaviorsSelectorTitle.replace(
          '${firstName}',
          `<b>${this.selectedUser.firstName}</b>`
        )
      : '';
  }

  protected get recognitionTitleHtml() {
    return this.question
      ? this.question.params.recognitionTitleHtml.replace(
          '${firstName}',
          this.selectedUser.firstName
        )
      : '';
  }

  protected skipQuestion() {
    this.completed.emit({
      questionId: this.question._id,
      result: {
        usersFeedback: []
      }
    });
  }

  protected completeQuestion() {
    this.completed.emit({
      questionId: this.question._id,
      result: this.result
    });
  }

  protected getUserName(userId: string) {
    const user = this.allUsers.find((user) => userId === user._id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected getUserInitials(userId: string) {
    const user = this.allUsers.find((user) => userId === user._id);
    return `${user.firstName ? user.firstName[0] : ''}${
      user.lastName ? user.lastName[0] : ''
    }`;
  }

  protected editUser(userId: string) {
    const user = this.result.usersFeedback.find(
      (generalFeedback) => generalFeedback.userId === userId
    );

    this.selectedBehaviors = this.selectedBehaviors.map((behavior) => ({
      ...behavior,
      checked: user.surveyBehaviors.includes(behavior.id)
    }));
    this.comment = user.comment;
    this.isPrivateComment = user.isPrivateComment;
    this.isSuggestedOptionChecked = !!user.suggestedBehavior;
    this.suggestedOption = user.suggestedBehavior;

    this.selectedUser = this.allUsers.find((user) => user._id === userId);
    this.isRecognitionScreen = false;
  }

  protected async removeUser(event: any, userId: string) {
    event.stopPropagation();

    const user = this.allUsers.find((user) => user._id === userId);

    const actionSheet = await this._actionSheetCtrl.create({
      header: `Are you sure you want to delete the details for ${user.firstName}?`,
      buttons: [
        {
          text: 'Delete',
          role: 'destructive'
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    if (role === 'destructive') {
      this.result.usersFeedback = this.result.usersFeedback.filter(
        (user) => user.userId !== userId
      );
    }
  }

  protected onUserSelect(value: ObjectId[]): void {
    if (value.length) {
      this.selectedUser = this.allUsers.find((user) => user._id === value[0]);
      this.isRecognitionScreen = false;
    }

    this.selectIndividualsModal.dismiss();
  }

  protected showRecognition() {
    if (!this.comment) {
      this.comment = this.selectedBehaviors
        .filter((b) => b.checked)
        .reduce((prev, b) => `${prev}${b.name}\n`, '');
    }
    this.isRecognitionScreen = true;
  }

  protected submitUser() {
    const userExists = this.result.usersFeedback.find(
      (user) => user.userId === this.selectedUser._id
    );

    if (userExists) {
      userExists.surveyBehaviors = this.selectedBehaviors
        .filter((behavior) => behavior.checked)
        .map((behavior) => behavior.id);
      userExists.comment = this.comment;
      userExists.isPrivateComment = this.isPrivateComment;
      userExists.suggestedBehavior = this.isSuggestedOptionChecked
        ? this.suggestedOption
        : '';
    } else {
      this.result.usersFeedback.push({
        userId: this.selectedUser._id,
        surveyBehaviors: this.selectedBehaviors
          .filter((behavior) => behavior.checked)
          .map((behavior) => behavior.id),
        comment: this.comment,
        isPrivateComment: this.isPrivateComment,
        suggestedBehavior: this.isSuggestedOptionChecked
          ? this.suggestedOption
          : ''
      });
    }

    this._clearDetails();
  }

  private _clearDetails() {
    this.selectedUser = null;
    this.isRecognitionScreen = false;
    this.selectedBehaviors = this.selectedBehaviors.map((behavior) => ({
      ...behavior,
      checked: false
    }));
    this.comment = '';
    this.isPrivateComment = false;
    this.isSuggestedOptionChecked = false;
    this.suggestedOption = '';
  }

  protected trackBehaviors(
    _: number,
    item: {
      id: ObjectId;
      name: string;
      checked: boolean;
    }
  ) {
    return item.id;
  }

  protected checkboxClick(event: any, value: ObjectId) {
    event.preventDefault();

    this.selectedBehaviors = this.selectedBehaviors.map((item) => ({
      ...item,
      checked: item.id === value ? !item.checked : item.checked
    }));
  }

  protected suggestedOptionCheckboxClick(event: any) {
    event.preventDefault();

    this.isSuggestedOptionChecked = !this.isSuggestedOptionChecked;

    if (this.isSuggestedOptionChecked) {
      setTimeout(() => {
        this.suggestionTextarea.setFocus();
      });
    }
  }

  protected onSuggestedOptionChange(event: any) {
    this.suggestedOption = event.detail.value;
  }

  protected onSuggestionTextareaKeyPress(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.suggestionTextarea
        .getInputElement()
        .then((textarea) => textarea.blur());
    }
  }

  protected onCommentChange(event: any) {
    this.comment = event.detail.value;
  }

  protected onCommentTextareaKeyPress(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.commentTextarea
        .getInputElement()
        .then((textarea) => textarea.blur());
    }
  }

  protected selectIndividuals() {
    this.selectIndividualsModal.present();
  }

  protected onBackButtonClick() {
    if (this.selectedUser) {
      if (this.isRecognitionScreen) {
        this.isRecognitionScreen = false;
      } else {
        this._clearDetails();
      }
    } else {
      this.goBack.emit();
    }
  }

  protected get privateInfo() {
    if (this.isPrivateComment) {
      return {
        description: `Only visible to you, ${this.selectedUser.firstName}, and administrators.`
      };
    } else {
      return {
        description: 'Visible to everyone in your company.'
      };
    }
  }

  protected onCancelClick() {
    this.cancelClicked.emit();
  }
}
