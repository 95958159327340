import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['../login/login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent {
  @ViewChild('password1Input', { read: ElementRef })
  public password1Input: ElementRef<HTMLInputElement>;

  @ViewChild('password2Input', { read: ElementRef })
  public password2Input: ElementRef<HTMLInputElement>;

  resetPasswordForm: FormGroup;
  userFullName: string;
  userId: string;
  token: string;
  email: string;
  protected prevValue: { password1: string; password2: string } = {
    password1: '',
    password2: ''
  };

  public constructor(
    private readonly _router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController
  ) {
    this.route.queryParams.subscribe((params) => {
      if (params['user'] && params['token'] && params['email']) {
        this.userId = params['user'];
        this.token = params['token'];
        this.email = params['email'];
      } else {
        this._router.navigate(['/login'], { replaceUrl: true });
      }
    });
    this.createForm();
  }

  protected checkPasswords(group: FormGroup) {
    const { password1, password2 } = group.value;

    return password1 === password2
      ? null
      : { notSame: 'Passwords do not match' };
  }

  protected async submit(password1Value?: string, password2Value?: string) {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const updatedUser = {
      password:
        password1Value ||
        password2Value ||
        this.resetPasswordForm.value.password2,
      token: this.token
    };

    this.authenticationService
      .setNewPassword(this.userId, updatedUser)
      .pipe(
        finalize(() => {
          loading.dismiss();
          this.resetPasswordForm.markAsPristine();
          this._cdr.markForCheck();
        })
      )
      .subscribe({
        next: () => {
          loading.dismiss();
          this._toastService.presentToast('Password has been changed.');
          this._router.navigate(['/login']);
        },
        error: ({ error }) => {
          loading.dismiss();
          this._toastService.presentToast(
            error.message || 'Something went wrong'
          );
          this._cdr.markForCheck();
        }
      });
  }

  private createForm() {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password1: ['', Validators.required],
        password2: ['', Validators.required]
      },
      {
        validators: this.checkPasswords
      }
    );
  }

  protected goToLogin() {
    this._router.navigate(['/login']);
  }

  protected onInput() {
    setTimeout(() => {
      if (
        this.prevValue.password1 !== this.password1Input.nativeElement.value &&
        this.prevValue.password2 !== this.password2Input.nativeElement.value
      ) {
        if (
          this.password1Input.nativeElement.value &&
          this.password2Input.nativeElement.value
        ) {
          this.submit(
            this.password1Input.nativeElement.value,
            this.password2Input.nativeElement.value
          );
        }
      }

      this.resetPasswordForm.controls.password1.setValue(
        this.password1Input.nativeElement.value
      );
      this.resetPasswordForm.controls.password2.setValue(
        this.password2Input.nativeElement.value
      );

      this.prevValue = {
        password1: this.password1Input.nativeElement.value,
        password2: this.password2Input.nativeElement.value
      };
    }, 100);
  }
}
