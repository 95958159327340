import { Injectable } from '@angular/core';
import { DateInterval } from '../../../../backend/src/types/date-interval';
import { AsyncSubject, Observable, Subject, filter, map, repeat } from 'rxjs';
import { TaskPerformanceApiService } from './task-performance-api.service';
import { ObjectId } from '../../types/object-id';
import { TaskPerformanceInputDto } from '../../../../backend/src/models/types/task-performance-input-dto';
import { TasksApiService } from './tasks-api.service';
import { PerformanceWithUser } from '../../types/performance-with-user';
import { TaskPerformanceBonus } from '@backend/models/types/task-performance';

function hydrateTaskPerformance(input: any): PerformanceWithUser {
  if (input.startTime) {
    input.startTime = new Date(input.startTime);
  }
  if (input.completeTime) {
    input.completeTime = new Date(input.completeTime);
  }
  if (input.taskAppearTime) {
    input.taskAppearTime = new Date(input.taskAppearTime);
  }
  return input;
}

@Injectable({ providedIn: 'root' })
export class TaskPerformanceService {
  private readonly _performanceUpdate$ = new Subject<Date>();

  public constructor(
    private readonly _taskPerformanceApi: TaskPerformanceApiService,
    private readonly _taskApi: TasksApiService
  ) {}

  public getPerformanceForInterval(interval: DateInterval) {
    return this._taskPerformanceApi.getTaskPerformance(interval).pipe(
      map((list) => list.map(hydrateTaskPerformance)),
      repeat({
        delay: () =>
          this._performanceUpdate$.pipe(
            filter((update) => {
              const updateTime = update.getTime();
              return (
                interval.start.getTime() <= updateTime &&
                updateTime <= interval.end.getTime()
              );
            })
          )
      })
    );
  }

  public setTaskPerformance(
    taskId: ObjectId,
    performance: TaskPerformanceInputDto,
    userId?: ObjectId,
    isLastCriticalTask?: boolean
  ): Observable<PerformanceWithUser> {
    const signal = new AsyncSubject<PerformanceWithUser>();

    this._taskApi
      .setPerformance(taskId, performance, userId, isLastCriticalTask)
      .pipe(map(hydrateTaskPerformance))
      .subscribe({
        next: (performance) => {
          this._performanceUpdate$.next(performance.taskAppearTime);
          signal.next(performance);
          signal.complete();
        },
        error: (error) => {
          signal.error(error);
        }
      });

    return signal.asObservable();
  }

  public cancelTaskPerformance(
    taskPerformanceId: ObjectId
  ): Observable<PerformanceWithUser> {
    const signal = new AsyncSubject<PerformanceWithUser>();

    this._taskPerformanceApi
      .cancelTaskPerformance(taskPerformanceId)
      .pipe(map(hydrateTaskPerformance))
      .subscribe({
        next: (performance) => {
          this._performanceUpdate$.next(performance.taskAppearTime);
          signal.next(performance);
          signal.complete();
        },
        error: (error) => {
          signal.error(error);
        }
      });

    return signal.asObservable();
  }

  public stopTaskPerformance(
    taskPerformanceId: ObjectId,
    taskAppearTime: string,
    quantity?: number,
    completeTime?: string
  ): Observable<PerformanceWithUser> {
    const signal = new AsyncSubject<PerformanceWithUser>();

    this._taskPerformanceApi
      .stopTaskPerformance(
        taskPerformanceId,
        taskAppearTime,
        quantity,
        completeTime
      )
      .pipe(map(hydrateTaskPerformance))
      .subscribe({
        next: (performance) => {
          this._performanceUpdate$.next(performance.taskAppearTime);
          signal.next(performance);
          signal.complete();
        },
        error: (error) => {
          signal.error(error);
        }
      });

    return signal.asObservable();
  }

  public approveTaskPerformance(
    taskPerformanceId: ObjectId,
    bonus?: TaskPerformanceBonus,
    isFasterThanAverage?: boolean,
    startTime?: string,
    completeTime?: string
  ): Observable<PerformanceWithUser> {
    const signal = new AsyncSubject<PerformanceWithUser>();

    this._taskPerformanceApi
      .approveTaskPerformance(
        taskPerformanceId,
        bonus,
        isFasterThanAverage,
        startTime,
        completeTime
      )
      .pipe(map(hydrateTaskPerformance))
      .subscribe({
        next: (performance) => {
          this._performanceUpdate$.next(performance.taskAppearTime);
          signal.next(performance);
          signal.complete();
        },
        error: (error) => {
          signal.error(error);
        }
      });

    return signal.asObservable();
  }

  public acknowledgeTaskPerformanceNote(
    taskPerformanceId: ObjectId,
    forAllUsers = false
  ): Observable<PerformanceWithUser> {
    const signal = new AsyncSubject<PerformanceWithUser>();

    this._taskPerformanceApi
      .acknowledgeTaskPerformanceNote(taskPerformanceId, forAllUsers)
      .pipe(map(hydrateTaskPerformance))
      .subscribe({
        next: (performance) => {
          this._performanceUpdate$.next(performance.taskAppearTime);
          signal.next(performance);
          signal.complete();
        },
        error: (error) => {
          signal.error(error);
        }
      });

    return signal.asObservable();
  }
}
