import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { IonicModule, IonSearchbar } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { ObjectId } from '@app/types/object-id';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterTasksPipe } from '@app/modules/task-selector/filter-tasks.pipe';
import { DirectivesModule } from '@app/modules/directives.module';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskDto } from '@app/types/task-dto';
import { EmptyStubModule } from '../empty-stub/empty-stub.module';

@Component({
  selector: 'app-task-selector',
  templateUrl: './task-selector.component.html',
  styleUrls: ['./task-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    WidthRestrictedContainerModule,
    FormsModule,
    FilterTasksPipe,
    DirectivesModule,
    EmptyStubModule
  ]
})
export class TaskSelectorComponent implements OnInit, AfterViewInit {
  @Input()
  public set selectedTasks(value: ObjectId[]) {
    this.originalSelectedTasks = value;
  }

  @Input()
  public readonly title?: string = 'Tasks';

  @Input()
  public readonly tasks: MongoStoredObject<TaskDto>[];

  @Input()
  public readonly hiddenTasks: string[] = [];

  @Input()
  public readonly multiple?: boolean = false;

  @Input()
  public readonly focusSearchbar?: boolean = false;

  @Input()
  public readonly emptyText?: string = 'No Tasks';

  @Output()
  public selectionCancel = new EventEmitter<void>();

  @Output()
  public selectionChange = new EventEmitter<ObjectId[]>();

  @ViewChild('ionSearchbar')
  public ionSearchbar!: IonSearchbar;

  protected originalSelectedTasks: ObjectId[] = [];
  public selected: {
    id: ObjectId;
    name: string;
    checked: boolean;
    isDeleted: boolean;
  }[] = [];
  public filter = '';

  protected get isAllChecked(): boolean {
    return (
      this.selected.filter((item) => item.checked).length ===
        this.tasks.length && this.tasks.length > 0
    );
  }

  protected get isIndeterminate(): boolean {
    const checkedItems = this.selected.filter((i) => i.checked);
    return checkedItems.length > 0 && checkedItems.length < this.tasks.length;
  }

  protected get canSave(): boolean {
    const checkedItems = this.selected.filter((i) => i.checked);

    if (checkedItems.length !== this.originalSelectedTasks.length) {
      return true;
    }

    return !this.originalSelectedTasks.reduce((prev, cur) => {
      if (prev === false) return false;
      return !!checkedItems.find((item) => item.id === cur);
    }, true);
  }

  public ngOnInit(): void {
    this.selected = this.tasks
      .map((task) => ({
        id: task._id,
        name: task.title,
        checked: this.originalSelectedTasks.includes(task._id),
        isDeleted: task.isDeleted
      }))
      .sort((a, b) => {
        const aChecked = a.checked;
        const bChecked = b.checked;
        if (aChecked && !bChecked) {
          return -1;
        }
        if (!aChecked && bChecked) {
          return 1;
        }
        return 0;
      });
  }

  public ngAfterViewInit(): void {
    if (this.focusSearchbar) {
      setTimeout(() => {
        this.ionSearchbar.setFocus();
      }, 600);
    }
  }

  protected trackItems(
    _: number,
    item: {
      id: ObjectId;
      name: string;
      checked: boolean;
    }
  ) {
    return item.id;
  }

  protected cancelChanges() {
    this.selectionCancel.emit();
  }

  protected confirmChanges() {
    const checkedItems = this.selected
      .filter((i) => i.checked)
      .map((i) => i.id);
    this.selectionChange.emit(checkedItems);
  }

  protected checkboxClick(event: any, value: ObjectId) {
    event.preventDefault();

    if (this.multiple) {
      this.selected = this.selected.map((item) => ({
        ...item,
        checked: item.id === value ? !item.checked : item.checked
      }));
    } else {
      this.selected = this.selected.map((item) => ({
        ...item,
        checked: item.id === value
      }));

      this.confirmChanges();
    }
  }

  protected allTasksChange(event: any): void {
    const {
      detail: { checked }
    }: { detail: { checked: boolean } } = event;

    this.selected = this.selected.map((item) => ({
      ...item,
      checked
    }));
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected isTaskHidden(taskId: string) {
    return this.hiddenTasks.includes(taskId);
  }

  protected getFirst() {
    if (!this.filter) return null;

    const tasks = new FilterTasksPipe()
      .transform(this.selected, this.filter)
      .filter((task) => !this.isTaskHidden(task.id));

    if (tasks.length > 0) {
      return tasks[0];
    }

    return null;
  }

  protected selectFirst() {
    const first = this.getFirst();

    if (first) {
      if (this.multiple) {
        first.checked = !first.checked;
      } else {
        this.selected = this.selected.map((task) => ({
          ...task,
          checked: task.id === first.id
        }));

        this.confirmChanges();
      }
    }
  }
}
