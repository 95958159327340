import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

if (environment.production) {
  /**
   * To enable PWA Service worker uncomment this code
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function () {
      navigator.serviceWorker.register('ngsw-worker.js');
    });
  }
   */
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.log(err));
