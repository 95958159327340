<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>Skill Matrix</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" color="primary" appHaptics>
        Done
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="ion-padding-vertical">
    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="selectTasksForGroup(skillMatrixStatus.CANNOT_PERFORM)"
          fill="clear"
          class="ion-no-margin"
        >
          Cannot Perform
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let task of cannotPerformTasks; trackBy: trackItems"
        [label]="task.title"
        [status]="getTaskStatus(task)"
        (statusChanged)="onStatusChange($event, task)"
      />
      <ion-note *ngIf="cannotPerformTasks.length === 0">
        No tasks with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="
            selectTasksForGroup(skillMatrixStatus.CAN_PERFORM_WITH_SUPERVISON)
          "
          fill="clear"
          class="ion-no-margin"
        >
          Can Perform with Supervision
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let task of canPerformWithSupervisionTasks; trackBy: trackItems"
        [label]="task.title"
        [status]="getTaskStatus(task)"
        (statusChanged)="onStatusChange($event, task)"
      />
      <ion-note *ngIf="canPerformWithSupervisionTasks.length === 0">
        No tasks with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="
            selectTasksForGroup(skillMatrixStatus.CAN_PERFORM_WITH_REVIEW)
          "
          fill="clear"
          class="ion-no-margin"
        >
          Can Perform with Review
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let task of canPerformWithReviewTasks; trackBy: trackItems"
        [label]="task.title"
        [status]="getTaskStatus(task)"
        (statusChanged)="onStatusChange($event, task)"
      />
      <ion-note *ngIf="canPerformWithReviewTasks.length === 0">
        No tasks with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="
            selectTasksForGroup(skillMatrixStatus.CAN_PERFORM_WITHOUT_REVIEW)
          "
          fill="clear"
          class="ion-no-margin"
        >
          Can Perform without Review
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let task of canPerformWithoutReviewTasks; trackBy: trackItems"
        [label]="task.title"
        [status]="getTaskStatus(task)"
        (statusChanged)="onStatusChange($event, task)"
      />
      <ion-note *ngIf="canPerformWithoutReviewTasks.length === 0">
        No tasks with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="selectTasksForGroup(skillMatrixStatus.CAN_PERFORM_AND_TRAIN)"
          fill="clear"
          class="ion-no-margin"
        >
          Can Perform and Train
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let task of canPerformAndTrainTasks; trackBy: trackItems"
        [label]="task.title"
        [status]="getTaskStatus(task)"
        (statusChanged)="onStatusChange($event, task)"
      />
      <ion-note *ngIf="canPerformAndTrainTasks.length === 0">
        No tasks with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="
            selectTasksForGroup(skillMatrixStatus.MUST_REVIEW_NEW_VERSION)
          "
          fill="clear"
          class="ion-no-margin"
        >
          Must Review New Version
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let task of mustReviewNewVersionTasks; trackBy: trackItems"
        [label]="task.title"
        [status]="getTaskStatus(task)"
        (statusChanged)="onStatusChange($event, task)"
      />
      <ion-note *ngIf="mustReviewNewVersionTasks.length === 0">
        No tasks with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="selectTasksForGroup(skillMatrixStatus.REVOKED)"
          fill="clear"
          class="ion-no-margin"
        >
          Revoked
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let task of revokedTasks; trackBy: trackItems"
        [label]="task.title"
        [status]="getTaskStatus(task)"
        (statusChanged)="onStatusChange($event, task)"
      />
      <ion-note *ngIf="revokedTasks.length === 0">
        No tasks with this status
      </ion-note>
    </ion-list>
  </app-width-restricted-container>
</ion-content>

<ion-modal
  #taskModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-task-selector
      class="ion-page"
      [title]="selectedStatusTitle"
      [tasks]="allTasks"
      [selectedTasks]="tasksIdsOfSelectedStatus"
      (selectionChange)="selectedIdChanged($event)"
      (selectionCancel)="taskModal.dismiss()"
      [multiple]="true"
    ></app-task-selector>
  </ng-template>
</ion-modal>
