<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> Daily Report </ion-title>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-buttons class="ion-justify-content-center">
      <ion-button color="primary" (click)="onPrevDayClick()" class="arrow-btn">
        <ion-icon slot="icon-only" name="chevron-back-outline" />
      </ion-button>
      <ion-button id="selectedDateButton" color="primary" appHaptics>
        {{ selectedDate | date : 'MMM d, y' }}
      </ion-button>
      <ion-button color="primary" (click)="onNextDayClick()" class="arrow-btn">
        <ion-icon slot="icon-only" name="chevron-forward-outline" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container>
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ng-container
      *ngIf="
        completedGrouped.length === 0 &&
        incompleteCriticalTasksGrouped.length === 0 &&
        !isLoading
      "
    >
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <div
      class="daily-report-table daily-report-table--pre ion-padding"
      *ngIf="!isLoading"
    >
      <div class="table-head" *ngIf="completedGrouped.length">
        <div class="table-head-col">Name</div>
        <div class="table-head-col">Points</div>
      </div>
      <div class="table-row" *ngFor="let item of completedGrouped">
        <div class="table-row-header">
          <div class="table-row-title">
            {{ item.fullName }}
          </div>
          <div class="table-row-points">
            <ion-button
              fill="clear"
              expand="block"
              class="ion-no-padding ion-no-margin"
              (click)="showTasksCompletedOfUser(item.userId, item.fullName)"
            >
              <div class="table-row-points-value">
                {{ item.points }}
              </div>
            </ion-button>
          </div>
        </div>
        <div class="table-row-performances">
          <div *ngFor="let performances of item.performances">
            <app-performance-row
              *ngIf="performances.length === 1; else performancesFolder"
              [performance]="performances[0]"
              (click)="onPerformanceClick(performances[0])"
            />
            <ng-template #performancesFolder>
              <app-performances-folder
                [performances]="performances"
                (performanceClicked)="onPerformanceClick($event)"
              />
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div
      class="daily-report-table daily-report-table ion-padding"
      *ngIf="!isLoading"
    >
      <div class="table-head" *ngIf="incompleteCriticalTasksGrouped.length">
        <div class="table-head-col">Incomplete Critical Tasks</div>
      </div>
      <div
        class="table-row"
        *ngFor="let folder of incompleteCriticalTasksGrouped"
      >
        <div class="table-row-header">
          <div class="table-row-title">
            {{ folder.taskFolderTitle }}
          </div>
        </div>
        <div class="table-row-performances">
          <div *ngFor="let item of folder.items">
            <app-task-row [task]="item.task" />
          </div>
        </div>
      </div>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-popover
  [keepContentsMounted]="true"
  trigger="selectedDateButton"
  cssClass="popover-datetime"
  #selectedDatePopover
>
  <ng-template>
    <ion-datetime
      presentation="date"
      class="date-time-picker"
      (ionChange)="onCalendarDateClick($event)"
    >
    </ion-datetime>
  </ng-template>
</ion-popover>

<ng-template #emptyList>
  <app-empty-stub text="No tasks logged today"></app-empty-stub>
</ng-template>
