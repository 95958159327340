<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start" class="ion-hide-lg-up" *ngIf="!input">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-buttons slot="start" *ngIf="input">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!input">Upload a Tag</ion-title>
    <ion-title *ngIf="input"> Tag: #{{ input.tag.tagNumber }} </ion-title>
    <ion-buttons slot="end" *ngIf="!input">
      <ion-button color="primary" (click)="viewTags()" appHaptics>
        View Tags
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-common-background" *ngIf="!input">
    <ion-card-title
      class="ion-text-center ticket-number"
      *ngIf="tag; else reserveTagButton"
    >
      #{{ tag.tagNumber }}
    </ion-card-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <ion-grid [formGroup]="mainFormGroup">
        <ion-row>
          <ion-col size="12">
            <ion-input
              [label]="pickupDateFieldName"
              class="app-w100 input-with-background ion-text-capitalize"
              formControlName="pickupDate"
              labelPlacement="floating"
              fill="solid"
              [readonly]="true"
              (click)="onPickupDateInputClick($event)"
              appHaptics
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Server"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              [value]="formattedServer"
              [readonly]="true"
              appHaptics
              (click)="userSelectorModal.present()"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Customer Cell"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              placeholder="(XXX) XXX-XXXX"
              inputmode="numeric"
              formControlName="customerCell"
              pattern="[1-9][0-9]*"
              type="text"
              digitOnly
              [maxlength]="10"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="customerNameEnabled">
          <ion-col size="12">
            <ion-input
              label="Customer Name"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              formControlName="customerName"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Number of Items"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              pattern="[1-9][0-9]*"
              formControlName="count"
              [maxlength]="6"
              inputmode="numeric"
              type="text"
              digitOnly
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-textarea
              label="Notes (optional)"
              class="app-w100 input-with-background fix-textarea"
              formControlName="notes"
              labelPlacement="floating"
              fill="solid"
              [autoGrow]="true"
              autocapitalize="on"
            ></ion-textarea>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="bottom-content">
      <div class="image-scroller">
        <div class="images">
          <div class="image-card">
            <div class="image-card-text">Tag</div>
            <button
              class="capture-btn"
              (click)="takePicture('TAG')"
              *ngIf="!tagImage"
              appHaptics
            >
              <ion-icon name="camera" size="large"></ion-icon>
            </button>
            <button
              *ngIf="tagImage"
              class="image-btn"
              (click)="viewPhoto(-1)"
              appHaptics
            >
              <img class="image" [src]="tagImage.url" />
            </button>
            <ion-fab-button
              *ngIf="tagImage"
              size="small"
              class="delete-btn"
              (click)="deletePhoto(tagImage.blobName)"
              color="danger"
              appHaptics
            >
              <ion-icon name="trash"></ion-icon>
            </ion-fab-button>
          </div>
          <div class="image-card">
            <div class="image-card-text">Items</div>
            <button
              class="capture-btn"
              (click)="takePicture('PRODUCT')"
              appHaptics
            >
              <ion-icon name="camera" size="large"></ion-icon>
            </button>
          </div>
          <div
            *ngFor="
              let image of productImages;
              trackBy: productImageTrackBy;
              let i = index
            "
            class="image-card"
          >
            <button class="image-btn" (click)="viewPhoto(i)" appHaptics>
              <img class="image" [src]="image.url" />
            </button>
            <ion-fab-button
              size="small"
              class="delete-btn"
              (click)="deletePhoto(image.blobName)"
              color="danger"
              appHaptics
            >
              <ion-icon name="trash"></ion-icon>
            </ion-fab-button>
          </div>
        </div>
      </div>
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="4">
            <ion-button
              *ngIf="!input"
              color="dark"
              expand="block"
              [disabled]="!canSave || !tag"
              (click)="uploadTag()"
              appHaptics
              [disableHaptics]="!canSave || !tag"
            >
              Upload
            </ion-button>
            <ion-button
              *ngIf="input"
              color="dark"
              expand="block"
              [disabled]="!canSave"
              (click)="updateTag()"
              appHaptics
              [disableHaptics]="!canSave"
            >
              Update
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-popover
  [keepContentsMounted]="true"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
  cssClass="popover-datetime"
  #popover
>
  <ng-template>
    <ion-datetime
      presentation="date"
      class="date-time-picker"
      (ionChange)="onCalendarChange($event)"
    />
  </ng-template>
</ion-popover>

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Server"
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="onUserChange($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>

<ng-template #reserveTagButton>
  <ion-row class="ion-justify-content-center">
    <ion-button
      fill="clear"
      (click)="reserveTag()"
      class="ion-no-margin reserve-tag-btn"
    >
      <ion-icon slot="icon-only" name="refresh-outline"></ion-icon>
    </ion-button>
  </ion-row>
</ng-template>
