import { Injectable } from '@angular/core';
import { repeat, Observable, Subject, shareReplay, tap } from 'rxjs';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskFolderApiService } from './task-folder-api.service';
import { ITaskFolder } from '@backend/models/types/task-folder';

@Injectable({ providedIn: 'root' })
export class TaskFolderListService {
  private readonly _update$ = new Subject<void>();

  public taskFolders$: Observable<MongoStoredObject<ITaskFolder>[]> =
    this._taskFolderApiService
      .getTaskFolders()
      .pipe(repeat({ delay: () => this._update$ }), shareReplay(1));

  public constructor(
    private readonly _taskFolderApiService: TaskFolderApiService
  ) {}

  public update(): void {
    this._update$.next();
  }

  public createTaskFolder(
    taskFolderBody: Omit<ITaskFolder, 'orderIndex' | 'tenant'>
  ): Observable<MongoStoredObject<ITaskFolder>> {
    return this._taskFolderApiService.createTaskFolder(taskFolderBody).pipe(
      tap(() => {
        this._update$.next();
      })
    );
  }

  public updateTaskFolder(
    taskFolderId: string,
    taskFolderBody: Omit<ITaskFolder, 'orderIndex' | 'tenant'>
  ): Observable<MongoStoredObject<ITaskFolder>> {
    return this._taskFolderApiService
      .updateTaskFolder(taskFolderId, taskFolderBody)
      .pipe(
        tap(() => {
          this._update$.next();
        })
      );
  }

  public reorderTaskFoldersMultiple(
    taskFolders: {
      taskFolderId: string;
      orderIndex: number;
    }[]
  ): Observable<null> {
    return this._taskFolderApiService
      .reorderTaskFoldersMultiple(taskFolders)
      .pipe(
        tap(() => {
          this._update$.next();
        })
      );
  }

  public deleteTaskFolder(taskFolderId: string): Observable<null> {
    return this._taskFolderApiService.deleteTaskFolder(taskFolderId).pipe(
      tap(() => {
        this._update$.next();
      })
    );
  }
}
