import { InjectionToken, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ChecklistItem } from '@app/types/checklist-item';
import { ChecklistService } from '@app/core/service/checklist.service';
import { TaskListService } from '@app/core/service/task-list.service';
import { TODAY_INTERVAL } from '@app/core/tokens/today-interval';

export const TODAY_CHECKLIST = new InjectionToken<Observable<ChecklistItem[]>>(
  'TODAY_CHECKLIST',
  {
    providedIn: 'root',
    factory: () => {
      const checklist = inject(ChecklistService);
      const taskList = inject(TaskListService);
      const interval$ = inject(TODAY_INTERVAL);
      return checklist.getMyChecklist(taskList.list$, interval$);
    }
  }
);
