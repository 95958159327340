import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewChild
} from '@angular/core';
import { IonTitle } from '@ionic/angular';

@Component({
  selector: 'app-content-title',
  templateUrl: './content-title.component.html',
  styleUrls: ['./content-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentTitleComponent implements AfterViewInit {
  @ViewChild('title', { static: true })
  public title!: IonTitle;

  public ngAfterViewInit(): void {
    setTimeout(() => {
      const c = (this.title as any).el.shadowRoot.querySelector(
        '.toolbar-title'
      );

      c.style.overflow = 'initial';
      c.style.whiteSpace = 'initial';
    });
  }
}
