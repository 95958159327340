import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from '@angular/forms';
import { extractDayPart } from '../../utils/extract-day-part';
import { DateFlags } from '../task-form/types/date-flags.enum';

@Component({
  selector: 'app-month-days-picker',
  templateUrl: './month-days-picker.component.html',
  styleUrls: ['./month-days-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MonthDaysPickerComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: MonthDaysPickerComponent
    }
  ]
})
export class MonthDaysPickerComponent implements ControlValueAccessor {
  protected isWorkingDay = false;
  protected readonly days = new Array(31).fill(null).map((_, i) => i + 1);
  protected readonly dayValues = new Array(31).fill(false);
  protected lastDay = false;

  public onChange = (_daysOfMonth: string[] | null) => {};

  public onTouched = () => {};

  private _touched = false;

  private _disabled = false;

  public writeValue(daysOfMonth: string[] | null) {
    if (daysOfMonth === null) {
      this.dayValues.forEach((_, i) => {
        this.dayValues[i] = false;
      });
      this.lastDay = false;
      this.isWorkingDay = false;
      return;
    }
    const newDayValues = new Array(31).fill(false);
    let newLastDay = false;
    let newIsWorkingDay = false;
    daysOfMonth.forEach(dayOfMonth => {
      if (dayOfMonth.includes(DateFlags.WEEKDAY)) {
        newIsWorkingDay = true;
      }
      const dayPart = extractDayPart(dayOfMonth);
      if (dayPart === DateFlags.LAST_DAY) {
        newLastDay = true;
      } else {
        const index = Number.parseInt(dayPart) - 1;
        newDayValues[index] = true;
      }
    });

    for (let i = 0; i < 31; i++) {
      this.dayValues[i] = newDayValues[i];
    }
    this.lastDay = newLastDay;
    this.isWorkingDay = newIsWorkingDay;
  }

  public registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  protected markAsTouched() {
    if (!this._touched) {
      this.onTouched();
      this._touched = true;
    }
  }

  public setDisabledState(disabled: boolean) {
    this._disabled = disabled;
  }

  protected onCheckedChange(): void {
    this.markAsTouched();
    this.onChange(this._getValues());
  }

  private _getValues(): string[] {
    const result = this.dayValues.reduce((acc, isSelected, index) => {
      if (isSelected) {
        return [
          ...acc,
          `${index + 1}${this.isWorkingDay ? DateFlags.WEEKDAY : ''}`
        ];
      }
      return acc;
    }, []);
    if (this.lastDay) {
      result.push(
        `${DateFlags.LAST_DAY}${this.isWorkingDay ? DateFlags.WEEKDAY : ''}`
      );
    }
    return result;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    const daysOfMonth: string[] | null = control.value;
    if (daysOfMonth === null || daysOfMonth.length === 0) {
      return {
        mustHaveValues: {
          length: daysOfMonth?.length ?? 0
        }
      };
    }
    return null;
  }
}
