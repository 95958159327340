<div matRipple [matRippleColor]="'primary'">
  <input
    type="checkbox"
    class="native-control"
    [(ngModel)]="checkedState"
    [ngModelOptions]="{ standalone: true }"
    (change)="onStateChange()"
  />
  <div class="button">
    <ng-content></ng-content>
  </div>
</div>
