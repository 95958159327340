import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import {
  ITaskPerformance,
  TaskPerformanceBonus,
  TaskPerformanceStatus
} from '@backend/models/types/task-performance';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITask } from '@backend/models/types/task';
import { format, intervalToDuration } from 'date-fns';
import { UserModel } from '@app/core/model/user.model';
import { LoadingController, ModalController } from '@ionic/angular';
import { TaskPerformanceService } from '@app/core/service/task-performance.service';
import { ApprovalsListService } from '@app/core/service/approvals-list.service';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { ToastService } from '@app/core/service/toast.service';
import {
  formatDurationShort,
  valueOfDuration
} from '@app/utils/format-duration';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TasksApiService } from '@app/core/service/tasks-api.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-approval-modal',
  templateUrl: './approval-modal.component.html',
  styleUrls: ['./approval-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApprovalModalComponent implements OnInit {
  @Input()
  public taskPerformance: MongoStoredObject<
    ITaskPerformance & {
      user: UserModel;
      task: MongoStoredObject<ITask>;
    }
  >;

  protected taskPerformanceBonus = TaskPerformanceBonus;
  protected taskPerformanceStatus = TaskPerformanceStatus;

  public form = new FormGroup({
    startDate: new FormControl<Date>(null, Validators.required),
    startTime: new FormControl<string>('00:00', Validators.required),
    completeDate: new FormControl<Date>(null, Validators.required),
    completeTime: new FormControl<string>('00:00', Validators.required),
    bonus: new FormControl<TaskPerformanceBonus>(
      TaskPerformanceBonus.EXCELLENT,
      Validators.required
    )
  });
  protected timeAttackStats: {
    averageDuration: Duration;
    currentRecord: {
      user: UserModel;
      duration: Duration;
    };
    rewardForBeatingTheAverage: number;
    rewardForAttempt: number;
  } = null;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _taskPerformanceService: TaskPerformanceService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _approvalsListService: ApprovalsListService,
    private readonly _userEntityService: UserEntityService,
    private readonly _toastService: ToastService,
    private readonly _tasksApiService: TasksApiService,
    private readonly _cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.form.reset({
      startDate: new Date(this.taskPerformance.startTime),
      startTime: format(new Date(this.taskPerformance.startTime), 'HH:mm'),
      completeDate: new Date(this.taskPerformance.completeTime),
      completeTime: format(
        new Date(this.taskPerformance.completeTime),
        'HH:mm'
      ),
      bonus: TaskPerformanceBonus.EXCELLENT
    });

    this._tasksApiService
      .getTimeAttackStats(this.taskPerformance.task._id)
      .pipe(take(1))
      .subscribe((timeAttackStats) => {
        this.timeAttackStats = timeAttackStats;
        this._cdr.markForCheck();
      });
  }

  protected get startDate() {
    return this.form.get('startDate').value.toISOString();
  }

  protected get completeDate() {
    return this.form.get('completeDate').value.toISOString();
  }

  protected onCloseClick(role = 'cancel') {
    this._modalCtrl.dismiss(null, role, 'approval-modal');
  }

  protected get computedStart() {
    const date = this.form.get('startDate').value;

    const [hours, minutes] = this.form.get('startTime').value.split(':');

    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));

    return date;
  }

  protected get computedComplete() {
    const date = this.form.get('completeDate').value;

    const [hours, minutes] = this.form.get('completeTime').value.split(':');

    date.setHours(Number(hours));
    date.setMinutes(Number(minutes));

    return date;
  }

  protected get timeSpentEach() {
    const diff = this.computedComplete.valueOf() - this.computedStart.valueOf();

    return formatDurationShort(
      intervalToDuration({
        start: 0,
        end: diff / this.taskPerformance.quantity
      })
    );
  }

  protected get timeSpentTotal() {
    return formatDurationShort(
      intervalToDuration({
        start: this.computedStart,
        end: this.computedComplete
      })
    );
  }

  protected get user() {
    return `${this.taskPerformance.user.firstName} ${this.taskPerformance.user.lastName}`;
  }

  protected async onApproveClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Approving...'
    });
    loading.present();

    this._taskPerformanceService
      .approveTaskPerformance(
        this.taskPerformance._id,
        this.taskPerformance.status ===
          TaskPerformanceStatus.WAITING_FOR_APPROVAL
          ? this.form.value.bonus
          : undefined,
        this.taskPerformance.status ===
          TaskPerformanceStatus.WAITING_FOR_APPROVAL
          ? this.isFasterThanAverage
          : undefined,
        this.taskPerformance.status === TaskPerformanceStatus.NOT_STARTED
          ? new Date().toISOString()
          : this.computedStart.toISOString(),
        this.taskPerformance.status === TaskPerformanceStatus.NOT_STARTED
          ? undefined
          : this.computedComplete.toISOString()
      )
      .subscribe({
        next: () => {
          this._approvalsListService.update();
          this._userEntityService.update();
          loading.dismiss();
          this.onCloseClick('confirm');
          this._toastService.presentToast('Task Approved Successfully!');
        },
        error: () => {
          loading.dismiss();
          this._toastService.presentToast('Unable to Start Task Performance');
        }
      });
  }

  protected async onRejectClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Rejecting...'
    });
    loading.present();

    this._taskPerformanceService
      .cancelTaskPerformance(this.taskPerformance._id)
      .subscribe({
        next: () => {
          this._approvalsListService.update();
          this._userEntityService.update();
          loading.dismiss();
          this.onCloseClick('confirm');
          this._toastService.presentToast('Task Rejected Successfully!');
        },
        error: () => {
          loading.dismiss();
          this._toastService.presentToast('Unable to Reject Task Performance');
        }
      });
  }

  protected get averageTime() {
    const t =
      this.timeAttackStats &&
      formatDurationShort(this.timeAttackStats.averageDuration);
    return t || 'No Data';
  }

  protected get isFasterThanAverage() {
    if (!this.timeAttackStats) return false;

    const myTime = valueOfDuration(
      intervalToDuration({
        start: this.computedStart,
        end: this.computedComplete
      })
    );

    const average = valueOfDuration(this.timeAttackStats.averageDuration);

    return myTime < average;
  }
}
