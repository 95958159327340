import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateSelectorComponent } from './date-selector.component';
import { IonicModule } from '@ionic/angular';
import { WideButtonComponent } from '@app/modules/wide-button/wide-button.component';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  declarations: [DateSelectorComponent],
  imports: [CommonModule, IonicModule, WideButtonComponent, DirectivesModule],
  exports: [DateSelectorComponent]
})
export class DateSelectorModule {}
