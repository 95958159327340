import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormControlComponent } from './date-form-control.component';
import { IonicModule } from '@ionic/angular';
import { transformerApplicatorPipe } from './transformer-applicator.pipe';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  declarations: [DateFormControlComponent, transformerApplicatorPipe],
  imports: [CommonModule, IonicModule, DirectivesModule],
  exports: [DateFormControlComponent]
})
export class DateFormControlModule {}
