<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>Date Range</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div>
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-select
          interface="action-sheet"
          placeholder="Click to select range"
          fill="solid"
          aria-label="Date range"
          class="app-w100 input-with-background select-round-fix"
          [(ngModel)]="selectedPreset"
          (ngModelChange)="onModelSelect($event)"
          (ionCancel)="hapticsImpact()"
        >
          <ion-select-option
            *ngFor="let option of rangePresets"
            [value]="option[0]"
          >
            {{ option[1] }}
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <form [formGroup]="form" (ngSubmit)="onFormSubmit()">
      <ion-row>
        <ion-col size="6">
          <app-date-selector label="Start Date" formControlName="start">
          </app-date-selector>
        </ion-col>
        <ion-col size="6">
          <app-date-selector
            label="End Date"
            formControlName="end"
            [endOfDay]="true"
          >
          </app-date-selector>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="4">
          <ion-button
            type="submit"
            color="dark"
            expand="block"
            [disabled]="form.invalid"
            appHaptics
            [disableHaptics]="form.invalid"
          >
            Apply
          </ion-button>
        </ion-col>
      </ion-row>
    </form>
  </ion-grid>
</div>
