<ion-content color="light">
  <app-width-restricted-container class="not-found-container">
    <div class="top-content">
      <ion-text>
        <h1 class="ion-text-center heading">404</h1>
      </ion-text>
      <ion-text>
        <h3 class="ion-text-center subheading">
          This page could not be found.
        </h3>
      </ion-text>
      <ion-text>
        <h3 class="ion-text-center emoji">🚫</h3>
      </ion-text>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              color="dark"
              expand="block"
              (click)="backToHome()"
              appHaptics
            >
              Back to home
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>
