import { InjectionToken } from '@angular/core';
import { getZonedTime } from '@app/utils/get-zoned-time';
import { DateInterval } from '@backend/types/date-interval';
import { addSeconds, endOfDay, startOfDay } from 'date-fns';
import { Observable, expand, map, of, shareReplay, timer } from 'rxjs';

function todayInterval(): DateInterval {
  const todayStart = startOfDay(getZonedTime(new Date())); // beginning of today
  const todayEnd = endOfDay(todayStart); // end of today

  return {
    start: todayStart,
    end: todayEnd
  };
}

export const TODAY_INTERVAL = new InjectionToken<Observable<DateInterval>>(
  'TODAY_INTERVAL',
  {
    providedIn: 'root',
    factory: () => {
      return of(todayInterval()).pipe(
        expand((interval) =>
          timer(addSeconds(interval.end, 1)).pipe(map(() => todayInterval()))
        ),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
  }
);
