import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ILotteryMachineTicket } from '../../lottery-machine.component';

@Component({
  selector: 'app-lottery-machine-winner-item',
  templateUrl: './lottery-machine-winner-item.component.html',
  styleUrls: ['./lottery-machine-winner-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotteryMachineWinnerItemComponent {
  @Input()
  public readonly winner: ILotteryMachineTicket;

  @Input()
  public animationOrder?: number = -1;

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  public constructor(private readonly _sanitizer: DomSanitizer) {}
}
