export enum RangePresets {
  ThisPayPeriod = 'THIS_PAY_PERIOD',
  LastPayPeriod = 'LAST_PAY_PERIOD',
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Last7Days = 'LAST_7_DAYS',
  Last30Days = 'LAST_30_DAYS',
  Last90Days = 'LAST_90_DAYS',
  LastMonth = 'LAST_MONTH',
  ThisYear = 'THIS_YEAR',
  LastYear = 'LAST_YEAR',
  AllTime = 'ALL_TIME',
  Custom = 'CUSTOM'
}
