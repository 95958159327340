import { Injectable } from '@angular/core';
import { TaskScheduleType } from '@backend/models/types/task';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import { TaskDailyRenderer } from '../class/task-daily-renderer.class';
import { TaskRenderer } from '../class/task-renderer.class';
import { TaskNeverRenderer } from '../class/task-never-renderer.class';
import { TaskWeeklyRenderer } from '../class/task-weekly-renderer.class';
import { TaskSpecificDatesRenderer } from '../class/task-specific-dates-renderer.class';
import { TaskDto } from '@app/types/task-dto';

@Injectable({ providedIn: 'root' })
export class TaskRendererFabricService {
  public createTaskRenderer(
    task: MongoStoredObject<TaskDto>,
    allowTasksToBeDisplayedOnlyDuringCertainHours: boolean
  ): TaskRenderer {
    const schedule = task.schedule[0];
    switch (schedule.scheduleType) {
      case TaskScheduleType.DAILY:
        return new TaskDailyRenderer(
          task,
          allowTasksToBeDisplayedOnlyDuringCertainHours
        );
      case TaskScheduleType.NEVER:
        return new TaskNeverRenderer(
          task,
          allowTasksToBeDisplayedOnlyDuringCertainHours,
          schedule
        );
      case TaskScheduleType.WEEKLY:
        return new TaskWeeklyRenderer(
          task,
          allowTasksToBeDisplayedOnlyDuringCertainHours,
          schedule
        );
      case TaskScheduleType.SPECIFIC_DATES:
        return new TaskSpecificDatesRenderer(
          task,
          allowTasksToBeDisplayedOnlyDuringCertainHours,
          schedule
        );
      case TaskScheduleType.MONTHLY:
        throw new Error('Monthly checklist renderer not implemented');
    }
  }
}
