import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { ToastService } from '@app/core/service/toast.service';
import { IonModal, IonNav, LoadingController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantService } from '@app/core/service/tenant.service';
import { UploadFileApiService } from '@app/core/service/upload-file-api.service';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import {
  ESuggestionStatus,
  ESuggestionType
} from '@backend/models/types/suggestion';
import { SuggestionApiService } from '@app/core/service/suggestion-api.service';
import { AuthenticationService, ROLES } from '@app/core';
import { OpportunityModel } from '@app/core/model/suggestion.model';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { ObjectId } from '@app/types/object-id';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';

@Component({
  selector: 'app-suggestion-add',
  templateUrl: './suggestion-add.component.html',
  styleUrls: ['./suggestion-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionAddComponent implements OnInit {
  @Input()
  public readonly suggestion: OpportunityModel = null;

  @Input()
  public readonly parentId: string = '';

  @ViewChild('userSelectorModal')
  public userSelectorModal!: IonModal;

  protected suggestionType = ESuggestionType;

  protected mainFormGroup = new FormGroup({
    status: new FormControl<ESuggestionStatus>(
      ESuggestionStatus.AWAITING_REVIEW,
      Validators.required
    ),
    author: new FormControl<ObjectId>(undefined)
  });
  protected body = '';
  protected isPublic = false;
  protected disableAttachments = false;
  protected statuses = [
    {
      title: 'Awaiting Review',
      value: ESuggestionStatus.AWAITING_REVIEW
    },
    {
      title: 'In Queue',
      value: ESuggestionStatus.IN_QUEUE
    },
    {
      title: 'In Progress',
      value: ESuggestionStatus.IN_PROGRESS
    },
    {
      title: 'Resolved',
      value: ESuggestionStatus.RESOLVED
    },
    {
      title: 'Archived',
      value: ESuggestionStatus.ARCHIVED
    }
  ];
  protected isAdmin = false;
  protected allUsers: UserModel[] = [];

  public constructor(
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _ionNav: IonNav,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _tenantService: TenantService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _environmentService: EnvironmentService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _userEntityService: UserEntityService,
    private readonly _userListService: UserListService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.disableAttachments = !tenant.features.suggestionAttachments;
    });

    this.isAdmin = this._authenticationService.user.roles.includes(
      ROLES.adminName
    );

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });
  }

  public ngOnInit(): void {
    if (this.suggestion) {
      this.mainFormGroup.reset({
        ...this.suggestion,
        author: this.suggestion.author?._id || undefined
      });
      this.body = formatUploadedBody(
        this.suggestion.body,
        this.suggestion.attachments,
        this._environmentService.serverUrl
      );
      this.isPublic = this.suggestion.isPublic;
    } else {
      this.mainFormGroup.patchValue({
        author: this._authenticationService.user._id
      });
    }
  }

  protected get selectedUserIds(): ObjectId[] {
    const author = this.mainFormGroup.get('author').value;
    return author ? [author] : [];
  }

  protected get formattedAuthor(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('author').value
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected onUserChange(event: ObjectId[]): void {
    this.mainFormGroup.controls.author.setValue(event[0]);
    this.userSelectorModal.dismiss();
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected async onFormSubmit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._uploadFileApiService
      .extractAttachmentsFromBody(
        this.body || '',
        this.suggestion?.attachments || []
      )
      .subscribe({
        next: ({ body, newAttachments, keptAttachments }) => {
          if (this.suggestion) {
            this._suggestionApiService
              .updateOpportunity(this.suggestion._id.toString(), {
                status: this.mainFormGroup.get('status').value,
                body,
                isPublic: this.isPublic,
                author: this.mainFormGroup.get('author').value,
                attachments: [
                  ...keptAttachments,
                  ...newAttachments.map((a) => a._id.toString())
                ]
              })
              .subscribe({
                next: () => {
                  this._toastService.presentToast(
                    'Suggestion Successfully Updated!'
                  );
                  this._ionNav.pop().then(() => this._ionNav.pop());
                },
                error: (e) => {
                  loading.dismiss();
                  this._toastService.presentToast(
                    e.error?.message || 'Unable to update suggestion'
                  );
                }
              });
          } else {
            if (!this.parentId) {
              this._suggestionApiService
                .postOpportunity({
                  isPublic: this.isPublic,
                  body,
                  attachments: [
                    ...keptAttachments,
                    ...newAttachments.map((a) => a._id.toString())
                  ],
                  status: this.mainFormGroup.get('status').value,
                  author: this.mainFormGroup.get('author').value,
                  tenant: this._authenticationService.user.tenant
                })
                .subscribe({
                  next: () => {
                    this._userEntityService.update();
                    this._toastService.presentToast(
                      'Suggestion Successfully Added!'
                    );
                    this._ionNav.pop();
                  },
                  error: (e) => {
                    loading.dismiss();
                    this._toastService.presentToast(
                      e.error?.message || 'Unable to submit suggestion'
                    );
                  }
                });
            } else {
              this._suggestionApiService
                .postSolution(this.parentId, {
                  isPublic: this.isPublic,
                  body,
                  attachments: [
                    ...keptAttachments,
                    ...newAttachments.map((a) => a._id.toString())
                  ]
                })
                .subscribe({
                  next: () => {
                    this._toastService.presentToast(
                      'Comment Successfully Added!'
                    );
                    this._ionNav.pop();
                  },
                  error: (e) => {
                    loading.dismiss();
                    this._toastService.presentToast(
                      e.error?.message || 'Unable to submit suggestion'
                    );
                  }
                });
            }
          }
        },
        error: (e) => {
          console.log(e);
          loading.dismiss();
          this._toastService.presentToast('Unable to upload attachments');
        }
      });
  }

  protected get publicInfo() {
    return this.isPublic
      ? 'Everybody on the team can view'
      : 'Only visible to Managers & Admins';
  }

  protected get canSave() {
    if (!this.parentId) {
      return this.body && !this.mainFormGroup.invalid;
    } else {
      return this.body;
    }
  }

  protected async onDeleteClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._suggestionApiService
      .deleteOpportunity(this.suggestion._id)
      .subscribe(() => {
        this._toastService.presentToast('Suggestion Successfully Deleted');
        this._ionNav.pop().then(() => this._ionNav.pop());
      });
  }
}
