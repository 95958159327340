import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes, parse, formatDuration } from 'date-fns';

@Pipe({
  name: 'appDuration'
})
export class DurationPipe implements PipeTransform {
  public transform(timeStart: string, timeEnd: string): string {
    const startDate = parse(timeStart, 'HH:mm', new Date()); // parse the start time
    let endDate = parse(timeEnd, 'HH:mm', new Date()); // parse the end time

    if (endDate < startDate) {
      endDate = new Date(endDate.getTime() + 24 * 60 * 60 * 1000); // add a day to the end date if it is earlier than the start date
    }

    const durationInMinutes = differenceInMinutes(endDate, startDate); // calculate the duration in minutes
    const durationHours = Math.floor(durationInMinutes / 60); // calculate the duration hours
    const durationMinutes = durationInMinutes % 60; // calculate the duration minutes

    if (durationHours && durationMinutes) {
      return `<div>${formatDuration({
        hours: durationHours
      })}</div><div>${formatDuration({
        minutes: durationMinutes
      })}</div>`;
    } else {
      return `<div>${formatDuration({
        hours: durationHours,
        minutes: durationMinutes
      })}</div>`;
    }
  }
}
