import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TenantService } from '@app/core/service/tenant.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {
  protected navigateTo = '';

  public constructor(
    private readonly _router: Router,
    private readonly _tenantService: TenantService
  ) {}

  public ngOnInit(): void {
    this._tenantService.tenant$.pipe(take(1)).subscribe((tenant) => {
      if (tenant) {
        this._router.navigate([tenant.loginRedirectsTo || '/i/recognition']);
      }
    });
  }
}
