<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-title> Connected Accounts </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ng-container *ngIf="groups.length === 0">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <div class="account-group" *ngFor="let group of groups">
      <div class="account" *ngFor="let account of group.accounts">
        <div class="avatar">
          <div class="diagram">
            <div class="user-name">
              {{ accountInitials(account.firstName, account.lastName) }}
            </div>
          </div>
        </div>
        <div class="account-info">
          <div class="account-name">
            {{ account.firstName }} {{ account.lastName }}
          </div>
          <div class="account-name-subrow">Email: {{ account.email }}</div>
          <div class="account-name-subrow">
            Tenant: {{ account.tenant.name }}
          </div>
        </div>
        <ion-icon
          *ngIf="!account.parent"
          (click)="removeAccount(group._id, account._id)"
          name="close-circle"
          class="account-action remove-account-button"
          color="danger"
        ></ion-icon>
      </div>
    </div>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="List Is Empty"></app-empty-stub>
</ng-template>
