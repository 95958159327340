import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { ITask } from '@backend/models/types/task';
import { TaskPointsEarns } from '@backend/models/types/task';
import { IAttachment } from '@backend/models/types/attachment';

@Component({
  selector: 'app-performances-folder',
  templateUrl: './performances-folder.component.html',
  styleUrls: ['./performances-folder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerformancesFolderComponent {
  @Input()
  public performances: MongoStoredObject<
    ITaskPerformance & {
      task: MongoStoredObject<ITask>;
      note?: {
        attachments: MongoStoredObject<IAttachment>[];
      };
    }
  >[];

  @Output()
  public performanceClicked = new EventEmitter<
    MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        note?: {
          attachments: MongoStoredObject<IAttachment>[];
        };
      }
    >
  >();

  protected readonly taskPointsEarns = TaskPointsEarns;

  protected isOpened = false;

  protected onFolderClick() {
    this.isOpened = true;
  }

  protected get folderPerformance() {
    return this.performances && this.performances.length
      ? {
          performance: this.performances[0],
          quantity: this.performances.reduce(
            (prev, cur) => prev + (cur.quantity || 1),
            0
          )
        }
      : null;
  }
}
