import { formatDate } from '@angular/common';

export const groupByKey = (array: any[], key: string) => {
  return array.reduce((hash: any, obj: any) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj)
    });
  }, {});
};

export function groupByDate<Type>(data: Type[], dateField: string) {
  const array = data as any[];

  const withFormattedDates = array.map((item) => {
    return {
      ...item,
      [dateField]: formatDate(item[dateField], 'MMMM dd, yyyy', 'en-US')
    };
  });
  const groupedByKey = groupByKey(withFormattedDates, dateField);
  const newData = Object.values(groupedByKey).map((group: any) => {
    return {
      date: group[0][dateField].toString(),
      items: group
    };
  });
  return newData as { date: string; items: Type[] }[];
}
