<ion-grid [formGroup]="form">
  <ion-row>
    <ion-col size="12">
      <div class="week-container" formGroupName="weekDays">
        <label
          *ngFor="let day of weekDays; let i = index"
          for="day{{ i }}"
          class="label"
          id="day{{ i }}-label"
        >
          {{ day }}
        </label>
        <ion-checkbox
          *ngFor="let control of dayArray.controls; let i = index"
          formControlName="{{ i }}"
          id="day{{ i }}"
          appHaptics
        ></ion-checkbox>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
