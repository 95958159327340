<ion-input
  *ngIf="type === 'number'; else textField"
  #input
  class="inline-input"
  [(ngModel)]="innerValue"
  (ngModelChange)="valueChange()"
  [size]="size"
  [placeholder]="placeholder"
  inputmode="numeric"
  type="text"
  autocapitalize="off"
  [disabled]="disabled"
  digitOnly
  pattern="[1-9][0-9]*"
  [maxlength]="6"
/>
<span
  class="append-text"
  [ngClass]="{
    'append-text--filled': innerValue && innerValue.length > 0,
    'append-text--disabled': disabled
  }"
  *ngIf="appendText"
>
  {{ appendedText }}
</span>

<ng-template #textField>
  <ion-input
    #input
    class="inline-input"
    [(ngModel)]="innerValue"
    (ngModelChange)="valueChange()"
    [size]="size"
    [placeholder]="placeholder"
    type="text"
    autocapitalize="off"
    [disabled]="disabled"
    [maxlength]="128"
    [spellcheck]="true"
  />
</ng-template>
