import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-date-chip',
  templateUrl: './date-chip.component.html',
  styleUrls: ['./date-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateChipComponent {
  @Input()
  public value: string;
  @Output()
  public removed = new EventEmitter<string>();
}
