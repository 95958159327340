import { Pipe, PipeTransform } from '@angular/core';
import { DateInterval } from '@backend/types/date-interval';
import { Observable } from 'rxjs';
import { UserPointsRecord } from '../../core/model/user.model';
import { UserEntityService } from '../../core/service/user-entity.service';

@Pipe({
  name: 'intervalStats'
})
export class IntervalStatsPipe implements PipeTransform {
  public constructor(private _user: UserEntityService) {}

  public transform(interval: DateInterval): Observable<UserPointsRecord> {
    return this._user.getPointsForPeriod(interval);
  }
}
