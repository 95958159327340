import { NgModule } from '@angular/core';
import { LotteryMachineComponent } from './lottery-machine.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { LotteryMachineWinnerItemComponent } from './components/lottery-machine-winner-item/lottery-machine-winner-item.component';
import { DiceComponent } from './components/dice/dice.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [LotteryMachineComponent],
  declarations: [
    LotteryMachineComponent,
    LotteryMachineWinnerItemComponent,
    DiceComponent
  ]
})
export class LotteryMachineModule {}
