import {
  ChecklistItem,
  ChecklistItemWithCount
} from '@app/types/checklist-item';

const countUnique = (arr: ChecklistItem[]) => {
  const counts: { [id: string]: number } = {};
  arr.map((_, i) => {
    counts[arr[i].task._id] = 1 + (counts[arr[i].task._id] || 0);
  });

  return counts;
};

export const makeChecklistItemsUnique = (
  array: ChecklistItem[]
): ChecklistItemWithCount[] => {
  const counts = countUnique(array);

  // Filter by looking at the next objects if the key is present a second time
  return array
    .filter(
      (x, xi) => !array.slice(xi + 1).some((y) => y.task._id === x.task._id)
    )
    .map((item) => ({ item, count: counts[item.task._id] }));
};
