<ion-button
  class="instant-feedback-button ion-no-margin"
  [ngClass]="{ 'instant-feedback-button--show': showButton || isOpen }"
  color="dark"
  (click)="onButtonClick()"
  appHaptics
>
  {{ isOpen ? 'Cancel' : 'Feedback' }}
</ion-button>

<ion-alert
  [isOpen]="isOpen"
  header="App Feedback"
  subHeader="Please share any questions, problems, or ideas — your feedback helps us improve Valchemy."
  [buttons]="alertButtons"
  [inputs]="alertInputs"
  (willDismiss)="willDismiss($event)"
></ion-alert>
