import { Component, OnInit } from '@angular/core';
import { MembershipService } from '@app/core/service/membership.service';

@Component({
  selector: 'app-subscription-management',
  templateUrl: './subscription-management.component.html',
  styleUrls: ['./subscription-management.component.css']
})
export class SubscriptionManagementComponent implements OnInit {
  public checkoutSession: any = null;
  public showSuccess = false;
  public showPayment = false;
  constructor(private subscriptionService: MembershipService) {}
  Session_Payment_Status = {
    paid: 'paid',
    unpaid: 'unpaid',
    no_payment_required: 'no_payment_required'
  };
  ngOnInit() {
    this.loadData();
  }
  loadData() {
    this.showSuccess = false;
    this.subscriptionService.getTenantSubscriptionStatus().subscribe(data => {
      if (data.status === this.Session_Payment_Status.paid) {
        this.showSuccess = true;
        this.showPayment = false;
      } else {
        this.showSuccess = false;
        this.showPayment = true;
      }
      console.log({ success: this.showSuccess, showPayment: this.showPayment });
    });
  }

  initiateCheckoutSession() {
    console.log('initiateCheckoutSession');
    this.subscriptionService.initiateCheckoutSession().subscribe(data => {
      console.log(data.session.url);
      this.checkoutSession = data;

      const unPaidStatus =
        data.status === this.Session_Payment_Status.unpaid &&
        data.session.url.length > 0;

      const paidStatus =
        data.status === this.Session_Payment_Status.paid &&
        data.session.url.length > 0;

      // redirect to checkout page from Stripe
      if (unPaidStatus) {
        window.location.href = data.session.url;
      }
      // show the success page
      if (paidStatus) {
        this.showSuccess = true;
        this.showPayment = false;
      }
      console.log({ success: this.showSuccess, showPayment: this.showPayment });
    });
  }
}
