import { Injectable } from '@angular/core';
import { RangePresets } from '@app/types/range-presets';
import { DateInterval } from '@backend/types/date-interval';
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  parse,
  startOfDay,
  startOfMonth,
  startOfYear
} from 'date-fns';
import { Observable, defer, forkJoin, map, of, take } from 'rxjs';
import { TenantService } from './tenant.service';
import {
  PayrollDefinition,
  PayrollDefinitionTwicePerMonth
} from '@backend/types/payroll-definition';
import { createDefaultPayroll } from '@app/utils/create-default-payroll';
import { PayFrequency } from '@backend/types/pay-frequency';
import { nextWeeklyPeriodStart } from '@app/utils/next-weekly-period-start';
import { nextTwoWeekPeriodStart } from '@app/utils/next-two-week-period-start';
import { nextMonthlyPeriodStart } from '@app/utils/next-monthly-period-start';
import { isBefore } from 'date-fns';
import { setDayOfMonth } from '@app/utils/set-day-of-month';

export function lastNDays(n: number) {
  const start = startOfDay(addDays(new Date(), -(n - 1)));
  const end = endOfDay(addDays(start, n - 1));
  return { start, end };
}

function getDaysFromTwiceMonthlyPayroll(
  payroll: PayrollDefinitionTwicePerMonth
) {
  const now = new Date();
  let day1 = parse(payroll.date1, 'yyyy-MM-dd', now).getDate();
  let day2 = parse(payroll.date2, 'yyyy-MM-dd', now).getDate();
  if (day2 < day1) {
    const tmp = day2;
    day2 = day1;
    day1 = tmp;
  }
  return [day1, day2];
}

function previousMonthBegin(date: Date): Date {
  return addMonths(new Date(date.getFullYear(), date.getMonth(), 1), -1);
}

@Injectable({ providedIn: 'root' })
export class InsightsDateRangeHelperService {
  public constructor(private _tenant: TenantService) {}

  public getIntervalFromPreset(preset: RangePresets): Observable<DateInterval> {
    switch (preset) {
      case RangePresets.ThisPayPeriod:
        return this._tenant.tenant$.pipe(
          take(1),
          map(({ settings }) => {
            const payroll = settings?.payroll ?? createDefaultPayroll();
            return this._getCurrentPayPeriodDates(payroll);
          })
        );
      case RangePresets.LastPayPeriod:
        return this._tenant.tenant$.pipe(
          take(1),
          map(({ settings }) => {
            const payroll = settings?.payroll ?? createDefaultPayroll();
            return this._getPrevPayPeriodDates(payroll);
          })
        );
      case RangePresets.Today:
        return of(lastNDays(1));
      case RangePresets.Yesterday:
        return (() => {
          const t = lastNDays(1);
          return of({ start: addDays(t.start, -1), end: addDays(t.end, -1) });
        })();
      case RangePresets.Last7Days:
        return of(lastNDays(7));
      case RangePresets.Last30Days:
        return of(lastNDays(30));
      case RangePresets.Last90Days:
        return of(lastNDays(90));
      case RangePresets.LastMonth:
        return (() => {
          const start = startOfMonth(addMonths(new Date(), -1));
          const end = endOfMonth(start);
          return of({ start, end });
        })();
      case RangePresets.ThisYear:
        return (() => {
          const start = startOfYear(new Date());
          const end = endOfYear(start);
          return of({ start, end });
        })();
      case RangePresets.LastYear:
        return (() => {
          const start = startOfYear(addYears(new Date(), -1));
          const end = endOfYear(start);
          return of({ start, end });
        })();
      case RangePresets.AllTime:
        return (() => {
          const start = new Date(2000, 0, 1, 0, 0, 0, 0);
          const end = endOfDay(new Date());
          return of({ start, end });
        })();
      default:
        throw new Error(`Cant create interval for ${preset}`);
    }
  }

  public detectPreset(range: DateInterval): Observable<RangePresets> {
    return defer(() => {
      const testPresets = [
        RangePresets.ThisPayPeriod,
        RangePresets.LastPayPeriod,
        RangePresets.Today,
        RangePresets.Yesterday,
        RangePresets.Last7Days,
        RangePresets.Last30Days,
        RangePresets.Last90Days,
        RangePresets.LastMonth,
        RangePresets.ThisYear,
        RangePresets.LastYear,
        RangePresets.AllTime
      ];
      return forkJoin(
        testPresets.map((preset) =>
          this.getIntervalFromPreset(preset).pipe(
            map((candidate) => ({ preset, candidate }))
          )
        )
      );
    }).pipe(
      map((options) => {
        for (const {
          preset,
          candidate: { start, end }
        } of options) {
          if (
            start.getTime() === range.start.getTime() &&
            end.getTime() === range.end.getTime()
          ) {
            return preset;
          }
        }
        return RangePresets.Custom;
      })
    );
  }

  private _getCurrentPayPeriodDates(payroll: PayrollDefinition): DateInterval {
    if (payroll.frequency === PayFrequency.EVERY_WEEK) {
      const next = nextWeeklyPeriodStart(
        parse(payroll.date1, 'yyyy-MM-dd', new Date())
      );
      return {
        start: startOfDay(addDays(next, -7)),
        end: endOfDay(addDays(next, -1))
      };
    }

    if (payroll.frequency === PayFrequency.EVERY_TWO_WEEKS) {
      const next = nextTwoWeekPeriodStart(
        parse(payroll.date1, 'yyyy-MM-dd', new Date())
      );
      return {
        start: startOfDay(addDays(next, -14)),
        end: endOfDay(addDays(next, -1))
      };
    }

    if (payroll.frequency === PayFrequency.ONCE_PER_MONTH) {
      const next = nextMonthlyPeriodStart(
        parse(payroll.date1, 'yyyy-MM-dd', new Date())
      );
      return {
        start: startOfDay(addMonths(next, -1)),
        end: endOfDay(addDays(next, -1))
      };
    }

    if (payroll.frequency === PayFrequency.TWICE_PER_MONTH) {
      const now = new Date();
      const [day1, day2] = getDaysFromTwiceMonthlyPayroll(payroll);
      const date1 = startOfDay(setDayOfMonth(now, day1));
      const date2 = startOfDay(setDayOfMonth(now, day2));
      if (isBefore(now, date1)) {
        const prevMonthBegin = previousMonthBegin(now);
        return {
          start: startOfDay(setDayOfMonth(prevMonthBegin, day2)),
          end: endOfDay(addDays(date1, -1))
        };
      }
      if (isBefore(now, date2)) {
        return {
          start: date1,
          end: endOfDay(addDays(date2, -1))
        };
      }
      return {
        start: date2,
        end: endOfDay(addDays(addMonths(date1, 1), -1))
      };
    }
  }

  private _getPrevPayPeriodDates(payroll: PayrollDefinition): DateInterval {
    if (payroll.frequency === PayFrequency.EVERY_WEEK) {
      const next = nextWeeklyPeriodStart(
        parse(payroll.date1, 'yyyy-MM-dd', new Date())
      );
      return {
        start: startOfDay(addDays(next, -14)),
        end: endOfDay(addDays(next, -8))
      };
    }

    if (payroll.frequency === PayFrequency.EVERY_TWO_WEEKS) {
      const next = nextTwoWeekPeriodStart(
        parse(payroll.date1, 'yyyy-MM-dd', new Date())
      );
      return {
        start: startOfDay(addDays(next, -28)),
        end: endOfDay(addDays(next, -15))
      };
    }

    if (payroll.frequency === PayFrequency.ONCE_PER_MONTH) {
      const next = nextMonthlyPeriodStart(
        parse(payroll.date1, 'yyyy-MM-dd', new Date())
      );
      return {
        start: startOfDay(addMonths(next, -2)),
        end: endOfDay(addDays(addMonths(next, -1), -1))
      };
    }

    if (payroll.frequency === PayFrequency.TWICE_PER_MONTH) {
      const now = new Date();
      const [day1, day2] = getDaysFromTwiceMonthlyPayroll(payroll);
      const date1 = startOfDay(setDayOfMonth(now, day1));
      const date2 = startOfDay(setDayOfMonth(now, day2));
      if (isBefore(now, date1)) {
        const prevMonthBegin = previousMonthBegin(now);
        return {
          start: startOfDay(setDayOfMonth(prevMonthBegin, day1)),
          end: endOfDay(addDays(setDayOfMonth(prevMonthBegin, day2), -1))
        };
      }
      if (isBefore(now, date2)) {
        const prevMonthBegin = previousMonthBegin(now);
        return {
          start: startOfDay(setDayOfMonth(prevMonthBegin, day2)),
          end: endOfDay(addDays(date1, -1))
        };
      }
      return {
        start: date1,
        end: endOfDay(addDays(date2, -1))
      };
    }
  }
}
