import { createGesture } from '@ionic/angular';

const gesture = createGesture({
  el: document.querySelector('ion-app'),
  threshold: 0,
  gestureName: 'disable-back-gesture',
  gesturePriority: 40.5 // priority of swipe to go back is 40
});

export const disableBackGesture = () => {
  gesture.enable(true);
};

export const enableBackGesture = () => {
  gesture.enable(false);
};
