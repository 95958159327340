import { Component, ViewChild } from '@angular/core';
import { UserApiService, AuthenticationService, ROLES } from '@app/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {
  IonNav,
  LoadingController,
  ViewDidEnter,
  ViewWillEnter
} from '@ionic/angular';
import { InviteUserComponent } from './components/invite-user/invite-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { ObjectId } from '@app/types/object-id';
import { UserModel } from '@app/core/model/user.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements ViewDidEnter, ViewWillEnter {
  @ViewChild(MatSort, { static: false })
  public sort: MatSort;

  protected cdkItemSize = 61;
  protected hasEditRights = false;
  protected currentUser: UserModel;
  protected users: UserModel[] = [];
  protected dataSource: MatTableDataSource<UserModel>;
  protected searchTerm = '';
  protected showDisabledUsers = false;
  protected isLoading = false;
  protected displayedColumns: string[] = [
    'firstName',
    'lastName',
    'email',
    'supervisor',
    'roles'
  ];

  public constructor(
    private readonly _userApiService: UserApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _ionNav: IonNav,
    private readonly _loadingCtrl: LoadingController
  ) {
    this.currentUser = this._authenticationService.user;
    this.hasEditRights = this.currentUser.roles.includes(ROLES.adminName);
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  public ionViewWillEnter() {
    this._getUsers();
  }

  protected inviteUser() {
    this._ionNav.push(InviteUserComponent);
  }

  protected editUser(userId: ObjectId) {
    this._ionNav.push(EditUserComponent, { userId: userId.toString() });
  }

  protected handleSearchInput(event: any): void {
    this.searchTerm = event.detail.value;
    this.dataSource.filter = event.detail.value.trim().toLowerCase();
  }

  protected trackItems(_: number, item: UserModel) {
    return item._id;
  }

  protected handleDisabledUsersToggle(event: any): void {
    this.showDisabledUsers = event.detail.checked;

    if (event.detail.checked) {
      this.dataSource = new MatTableDataSource(this.users);
    } else {
      this.dataSource = new MatTableDataSource(
        this.users.filter((user) => !user.isDeleted)
      );
    }
    setTimeout(() => {
      this.dataSource.sort = this.sort;
      this.dataSource.filter = this.searchTerm;
    }, 100);
  }

  private _getUsers() {
    this.isLoading = true;
    this.users = [];
    this._userApiService.getAllUsersWithDeleted().subscribe((data) => {
      if (data) {
        this.isLoading = false;
        this.users = data;
        this.dataSource = new MatTableDataSource(
          data.filter((user) => !user.isDeleted)
        );
        setTimeout(() => {
          this.dataSource.sort = this.sort;
          this.dataSource.filter = this.searchTerm;
        }, 100);
      }
    });
  }
}
