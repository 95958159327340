import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { formatZonedTime } from '@app/utils/format-zoned-time';
import { getZonedTime } from '@app/utils/get-zoned-time';
import { IonDatetime, IonPopover } from '@ionic/angular';
import {
  parse,
  addHours,
  setMinutes,
  setSeconds,
  setMilliseconds
} from 'date-fns';
import { toDate, format } from 'date-fns-tz';
import { getFormattedZonedTime } from '@app/utils/get-formatted-zoned-time';

function getClosestTime(time: Date, sortedMinuteValues: number[]): Date {
  const baseTime = setMilliseconds(setSeconds(setMinutes(time, 0), 0), 0);
  let compareDates = sortedMinuteValues.map((minutes) =>
    setMinutes(baseTime, minutes)
  );
  compareDates.push(addHours(baseTime, 1));
  compareDates = compareDates.filter((d) => d.getTime() >= time.getTime());
  return compareDates[0];
}

@Component({
  selector: 'app-time-form-control',
  templateUrl: './time-form-control.component.html',
  styleUrls: ['./time-form-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TimeFormControlComponent
    }
  ]
})
export class TimeFormControlComponent implements ControlValueAccessor {
  @Input()
  public minuteStep? = 15;

  @Input()
  public label: string;

  @ViewChild('popover')
  protected popover: IonPopover;

  @ViewChild('datetime')
  protected datetime: IonDatetime;

  protected isOpen = false;
  protected value: string | null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private _onChange = (value: string) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouched = () => {};

  private _touched = false;

  protected disabled = false;

  public constructor(private _cdr: ChangeDetectorRef) {}

  protected get minuteValues() {
    return new Array(60 / this.minuteStep)
      .fill(0)
      .map((_, index) => index * this.minuteStep);
  }

  public writeValue(value: string) {
    this.value = value;
    this._cdr.markForCheck();
  }

  public registerOnChange(onChange: (v: string) => void) {
    this._onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void) {
    this._onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  protected onTriggerClick(e: Event) {
    this.popover.event = e;
    const parsedTime =
      this.value !== null
        ? parse(this.value, 'HH:mm', new Date())
        : getClosestTime(getZonedTime(new Date()), this.minuteValues);
    this.datetime.reset(formatZonedTime(parsedTime));
    this.isOpen = true;
  }

  protected onPopovedDismiss(): void {
    this.isOpen = false;
  }

  protected onCalendarDoneClick(): void {
    this.datetime.confirm().then(() => {
      const zonedTime =
        (this.datetime.value as string) ?? getFormattedZonedTime(new Date());
      const date = toDate(zonedTime);
      const time = format(date, 'HH:mm');
      this._touched = true;
      this.value = time;
      this._onChange(time);
      this._onTouched();
    });
    this.isOpen = false;
  }
}
