import { Pipe, PipeTransform } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IBackdoorInfo } from '@backend/models/types/backdoor-info';
import { ITenant } from '@backend/models/types/tenant';

@Pipe({
  name: 'backdoorInfoTenants',
  standalone: true
})
export class BackdoorInfoTenantsPipe implements PipeTransform {
  public transform(
    backdoorInfo: IBackdoorInfo,
    tenants: MongoStoredObject<ITenant>[]
  ): string {
    if (backdoorInfo.tenantsToShow.length === 0) {
      return 'None';
    }

    return backdoorInfo.tenantsToShow
      .map((tenantId) => {
        return tenants.find(
          (tenant) => tenant._id.toString() === tenantId.toString()
        ).name;
      })
      .join(', ');
  }
}
