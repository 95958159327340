import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  ICompensation,
  ICompensationInput
} from '@backend/models/types/compensation';
import {
  ECompensationFieldType,
  ICompensationField
} from '@backend/models/types/compensation-field';
import {
  ECompensationPoolType,
  ICompensationPool
} from '@backend/models/types/compensation-pool';
import { Observable } from 'rxjs';

@Injectable()
export class CompensationApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getCompensations(): Observable<
    MongoStoredObject<
      Omit<ICompensation, 'pools' | 'fields'> & {
        pools: { pool: MongoStoredObject<ICompensationPool>; value: number }[];
        fields: MongoStoredObject<ICompensationField>[];
      }
    >[]
  > {
    return this._httpClient.get<
      MongoStoredObject<
        Omit<ICompensation, 'pools' | 'fields'> & {
          pools: {
            pool: MongoStoredObject<ICompensationPool>;
            value: number;
          }[];
          fields: MongoStoredObject<ICompensationField>[];
        }
      >[]
    >('/compensation');
  }

  public getCompensationPools(): Observable<
    MongoStoredObject<ICompensationPool>[]
  > {
    return this._httpClient.get<MongoStoredObject<ICompensationPool>[]>(
      '/compensation/pools'
    );
  }

  public addCompensationPool(input: {
    name: string;
    type: ECompensationPoolType;
    users: string[];
  }): Observable<MongoStoredObject<ICompensationPool>> {
    return this._httpClient.post<MongoStoredObject<ICompensationPool>>(
      '/compensation/pools',
      input
    );
  }

  public updateCompensationPool(
    compensationPoolId: string,
    input: {
      name: string;
      type: ECompensationPoolType;
      users: string[];
    }
  ): Observable<MongoStoredObject<ICompensationPool>> {
    return this._httpClient.put<MongoStoredObject<ICompensationPool>>(
      `/compensation/pools/${compensationPoolId}`,
      input
    );
  }

  public removeCompensationPool(
    compensationPoolId: string
  ): Observable<MongoStoredObject<ICompensationPool>> {
    return this._httpClient.delete<MongoStoredObject<ICompensationPool>>(
      `/compensation/pools/${compensationPoolId}`
    );
  }

  public parseCsv(file: File): Observable<any[]> {
    const formData = new FormData();
    formData.append('file', file);

    return this._httpClient.post<any[]>('/compensation/parse-file', formData);
  }

  public getCompensationFields(): Observable<
    MongoStoredObject<ICompensationField>[]
  > {
    return this._httpClient.get<MongoStoredObject<ICompensationField>[]>(
      '/compensation/fields'
    );
  }

  public addCompensationField(input: {
    name: string;
    type: ECompensationFieldType;
  }): Observable<MongoStoredObject<ICompensationField>> {
    return this._httpClient.post<MongoStoredObject<ICompensationField>>(
      '/compensation/fields',
      input
    );
  }

  public updateCompensationField(
    compensationFieldId: string,
    input: {
      name: string;
    }
  ): Observable<MongoStoredObject<ICompensationField>> {
    return this._httpClient.put<MongoStoredObject<ICompensationField>>(
      `/compensation/fields/${compensationFieldId}`,
      input
    );
  }

  public removeCompensationField(
    compensationFieldId: string
  ): Observable<MongoStoredObject<ICompensationField>> {
    return this._httpClient.delete<MongoStoredObject<ICompensationField>>(
      `/compensation/fields/${compensationFieldId}`
    );
  }

  public submitCompensation(
    input: ICompensationInput
  ): Observable<MongoStoredObject<ICompensation>> {
    return this._httpClient.post<MongoStoredObject<ICompensation>>(
      '/compensation',
      input
    );
  }

  public getCompensationAnnouncementPreview(
    userId: string,
    input: ICompensationInput
  ): Observable<{ title: string; body: string }> {
    return this._httpClient.post<{ title: string; body: string }>(
      '/compensation/announcement-preview',
      {
        compensation: input,
        userId
      }
    );
  }
}
