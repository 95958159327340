import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { PsychologicalSurveyComponent } from './psychological-survey.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { PsychologicalSurveyApiService } from '@app/core/service/psychological-survey-api.service';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';
import { PsychologicalBehaviorSelectorComponent } from '@app/modules/psychological-behavior-selector/psychological-behavior-selector.component';
import { FormsModule } from '@angular/forms';
import { PositiveFeedbackQuestionModule } from '@app/modules/positive-feedback-question/positive-feedback-question.module';
import { NegativeFeedbackQuestionModule } from '@app/modules/negative-feedback-question/negative-feedback-question.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Psychological Survey',
      rootComponent: PsychologicalSurveyComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    SlimMenuButtonComponent,
    DirectivesModule,
    EmptyStubModule,
    UserSelectorComponent,
    PsychologicalBehaviorSelectorComponent,
    FormsModule,
    PositiveFeedbackQuestionModule,
    NegativeFeedbackQuestionModule
  ],
  declarations: [PsychologicalSurveyComponent],
  providers: [PsychologicalSurveyApiService]
})
export class PsychologicalSurveyModule {}
