import { Component } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { ApprovalsListService } from '@app/core/service/approvals-list.service';
import { ITask } from '@backend/models/types/task';
import { UserModel } from '@app/core/model/user.model';
import { ModalController } from '@ionic/angular';
import { ApprovalModalComponent } from '@app/modules/approval-modal/approval-modal.component';

@Component({
  selector: 'app-approvals',
  templateUrl: './approvals.component.html'
})
export class ApprovalsComponent {
  protected waitingForApprovalsTaskPerformances: MongoStoredObject<
    ITaskPerformance & {
      user: UserModel;
      task: MongoStoredObject<ITask>;
    }
  >[] = [];

  public constructor(
    private readonly _approvalsListService: ApprovalsListService,
    private readonly _modalCtrl: ModalController
  ) {
    this._approvalsListService.approvals$.subscribe(
      (waitingForApprovalsTaskPerformances) => {
        this.waitingForApprovalsTaskPerformances =
          waitingForApprovalsTaskPerformances;
      }
    );
  }

  protected listItemTrackBy(
    _index: number,
    item: MongoStoredObject<
      ITaskPerformance & {
        user: UserModel;
        task: MongoStoredObject<ITask>;
      }
    >
  ) {
    return item._id;
  }

  protected async onItemClick(
    taskPerformance: MongoStoredObject<
      ITaskPerformance & {
        user: UserModel;
        task: MongoStoredObject<ITask>;
      }
    >
  ) {
    const timeAttackModal = await this._modalCtrl.create({
      id: 'approval-modal',
      component: ApprovalModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        taskPerformance
      },
      handle: false
    });
    timeAttackModal.present();
  }
}
