import { Injectable } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

@Injectable({ providedIn: 'root' })
export class HapticsService {
  public async hapticsImpact(impactStyle: ImpactStyle = ImpactStyle.Light) {
    console.log('HapticsService hapticsImpact = ', impactStyle);

    await Haptics.impact({ style: impactStyle });
  }
}
