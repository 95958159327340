import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@app/modules/directives.module';

@Component({
  selector: 'app-read-eye',
  templateUrl: './read-eye.component.html',
  styleUrls: ['./read-eye.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule, DirectivesModule]
})
export class ReadEyeComponent {
  @Input()
  public readonly label: string;

  @Input()
  public readonly button?: boolean = false;
}
