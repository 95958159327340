import { Pipe, PipeTransform } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';

import FuzzySearch from 'fuzzy-search';

@Pipe({
  name: 'filterUsers',
  standalone: true
})
export class FilterUsersPipe implements PipeTransform {
  public transform(
    users: (UserModel & { pin: string })[],
    filter: string
  ): (UserModel & { pin: string })[] {
    if (filter.length === 0) {
      return users;
    }
    const searcher = new FuzzySearch(users, ['firstName', 'lastName'], {
      caseSensitive: false
    });
    return searcher.search(filter);
  }
}
