<ion-content>
  <app-width-restricted-container>
    <ion-list>
      <ion-item
        button
        [detail]="false"
        *ngFor="let behavior of selected; trackBy: trackItems"
        (click)="checkboxClick($event, behavior.id)"
        appHaptics
      >
        <ion-checkbox [value]="behavior.id" [checked]="behavior.checked">
          <span class="psychological-behavior-name">
            {{ behavior.name }}
          </span>
        </ion-checkbox>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>
