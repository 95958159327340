import { Types } from 'mongoose';
import { IUploadedFile } from '../../types/uploaded-file';

export enum ETagStatus {
  RESERVED = 'RESERVED',
  UPLOADED = 'UPLOADED'
}

export interface ITag {
  tagNumber: string;
  customerCell: string;
  customerName: string;
  serverId: Types.ObjectId | null;
  notes: string;
  count: number;
  status: ETagStatus;
  user: Types.ObjectId;
  tenant: Types.ObjectId;
  pickupDate: string;
  serverTaskPerformance: Types.ObjectId | null;
  dateReserved: Date;
  dateUploaded: Date | null;
  airtableRecordId: string;
  goHighLevelContactId: string;
  goHighLevelOpportunityId: string;
  pickupStatus: string;
  pickupStatusHistory: {
    date: Date;
    pickupStatus: string;
  }[];
  customerIsNotified: boolean;
  images: IUploadedFile[];
}
