import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { DiceComponent } from './components/dice/dice.component';

export interface ILotteryMachineTicket {
  id: string;
  name: string;
  tickets: number;
}

@Component({
  selector: 'app-lottery-machine',
  templateUrl: './lottery-machine.component.html',
  styleUrls: ['./lottery-machine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LotteryMachineComponent {
  @Input()
  public lotteryTickets: ILotteryMachineTicket[] = [];

  @ViewChild('diceComponent')
  public diceComponent!: DiceComponent;

  protected winners: ILotteryMachineTicket[] = [];
  protected isRolling = false;

  public constructor(private readonly _cdr: ChangeDetectorRef) {}

  protected getWinner() {
    let lotteryTotal = 0;
    this.lotteryTickets.forEach(function (data) {
      lotteryTotal = lotteryTotal + data.tickets;
    });

    const winningTicket = Math.random() * lotteryTotal;

    let ticket = 0;
    let winner = 0;

    for (let i = 0; i < this.lotteryTickets.length; i++) {
      ticket += this.lotteryTickets[i].tickets;
      if (winningTicket < ticket) {
        winner = i;
        break;
      }
    }

    return this.lotteryTickets[winner];
  }

  protected roll() {
    this.isRolling = true;

    setTimeout(() => {
      this.diceComponent.roll();
    }, 500);

    setTimeout(() => {
      const winner = this.getWinner();
      this.winners.push(winner);
      this.lotteryTickets = this.lotteryTickets.filter(
        (ticket) => ticket.id !== winner.id
      );
      this.isRolling = false;
      this._cdr.markForCheck();
    }, 2500);
  }

  protected trackItems(_: number, item: ILotteryMachineTicket) {
    return item.id;
  }
}
