import { Component, Input } from '@angular/core';
import { ToastService } from '@app/core/service/toast.service';
import {
  IBackdoorInfo,
  IBackdoorInfoLink
} from '@backend/models/types/backdoor-info';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-backdoor-info-modal',
  templateUrl: './backdoor-info-modal.component.html'
})
export class BackdoorInfoModalComponent {
  @Input()
  public backdoorInfo: IBackdoorInfo;

  @Input()
  public preview: boolean;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _toastService: ToastService
  ) {}

  protected async goToUrl(link: IBackdoorInfoLink) {
    try {
      await Browser.open({
        url: encodeURI(link.url)
      });
    } catch (e) {
      this._toastService.presentToast('Unable to open link');
    }
  }

  protected dismiss() {
    this._modalCtrl.dismiss({}, 'cancel', 'backdoor-info-modal');
  }
}
