<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Announcement</ion-title>
    <ion-buttons slot="end" *ngIf="!isLoading && announcement">
      <app-read-eye
        [button]="true"
        (click)="showAcknowledgments()"
        label="{{ acknowledged.length }}/{{ announcement.recipients.length }}"
      />
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <ion-grid *ngIf="isLoading || !announcement">
        <ion-row class="ion-justify-content-center">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </ion-row>
      </ion-grid>

      <div
        *ngIf="!isLoading && announcement"
        class="announcement-view__container"
      >
        <div class="announcement-view__header">
          <div class="announcement-view__heading">
            <div class="announcement-view__heading-text">
              {{ announcement.title }}
            </div>
            <div
              class="announcement-view__heading-actions"
              *ngIf="hasEditRights"
            >
              <ion-button
                fill="clear"
                size="small"
                appHaptics
                class="announcement-view__heading-action"
                (click)="openOptions($event)"
              >
                <ion-icon
                  slot="icon-only"
                  name="ellipsis-horizontal"
                  class="announcement-view__heading-action-icon"
                ></ion-icon>
              </ion-button>
            </div>
          </div>
          <h2 class="announcement-view__subheading">
            <span>
              {{ fDateAndTime(announcement.date) }} |
              {{ announcement.authorName }}
            </span>
            <span>{{ editedString }}</span>
          </h2>
          <div class="app-divider-line"></div>
        </div>
        <app-wysiwyg-editor [value]="body" mode="view" />
        <app-announcement-thread [announcements]="thread" />
      </div>
    </div>
    <div
      class="bottom-content"
      *ngIf="!alreadyAcknowledged && !isLoading && announcement"
    >
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col
            size="6"
            *ngIf="announcement.actions !== announcementActions.ONLY_RESPOND"
          >
            <ion-button
              color="dark"
              (click)="acknowledge()"
              appHaptics
              expand="block"
            >
              {{
                announcement.acknowledgeButtonText ===
                announcementAcknowledgeButtonText.UNDERSTAND_AND_AGREE
                  ? 'Understand and Agree'
                  : 'Acknowledge'
              }}
            </ion-button>
          </ion-col>
          <ion-col
            size="6"
            *ngIf="
              announcement.actions !== announcementActions.ONLY_ACKNOWLEDGE &&
              announcement.author
            "
          >
            <ion-button
              color="dark"
              (click)="respond()"
              appHaptics
              expand="block"
              fill="outline"
            >
              {{ respondButtonText }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-popover
  [dismissOnSelect]="true"
  [isOpen]="showOptions"
  (didDismiss)="showOptions = false"
  #options
>
  <ng-template>
    <ion-content>
      <ion-list lines="none">
        <ion-item button (click)="editAnnouncement()" appHaptics>
          <ion-label>Edit</ion-label>
        </ion-item>
        <ion-item button (click)="askDeleteAnnouncement()" appHaptics>
          <ion-label>Delete</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>
