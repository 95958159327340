import { Pipe, PipeTransform } from '@angular/core';
import { EBackdoorInfoPlatform } from '@backend/models/types/backdoor-info';

@Pipe({
  name: 'formatPlatforms',
  standalone: true
})
export class FormatPlatformsPipe implements PipeTransform {
  public transform(platforms: EBackdoorInfoPlatform[]): string {
    if (platforms.length === 0) {
      return 'None';
    }

    return platforms.join(', ');
  }
}
