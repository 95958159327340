import { Pipe, PipeTransform } from '@angular/core';
import { ChecklistItem } from '../../../types/checklist-item';

@Pipe({
  name: 'notInFolder'
})
export class NotInFolderPipe implements PipeTransform {
  public transform(items: ChecklistItem[]): ChecklistItem[] {
    return items.filter((item) => !item.task.taskFolder);
  }
}
