import { Pipe, PipeTransform } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskFolder } from '@backend/models/types/task-folder';
import FuzzySearch from 'fuzzy-search';

@Pipe({
  name: 'filterTaskFolders',
  standalone: true
})
export class FilterTaskFoldersPipe implements PipeTransform {
  public transform(
    items: MongoStoredObject<ITaskFolder>[],
    filter: string
  ): MongoStoredObject<ITaskFolder>[] {
    if (filter.length === 0) {
      return items;
    }
    const searcher = new FuzzySearch(items, ['title'], {
      caseSensitive: false
    });
    return searcher.search(filter);
  }
}
