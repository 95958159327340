import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {
  public constructor(private readonly _router: Router) {}

  protected backToHome() {
    this._router.navigate(['/'], {
      replaceUrl: true
    });
  }
}
