<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="end">
      <ion-back-button text="" icon="close" appHaptics></ion-back-button>
    </ion-buttons>
    <ion-title>
      {{ backdoorInfo ? 'Update' : 'Edit' }} Backdoor Message
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <ion-list [formGroup]="mainFormGroup">
        <ion-item>
          <ion-input
            label="Title"
            formControlName="title"
            fill="solid"
            placeholder="New Update Available"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Message"
            formControlName="message"
            fill="solid"
            placeholder="Please update app to the latest version"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Version Range"
            formControlName="versionRange"
            fill="solid"
            placeholder=">=1.2.7 <1.3.0"
          >
          </ion-input>
          <ion-button
            fill="clear"
            (click)="openVersionsHelp()"
            class="ion-no-margin"
          >
            <ion-icon slot="icon-only" name="information-circle"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-item>
          <ion-toggle formControlName="canDismiss">
            User Can Dismiss
          </ion-toggle>
        </ion-item>
        <ion-item>
          <ion-toggle formControlName="showToAllTenants">
            Show to All Tenants
          </ion-toggle>
        </ion-item>
        <ion-item *ngIf="!showToAllTenants">
          <ion-select
            label="Tenants"
            [multiple]="true"
            formControlName="tenantsToShow"
            appHaptics
          >
            <ion-select-option
              *ngFor="let tenant of tenantList$ | async as tenantList"
              [value]="tenant._id"
            >
              {{ tenant.name }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item>
          <ion-select
            label="Platforms"
            [multiple]="true"
            formControlName="platformsToShow"
            appHaptics
          >
            <ion-select-option
              *ngFor="let platform of platforms"
              [value]="platform"
            >
              {{ platform }}
            </ion-select-option>
          </ion-select>
        </ion-item>
        <ion-item lines="none">Links:</ion-item>
        <div class="links">
          <ion-chip
            class="link"
            *ngFor="let link of links; let i = index"
            (click)="removeLink(i)"
            appHaptics
          >
            <ion-label>{{ link.text }}</ion-label>
            <ion-icon name="close-circle"></ion-icon>
          </ion-chip>
          <ion-fab-button
            size="small"
            color="light"
            class="add-button"
            (click)="addLink()"
            appHaptics
          >
            <ion-icon name="add"></ion-icon>
          </ion-fab-button>
        </div>
      </ion-list>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="4">
            <ion-button
              color="dark"
              fill="outline"
              expand="block"
              [disabled]="mainFormGroup.invalid"
              appHaptics
              [disableHaptics]="mainFormGroup.invalid"
              (click)="preview()"
            >
              Preview
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button
              color="dark"
              expand="block"
              [disabled]="mainFormGroup.invalid"
              appHaptics
              [disableHaptics]="mainFormGroup.invalid"
              (click)="onFormSubmit()"
            >
              Save
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>
