import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  ViewChild
} from '@angular/core';
import { ModalController, IonModal } from '@ionic/angular';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { UserModel } from '@app/core/model/user.model';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { BehaviorModel } from '@app/core/model/behavior.model';
import fastDeepEqual from 'fast-deep-equal';
import { AuthenticationService } from '@app/core';
import { isMobile } from '@app/utils/is-mobile';

export interface IEditRecognitionModalResult {
  userId: string;
  behaviorId: string;
  description: string;
  isPrivate: boolean;
}

export interface IEditRecognitionModalInitialData {
  userId: ObjectId;
  behaviorId: ObjectId;
  description: string;
  isPrivate: boolean;
}

export interface IEditRecognitionModalProps {
  initialData?: IEditRecognitionModalInitialData;
  createMode?: boolean;
  behaviors: BehaviorModel[];
}

@Component({
  selector: 'app-edit-recognition-modal',
  templateUrl: './edit-recognition-modal.component.html',
  styleUrls: ['./edit-recognition-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditRecognitionModalComponent implements OnInit {
  @Input()
  public readonly initialData?: IEditRecognitionModalInitialData;

  @Input()
  public readonly createMode?: boolean = false;

  @Input()
  public readonly behaviors: BehaviorModel[];

  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  @ViewChild('behaviorSelectorModal', { static: true })
  public behaviorSelectorModal!: IonModal;

  protected form = new FormGroup({
    userId: new FormControl<ObjectId>(null, Validators.required),
    behaviorId: new FormControl<ObjectId>(null, Validators.required),
    description: new FormControl<string>('', Validators.required),
    isPrivate: new FormControl<boolean>(false)
  });
  protected allUsers: UserModel[] = [];
  protected presentingElement: Element;
  protected isMobile = isMobile();

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _userListService: UserListService,
    private readonly _authenticationService: AuthenticationService
  ) {}

  public ngOnInit(): void {
    this.presentingElement = document.querySelector('#edit-recognition-modal');

    if (this.initialData) {
      this.form.reset({
        userId: this.initialData.userId,
        behaviorId: this.initialData.behaviorId,
        description: this.initialData.description,
        isPrivate: this.initialData.isPrivate
      });
    }

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users.filter(
        (user) =>
          user._id.toString() !==
          this._authenticationService.user._id.toString()
      );
    });
  }

  protected get canSave() {
    if (this.createMode) {
      return (
        this.form.get('userId').value &&
        this.form.get('behaviorId').value &&
        this.form.get('description').value
      );
    } else {
      return (
        this.form.get('userId').value &&
        this.form.get('behaviorId').value &&
        this.form.get('description').value &&
        !fastDeepEqual(this.initialData, {
          userId: this.form.get('userId').value,
          behaviorId: this.form.get('behaviorId').value,
          description: this.form.get('description').value,
          isPrivate: this.form.get('isPrivate').value
        })
      );
    }
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.form.get('userId').value];
  }

  protected get currentUser(): UserModel | null {
    const user = this.allUsers.find(
      (user) => user._id === this.form.get('userId').value
    );
    return user;
  }

  protected get currentBehavior(): BehaviorModel | null {
    const behavior = this.behaviors.find(
      (behavior) => behavior._id === this.form.get('behaviorId').value
    );
    return behavior;
  }

  protected get privateInfo() {
    if (this.form.get('isPrivate').value) {
      return {
        description: this.currentUser
          ? `Only visible to you, ${this.currentUser.firstName}, and administrators.`
          : 'Only visible to you and administrators.'
      };
    } else {
      return {
        description: 'Visible to everyone in your company.'
      };
    }
  }

  protected onUserChange(event: ObjectId[]): void {
    this.form.controls.userId.setValue(event[0]);
    this.userSelectorModal.dismiss();
  }

  protected onBehaviorChange(event: ObjectId): void {
    this.form.controls.behaviorId.setValue(event);
    this.behaviorSelectorModal.dismiss();
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'edit-recognition-modal');
  }

  protected onSaveClick(): void {
    const result: IEditRecognitionModalResult = {
      userId: this.form.get('userId').value,
      behaviorId: this.form.get('behaviorId').value,
      description: this.form.get('description').value,
      isPrivate: this.form.get('isPrivate').value
    };

    this._modalCtrl.dismiss(result, 'confirm', 'edit-recognition-modal');
  }

  protected async presentSubModal(modal: IonModal) {
    modal.present();
  }
}
