import { inject } from '@angular/core';
import { forkJoin, map, take } from 'rxjs';
import { UserEntityService } from '../service/user-entity.service';
import { GlobalSettingsService } from '../service/global-settings.service';

export const superAdminGuard = () => {
  const globalSettings = inject(GlobalSettingsService);
  const userEntity = inject(UserEntityService);
  return forkJoin([
    globalSettings.globalSettings$.pipe(take(1)),
    userEntity.user$.pipe(take(1))
  ]).pipe(
    map(([settings, user]) => {
      return !!settings?.superAdmins.find(
        (u) => u._id.toString() === user._id.toString()
      );
    })
  );
};
