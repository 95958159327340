import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { ConnectedAccountsComponent } from './connected-accounts.component';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { DirectivesModule } from '@app/modules/directives.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Connected Accounts',
      rootComponent: ConnectedAccountsComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    SlimMenuButtonComponent,
    EmptyStubModule,
    DirectivesModule
  ],
  declarations: [ConnectedAccountsComponent]
})
export class ConnectedAccountsModule {}
