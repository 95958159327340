import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found.component';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [
    WidthRestrictedContainerModule,
    IonicModule,
    RouterModule.forChild([
      {
        path: '',
        component: NotFoundComponent
      }
    ]),
    DirectivesModule
  ],
  declarations: [NotFoundComponent]
})
export class NotFoundModule {}
