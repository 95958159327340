import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication';
import { ObjectId } from '@app/types/object-id';
import { RecognitionInput } from '@app/pages/recognition/types/recognition-input';
import { DateInterval } from '@backend/types/date-interval';
import { LedgerModel } from '../model/ledger.model';

interface IGetAllLedgersPaginatedResult {
  ledgers: LedgerModel[];
  totalItems: number;
  message: string;
  currentPage: string;
  totalPages: number;
}

@Injectable({
  providedIn: 'root'
})
export class LedgerService {
  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _authenticationService: AuthenticationService
  ) {}

  public getUnreadLedgers(): Observable<{
    items: LedgerModel[];
    count: number;
    totalCount: number;
  }> {
    return this._httpClient.get<{
      items: LedgerModel[];
      count: number;
      totalCount: number;
    }>('/ledger/unread');
  }

  public getCustomLedgers(page: number, limit: number): Observable<any> {
    return this._httpClient.get<any>(
      `/tenant/${this._authenticationService.user.tenant}/ledger?page=${page}&limit=${limit}`
    );
  }

  public getAllLedgersPaginated(
    page: number,
    pageSize: number,
    searchTerm?: string
  ): Observable<IGetAllLedgersPaginatedResult> {
    return this._httpClient.get<IGetAllLedgersPaginatedResult>(
      `/tenant/${
        this._authenticationService.user.tenant
      }/ledger?page=${page}&limit=${pageSize}${
        searchTerm ? `&searchTerm=${searchTerm}` : ''
      }`
    );
  }

  public getSentLedger(
    userId: string,
    range: DateInterval,
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<LedgerModel[]> {
    return this._httpClient.get<LedgerModel[]>(`/users/${userId}/ledger-sent`, {
      params: range
        ? {
            from: range.start.toISOString(),
            to: range.end.toISOString(),
            page,
            limit,
            searchTerm
          }
        : { page, limit, searchTerm }
    });
  }

  public getReceivedLedger(
    userId: string,
    range: DateInterval,
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<LedgerModel[]> {
    return this._httpClient.get<LedgerModel[]>(
      `/users/${userId}/ledger-received`,
      {
        params: range
          ? {
              from: range.start.toISOString(),
              to: range.end.toISOString(),
              page,
              limit,
              searchTerm
            }
          : { page, limit, searchTerm }
      }
    );
  }

  public getLedgerById(id: string): Observable<LedgerModel> {
    return this._httpClient.get<LedgerModel>(
      `/tenant/${this._authenticationService.user.tenant}/ledger/${id}`
    );
  }

  public readLedgerById(id: string): Observable<any> {
    return this._httpClient.post(
      `/tenant/${this._authenticationService.user.tenant}/ledger/${id}/read`,
      {}
    );
  }

  public deleteLedger(id: string): Observable<any> {
    return this._httpClient.delete(`/ledger/${id}`);
  }

  public editLedger(id: ObjectId, data: RecognitionInput): Observable<unknown> {
    return this._httpClient.patch(
      `/tenant/${this._authenticationService.user.tenant}/ledger/${id}`,
      data
    );
  }
}
