import { Component, Input, OnInit } from '@angular/core';
import { ObjectId } from '@app/types/object-id';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskDto } from '@app/types/task-dto';
import { ModalController } from '@ionic/angular';
import { TaskListService } from '@app/core/service/task-list.service';

@Component({
  selector: 'app-manage-user-tasks-modal',
  templateUrl: './manage-user-tasks-modal.component.html',
  styleUrls: ['./manage-user-tasks-modal.component.scss']
})
export class ManageUserTasksModalComponent implements OnInit {
  @Input()
  public userId: ObjectId;

  protected filter = '';
  protected tasks: (MongoStoredObject<TaskDto> & {
    isNotificationUponCompletion: boolean;
  })[] = [];
  protected initialTaskValues: {
    _id: ObjectId;
    isNotificationUponCompletion: boolean;
  }[] = [];

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _taskListService: TaskListService
  ) {}

  public ngOnInit(): void {
    this._taskListService.list$.subscribe((tasks) => {
      const formattedTasks = tasks
        .filter((task) => !task.isDeleted)
        .map((task) => ({
          ...task,
          isNotificationUponCompletion:
            task.notifyEveryoneUponCompletion ||
            task.notifyUsersUponCompletion.includes(this.userId)
        }));

      this.tasks = formattedTasks;
      this.initialTaskValues = formattedTasks.map((t) => ({
        _id: t._id,
        isNotificationUponCompletion: t.isNotificationUponCompletion
      }));
    });
  }

  protected get isAllNotificationChecked() {
    return (
      this.tasks.filter((t) => t.isNotificationUponCompletion).length ===
      this.tasks.length
    );
  }

  protected get isNotificationIndeterminate() {
    return (
      !this.isAllNotificationChecked &&
      this.tasks.filter((t) => t.isNotificationUponCompletion).length
    );
  }

  protected allNotificationChange(event: any): void {
    this.tasks = this.tasks.map((t) => ({
      ...t,
      isNotificationUponCompletion: event.detail.checked
    }));
  }

  protected trackItems(
    _: number,
    item: MongoStoredObject<TaskDto> & {
      isNotificationUponCompletion: boolean;
    }
  ) {
    return item._id.toString();
  }

  protected close() {
    this._modalCtrl.dismiss(null, 'destructive', 'manage-user-tasks-modal');
  }

  protected get result() {
    return this.tasks
      .map((t) => ({
        _id: t._id,
        isNotificationUponCompletion: t.isNotificationUponCompletion
      }))
      .filter((t) => {
        const initialTaskData = this.initialTaskValues.find(
          (task) => t._id.toString() === task._id.toString()
        );

        if (initialTaskData) {
          return (
            t.isNotificationUponCompletion !==
            initialTaskData.isNotificationUponCompletion
          );
        }
        return false;
      });
  }

  protected save() {
    this._taskListService.patchTasks(this.userId, this.result);
    this.close();
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected onNotificationChange(
    event: any,
    task: MongoStoredObject<TaskDto> & {
      isNotificationUponCompletion: boolean;
    }
  ) {
    task.isNotificationUponCompletion = event.detail.checked;
  }
}
