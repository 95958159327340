<ion-button
  *ngFor="let tab of tabs"
  fill="clear"
  expand="block"
  class="ion-no-margin tab-switch-button"
  [ngClass]="{ 'tab-switch-button--active': value === tab.value }"
  appHaptics
  [disableHaptics]="value === tab.value"
  (click)="onChange($event, tab.value)"
  [disabled]="disabled"
>
  {{ tab.text }}
</ion-button>
