import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pick',
  standalone: true
})
export class PickPipe implements PipeTransform {
  public transform<T extends Record<string, any>, K extends keyof T>(
    values: T[],
    key: K
  ): T[K][] {
    return values.map((obj) => obj[key]);
  }
}
