import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { IConnectedAccount } from '@app/core/service/connected-accounts-api.service';
import { initials } from '@app/utils/initials';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { LoadingController } from '@ionic/angular';
import { UserListService } from '@app/core/service/user-list.service';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';
import { ConnectedAccountsListService } from '@app/core/service/connected-accounts-list.service';

@Component({
  selector: 'app-connected-accounts',
  templateUrl: './connected-accounts.component.html',
  styleUrls: ['./connected-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnectedAccountsComponent {
  protected groups: MongoStoredObject<{ accounts: IConnectedAccount[] }>[] = [];
  protected allUsers: UserModel[] = [];
  protected step = '';
  protected selectedGroupId: string = null;
  protected selectedUserIds: ObjectId[] = [];

  public constructor(
    private readonly _connectedAccountsListService: ConnectedAccountsListService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _loadingCtrl: LoadingController,
    private readonly _userListService: UserListService
  ) {
    this._connectedAccountsListService.connectedAccountsGroups$.subscribe(
      (groups) => {
        this.groups = groups;
        this._cdr.markForCheck();
      }
    );

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });
  }

  protected get hiddenUsers() {
    return this.groups.reduce(
      (prev, cur) => [
        ...prev,
        ...cur.accounts.map((acc) => acc._id.toString())
      ],
      []
    );
  }

  protected accountInitials(firstName: string, lastName: string) {
    return initials(firstName, lastName);
  }

  protected async removeAccount(groupId: string, userId: string) {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._connectedAccountsListService
      .removeConnectedAccountFromGroup(groupId, userId)
      .subscribe(() => {
        loading.dismiss();
      });
  }
}
