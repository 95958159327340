<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="end">
      <ion-back-button text="" icon="close" appHaptics></ion-back-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="item === null; else updateTask">
        Create Tenant
      </ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <ion-grid [formGroup]="mainFormGroup">
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Tenant Name"
              class="app-w100 input-with-background"
              formControlName="tenantName"
              labelPlacement="floating"
              fill="solid"
              autocapitalize="on"
              [spellcheck]="true"
            >
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Admin First Name"
              class="app-w100 input-with-background"
              formControlName="adminFirstName"
              labelPlacement="floating"
              fill="solid"
              autocapitalize="on"
            >
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Admin Last Name"
              class="app-w100 input-with-background"
              formControlName="adminLastName"
              labelPlacement="floating"
              fill="solid"
              autocapitalize="on"
            >
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              type="email"
              label="Admin Email"
              class="app-w100 input-with-background"
              formControlName="adminEmail"
              labelPlacement="floating"
              fill="solid"
            >
            </ion-input>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              id="selectTimezone"
              label="Team Timezone"
              class="app-w100 input-with-background"
              formControlName="timezone"
              labelPlacement="floating"
              fill="solid"
              [readonly]="true"
              appHaptics
            >
            </ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="4" *ngIf="item === null; else updateActions">
            <ion-button
              type="submit"
              color="dark"
              expand="block"
              [disabled]="
                mainFormGroup.invalid || isBusy || mainFormGroup.pristine
              "
              appHaptics
              [disableHaptics]="
                mainFormGroup.invalid || isBusy || mainFormGroup.pristine
              "
              (click)="onFormSubmit()"
            >
              Create
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ng-template #updateTask> Edit tenant </ng-template>

<ng-template #updateActions>
  <ion-col size="4">
    <ion-button
      type="button"
      color="danger"
      expand="block"
      fill="outline"
      [disabled]="isBusy"
      (click)="onDeleteClick()"
    >
      Delete
    </ion-button>
  </ion-col>
  <ion-col size="4">
    <ion-button
      type="submit"
      color="dark"
      expand="block"
      [disabled]="mainFormGroup.invalid || isBusy || mainFormGroup.pristine"
      (click)="onFormSubmit()"
    >
      Update
    </ion-button>
  </ion-col>
</ng-template>

<ion-modal
  trigger="selectTimezone"
  #timezoneSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-timezone-selector
      class="ion-page"
      [timezones]="timezones"
      [initialTzCode]="getTzCode"
      (selectionChange)="onTimezoneSelect($event)"
      (selectionCancel)="timezoneSelectorModal.dismiss()"
    >
    </app-timezone-selector>
  </ng-template>
</ion-modal>
