import { isWithinInterval } from 'date-fns';
import { DateInterval } from '../../../backend/src/types/date-interval';

export function isIntervalsIntersect(
  i1: DateInterval,
  i2: DateInterval
): boolean {
  return (
    isWithinInterval(i1.start, i2) ||
    isWithinInterval(i1.end, i2) ||
    isWithinInterval(i2.start, i1) ||
    isWithinInterval(i2.end, i1)
  );
}
