import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ValuesAccordionComponent } from './values-accordion.component';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [CommonModule, IonicModule, EmptyStubModule, DirectivesModule],
  exports: [ValuesAccordionComponent],
  declarations: [ValuesAccordionComponent]
})
export class ValuesAccordionModule {}
