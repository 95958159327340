import { NgModule } from '@angular/core';
import { SkillMatrixForTaskModalComponent } from './skill-matrix-for-task-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';
import { SkillMatrixSelectModule } from '../skill-matrix-status-select/skill-matrix-status-select.module';
import { UserSelectorComponent } from '../user-selector/user-selector.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    WidthRestrictedContainerModule,
    SkillMatrixSelectModule,
    UserSelectorComponent
  ],
  exports: [SkillMatrixForTaskModalComponent],
  declarations: [SkillMatrixForTaskModalComponent]
})
export class SkillMatrixForTaskModalModule {}
