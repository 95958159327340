import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatRoles',
  standalone: true
})
export class FormatRolesPipe implements PipeTransform {
  public transform(roles: string[]): string {
    if (roles.length === 0) {
      return 'None';
    }

    return roles.join(', ');
  }
}
