import { Injectable } from '@angular/core';
import { Observable, Subject, repeat, shareReplay, tap } from 'rxjs';
import {
  CreateTenantInput,
  TenantApiService,
  TenantPatchInput
} from './tenant-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITenant } from '@backend/models/types/tenant';
import { ObjectId } from '@app/types/object-id';
import { IIntegration } from '@backend/models/types/integration';

@Injectable({ providedIn: 'root' })
export class TenantListService {
  private readonly _update$ = new Subject<void>();

  public tenants$: Observable<
    MongoStoredObject<ITenant & { integrations: IIntegration }>[]
  > = this._tenantApi
    .getAllTenants()
    .pipe(repeat({ delay: () => this._update$ }), shareReplay(1));

  public constructor(private readonly _tenantApi: TenantApiService) {}

  public update(): void {
    this._update$.next();
  }

  public createTenant(data: CreateTenantInput): Observable<unknown> {
    return this._tenantApi.createTenant(data).pipe(
      tap(() => {
        this._update$.next();
      })
    );
  }

  public patchTenantById(
    id: ObjectId,
    data: TenantPatchInput
  ): Observable<unknown> {
    return this._tenantApi.patchTenantById(id, data).pipe(
      tap(() => {
        this._update$.next();
      })
    );
  }
}
