import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { BlankComponent } from './blank.component';

@NgModule({
  imports: [RouterModule, IonicModule],
  exports: [BlankComponent],
  declarations: [BlankComponent]
})
export class BlankModule {}
