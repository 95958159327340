<div class="feedback-name ion-text-center">{{ name }}</div>
<div class="feedback-row">
  <div class="feedback-col">
    <div class="no-data-label" *ngIf="isMounted && negative === '0%'">
      No feedback
    </div>
    <div
      class="feedback-bar"
      [ngStyle]="{ width: isMounted ? negative : '0%' }"
    ></div>
  </div>
  <div class="feedback-col">
    <div class="no-data-label" *ngIf="isMounted && positive === '0%'">
      No feedback
    </div>
    <div
      class="feedback-bar"
      [ngStyle]="{ width: isMounted ? positive : '0%' }"
    ></div>
  </div>
</div>
