import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ToastService } from '@app/core/service/toast.service';
import { IonNav, LoadingController } from '@ionic/angular';
import { LibraryApiService } from '@app/core/service/library-api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantService } from '@app/core/service/tenant.service';
import { UploadFileApiService } from '@app/core/service/upload-file-api.service';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import { LibraryDocModel } from '@app/core/model/library-doc.model';

@Component({
  selector: 'app-library-doc-add',
  templateUrl: './library-doc-add.component.html',
  styleUrls: ['./library-doc-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryDocAddComponent implements OnInit {
  @Input()
  public readonly libraryDoc: LibraryDocModel = null;

  protected mainFormGroup: FormGroup = new FormGroup({
    title: new FormControl<string>('', Validators.required)
  });
  protected body = '';
  protected disableAttachments = false;

  public constructor(
    private readonly _libraryApiService: LibraryApiService,
    private readonly _ionNav: IonNav,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _tenantService: TenantService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _environmentService: EnvironmentService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.disableAttachments = !tenant.features.libraryDocAttachments;
    });
  }

  public ngOnInit(): void {
    if (this.libraryDoc) {
      this.mainFormGroup.reset(this.libraryDoc);
      this.body = formatUploadedBody(
        this.libraryDoc.body,
        this.libraryDoc.attachments,
        this._environmentService.serverUrl
      );
    }
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected async onFormSubmit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._uploadFileApiService
      .extractAttachmentsFromBody(
        this.body || '',
        this.libraryDoc?.attachments || []
      )
      .subscribe({
        next: ({ body, newAttachments, keptAttachments }) => {
          if (this.libraryDoc) {
            this._libraryApiService
              .saveAsNewVersion(
                (this.libraryDoc.originalDoc || this.libraryDoc._id).toString(),
                {
                  ...this.mainFormGroup.value,
                  body,
                  attachments: [
                    ...keptAttachments,
                    ...newAttachments.map((a) => a._id.toString())
                  ]
                }
              )
              .subscribe(() => {
                this._toastService.presentToast(
                  'New Revision Successfully Saved!'
                );
                this._ionNav.pop().then(() => this._ionNav.pop());
              });
          } else {
            this._libraryApiService
              .addLibraryDoc({
                ...this.mainFormGroup.value,
                body,
                attachments: [
                  ...keptAttachments,
                  ...newAttachments.map((a) => a._id.toString())
                ]
              })
              .subscribe(() => {
                this._toastService.presentToast('Document Successfully Added!');
                this._ionNav.pop();
              });
          }
        },
        error: (e) => {
          console.log(e);
          loading.dismiss();
          this._toastService.presentToast('Unable to upload attachments');
        }
      });
  }

  protected async onDeleteClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._libraryApiService
      .softDeleteLibraryDoc(this.libraryDoc._id)
      .subscribe({
        next: () => {
          this._toastService.presentToast('Document Successfully Deleted');
          this._ionNav.pop().then(() => this._ionNav.pop());
        },
        error: (e) => {
          loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Unable to delete document'
          );
        }
      });
  }
}
