import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DashboardRoutes } from '@app/dashboard/dashboard.routing';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { RecognitionItemModule } from '@app/modules/recognition-item/recognition-item.module';
import { AnnouncementsComponent } from './dashboard/announcements/announcements.component';
import { AnnouncementViewComponent } from './dashboard/announcements/announcement-view/announcement-view.component';
import { AnnouncementAddComponent } from './dashboard/announcements/announcement-add/announcement-add.component';
import { InsightsComponent } from './dashboard/insights/insights.component';
import { TasksCompletedComponent } from './dashboard/insights/tasks-completed/tasks-completed.component';
import { PraiseReceivedComponent } from './dashboard/insights/praise-received/praise-received.component';
import { PraiseSentComponent } from './dashboard/insights/praise-sent/praise-sent.component';
import { InsightsRangeModalModule } from '@app/modules/insights-range-modal/insights-range-modal.module';
import { EditTaskPerformanceModalModule } from '@app/modules/edit-task-performance-modal/edit-task-performance-modal.module';
import { PerformanceItemModule } from '@app/modules/performance-item/performance-item.module';
import { CompensationAnnouncementPreviewModalModule } from '@app/modules/compensation-announcement-preview-modal/compensation-announcement-preview-modal.module';
import {
  ContextMenuService,
  EditService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { WarnIeDialogComponent } from '../modules/warn-ie-dialog/warn-ie-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { SubscriptionManagementComponent } from './dashboard/subscription-management/subscription-management.component';
import { TaskManagementComponent } from './dashboard/task-management/task-management.component';
import { IonicModule } from '@ionic/angular';
import { PageWrapperModule } from '../modules/page-wrapper/page-wrapper.module';
import { TaskCreateComponent } from './dashboard/task-management/components/task-create/task-create.component';
import { TaskFolderCreateComponent } from './dashboard/task-management/components/task-folder-create/task-folder-create.component';
import { TaskFormModule } from '../modules/task-form/task-form.module';
import { TaskFolderFormModule } from '@app/modules/task-folder-form/task-folder-form.module';
import { WidthRestrictedContainerModule } from '../modules/width-restricted-container/width-restricted-container.module';
import { ChecklistComponent } from './dashboard/checklist/checklist.component';
import { DailyReportComponent } from './dashboard/checklist/daily-report/daily-report.component';
import { ChecklistItemModule } from '../modules/checklist-item/checklist-item.module';
import { ChecklistFolderModule } from '@app/modules/checklist-folder/checklist-folder.module';
import { ChecklistItemPickupComponent } from './dashboard/checklist-item-pickup/checklist-item-pickup.component';
import { TaskHistoryModalModule } from '@app/modules/task-history-modal/task-history-modal.module';
import { UnitChangeButtonModule } from '@app/modules/unit-change-button/unit-change-button.module';
import { DaysOfWeeklySchedulePipe } from './dashboard/checklist-item-pickup/days-of-weekly-schedule.pipe';
import { IntervalStatsModule } from '@app/modules/interval-stats/interval-stats.module';
import { PerformanceRowComponent } from './dashboard/checklist/daily-report/components/performance-row/performance-row.component';
import { TaskRowComponent } from './dashboard/checklist/daily-report/components/task-row/task-row.component';
import { PerformancesFolderComponent } from './dashboard/checklist/daily-report/components/performances-folder/performances-folder.component';
import { DividerModule } from '../modules/divider/divider.module';
import { FilterCompletedPipe } from './dashboard/checklist/filter-completed.pipe';
import { FilterUpcomingPipe } from './dashboard/checklist/filter-upcoming.pipe';
import { FilterCurrentPipe } from './dashboard/checklist/filter-current.pipe';
import { NotInFolderPipe } from './dashboard/checklist/not-in-folder.pipe';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';
import { WideButtonComponent } from '@app/modules/wide-button/wide-button.component';
import { TaskSelectorComponent } from '@app/modules/task-selector/task-selector.component';
import { UserListService } from '@app/core/service/user-list.service';
import { take } from 'rxjs';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { FormatDurationPipe } from './dashboard/checklist-item-pickup/format-duration.pipe';
import { DirectivesModule } from '@app/modules/directives.module';
import { AnnouncementItemModule } from '@app/modules/announcement-item/announcement-item.module';
import { AcknowledgmentsModalModule } from '@app/modules/acknowledgments-modal/acknowledgments-modal.module';
import { UploadFileApiService } from '@app/core/service/upload-file-api.service';
import { WysiwygEditorComponent } from '@app/modules/wysiwyg-editor/wysiwyg-editor.component';
import { TaskFolderListService } from '@app/core/service/task-folder-list.service';
import { TaskFolderApiService } from '@app/core/service/task-folder-api.service';
import { TimeAttackModalModule } from '@app/modules/time-attack-modal/time-attack-modal.module';
import { CompensationApiService } from '@app/core/service/compensation-api.service';
import { CompensationHoursComponent } from './dashboard/insights/compensation-hours/compensation-hours.component';
import { CompensationPoolsComponent } from './dashboard/insights/compensation-pools/compensation-pools.component';
import { CompensationPreviewComponent } from './dashboard/insights/compensation-preview/compensation-preview.component';
import { CompensationsComponent } from './dashboard/insights/compensations/compensations.component';
import { AnnouncementRespondModalModule } from '@app/modules/announcement-respond-modal/announcement-respond-modal.module';
import { AnnouncementThreadModule } from '@app/modules/announcement-thread/announcement-thread.module';
import { ReadEyeComponent } from '@app/modules/read-eye/read-eye.component';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { LotteryMachineModule } from '@app/modules/lottery-machine/lottery-machine.module';
import { LotteryComponent } from './dashboard/insights/lottery/lottery.component';
import { CheckTaskCriteriaModalModule } from '@app/modules/check-task-criteria-modal/check-task-criteria-modal.module';
import { TaskAuditApiService } from '@app/core/service/task-audit-api.service';
import { PullToSearchModule } from '@app/modules/pull-to-search/pull-to-search.module';
import { DailyReportApiService } from '@app/core/service/daily-report-api.service';
import { IsOverduePipe } from '@app/pipes/standalone/is-overdue.pipe';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatTableModule,
    RouterModule.forChild(DashboardRoutes),
    MatSortModule,
    GridModule,
    IonicModule,
    PageWrapperModule,
    TaskFormModule,
    TaskFolderFormModule,
    WidthRestrictedContainerModule,
    ChecklistItemModule,
    ChecklistFolderModule,
    UnitChangeButtonModule,
    IntervalStatsModule,
    DividerModule,
    UserSelectorComponent,
    WideButtonComponent,
    ReadEyeComponent,
    TaskSelectorComponent,
    SlimMenuButtonComponent,
    InsightsRangeModalModule,
    EditTaskPerformanceModalModule,
    TaskHistoryModalModule,
    RecognitionItemModule,
    PullToSearchModule,
    PerformanceItemModule,
    EmptyStubModule,
    DirectivesModule,
    AnnouncementItemModule,
    AcknowledgmentsModalModule,
    WysiwygEditorComponent,
    TimeAttackModalModule,
    CompensationAnnouncementPreviewModalModule,
    AnnouncementRespondModalModule,
    AnnouncementThreadModule,
    DigitOnlyModule,
    LotteryMachineModule,
    CheckTaskCriteriaModalModule,
    IsOverduePipe
  ],
  declarations: [
    AnnouncementsComponent,
    AnnouncementViewComponent,
    AnnouncementAddComponent,
    WarnIeDialogComponent,
    SubscriptionManagementComponent,
    TaskManagementComponent,
    TaskCreateComponent,
    TaskFolderCreateComponent,
    ChecklistComponent,
    DailyReportComponent,
    ChecklistItemPickupComponent,
    DaysOfWeeklySchedulePipe,
    FilterCompletedPipe,
    FilterUpcomingPipe,
    FilterCurrentPipe,
    NotInFolderPipe,
    PerformanceRowComponent,
    TaskRowComponent,
    PerformancesFolderComponent,
    FormatDurationPipe,
    InsightsComponent,
    TasksCompletedComponent,
    PraiseReceivedComponent,
    PraiseSentComponent,
    CompensationHoursComponent,
    CompensationPoolsComponent,
    CompensationPreviewComponent,
    CompensationsComponent,
    LotteryComponent
  ],
  providers: [
    EditService,
    ToolbarService,
    ContextMenuService,
    UploadFileApiService,
    TaskFolderApiService,
    TaskFolderListService,
    CompensationApiService,
    TaskAuditApiService,
    DailyReportApiService
  ]
})
export class DashboardModule {
  public constructor(private readonly _userList: UserListService) {
    // user list cache initial fill
    this._userList.users$.pipe(take(1)).subscribe();
  }
}
