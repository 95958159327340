import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthToken } from '../model/authentication.model';
import { UserModel } from '../model/user.model';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITenant } from '@backend/models/types/tenant';

export type IConnectedAccount = MongoStoredObject<
  Pick<
    UserModel,
    'firstName' | 'lastName' | 'email' | 'tenant' | 'fcmToken' | 'parent'
  > & {
    tenant: MongoStoredObject<Pick<ITenant, 'name'>>;
  }
>;

@Injectable({ providedIn: 'root' })
export class ConnectedAccountsApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getMyConnectedAccounts() {
    return this._httpClient.get<IConnectedAccount[]>('/connected-accounts');
  }

  public loginToConnectedAccount(userId: string) {
    return this._httpClient.post<{
      authToken: AuthToken;
      user: UserModel;
    }>('/connected-accounts/login', {
      userId
    });
  }

  public removeMyConnectedAccount(userId: string) {
    return this._httpClient.delete<IConnectedAccount[]>('/connected-accounts', {
      params: { userId }
    });
  }

  public getConnectedAccountsGroups() {
    return this._httpClient.get<
      MongoStoredObject<{ accounts: IConnectedAccount[] }>[]
    >('/connected-accounts/groups');
  }

  public removeConnectedAccountFromGroup(groupId: string, userId: string) {
    return this._httpClient.delete<
      MongoStoredObject<{ accounts: IConnectedAccount[] }>[]
    >('/connected-accounts/groups', {
      params: { groupId, userId }
    });
  }
}
