import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { IonicModule, IonSearchbar } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { ObjectId } from '@app/types/object-id';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IPsychologicalSurveyBehavior } from '@backend/models/types/psychological-survey-behavior';

@Component({
  selector: 'app-psychological-behavior-selector',
  templateUrl: './psychological-behavior-selector.component.html',
  styleUrls: ['./psychological-behavior-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    WidthRestrictedContainerModule,
    FormsModule,
    DirectivesModule
  ]
})
export class PsychologicalBehaviorSelectorComponent implements OnInit {
  @Input()
  public readonly title?: string = 'Recipients';

  @Input()
  public readonly psychologicalBehaviors: MongoStoredObject<IPsychologicalSurveyBehavior>[] =
    [];

  @Input()
  public readonly tense: 'futureTense' | 'pastTense' = 'pastTense';

  @Output()
  public selectionCancel = new EventEmitter<void>();

  @Output()
  public selectionChange = new EventEmitter<ObjectId[]>();

  @ViewChild('ionSearchbar')
  public ionSearchbar!: IonSearchbar;

  protected originalBehaviors: ObjectId[] = [];
  public selected: {
    id: ObjectId;
    name: string;
    checked: boolean;
  }[] = [];
  public filter = '';

  protected get canSave(): boolean {
    const checkedItems = this.selected.filter((i) => i.checked);

    if (checkedItems.length !== this.originalBehaviors.length) {
      return true;
    }

    return !this.originalBehaviors.reduce((prev, cur) => {
      if (prev === false) return false;
      return !!checkedItems.find((item) => item.id === cur);
    }, true);
  }

  public ngOnInit(): void {
    this.selected = this.psychologicalBehaviors
      .map((behavior) => ({
        id: behavior._id,
        name: behavior[this.tense],
        checked: this.originalBehaviors.includes(behavior._id)
      }))
      .sort((a, b) => {
        const aChecked = a.checked;
        const bChecked = b.checked;
        if (aChecked && !bChecked) {
          return -1;
        }
        if (!aChecked && bChecked) {
          return 1;
        }
        return 0;
      });
  }

  protected trackItems(
    _: number,
    item: {
      id: ObjectId;
      name: string;
      checked: boolean;
    }
  ) {
    return item.id;
  }

  protected cancelChanges() {
    this.selectionCancel.emit();
  }

  protected confirmChanges() {
    const checkedItems = this.selected
      .filter((i) => i.checked)
      .map((i) => i.id);
    this.selectionChange.emit(checkedItems);
  }

  protected checkboxClick(event: any, value: ObjectId) {
    event.preventDefault();

    this.selected = this.selected.map((item) => ({
      ...item,
      checked: item.id === value ? !item.checked : item.checked
    }));
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }
}
