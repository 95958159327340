import { addMonths, getDate, isBefore, isSameDay } from 'date-fns';
import { setDayOfMonth } from './set-day-of-month';

export function nextMonthlyPeriodStart(value: Date) {
  const now = new Date();
  const dayOfMonth = getDate(value);
  const currentPeriodStart = setDayOfMonth(now, dayOfMonth);
  if (isSameDay(currentPeriodStart, now) || isBefore(currentPeriodStart, now)) {
    return addMonths(currentPeriodStart, 1);
  }
  return currentPeriodStart;
}
