import { Injectable } from '@angular/core';
import { Subject, repeat, shareReplay, tap } from 'rxjs';
import { GlobalSettingsApiService } from './global-settings-api.service';
import { GlobalSettings } from '@app/types/global-settings';

@Injectable({ providedIn: 'root' })
export class GlobalSettingsService {
  private readonly _update$ = new Subject<void>();

  public readonly globalSettings$ = this._api
    .get()
    .pipe(repeat({ delay: () => this._update$ }), shareReplay(1));

  public constructor(private readonly _api: GlobalSettingsApiService) {}

  public updateGlobalSettings(data: Omit<GlobalSettings, 'superAdmins'>) {
    return this._api.update(data).pipe(tap(() => this.update()));
  }

  public update() {
    this._update$.next();
  }
}
