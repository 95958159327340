import { Injectable } from '@angular/core';
import { defer, Observable, of, ReplaySubject, Subject, timer } from 'rxjs';
import { repeat, share, switchMap } from 'rxjs/operators';
import { startOfTomorrow, add } from 'date-fns';
import { UserModel, UserPointsRecord } from '../model/user.model';
import { UserApiService } from './user-api.service';
import { DateInterval } from '@backend/types/date-interval';

@Injectable({ providedIn: 'root' })
export class UserEntityService {
  private readonly _STATISTICS_FRAME_DAYS = 30;
  private readonly _statsObservables = new Map<
    string,
    Observable<UserPointsRecord>
  >();
  private readonly _teamStatsObservables = new Map<
    string,
    Observable<{ teamAveragePoints: number }>
  >();
  private readonly _update$ = new Subject<void>();

  public readonly user$: Observable<UserModel> = this._userApi.getUser().pipe(
    repeat({ delay: () => this._update$ }),
    share({
      connector: () => new ReplaySubject(),
      resetOnRefCountZero: () => timer(1000)
    })
  );

  // public readonly receivedInPeriod$: Observable<UserPointsRecord> = defer(
  //   () => {
  //     const end = startOfTomorrow();
  //     const start = add(end, { days: -this._STATISTICS_FRAME_DAYS });
  //     return of({ start, end });
  //   }
  // ).pipe(switchMap((interval) => this.getPointsForPeriod(interval)));

  // public readonly teamAverageInPeriod$: Observable<{
  //   teamAveragePoints: number;
  // }> = defer(() => {
  //   const end = startOfTomorrow();
  //   const start = add(end, { days: -this._STATISTICS_FRAME_DAYS });
  //   return of({ start, end });
  // }).pipe(
  //   switchMap((interval) => this.getAverageTeamPointsForPeriod(interval))
  // );

  public constructor(private readonly _userApi: UserApiService) {}

  public getPointsForPeriod(
    period: DateInterval
  ): Observable<UserPointsRecord> {
    const periodHash = `${period.start.getTime()}${period.end.getTime()}`;
    if (!this._statsObservables.has(periodHash)) {
      const result = this._userApi
        .getPointsForPeriod('recipient', period.start, period.end)
        .pipe(
          repeat({ delay: () => this._update$ }),
          share({
            connector: () => new ReplaySubject(),
            resetOnRefCountZero: () => timer(10000)
          })
        );
      this._statsObservables.set(periodHash, result);
    }
    return this._statsObservables.get(periodHash);
  }

  public getAverageTeamPointsForPeriod(
    period: DateInterval
  ): Observable<{ teamAveragePoints: number }> {
    const periodHash = `${period.start.getTime()}${period.end.getTime()}`;
    if (!this._teamStatsObservables.has(periodHash)) {
      const result = this._userApi
        .getAverageTeamPointsForPeriod('recipient', period.start, period.end)
        .pipe(
          repeat({ delay: () => this._update$ }),
          share({
            connector: () => new ReplaySubject(),
            resetOnRefCountZero: () => timer(10000)
          })
        );
      this._teamStatsObservables.set(periodHash, result);
    }
    return this._teamStatsObservables.get(periodHash);
  }

  public update(): void {
    this._update$.next();
  }
}
