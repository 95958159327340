import { Pipe, PipeTransform } from '@angular/core';
import { nextMonthlyPeriodStart } from '@app/utils/next-monthly-period-start';
import { nextTwoWeekPeriodStart } from '@app/utils/next-two-week-period-start';
import { nextWeeklyPeriodStart } from '@app/utils/next-weekly-period-start';
import { PayFrequency } from '@backend/types/pay-frequency';

@Pipe({
  name: 'dateTransformer'
})
export class DateTransformerPipe implements PipeTransform {
  public transform(frequency: PayFrequency): (value: Date) => Date {
    switch (frequency) {
      case PayFrequency.EVERY_WEEK:
        return nextWeeklyPeriodStart;
      case PayFrequency.EVERY_TWO_WEEKS:
        return nextTwoWeekPeriodStart;
      case PayFrequency.ONCE_PER_MONTH:
      case PayFrequency.TWICE_PER_MONTH:
        return nextMonthlyPeriodStart;
    }
  }
}
