import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginPageWrapperComponent } from './login-page-wrapper.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [LoginPageWrapperComponent],
  imports: [CommonModule, IonicModule],
  exports: [LoginPageWrapperComponent]
})
export class LoginPageWrapperModule {}
