import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChecklistItemComponent } from './checklist-item.component';
import { hasCompletedPipe } from './has-completed.pipe';
import { UserInitialsPipe } from './user-initials.pipe';

@NgModule({
  declarations: [ChecklistItemComponent, hasCompletedPipe, UserInitialsPipe],
  imports: [CommonModule],
  exports: [ChecklistItemComponent]
})
export class ChecklistItemModule {}
