import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UnitChangeButtonComponent } from './unit-change-button.component';

@NgModule({
  declarations: [UnitChangeButtonComponent],
  imports: [CommonModule],
  exports: [UnitChangeButtonComponent]
})
export class UnitChangeButtonModule {}
