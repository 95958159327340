import { PayFrequency } from '@backend/types/pay-frequency';
import { PayrollDefinition } from '@backend/types/payroll-definition';
import {
  addDays,
  addMonths,
  format,
  getDay,
  isSameDay,
  startOfMonth
} from 'date-fns';

export function createDefaultPayrollForFrequency(
  frequency: PayFrequency
): PayrollDefinition {
  let now = new Date();
  if (
    frequency === PayFrequency.EVERY_WEEK ||
    frequency === PayFrequency.EVERY_TWO_WEEKS
  ) {
    const dow = getDay(now);
    if (dow !== 0) {
      now = addDays(now, 7 - dow);
    }
    const date1 = format(now, 'yyyy-MM-dd');
    return { frequency, date1 };
  }
  if (frequency === PayFrequency.ONCE_PER_MONTH) {
    let date = startOfMonth(now);
    if (!isSameDay(date, now)) {
      date = addMonths(date, 1);
    }
    return { frequency, date1: format(date, 'yyyy-MM-dd') };
  }
  if (frequency === PayFrequency.TWICE_PER_MONTH) {
    let date = startOfMonth(now);
    if (!isSameDay(date, now)) {
      date = addMonths(date, 1);
    }
    return {
      frequency,
      date1: format(date, 'yyyy-MM-dd'),
      date2: format(addDays(date, 15), 'yyyy-MM-dd')
    };
  }
}
