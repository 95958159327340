import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewChild
} from '@angular/core';
import { PsychologicalSurveyApiService } from '@app/core/service/psychological-survey-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { IPsychologicalSurveyBehavior } from '@backend/models/types/psychological-survey-behavior';
import { AuthenticationService, ROLES } from '@app/core';
import { UserListService } from '@app/core/service/user-list.service';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';
import { IonModal } from '@ionic/angular';
import { EPsychologicalFeedbackView } from '@backend/models/types/tenant';
import { TenantService } from '@app/core/service/tenant.service';

enum EFeedbackType {
  TEAM = 'TEAM',
  PERSONAL = 'PERSONAL'
}

@Component({
  selector: 'app-psychological-survey-feedback',
  templateUrl: './psychological-survey-feedback.component.html',
  styleUrls: ['./psychological-survey-feedback.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PsychologicalSurveyFeedbackComponent {
  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  protected readonly feedbackType = EFeedbackType;
  protected readonly feedbackView = EPsychologicalFeedbackView;

  protected currentTab = EFeedbackType.TEAM;
  protected isLoading = true;
  protected survey: MongoStoredObject<IPsychologicalSurvey> = null;
  protected surveyBehaviors: MongoStoredObject<
    IPsychologicalSurveyBehavior & {
      personalFeedback: {
        positiveCount: number;
        negativeCount: number;
        finalValue: number;
      };
      teamFeedback: {
        positiveCount: number;
        negativeCount: number;
        finalValue: number;
      };
    }
  >[] = [];
  protected allUsers: UserModel[] = [];
  protected selectedUserIds: ObjectId[] = [];
  protected isAdmin = false;
  protected selectedView = EPsychologicalFeedbackView.SINGLE_BAR_VIEW;

  public constructor(
    private readonly _psychologicalSurveyApiService: PsychologicalSurveyApiService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _authenticationService: AuthenticationService,
    private readonly _userListService: UserListService,
    private readonly _tenantService: TenantService
  ) {
    this.selectedUserIds = [this._authenticationService.user._id];
    this.isAdmin =
      this._authenticationService.user?.roles.includes(ROLES.adminName) ??
      false;

    this._tenantService.tenant$.subscribe((tenant) => {
      this.selectedView = tenant.psychologicalFeedbackView;
    });

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });

    this._fetchFeedbackStats();
  }

  private _fetchFeedbackStats() {
    this.isLoading = true;

    this._psychologicalSurveyApiService
      .getFeedbackStats(
        this.selectedUserIds[0] === this._authenticationService.user._id
          ? undefined
          : this.selectedUserIds[0]
      )
      .subscribe((surveyBehaviors) => {
        this.surveyBehaviors = surveyBehaviors;
        this.isLoading = false;
        this._cdr.markForCheck();
      });
  }

  protected get formattedUser(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.selectedUserIds[0]
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  // getters for double bar view
  protected get maxTeamValue() {
    return this.surveyBehaviors.reduce((prev, behavior) => {
      const max = Math.max(
        behavior.teamFeedback.positiveCount,
        behavior.teamFeedback.negativeCount
      );
      return max > prev ? max : prev;
    }, 0);
  }
  protected get maxPersonalValue() {
    return this.surveyBehaviors.reduce((prev, behavior) => {
      const max = Math.max(
        behavior.personalFeedback.positiveCount,
        behavior.personalFeedback.negativeCount
      );
      return max > prev ? max : prev;
    }, 0);
  }

  // getters for single bar view
  protected get minTeamFinalValue() {
    return Math.min(
      ...this.surveyBehaviors.map((b) => b.teamFeedback.finalValue)
    );
  }
  protected get maxTeamFinalValue() {
    return Math.max(
      ...this.surveyBehaviors.map((b) => b.teamFeedback.finalValue)
    );
  }
  protected get minPersonalFinalValue() {
    return Math.min(
      ...this.surveyBehaviors.map((b) => b.personalFeedback.finalValue)
    );
  }
  protected get maxPersonalFinalValue() {
    return Math.max(
      ...this.surveyBehaviors.map((b) => b.personalFeedback.finalValue)
    );
  }

  protected trackFeedbacks(_: number, item: { _id: string }) {
    return item._id;
  }

  protected onCurrentTabChange(event: any) {
    this.currentTab = event.detail.value;
  }

  protected onUserChange(event: ObjectId[]): void {
    this.selectedUserIds = event;
    this.userSelectorModal.dismiss();
    this._fetchFeedbackStats();
  }

  protected handleViewChange(event: any): void {
    this.selectedView = event.detail.value;
  }
}
