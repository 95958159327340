import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TaskScheduleType } from '@backend/models/types/task';
import { TaskScheduleForm } from '../../types/task-schedule-form';
import { updateScheduleFormGroup } from '../../utils/update-schedule-form-group';
import { HapticsService } from '@app/core/service/haptics.service';

@Component({
  selector: 'app-schedule-subform',
  templateUrl: './schedule-subform.component.html',
  styleUrls: ['./schedule-subform.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleSubformComponent {
  public readonly taskScheduleType = TaskScheduleType;
  @Input()
  public form!: FormGroup<TaskScheduleForm>;

  public constructor(private readonly _hapticsService: HapticsService) {}

  protected onScheduleTypeChange({ detail: { value } }: any): void {
    this._hapticsService.hapticsImpact();

    let data = {};
    updateScheduleFormGroup(this.form, value, data);
    this.form.markAsDirty();
  }
}
