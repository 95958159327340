import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { BehaviorApiService } from '../core';
import { BehaviorModel } from '../core/model/behavior.model';

@Injectable({ providedIn: 'root' })
export class BehaviorsResolver implements Resolve<BehaviorModel[]> {
  public constructor(private _behaviorService: BehaviorApiService) {}

  public resolve(): Observable<BehaviorModel[]> {
    return this._behaviorService.getNonSysGenBehaviors();
  }
}
