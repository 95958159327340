import { NgModule } from '@angular/core';
import { ManageUserTasksModalComponent } from './manage-user-tasks-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { FilterTasksPipe } from './filter-tasks.pipe';

@NgModule({
  imports: [CommonModule, IonicModule, DirectivesModule, FilterTasksPipe],
  exports: [ManageUserTasksModalComponent],
  declarations: [ManageUserTasksModalComponent]
})
export class ManageUserTasksModalModule {}
