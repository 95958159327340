<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Hours</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onPasteButtonClick()" appHaptics color="primary">
        Load CSV File
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container>
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="users.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <div class="layout-container" *ngIf="!isLoading && users.length > 0">
      <div class="top-content">
        <div class="scrollable-table">
          <div class="user-row">
            <div class="table-header-name">User</div>
            <div class="table-header-col" *ngFor="let field of onlyHoursFields">
              {{ field.name }}
            </div>
          </div>

          <div *ngFor="let user of users" class="user-row">
            <div class="user-name">
              <div>{{ user.firstName }} {{ user.lastName }}</div>
              <div *ngIf="user.compensationEmployeeId" class="muted">
                Employee ID: {{ user.compensationEmployeeId }}
              </div>
            </div>
            <div
              class="user-value-input"
              *ngFor="let field of onlyHoursFields; let i = index"
            >
              <ion-input
                class="input-with-background"
                slot="end"
                [value]="user.values[i].value"
                fill="solid"
                inputmode="decimal"
                (ionChange)="onInputChange(user, field.name, $event)"
                placeholder="0"
              ></ion-input>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-content">
        <ion-grid>
          <ion-row class="ion-justify-content-center ion-padding">
            <ion-col size="4">
              <ion-button
                color="dark"
                (click)="next()"
                appHaptics
                expand="block"
              >
                Next
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="List Is Empty"></app-empty-stub>
</ng-template>
