import {
  FormControl,
  FormGroup,
  Validators,
  ɵFormGroupValue
} from '@angular/forms';
import { TaskScheduleType } from '@backend/models/types/task';
import { TaskScheduleForm } from '../types/task-schedule-form';
import { updateScheduleFormGroup } from './update-schedule-form-group';

type TaskScheduleFormType = Omit<TaskScheduleForm, 'scheduleType'>;

export function createScheduleFormGroup(
  scheduleType: TaskScheduleType,
  data: Omit<ɵFormGroupValue<TaskScheduleFormType>, 'scheduleType'> = {}
): FormGroup<TaskScheduleForm> {
  const group = new FormGroup<TaskScheduleForm>({
    scheduleType: new FormControl<TaskScheduleType>(scheduleType, {
      validators: [Validators.required],
      nonNullable: true
    })
  });
  updateScheduleFormGroup(group, scheduleType, data);
  return group;
}
