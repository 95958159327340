import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LedgerService } from '@app/core/service/ledger.service';
import { UserModel } from '@app/core/model/user.model';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { AuthenticationService, ROLES } from '@app/core';
import { ActionSheetController, LoadingController } from '@ionic/angular';
import { RecognitionInput } from '@app/pages/recognition/types/recognition-input';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { LedgerModel } from '@app/core/model/ledger.model';
import { RecognitionModalService } from '@app/core/service/recognition-modal.service';

@Component({
  selector: 'app-view-recognition',
  templateUrl: './view-recognition.component.html',
  styleUrls: ['./view-recognition.component.scss']
})
export class ViewRecognitionComponent {
  protected isAdmin: boolean;
  protected authenticatedUser: UserModel;
  protected post: LedgerModel;
  protected behaviorData: BehaviorModel[] = this._route.snapshot.data.behaviors;
  protected behaviorOptions: BehaviorModel[] = [];

  public constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
    private readonly _ledgerService: LedgerService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _userEntity: UserEntityService,
    private readonly _recognitionModalService: RecognitionModalService
  ) {
    this._route.queryParams.subscribe((params) => {
      const queryPost = params['post'];

      if (queryPost !== '' && queryPost !== undefined) {
        this._getPost(queryPost);
      } else {
        this.done();
      }
    });

    this.authenticatedUser = this._authenticationService.user;
    this.isAdmin = this.authenticatedUser
      ? this.authenticatedUser.roles.includes(ROLES.adminName)
      : false;

    this.behaviorOptions = this.behaviorData.filter(
      ({ isSystemGenerated, eligible }) =>
        isSystemGenerated === false &&
        (eligible.length === 0 ||
          eligible.find((e) => this.authenticatedUser.roles.includes(e)))
    );
  }

  protected get isRecognitionForMe() {
    if (!this.post) return false;

    return (
      this.post.recipient._id.toString() ===
      this.authenticatedUser._id.toString()
    );
  }

  protected get recipientFullName() {
    if (this.post) {
      return `${this.post.recipient.firstName} ${this.post.recipient.lastName}`;
    } else {
      return '';
    }
  }

  protected done() {
    this._router.navigate(['/'], {
      replaceUrl: true
    });
  }

  private _getPost(id: string) {
    this._ledgerService.getLedgerById(id).subscribe((res) => {
      this.post = res;

      if (this.isRecognitionForMe) {
        this._readPost(res._id);
      }
    });
  }

  private _readPost(id: string) {
    this._ledgerService.readLedgerById(id).subscribe();
  }

  protected updatePrivate(value: boolean, post: LedgerModel) {
    const updatedPost: RecognitionInput = {
      description: post.description,
      recipientId: post.recipient._id.toString(),
      behaviorId: post.behavior._id.toString(),
      isPrivate: value
    };
    this._ledgerService.editLedger(post._id, updatedPost).subscribe(() => {
      post.isPrivate = value;
    });
  }

  protected async onEditRecognition(ledger: LedgerModel) {
    const { recognition, role } =
      await this._recognitionModalService.showEditRecognitionModal(
        {
          initialData: {
            userId: ledger.recipient._id,
            behaviorId: ledger.behavior._id,
            description: ledger.description,
            isPrivate: ledger.isPrivate
          },
          behaviors: this.behaviorOptions
        },
        'ion-split-pane'
      );

    if (role === 'confirm') {
      const loading = await this._loadingCtrl.create({
        message: 'Updating...'
      });
      loading.present();

      this._ledgerService
        .editLedger(ledger._id, {
          recipientId: recognition.userId,
          behaviorId: recognition.behaviorId,
          description: recognition.description,
          isPrivate: recognition.isPrivate
        })
        .subscribe(() => {
          loading.dismiss();
          this._getPost(ledger._id);
        });
    }
  }

  protected async onDeleteRecognition(ledger: LedgerModel) {
    const actionSheet = await this._actionSheetCtrl.create({
      header: 'Are you sure?',
      subHeader: 'This message will be permanently deleted.',
      buttons: [
        {
          role: 'destructive',
          text: 'Delete'
        },
        {
          role: 'cancel',
          text: 'Cancel'
        }
      ]
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    if (role === 'destructive') {
      const loading = await this._loadingCtrl.create({
        message: 'Deleting...'
      });
      loading.present();

      this._ledgerService.deleteLedger(ledger._id).subscribe(() => {
        loading.dismiss();
        this._userEntity.update();
        this.done();
      });
    }
  }
}
