import { NgModule } from '@angular/core';
import { AcknowledgmentsModalComponent } from './acknowledgments-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { PersonNamePipe } from './person-name.pipe';

@NgModule({
  imports: [CommonModule, IonicModule, DirectivesModule, PersonNamePipe],
  exports: [AcknowledgmentsModalComponent],
  declarations: [AcknowledgmentsModalComponent]
})
export class AcknowledgmentsModalModule {}
