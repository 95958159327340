<ion-header class="ion-no-border">
  <ng-container>
    <ion-toolbar class="app-toolbar">
      <ion-buttons slot="start">
        <app-slim-menu-button class="ion-hide-lg-up"></app-slim-menu-button>
      </ion-buttons>
      <ion-title>Recognition</ion-title>
    </ion-toolbar>
  </ng-container>
</ion-header>

<app-pull-to-search
  #pullToSearch
  (searchInput)="handleSearchInput($event)"
  [searchDebounce]="700"
>
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <div
      *ngFor="
        let dataItem of postsGroupedByDate;
        let groupIndex = index;
        trackBy: trackDates
      "
      class="recognition-day"
    >
      <div class="recognition-date">{{ dataItem.date }}</div>
      <app-recognition-item
        *ngFor="
          let post of dataItem.items;
          let postIndex = index;
          trackBy: trackPosts
        "
        [item]="post"
        [showOptions]="authenticatedUser._id === post.sender?._id || isAdmin"
        (privateToggle)="updatePrivate($event, post)"
        (editClicked)="onEditRecognition($event)"
        (deleteClicked)="onDeleteRecognition($event)"
        appHaptics
        [disableHaptics]="
          !(authenticatedUser._id === post.sender?._id || isAdmin)
        "
        [animationOrder]="
          postsLoaded <= postPageSize
            ? postIndex + firstPagePostsCount(groupIndex)
            : -1
        "
      ></app-recognition-item>
    </div>

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      [disabled]="postsGroupedByDate.length === 0 || isLoading || !hasNextPage"
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ng-container *ngIf="postsGroupedByDate.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>
  </app-width-restricted-container>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end" class="main-fab">
    <ion-fab-button color="dark" (click)="onAddRecognition()" appHaptics>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</app-pull-to-search>

<ng-template #emptyList>
  <app-empty-stub
    [icon]="searchTerm ? 'search-outline' : null"
    [text]="searchTerm ? 'Nothing Found' : 'List Is Empty'"
  ></app-empty-stub>
</ng-template>
