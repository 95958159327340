<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title class="ion-text-capitalize">
      {{ entity.name }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div>
  <ion-grid [formGroup]="form">
    <ion-row>
      <ion-col size="12">
        <ion-input
          label="Points"
          class="app-w100 input-with-background"
          labelPlacement="floating"
          fill="solid"
          pattern="[1-9][0-9]*"
          formControlName="points"
          [maxlength]="6"
          inputmode="numeric"
          type="text"
          digitOnly
          placeholder="0"
        />
      </ion-col>
    </ion-row>

    <ion-row class="ion-justify-content-center ion-padding">
      <ion-col size="4">
        <ion-button
          type="submit"
          color="dark"
          expand="block"
          (click)="onSaveClick()"
          appHaptics
          [disabled]="!canSave"
          [disableHaptics]="!canSave"
        >
          Save
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
