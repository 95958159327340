<div
  class="table-row-folder"
  [ngClass]="{
    'table-row-folder--opened': isOpened,
    'table-row-folder--clickable': !isOpened
  }"
  (click)="onFolderClick()"
>
  <div class="table-row-folder-header" *ngIf="!isOpened && folderPerformance">
    <div class="table-row-performance-arrow">
      <ion-icon name="chevron-down"></ion-icon>
    </div>
    <div
      class="table-row-performance-comment"
      *ngIf="folderPerformance.performance.task.verbPastSimple"
    >
      {{ folderPerformance.performance.task.verbPastSimple }}
      <b
        *ngIf="
          folderPerformance.performance.task.pointsEarns ===
          taskPointsEarns.PER_COMPLETION
        "
      >
        {{ folderPerformance.performance.task.pointsUnit }}
      </b>
      <b
        *ngIf="
          folderPerformance.performance.task.pointsEarns ===
          taskPointsEarns.PER_UNIT
        "
      >
        {{ folderPerformance.quantity }}
        {{
          folderPerformance.quantity === 1
            ? folderPerformance.performance.task.pointsUnit
            : folderPerformance.performance.task.nounPlural
        }}
      </b>
    </div>
    <div
      class="table-row-performance-comment"
      *ngIf="!folderPerformance.performance.task.verbPastSimple"
    >
      {{ folderPerformance.performance.task.title }}
      <b
        *ngIf="
          folderPerformance.performance.task.pointsEarns ===
          taskPointsEarns.PER_COMPLETION
        "
      >
        {{ folderPerformance.performance.task.pointsUnit }}
      </b>
      <b
        *ngIf="
          folderPerformance.performance.task.pointsEarns ===
            taskPointsEarns.PER_UNIT || folderPerformance.quantity > 1
        "
      >
        (x{{ folderPerformance.quantity }})
      </b>
    </div>
  </div>
  <div class="table-row-folder-performances" *ngIf="isOpened">
    <app-performance-row
      *ngFor="let performance of performances; let i = index"
      [performance]="performance"
      [animationOrder]="i"
      (click)="performanceClicked.emit(performance)"
    />
  </div>
</div>
