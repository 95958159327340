import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-page-wrapper',
  templateUrl: './login-page-wrapper.component.html',
  styleUrls: ['./login-page-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageWrapperComponent {
  @Input()
  public readonly backBtn?: boolean = false;

  public constructor(private readonly _router: Router) {}

  protected onBackBtnClick() {
    this._router.navigate(['/']);
  }
}
