<ion-header class="ion-hide-lg-up ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true">
  <div *ngIf="showSuccess" class="wrapper">
    <h2 class="title">Payment successfully completed</h2>
  </div>
  <div *ngIf="showPayment" class="wrapper">
    <h1 class="title">VALCHEMY Behavior ENGINE</h1>
    <h1 class="heading">Simple, transparent pricing</h1>
    <div class="bodyWrapper">
      <p>Monthly</p>
      <label class="switch">
        <input type="checkbox" checked />
        <span class="slider round"></span>
      </label>
      <p>Annually</p>
    </div>
    <div class="cardWrapper">
      <div class="leftCard">
        <div class="cardHeader">
          <div class="imageWrapper">
            <img src="../../../../assets/images/diamond.png" alt="" />
          </div>
          <p>Complete</p>
        </div>
        <div class="cardBody">
          <p>
            Start cultivating a high-performance, positive, and self-managing
            team with a 30-day trial.
          </p>
          <p class="price">
            $7.21
            <span>/user</span>
          </p>
        </div>
        <div class="btnWrapper">
          <button (click)="initiateCheckoutSession()" class="btn">
            Activate Free Trial
          </button>
        </div>
      </div>
      <div class="rightCard">
        <p>What’s included</p>
        <div class="rightCardBody">
          <div>
            <img src="../../../../assets/images/Check.png" alt="" />
          </div>
          <p>Team Analytics</p>
        </div>
        <div class="rightCardBody">
          <div>
            <img src="../../../../assets/images/Check.png" alt="" />
          </div>
          <p>Peer-to-Peer Recognition</p>
        </div>
        <div class="rightCardBody">
          <div>
            <img src="../../../../assets/images/Check.png" alt="" />
          </div>
          <p>Daily Checklist</p>
        </div>
        <div class="rightCardBody">
          <div>
            <img src="../../../../assets/images/Check.png" alt="" />
          </div>
          <p>Process Documentation</p>
        </div>
        <div class="rightCardBody">
          <div>
            <img src="../../../../assets/images/Check.png" alt="" />
          </div>
          <p>Productivity Reward System</p>
        </div>
        <div class="rightCardBody">
          <div>
            <img src="../../../../assets/images/Check.png" alt="" />
          </div>
          <p>Premium Support</p>
        </div>
      </div>
    </div>
  </div>
</ion-content>
