import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { ROLES } from '../constants';
import { UserEntityService } from '../service/user-entity.service';

@Injectable()
export class ManagerGuard  {
  constructor(
    private _router: Router,
    private _userEntity: UserEntityService
  ) {}

  canActivate(): Observable<true | UrlTree> {
    return this._userEntity.user$.pipe(
      map(user => {
        if (
          user.roles.includes(ROLES.adminName) ||
          user.roles.includes(ROLES.managerName)
        ) {
          return true;
        }
        return this._router.createUrlTree(['/']);
      })
    );
  }
}
