import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MonthDaysPickerComponent } from './month-days-picker.component';
import { DayToggleButtonModule } from '../day-toggle-button/day-toggle-button.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [MonthDaysPickerComponent],
  imports: [CommonModule, DayToggleButtonModule, FormsModule],
  exports: [MonthDaysPickerComponent]
})
export class MonthDaysPickerModule {}
