import { NgModule } from '@angular/core';
import { SurveyItemComponent } from './survey-item.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { EmojiButtonComponent } from '../emoji-button/emoji-button.component';
import { DirectivesModule } from '../directives.module';

@NgModule({
  imports: [CommonModule, IonicModule, EmojiButtonComponent, DirectivesModule],
  exports: [SurveyItemComponent],
  declarations: [SurveyItemComponent]
})
export class SurveyItemModule {}
