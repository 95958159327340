import { Component, Input, ViewChild } from '@angular/core';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';
import { ESkillMatrixStatus } from '@backend/models/types/task';
import { ModalController, IonModal } from '@ionic/angular';
import { skillMatrixStatuses } from '@app/utils/skill-matrix';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-skill-matrix-for-task-modal',
  templateUrl: './skill-matrix-for-task-modal.component.html',
  styleUrls: ['./skill-matrix-for-task-modal.component.scss']
})
export class SkillMatrixForTaskModalComponent {
  @Input()
  public readonly allUsers: UserModel[] = [];

  @Input()
  public skillMatrix: { status: ESkillMatrixStatus; user: ObjectId }[] = [];

  @Input()
  public skillMatrixSubject: BehaviorSubject<
    { status: ESkillMatrixStatus; user: ObjectId }[]
  >;

  @ViewChild('userModal', { static: true })
  public userModal!: IonModal;

  protected selectedUserStatus: ESkillMatrixStatus =
    ESkillMatrixStatus.CANNOT_PERFORM;

  protected readonly skillMatrixStatus = ESkillMatrixStatus;

  public constructor(private readonly _modalCtrl: ModalController) {}

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'skill-matrix-for-task-modal');
  }

  protected trackItems(_: number, user: UserModel) {
    return user._id;
  }

  protected getUserStatus(user: UserModel) {
    return (
      this.skillMatrix.find(
        (smu) => smu.user.toString() === user._id.toString()
      )?.status || ESkillMatrixStatus.CANNOT_PERFORM
    );
  }

  protected onStatusChange(status: ESkillMatrixStatus, user: UserModel) {
    if (this.skillMatrix.find((smu) => smu.user === user._id)) {
      this.skillMatrix = this.skillMatrix.map((smu) =>
        smu.user === user._id ? { ...smu, status } : smu
      );
    } else {
      this.skillMatrix = [...this.skillMatrix, { user: user._id, status }];
    }

    this.emitSkillMatrix();
  }

  protected emitSkillMatrix() {
    this.skillMatrixSubject.next(this.skillMatrix);
  }

  protected get cannotPerformUsers() {
    return this.getUsersOfStatus(ESkillMatrixStatus.CANNOT_PERFORM);
  }

  protected get canPerformWithSupervisionUsers() {
    return this.getUsersOfStatus(
      ESkillMatrixStatus.CAN_PERFORM_WITH_SUPERVISON
    );
  }

  protected get canPerformWithReviewUsers() {
    return this.getUsersOfStatus(ESkillMatrixStatus.CAN_PERFORM_WITH_REVIEW);
  }

  protected get canPerformWithoutReviewUsers() {
    return this.getUsersOfStatus(ESkillMatrixStatus.CAN_PERFORM_WITHOUT_REVIEW);
  }

  protected get canPerformAndTrainUsers() {
    return this.getUsersOfStatus(ESkillMatrixStatus.CAN_PERFORM_AND_TRAIN);
  }

  protected get mustReviewNewVersionUsers() {
    return this.getUsersOfStatus(ESkillMatrixStatus.MUST_REVIEW_NEW_VERSION);
  }

  protected get revokedUsers() {
    return this.getUsersOfStatus(ESkillMatrixStatus.REVOKED);
  }

  protected selectUsersForGroup(skillMatrixStatus: ESkillMatrixStatus) {
    this.selectedUserStatus = skillMatrixStatus;
    this.userModal.present();
  }

  protected getUsersOfStatus(status: ESkillMatrixStatus) {
    if (status === ESkillMatrixStatus.CANNOT_PERFORM) {
      return this.allUsers.filter((user) => {
        const exists = this.skillMatrix.find((smu) => user._id === smu.user);

        if (exists) {
          return exists.status === ESkillMatrixStatus.CANNOT_PERFORM;
        } else {
          return true;
        }
      });
    }

    return this.allUsers.filter((user) => {
      return (
        this.skillMatrix.find((smu) => user._id === smu.user)?.status === status
      );
    });
  }

  protected get usersIdsOfSelectedStatus() {
    return this.getUsersOfStatus(this.selectedUserStatus).map(({ _id }) => _id);
  }

  protected get selectedStatusTitle() {
    return skillMatrixStatuses.find((s) => s.status === this.selectedUserStatus)
      .title;
  }

  protected selectedIdChanged(value: ObjectId[]): void {
    value.map((v) => {
      const foundUser = this.skillMatrix.find((u) => u.user === v);
      if (foundUser) {
        foundUser.status = this.selectedUserStatus;
      } else {
        this.skillMatrix.push({ user: v, status: this.selectedUserStatus });
      }
    });

    this.userModal.dismiss();
    this.emitSkillMatrix();
  }
}
