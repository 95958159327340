import {
  ChangeDetectionStrategy,
  Component,
  ViewChild,
  OnInit
} from '@angular/core';
import { IonNav, IonModal, LoadingController } from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { ToastService } from '@app/core/service/toast.service';
import { UserApiService, ROLES } from '@app/core';
import { CompensationApiService } from '@app/core/service/compensation-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ICompensationPool } from '@backend/models/types/compensation-pool';
import { TenantService } from '@app/core/service/tenant.service';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { combineLatest, take } from 'rxjs';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class InviteUserComponent implements OnInit {
  @ViewChild('supervisorSelectorModal', { static: true })
  public supervisorSelectorModal!: IonModal;

  protected emailRegex = /^\S+@\S+\.\S+$/;
  protected mainFormGroup: FormGroup = new FormGroup({
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.emailRegex)
    ]),
    supervisorId: new FormControl<string | null>(null),
    department: new FormControl<string>(''),
    role: new FormControl<ROLES | 'None'>('None'),
    compensationEmployeeId: new FormControl<string>(''),
    compensationPools: new FormControl<string[]>([])
  });
  protected allUsers: UserModel[] = [];
  protected roleOptions: (ROLES | 'None')[] = ['None', ...Object.values(ROLES)];
  protected compensationPools: MongoStoredObject<ICompensationPool>[] = [];
  protected tenantFeatures: Pick<ITenantFeatures, 'calculateRewards'> = {
    calculateRewards: false
  };
  protected dataIsLoaded = false;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _userListService: UserListService,
    private readonly _userApiService: UserApiService,
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _compensationApiService: CompensationApiService,
    private readonly _tenantService: TenantService
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this._userListService.users$,
      this._compensationApiService.getCompensationPools(),
      this._tenantService.tenant$
    ])
      .pipe(take(1))
      .subscribe(([users, compensationPools, tenant]) => {
        this.allUsers = users;
        this.compensationPools = compensationPools;
        this.tenantFeatures = tenant.features;

        this.dataIsLoaded = true;
      });
  }

  protected get emailError() {
    if (
      this.mainFormGroup.get('email').dirty &&
      this.mainFormGroup.get('email').errors
    ) {
      if (this.mainFormGroup.get('email').errors.required) {
        return 'Email is required';
      } else if (this.mainFormGroup.get('email').errors.pattern) {
        return 'Wrong email format';
      }
    }
    return '';
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.mainFormGroup.get('supervisorId').value];
  }

  protected get formattedSupervisor(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('supervisorId').value
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }

  protected onSupervisorChange(event: ObjectId[]): void {
    this.mainFormGroup.controls.supervisorId.setValue(event[0].toString());
    this.supervisorSelectorModal.dismiss();
  }

  protected handleSupervisorClearClick(): void {
    this.mainFormGroup.controls.supervisorId.setValue(null);
  }

  protected async inviteUser() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const supervisor = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('supervisorId').value
    );

    const user: any = {
      email: this.mainFormGroup.get('email').value.toLowerCase(),
      department: this.mainFormGroup.get('department').value,
      roles:
        this.mainFormGroup.get('role').value === 'None'
          ? []
          : [this.mainFormGroup.get('role').value],
      compensationEmployeeId: this.mainFormGroup.get('compensationEmployeeId')
        .value,
      compensationPools: this.mainFormGroup.get('compensationPools').value
    };

    if (supervisor) {
      user.supervisorId = supervisor._id;
    }

    this._userApiService.inviteUser(user).subscribe({
      next: () => {
        this._userListService.update();
        this._toastService.presentToast('The invitation has been emailed!');
        this._ionNav.pop();
      },
      error: ({ error }) => {
        loading.dismiss();
        this._toastService.presentToast(
          error.message || 'Unable to invite user'
        );
      }
    });
  }
}
