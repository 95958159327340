import { Component, OnInit, ViewChild } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { InstantFeedbackService } from '@app/core/service/instant-feedback.service';
import { IInstantFeedback } from '@backend/models/types/instant-feedback';
import { LoadingController, IonInfiniteScroll } from '@ionic/angular';
import { InstantFeedbackApiService } from '@app/core/service/instant-feedback-api.service';

@Component({
  selector: 'app-instant-feedback',
  templateUrl: './instant-feedback.component.html',
  styleUrls: ['./instant-feedback.component.scss']
})
export class InstantFeedbackComponent implements OnInit {
  @ViewChild('infiniteScroll', { static: true })
  public infiniteScroll!: IonInfiniteScroll;

  protected feedbacks: MongoStoredObject<IInstantFeedback>[] = [];
  protected isLoading = true;
  protected hasNextPage = true;
  protected page = 1;
  protected pageSize = 20;

  public constructor(
    private readonly _instantFeedbackService: InstantFeedbackService,
    private readonly _instantFeedbackApiService: InstantFeedbackApiService,
    private readonly _loadingCtrl: LoadingController
  ) {}

  public ngOnInit(): void {
    this._fetchFeedbacks();
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchFeedbacks();
  }

  private _fetchFeedbacks(reset = false): void {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.feedbacks = [];
    }

    this._instantFeedbackApiService
      .getInstantFeedbacksPaginated(this.page, this.pageSize)
      .subscribe((feedbacks) => {
        this.hasNextPage = feedbacks.length === this.pageSize;
        this.feedbacks = this.feedbacks.concat(feedbacks);
        this.isLoading = false;
        this.infiniteScroll.complete();
      });
  }

  protected async readInstantFeedback(instantFeedbackId: string) {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._instantFeedbackService
      .readInstantFeedback(instantFeedbackId)
      .subscribe(() => {
        loading.dismiss();
      });
  }
}
