import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  HostBinding
} from '@angular/core';
import { ChecklistItem } from '@app/types/checklist-item';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-checklist-folder',
  templateUrl: './checklist-folder.component.html',
  styleUrls: ['./checklist-folder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'checklist-folder'
  }
})
export class ChecklistFolderComponent {
  @Input()
  public readonly taskFolderTitle: string;

  @Input()
  public readonly items: ChecklistItem[];

  @Input()
  public readonly time: Date;

  @Input()
  public readonly forceOpen: boolean = false;

  @Input()
  public animationOrder?: number = -1;

  @Output()
  public readonly itemClick = new EventEmitter<ChecklistItem>();

  @HostBinding('style')
  public get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.checklist-folder-animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  protected isOpen = false;

  public constructor(private readonly _sanitizer: DomSanitizer) {}

  protected get formattedCountText() {
    const notCompletedToday = this.items.filter(
      (item) => !item.isCompletedAtLeastOnce()
    );

    return notCompletedToday.length
      ? `${notCompletedToday.length} remaining`
      : '';
  }

  protected listItemTrackBy(_index: number, { id }: ChecklistItem) {
    return id;
  }

  protected onFolderClick() {
    this.isOpen = !this.isOpen;
  }
}
