<ion-grid [formGroup]="form">
  <ion-row>
    <ion-col size="10">
      <app-date-list [formArray]="form.controls.dates"></app-date-list>
    </ion-col>
    <ion-col size="2" class="ion-text-right">
      <ion-fab-button
        size="small"
        color="light"
        class="add-button"
        (click)="onDateButtonClick($event)"
        appHaptics
      >
        <ion-icon name="add"></ion-icon>
      </ion-fab-button>
      <ion-popover
        [keepContentsMounted]="true"
        [isOpen]="isOpen"
        (didDismiss)="isOpen = false"
        cssClass="popover-datetime"
        #popover
      >
        <ng-template>
          <ion-datetime
            presentation="date"
            [multiple]="true"
            #datetime
            class="date-time-picker"
          >
            <div slot="buttons">
              <ion-buttons class="ion-justify-content-between">
                <ion-button
                  color="primary"
                  fill="clear"
                  (click)="isOpen = false"
                  appHaptics
                >
                  Cancel
                </ion-button>
                <ion-button
                  color="primary"
                  fill="clear"
                  (click)="onCalendarDoneClick()"
                  appHaptics
                >
                  Done
                </ion-button>
              </ion-buttons>
            </div>
          </ion-datetime>
        </ng-template>
      </ion-popover>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <ion-checkbox
        formControlName="repeatEveryYear"
        labelPlacement="end"
        class="semi-bold ion-margin-top"
        appHaptics
      >
        Repeat every year
      </ion-checkbox>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12">
      <ion-checkbox
        formControlName="adjustWeekdays"
        labelPlacement="end"
        class="semi-bold ion-margin-top"
        appHaptics
      >
        Repetitions should only fall only on weekdays
      </ion-checkbox>
    </ion-col>
  </ion-row>
</ion-grid>
