<div class="dice-container">
  <div class="dice" #dice>
    <div class="face" data-id="1">
      <div class="point point-middle point-center"></div>
    </div>
    <div class="face" data-id="2">
      <div class="point point-top point-right"></div>
      <div class="point point-bottom point-left"></div>
    </div>
    <div class="face" data-id="6">
      <div class="point point-top point-right"></div>
      <div class="point point-top point-left"></div>
      <div class="point point-middle point-right"></div>
      <div class="point point-middle point-left"></div>
      <div class="point point-bottom point-right"></div>
      <div class="point point-bottom point-left"></div>
    </div>
    <div class="face" data-id="5">
      <div class="point point-top point-right"></div>
      <div class="point point-top point-left"></div>
      <div class="point point-middle point-center"></div>
      <div class="point point-bottom point-right"></div>
      <div class="point point-bottom point-left"></div>
    </div>
    <div class="face" data-id="3">
      <div class="point point-top point-right"></div>
      <div class="point point-middle point-center"></div>
      <div class="point point-bottom point-left"></div>
    </div>
    <div class="face" data-id="4">
      <div class="point point-top point-right"></div>
      <div class="point point-top point-left"></div>
      <div class="point point-bottom point-right"></div>
      <div class="point point-bottom point-left"></div>
    </div>
  </div>
</div>
