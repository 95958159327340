<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Incentive History</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onNewClick()" appHaptics color="primary">
        New
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="compensations.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <div
      *ngFor="let compensation of compensations"
      class="compensation-item"
      (click)="openCompensation(compensation)"
    >
      {{ compensation.range.start | date : 'MMM d, y' }} -
      {{ compensation.range.end | date : 'MMM d, y' }}
    </div>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="List Is Empty"></app-empty-stub>
</ng-template>
