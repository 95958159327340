import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITag } from '@backend/models/types/tag';
import { IUploadedFile } from '@backend/types/uploaded-file';
import { Observable } from 'rxjs';
import { UserModel } from '../model/user.model';
import { ObjectId } from '@app/types/object-id';

@Injectable()
export class TagApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public prepareTag(clientDate: string): Observable<{
    serverId: string;
    pickupDate: string;
  }> {
    return this._httpClient.post<{
      serverId: string;
      pickupDate: string;
    }>('/tags/prepare', {
      clientDate
    });
  }

  public reserveTag(clientDate: string): Observable<{
    tag: MongoStoredObject<ITag>;
    pickupDate: string;
  }> {
    return this._httpClient.post<{
      tag: MongoStoredObject<ITag>;
      pickupDate: string;
    }>('/tags/reserve', {
      clientDate
    });
  }

  public deleteMyReservedTag(tagId: string): Observable<{
    deletedCount: number;
  }> {
    return this._httpClient.delete<{
      deletedCount: number;
    }>(`/tags/${tagId}`);
  }

  public uploadTag(
    tagId: string,
    body: {
      pickupDate: string;
      serverId: string;
      customerCell: string;
      customerName: string;
      notes: string;
      count: number;
      images: IUploadedFile[];
    }
  ): Observable<{
    success: boolean;
    message: string;
    tag: MongoStoredObject<ITag>;
  }> {
    return this._httpClient.post<{
      success: boolean;
      message: string;
      tag: MongoStoredObject<ITag>;
    }>(`/tags/${tagId}`, body);
  }

  public updateTag(
    tagId: string,
    body: {
      pickupDate: string;
      serverId: string;
      customerCell: string;
      customerName: string;
      notes: string;
      count: number;
      images: IUploadedFile[];
    }
  ): Observable<{
    success: boolean;
    message: string;
    tag: MongoStoredObject<ITag>;
  }> {
    return this._httpClient.put<{
      success: boolean;
      message: string;
      tag: MongoStoredObject<ITag>;
    }>(`/tags/${tagId}`, body);
  }

  public getAllTags(
    page: number,
    limit: number,
    searchTerm?: string
  ): Observable<MongoStoredObject<ITag>[]> {
    return this._httpClient.get<MongoStoredObject<ITag>[]>('/tags', {
      params: {
        page,
        limit,
        searchTerm
      }
    });
  }

  public getStatusForAllTags(
    ids: string[]
  ): Observable<
    { _id: ObjectId; pickupStatus: string; possiblePickupStatuses: string[] }[]
  > {
    return this._httpClient.get<
      {
        _id: ObjectId;
        pickupStatus: string;
        possiblePickupStatuses: string[];
      }[]
    >('/tags/status', {
      params: {
        ids
      }
    });
  }

  public getTag(tagId: string): Observable<{
    success: boolean;
    message: string;
    tag: MongoStoredObject<
      ITag & {
        user: Pick<UserModel, 'firstName' | 'lastName'>;
        possiblePickupStatuses: string[];
      }
    >;
    server: Pick<UserModel, 'firstName' | 'lastName'>;
  }> {
    return this._httpClient.get<{
      success: boolean;
      message: string;
      tag: MongoStoredObject<
        ITag & {
          user: Pick<UserModel, 'firstName' | 'lastName'>;
          possiblePickupStatuses: string[];
        }
      >;
      server: Pick<UserModel, 'firstName' | 'lastName'>;
    }>(`/tags/${tagId}`);
  }

  public updateTagStatus(
    tagId: string,
    status: string
  ): Observable<{
    success: boolean;
    message: string;
    tag: MongoStoredObject<
      ITag & { user: Pick<UserModel, 'firstName' | 'lastName'> }
    >;
  }> {
    return this._httpClient.put<{
      success: boolean;
      message: string;
      tag: MongoStoredObject<
        ITag & { user: Pick<UserModel, 'firstName' | 'lastName'> }
      >;
    }>(`/tags/${tagId}/status`, {
      status
    });
  }
}
