import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { TodaysShiftModalComponent } from './todays-shift-modal.component';
import { TimeFormControlModule } from '../time-form-control/time-form-control.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    TimeFormControlModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [TodaysShiftModalComponent],
  declarations: [TodaysShiftModalComponent]
})
export class TodaysShiftModalModule {}
