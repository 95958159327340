<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>App Feedback</ion-title>
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <div
      *ngFor="let feedback of feedbacks"
      class="feedback-card"
      [ngClass]="{ 'feedback-card--unread': !feedback.read }"
    >
      <ion-list>
        <ion-item>
          <ion-label>Tenant</ion-label>
          <ion-note slot="end">
            {{ feedback.tenantName }}
          </ion-note>
        </ion-item>
        <ion-item>
          <ion-label>User</ion-label>
          <div slot="end" class="row-labels">
            <ion-note>
              {{ feedback.userName }}
            </ion-note>
            <ion-note>
              {{ feedback.userEmail }}
            </ion-note>
          </div>
        </ion-item>
        <ion-item>
          <ion-label>Date</ion-label>
          <ion-note slot="end">
            {{ feedback.dateCreated | date : 'MMM dd, yyyy h:mm a' }}
          </ion-note>
        </ion-item>
        <ion-item>
          <ion-label>Page</ion-label>
          <ion-note slot="end">
            {{ feedback.appUrl }}
          </ion-note>
        </ion-item>
      </ion-list>
      <ion-textarea
        autoGrow
        [value]="feedback.message"
        readonly
        label="Message"
        labelPlacement="stacked"
      ></ion-textarea>
      <div class="actions">
        <ion-button
          *ngIf="!feedback.read"
          color="primary"
          (click)="readInstantFeedback(feedback._id)"
          fill="clear"
          class="action ion-no-margin"
          appHaptics
        >
          Read
        </ion-button>
      </div>
    </div>

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      [disabled]="feedbacks.length === 0 || isLoading || !hasNextPage"
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>

    <ng-container *ngIf="feedbacks.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="No Feedbacks"></app-empty-stub>
</ng-template>
