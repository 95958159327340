import { Component, Input, ViewChild } from '@angular/core';
import { TaskListService } from '@app/core/service/task-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ObjectId } from '@app/types/object-id';
import { TaskDto } from '@app/types/task-dto';
import { ESkillMatrixStatus } from '@backend/models/types/task';
import { ModalController, IonModal } from '@ionic/angular';
import { take } from 'rxjs';
import { skillMatrixStatuses } from '@app/utils/skill-matrix';

@Component({
  selector: 'app-skill-matrix-for-user-modal',
  templateUrl: './skill-matrix-for-user-modal.component.html',
  styleUrls: ['./skill-matrix-for-user-modal.component.scss']
})
export class SkillMatrixForUserModalComponent {
  @Input()
  public userId: ObjectId;

  @ViewChild('taskModal', { static: true })
  public taskModal!: IonModal;

  protected allTasks: MongoStoredObject<TaskDto>[] = [];
  protected skillMatrix: { status: ESkillMatrixStatus; task: ObjectId }[];
  protected selectedTaskStatus: ESkillMatrixStatus =
    ESkillMatrixStatus.CANNOT_PERFORM;

  protected readonly skillMatrixStatus = ESkillMatrixStatus;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _taskListService: TaskListService
  ) {
    this._taskListService.list$.pipe(take(1)).subscribe(async (at) => {
      const allTasks = at.filter((task) => !task.isDeleted);

      const skillMatrix = allTasks.map((t) => ({
        task: t._id,
        status:
          t.skillMatrix.find((smu) => smu.user.toString() === this.userId)
            ?.status || ESkillMatrixStatus.CANNOT_PERFORM
      }));

      this.allTasks = allTasks;
      this.skillMatrix = skillMatrix;
    });
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'skill-matrix-for-user-modal');
  }

  protected emitSkillMatrix(): void {
    this._taskListService.patchTasks(
      this.userId,
      this.skillMatrix.map((smt) => ({
        _id: smt.task,
        skillMatrixStatus: smt.status
      }))
    );
  }

  protected trackItems(_: number, task: MongoStoredObject<TaskDto>) {
    return task._id;
  }

  protected getTaskStatus(task: MongoStoredObject<TaskDto>) {
    return (
      this.skillMatrix.find(
        (smt) => smt.task.toString() === task._id.toString()
      )?.status || ESkillMatrixStatus.CANNOT_PERFORM
    );
  }

  protected onStatusChange(
    status: ESkillMatrixStatus,
    task: MongoStoredObject<TaskDto>
  ) {
    if (this.skillMatrix.find((smt) => smt.task === task._id)) {
      this.skillMatrix = this.skillMatrix.map((smt) =>
        smt.task === task._id ? { ...smt, status } : smt
      );
    } else {
      this.skillMatrix = [...this.skillMatrix, { task: task._id, status }];
    }

    this.emitSkillMatrix();
  }

  protected get cannotPerformTasks() {
    return this.getTasksOfStatus(ESkillMatrixStatus.CANNOT_PERFORM);
  }

  protected get canPerformWithSupervisionTasks() {
    return this.getTasksOfStatus(
      ESkillMatrixStatus.CAN_PERFORM_WITH_SUPERVISON
    );
  }

  protected get canPerformWithReviewTasks() {
    return this.getTasksOfStatus(ESkillMatrixStatus.CAN_PERFORM_WITH_REVIEW);
  }

  protected get canPerformWithoutReviewTasks() {
    return this.getTasksOfStatus(ESkillMatrixStatus.CAN_PERFORM_WITHOUT_REVIEW);
  }

  protected get canPerformAndTrainTasks() {
    return this.getTasksOfStatus(ESkillMatrixStatus.CAN_PERFORM_AND_TRAIN);
  }

  protected get mustReviewNewVersionTasks() {
    return this.getTasksOfStatus(ESkillMatrixStatus.MUST_REVIEW_NEW_VERSION);
  }

  protected get revokedTasks() {
    return this.getTasksOfStatus(ESkillMatrixStatus.REVOKED);
  }

  protected selectTasksForGroup(skillMatrixStatus: ESkillMatrixStatus) {
    this.selectedTaskStatus = skillMatrixStatus;
    this.taskModal.present();
  }

  protected getTasksOfStatus(status: ESkillMatrixStatus) {
    if (status === ESkillMatrixStatus.CANNOT_PERFORM) {
      return this.allTasks.filter((task) => {
        const exists = this.skillMatrix.find((smt) => task._id === smt.task);

        if (exists) {
          return exists.status === ESkillMatrixStatus.CANNOT_PERFORM;
        } else {
          return true;
        }
      });
    }

    return this.allTasks.filter((task) => {
      return (
        this.skillMatrix.find((smt) => task._id === smt.task)?.status === status
      );
    });
  }

  protected get tasksIdsOfSelectedStatus() {
    return this.getTasksOfStatus(this.selectedTaskStatus).map(({ _id }) => _id);
  }

  protected get selectedStatusTitle() {
    return skillMatrixStatuses.find((s) => s.status === this.selectedTaskStatus)
      .title;
  }

  protected selectedIdChanged(value: ObjectId[]): void {
    value.map((v) => {
      const foundUser = this.skillMatrix.find((u) => u.task === v);
      if (foundUser) {
        foundUser.status = this.selectedTaskStatus;
      } else {
        this.skillMatrix.push({ task: v, status: this.selectedTaskStatus });
      }
    });

    this.taskModal.dismiss();
    this.emitSkillMatrix();
  }
}
