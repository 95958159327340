import { eachDayOfInterval } from 'date-fns';
import { DateInterval } from '@backend/types/date-interval';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { isIntervalsIntersect } from '@app/utils/is-intervals-intersect';
import { TaskRenderer } from './task-renderer.class';
import { createTaskInterval } from '@app/utils/create-task-interval';
import { TaskDto } from '@app/types/task-dto';

export class TaskDailyRenderer extends TaskRenderer {
  public constructor(
    task: MongoStoredObject<TaskDto>,
    allowTasksToBeDisplayedOnlyDuringCertainHours: boolean
  ) {
    super(task, allowTasksToBeDisplayedOnlyDuringCertainHours);
  }

  protected createMatchingIntervals(interval: DateInterval): DateInterval[] {
    const result: DateInterval[] = eachDayOfInterval(interval)
      .map((d) =>
        createTaskInterval(
          d,
          this.task.appearsAt,
          this.task.disappearsAt,
          this.allowTasksToBeDisplayedOnlyDuringCertainHours
        )
      )
      .filter((i) => isIntervalsIntersect(i, interval));
    return result;
  }
}
