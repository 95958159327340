import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITask } from '@backend/models/types/task';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { TaskPointsEarns } from '@backend/models/types/task';
import { initials } from '@app/utils/initials';
import { UserModel } from '@app/core/model/user.model';

@Component({
  selector: 'app-performance-item',
  templateUrl: './performance-item.component.html',
  styleUrls: ['./performance-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PerformanceItemComponent {
  @Input()
  public readonly performance: MongoStoredObject<
    ITaskPerformance & { task: ITask }
  >;

  @Input()
  public readonly disabled?: boolean = false;

  @Input()
  public readonly user: UserModel;

  protected get isPerUnit() {
    return this.performance.task.pointsEarns === TaskPointsEarns.PER_UNIT;
  }

  protected get userInitials() {
    return initials(this.user.firstName, this.user.lastName);
  }
}
