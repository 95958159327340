<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>{{ title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" color="primary" appHaptics>
        Done
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="allUsers$ | async as allUsers" class="ion-padding">
    <h4 class="acknowledgments-modal__list-title" *ngIf="acknowledged.length">
      {{ acknowledgedLabel }}
    </h4>
    <ul class="acknowledgments-modal__list">
      <li
        class="acknowledgments-modal__list-item"
        *ngFor="let person of acknowledged; let i = index"
      >
        <ion-icon
          name="checkmark-circle"
          class="acknowledgments-modal__list-item-icon acknowledgments-modal__list-item-icon--accent"
        />
        <span class="acknowledgments-modal__list-item-text">
          {{ person.id | personName$ : person.recipientName | async }}
        </span>
        <span class="acknowledgments-modal__list-item-date">
          {{ fDateAndTime(person.acknowledged) }}
        </span>
      </li>
    </ul>
    <h4 class="acknowledgments-modal__list-title" *ngIf="read.length">Read</h4>
    <ul class="acknowledgments-modal__list">
      <li
        class="acknowledgments-modal__list-item"
        *ngFor="let person of read; let i = index"
      >
        <ion-icon name="eye" class="acknowledgments-modal__list-item-icon" />
        <span class="acknowledgments-modal__list-item-text">
          {{ person.id | personName$ : person.recipientName | async }}
        </span>
        <span class="acknowledgments-modal__list-item-date">
          {{ fDateAndTime(person.read) }}
        </span>
      </li>
    </ul>
    <h4 class="acknowledgments-modal__list-title" *ngIf="unread.length">
      Unread
    </h4>
    <ul class="acknowledgments-modal__list">
      <li
        class="acknowledgments-modal__list-item"
        *ngFor="let person of unread; let i = index"
      >
        <ion-icon
          name="eye-off"
          class="acknowledgments-modal__list-item-icon"
        />
        <span class="acknowledgments-modal__list-item-text">
          {{ person.id | personName$ : person.recipientName | async }}
        </span>
      </li>
    </ul>
  </div>
</ion-content>
