<app-wide-button
  [label]="label"
  [disabled]="disabled"
  (buttonClicked)="onDateButtonClick($event)"
>
  <ng-container *ngIf="value; else stub">
    {{ value | date : 'MMM d, y' }}
  </ng-container>
  <ng-template #stub>--- --, ----</ng-template>
</app-wide-button>

<ion-popover
  [keepContentsMounted]="true"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
  cssClass="popover-datetime"
  #popover
>
  <ng-template>
    <ion-datetime
      presentation="date"
      class="date-time-picker"
      [min]="min"
      [max]="max"
      (ionChange)="onCalendarChange($event)"
    />
  </ng-template>
</ion-popover>
