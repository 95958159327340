<ion-button
  color="light"
  expand="block"
  (click)="openPicker()"
  [disabled]="disabled"
  appHaptics
  [disableHaptics]="disabled"
  class="ion-no-margin"
>
  <ng-container *ngIf="value; else placeholder">
    {{ formattedValue }}
  </ng-container>
</ion-button>

<ng-template #placeholder>Click to set duration</ng-template>
