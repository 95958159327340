<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="close()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title>Multi-User Mode</ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="isMultiUserModeActive ? deactivate() : activate()"
        [color]="isMultiUserModeActive ? 'danger' : 'primary'"
        appHaptics
        [disabled]="isLoading"
        [disableHaptics]="isLoading"
      >
        {{ isMultiUserModeActive ? 'Deactivate' : 'Activate' }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-common-background">
    <ion-searchbar (ionInput)="searchbarInput($event)"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid *ngIf="isLoading">
    <ion-row class="ion-justify-content-center">
      <ion-spinner name="lines-sharp-small"></ion-spinner>
    </ion-row>
  </ion-grid>

  <ion-list *ngIf="!isLoading">
    <ion-item>
      <ion-label class="list-head"> User </ion-label>
      <ion-label class="list-head pin-code"> Passcode </ion-label>
    </ion-item>
    <ion-item
      *ngFor="let user of users | filterUsers : filter; trackBy: trackItems"
    >
      <ion-label class="user-name">
        {{ user.firstName }} {{ user.lastName }}
      </ion-label>
      <ion-button
        fill="clear"
        class="ion-no-margin"
        (click)="copyPinCode(user)"
        appHaptics
      >
        {{ user.pin }}
        <ion-icon slot="end" name="copy-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </ion-list>
</ion-content>
