import { Injectable } from '@angular/core';
import { AnnouncementApiService } from './announcement-api.service';
import { Observable, Subject, map, repeat, shareReplay, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AnnouncementListService {
  public readonly pageSize = 20;

  private readonly _update$ = new Subject<void>();

  public unacknowledgedCount$: Observable<number> = this._api
    .getUnacknowledgedCount()
    .pipe(
      map(({ unacknowledgedCount }) => unacknowledgedCount),
      repeat({ delay: () => this._update$ }),
      shareReplay({ refCount: true, bufferSize: 1 })
    );

  public constructor(private readonly _api: AnnouncementApiService) {}

  public updateUnacknowledgedCount() {
    this._update$.next();
  }

  public editAnnouncement(
    id: string,
    data: {
      title: string;
      body: string;
      recipients: string[];
      isCritical: boolean;
      attachments: string[];
    },
    keepStats?: boolean
  ) {
    return this._api
      .editAnnouncement(id, data, keepStats)
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public publishAnnouncement(data: {
    title: string;
    body: string;
    recipients: string[];
    isCritical: boolean;
    attachments: string[];
  }) {
    return this._api
      .publishAnnouncement(data)
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public acknowledgeAnnouncement(announcementId: string) {
    return this._api
      .acknowledgeAnnouncement(announcementId)
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public deleteAnnouncement(announcementId: string) {
    return this._api
      .deleteAnnouncement(announcementId)
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public readAnnouncement(announcementId: string) {
    return this._api
      .readAnnouncement(announcementId)
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public respondToAnnouncement(announcementId: string, data: { body: string }) {
    return this._api
      .respondToAnnouncement(announcementId, data)
      .pipe(tap(() => this.updateUnacknowledgedCount()));
  }

  public getThreadOfAnnouncement(announcementId: string) {
    return this._api.getThreadOfAnnouncement(announcementId);
  }
}
