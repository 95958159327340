import { NgModule } from '@angular/core';
import { SkillMatrixStatusSelectComponent } from './skill-matrix-status-select.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [CommonModule, IonicModule, DirectivesModule],
  exports: [SkillMatrixStatusSelectComponent],
  declarations: [SkillMatrixStatusSelectComponent]
})
export class SkillMatrixSelectModule {}
