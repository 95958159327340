import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-day-toggle-button',
  templateUrl: './day-toggle-button.component.html',
  styleUrls: ['./day-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'day-toggle-button'
  }
})
export class DayToggleButtonComponent {
  protected checkedState: boolean = false;

  @Input()
  public set checked(isChecked: boolean) {
    this.checkedState = isChecked;
  }

  @Output()
  public checkedChange = new EventEmitter<boolean>();

  protected onStateChange(): void {
    this.checkedChange.emit(this.checkedState);
  }
}
