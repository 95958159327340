import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import FuzzySearch from 'fuzzy-search';

@Pipe({
  name: 'filterBehaviors',
  standalone: true
})
export class FilterBehaviorsPipe implements PipeTransform {
  public transform(
    items: MongoStoredObject<BehaviorModel>[],
    filter: string
  ): MongoStoredObject<BehaviorModel>[] {
    if (filter.length === 0) {
      return items;
    }
    const searcher = new FuzzySearch(items, ['name'], {
      caseSensitive: false
    });
    return searcher.search(filter);
  }
}
