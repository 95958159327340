<button
  class="read-eye"
  [ngClass]="{ 'read-eye--button': button }"
  appHaptics
  [disableHaptics]="!button"
>
  <ion-icon name="eye" />
  <span class="read-eye__text">
    {{ label }}
  </span>
</button>
