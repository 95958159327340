import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  AfterViewInit
} from '@angular/core';
import { IonicModule, IonSearchbar } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { UserModel } from '@app/core/model/user.model';
import { ObjectId } from '@app/types/object-id';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FilterUsersPipe } from '@app/modules/user-selector/filter-users.pipe';
import { DirectivesModule } from '@app/modules/directives.module';
import { ROLES } from '@app/core';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    WidthRestrictedContainerModule,
    FormsModule,
    FilterUsersPipe,
    DirectivesModule
  ]
})
export class UserSelectorComponent implements OnInit, AfterViewInit {
  @Input()
  public set recipients(value: ObjectId[]) {
    this.originalRecipients = value;
  }

  @Input()
  public readonly hideSelectAllButton?: boolean = false;

  @Input()
  public readonly title?: string = 'Recipients';

  @Input()
  public readonly users: UserModel[];

  @Input()
  public readonly hiddenUsers: string[] = [];

  @Input()
  public readonly multiple?: boolean = false;

  @Input()
  public readonly focusSearchbar?: boolean = false;

  @Input()
  public readonly doneBtnText?: string = 'Done';

  @Output()
  public selectionCancel = new EventEmitter<void>();

  @Output()
  public selectionChange = new EventEmitter<ObjectId[]>();

  @ViewChild('ionSearchbar')
  public ionSearchbar!: IonSearchbar;

  protected originalRecipients: ObjectId[] = [];
  public selected: {
    id: ObjectId;
    name: string;
    checked: boolean;
    roles: ROLES[];
  }[] = [];
  public filter = '';

  protected get isAllChecked(): boolean {
    return (
      this.selected.filter((item) => item.checked).length ===
        this.users.length && this.users.length > 0
    );
  }

  protected get isIndeterminate(): boolean {
    const checkedItems = this.selected.filter((i) => i.checked);
    return checkedItems.length > 0 && checkedItems.length < this.users.length;
  }

  protected get canSave(): boolean {
    const checkedItems = this.selected.filter((i) => i.checked);

    if (checkedItems.length !== this.originalRecipients.length) {
      return true;
    }

    return !this.originalRecipients.reduce((prev, cur) => {
      if (prev === false) return false;
      return !!checkedItems.find((item) => item.id === cur);
    }, true);
  }

  public ngOnInit(): void {
    this.selected = this.users
      .map((user) => ({
        id: user._id,
        name: `${user.firstName} ${user.lastName}`,
        checked: this.originalRecipients.includes(user._id),
        roles: user.roles
      }))
      .sort((a, b) => {
        const aChecked = a.checked;
        const bChecked = b.checked;
        if (aChecked && !bChecked) {
          return -1;
        }
        if (!aChecked && bChecked) {
          return 1;
        }
        return 0;
      });
  }

  public ngAfterViewInit(): void {
    if (this.focusSearchbar) {
      setTimeout(() => {
        this.ionSearchbar.setFocus();
      }, 600);
    }
  }

  protected trackItems(
    _: number,
    item: {
      id: ObjectId;
      name: string;
      checked: boolean;
      roles: ROLES[];
    }
  ) {
    return item.id;
  }

  protected cancelChanges() {
    this.selectionCancel.emit();
  }

  protected confirmChanges() {
    const checkedItems = this.selected
      .filter((i) => i.checked)
      .map((i) => i.id);
    this.selectionChange.emit(checkedItems);
  }

  protected checkboxClick(event: any, value: ObjectId) {
    event.preventDefault();

    if (this.multiple) {
      this.selected = this.selected.map((item) => ({
        ...item,
        checked: item.id === value ? !item.checked : item.checked
      }));
    } else {
      this.selected = this.selected.map((item) => ({
        ...item,
        checked: item.id === value
      }));

      this.confirmChanges();
    }
  }

  protected allUsersChange(event: any): void {
    const {
      detail: { checked }
    }: { detail: { checked: boolean } } = event;

    this.selected = this.selected.map((item) => ({
      ...item,
      checked
    }));
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected isUserHidden(userId: string) {
    return this.hiddenUsers.includes(userId);
  }

  protected getFirst() {
    if (!this.filter) return null;

    const users = new FilterUsersPipe()
      .transform(this.selected, this.filter)
      .filter((user) => !this.isUserHidden(user.id));

    if (users.length > 0) {
      return users[0];
    }

    return null;
  }

  protected selectFirst() {
    const first = this.getFirst();

    if (first) {
      if (this.multiple) {
        first.checked = !first.checked;
      } else {
        this.selected = this.selected.map((user) => ({
          ...user,
          checked: user.id === first.id
        }));

        this.confirmChanges();
      }
    }
  }
}
