import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeFormControlComponent } from './time-form-control.component';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { FormatTimeModule } from '../format-time/format-time.module';

@NgModule({
  declarations: [TimeFormControlComponent],
  imports: [CommonModule, IonicModule, DirectivesModule, FormatTimeModule],
  exports: [TimeFormControlComponent]
})
export class TimeFormControlModule {}
