<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>Time Attack</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div>
  <div class="ion-padding">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <p class="time-attack-desc">
            Compete for the fastest time while maintaining quality.
          </p>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <p class="time-attack-desc">
            Click <b>Start</b> and ask a manager to initiate. Perform the
            official process to completion. When finished, hit <b>Finish</b> and
            have your manager verify your speed and quality.
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <ion-list class="ion-padding">
    <ion-item>
      <ion-label>Average Time</ion-label>
      <ion-note slot="end">
        {{ averageTime || 'No Data' }}
      </ion-note>
    </ion-item>
    <ion-item *ngIf="timeAttackStats.rewardForBeatingTheAverage">
      <ion-label>Reward for Beating the Average</ion-label>
      <ion-note slot="end">
        {{ timeAttackStats.rewardForBeatingTheAverage }} points
      </ion-note>
    </ion-item>
    <ion-item *ngIf="timeAttackStats.rewardForAttempt">
      <ion-label>Reward for Attempt</ion-label>
      <ion-note slot="end">
        {{ timeAttackStats.rewardForAttempt }} points
      </ion-note>
    </ion-item>
    <ion-item lines="none">
      <ion-label>Current Record</ion-label>
      <ion-note slot="end">
        {{ currentRecordTime || 'No Data' }}
      </ion-note>
    </ion-item>
    <ion-item *ngIf="currentRecordTime" class="current-record-user">
      <ion-label>by</ion-label>
      <ion-note slot="end">
        {{ currentRecordUser || 'No Data' }}
      </ion-note>
    </ion-item>
  </ion-list>
  <ion-grid>
    <ion-row class="ion-justify-content-center ion-padding">
      <ion-col size="6">
        <ion-button
          type="submit"
          color="dark"
          expand="block"
          (click)="onStartClick()"
          appHaptics
        >
          Start
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
