import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { ApprovalModalComponent } from './approval-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeFormControlModule } from '../time-form-control/time-form-control.module';
import { DateSelectorModule } from '../date-selector/date-selector.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    TimeFormControlModule,
    DateSelectorModule
  ],
  exports: [ApprovalModalComponent],
  declarations: [ApprovalModalComponent]
})
export class ApprovalModalModule {}
