import { Component, ViewChild, OnInit, Input } from '@angular/core';
import {
  IonNav,
  IonModal,
  LoadingController,
  ModalController
} from '@ionic/angular';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { UserApiService, ROLES, AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { UserEntityService } from '@app/core/service/user-entity.service';
import {
  defaultScheduleFormGroup,
  defaultWeekTimeFormGroup
} from '@app/utils/user-schedule';
import fastDeepEqual from 'fast-deep-equal';
import { IUserSchedule } from '@backend/models/types/user';
import { ManageUserTasksModalComponent } from '@app/modules/manage-user-tasks-modal/manage-user-tasks-modal.component';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { TenantService } from '@app/core/service/tenant.service';
import { CompensationApiService } from '@app/core/service/compensation-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ICompensationPool } from '@backend/models/types/compensation-pool';
import { combineLatest, take } from 'rxjs';
import { SkillMatrixForUserModalComponent } from '@app/modules/skill-matrix-for-user-modal/skill-matrix-for-user-modal.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {
  @Input()
  public readonly userId: string;

  @ViewChild('supervisorSelectorModal', { static: true })
  public supervisorSelectorModal!: IonModal;

  protected initialUserData: {
    firstName: string;
    lastName: string;
    email: string;
    supervisorId: string | null;
    department: string;
    role: ROLES | 'None';
    schedule: IUserSchedule;
    compensationEmployeeId: string;
    compensationPools: string[];
  } = null;
  protected emailRegex = /^\S+@\S+\.\S+$/;
  protected weekTimeFormGroup: FormGroup = new FormGroup(
    defaultWeekTimeFormGroup
  );
  protected scheduleFormGroup: FormGroup = new FormGroup(
    defaultScheduleFormGroup
  );
  protected mainFormGroup: FormGroup = new FormGroup({
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('[a-zA-Z -]*')
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('[a-zA-Z -]*')
    ]),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.emailRegex)
    ]),
    supervisorId: new FormControl<string | null>(''),
    department: new FormControl<string>(''),
    role: new FormControl<ROLES | 'None'>('None'),
    compensationEmployeeId: new FormControl<string>(''),
    compensationPools: new FormControl<string[]>([])
  });
  protected isDeleted = false;
  protected allUsers: UserModel[] = [];
  protected roleOptions: (ROLES | 'None')[] = ['None', ...Object.values(ROLES)];
  protected compensationPools: MongoStoredObject<ICompensationPool>[] = [];
  protected tenantFeatures: Pick<
    ITenantFeatures,
    'userSchedule' | 'calculateRewards'
  > = {
    userSchedule: false,
    calculateRewards: false
  };
  protected dataIsLoaded = false;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _userListService: UserListService,
    private readonly _userApiService: UserApiService,
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _userEntityService: UserEntityService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _modalCtrl: ModalController,
    private readonly _tenantService: TenantService,
    private readonly _compensationApiService: CompensationApiService
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this._userListService.users$,
      this._compensationApiService.getCompensationPools(),
      this._tenantService.tenant$
    ])
      .pipe(take(1))
      .subscribe(([users, compensationPools, tenant]) => {
        this.allUsers = users;
        this.compensationPools = compensationPools;
        this.tenantFeatures = tenant.features;

        this.dataIsLoaded = true;
        this._getUserData();
      });
  }

  protected get canSave() {
    if (!this.initialUserData) return false;

    const isValid =
      this.mainFormGroup.valid &&
      this.scheduleFormGroup.valid &&
      this.weekTimeFormGroup.valid &&
      this.initialUserData.schedule;
    const mainDataChanged = !fastDeepEqual(
      {
        firstName: this.initialUserData.firstName,
        lastName: this.initialUserData.lastName,
        email: this.initialUserData.email,
        supervisorId: this.initialUserData.supervisorId,
        department: this.initialUserData.department,
        role: this.initialUserData.role,
        compensationEmployeeId: this.initialUserData.compensationEmployeeId,
        compensationPools: this.initialUserData.compensationPools
      },
      {
        firstName: this.mainFormGroup.get('firstName').value,
        lastName: this.mainFormGroup.get('lastName').value,
        email: this.mainFormGroup.get('email').value,
        supervisorId: this.mainFormGroup.get('supervisorId').value,
        department: this.mainFormGroup.get('department').value,
        role: this.mainFormGroup.get('role').value,
        compensationEmployeeId: this.mainFormGroup.get('compensationEmployeeId')
          .value,
        compensationPools: this.mainFormGroup.get('compensationPools').value
      }
    );
    const scheduleDataChanged = !fastDeepEqual(this.scheduleFormGroup.value, {
      scheduleType: this.initialUserData.schedule.scheduleType,
      weekDays: this.initialUserData.schedule.weekDays
    });
    const weekTimeDataChanged = !fastDeepEqual(
      Object.values(this.weekTimeFormGroup.value),
      this.initialUserData.schedule.weekTime.map((time) => ({
        from: time.from,
        to: time.to
      }))
    );

    return (
      isValid && (mainDataChanged || scheduleDataChanged || weekTimeDataChanged)
    );
  }

  protected get firstNameError() {
    if (
      this.mainFormGroup.get('firstName').dirty &&
      this.mainFormGroup.get('firstName').errors
    ) {
      if (this.mainFormGroup.get('firstName').errors.required) {
        return 'First name is required';
      } else if (this.mainFormGroup.get('firstName').errors.pattern) {
        return 'First name contains invalid characters';
      }
    }
    return '';
  }

  protected get lastNameError() {
    if (
      this.mainFormGroup.get('lastName').dirty &&
      this.mainFormGroup.get('lastName').errors
    ) {
      if (this.mainFormGroup.get('lastName').errors.required) {
        return 'Last name is required';
      } else if (this.mainFormGroup.get('lastName').errors.pattern) {
        return 'Last name contains invalid characters';
      }
    }
    return '';
  }

  protected get emailError() {
    if (
      this.mainFormGroup.get('email').dirty &&
      this.mainFormGroup.get('email').errors
    ) {
      if (this.mainFormGroup.get('email').errors.required) {
        return 'Email is required';
      } else if (this.mainFormGroup.get('email').errors.pattern) {
        return 'Wrong email format';
      }
    }
    return '';
  }

  protected get selectedUserIds(): ObjectId[] {
    return [this.mainFormGroup.get('supervisorId').value];
  }

  protected get formattedSupervisor(): string {
    const user = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('supervisorId').value
    );
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected onBackButtonClick(): void {
    this._ionNav.pop();
  }

  protected onSupervisorChange(event: ObjectId[]): void {
    this.mainFormGroup.controls.supervisorId.setValue(event[0].toString());
    this.supervisorSelectorModal.dismiss();
  }

  protected handleSupervisorClearClick(): void {
    this.mainFormGroup.controls.supervisorId.setValue('');
  }

  protected async updateUser() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const supervisor = this.allUsers.find(
      (user) => user._id === this.mainFormGroup.get('supervisorId').value
    );

    const newScheduleValue = this.scheduleFormGroup.value;

    const user: any = {
      firstName: this.mainFormGroup.get('firstName').value,
      lastName: this.mainFormGroup.get('lastName').value,
      email: this.mainFormGroup.get('email').value.toLowerCase(),
      department: this.mainFormGroup.get('department').value,
      roles:
        this.mainFormGroup.get('role').value === 'None'
          ? []
          : [this.mainFormGroup.get('role').value],
      schedule: {
        ...newScheduleValue,
        weekTime: Object.values(this.weekTimeFormGroup.value)
      },
      compensationEmployeeId: this.mainFormGroup.get('compensationEmployeeId')
        .value,
      compensationPools: this.mainFormGroup.get('compensationPools').value
    };

    if (supervisor) {
      user.supervisorId = supervisor._id;
    }

    this._userApiService.editUser(this.userId, user).subscribe({
      next: () => {
        this._userListService.update();
        this._userEntityService.update();
        this._toastService.presentToast('Changes Saved Successfully!');
        this._ionNav.pop();
      },
      error: (error) =>
        console.log(`There was an error updating the user ${error}`)
    });
  }

  protected async toggleSoftDelete() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._userApiService
      .softDeleteRequest(!this.isDeleted, this.userId)
      .subscribe(() => {
        loading.dismiss();
        this._toastService.presentToast(
          this.isDeleted
            ? 'User Reactivated Successfully!'
            : 'User Disabled Successfully!'
        );

        this.isDeleted = !this.isDeleted;
        this._userListService.update();
      });
  }

  private async _getUserData() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._userApiService.getUserById(this.userId).subscribe((res) => {
      this.initialUserData = {
        firstName: res.firstName,
        lastName: res.lastName,
        email: res.email,
        supervisorId: res.supervisor ? res.supervisor._id : null,
        department: res.department,
        role: res.roles.length ? res.roles[0] : 'None',
        schedule: res.schedule,
        compensationEmployeeId: res.compensationEmployeeId || '',
        compensationPools: this.compensationPools.reduce((prev, cur) => {
          if (cur.users.find((u) => u.toString() === res._id)) {
            return [...prev, cur._id];
          }
          return prev;
        }, [])
      };
      this.isDeleted = res.isDeleted;
      this.mainFormGroup.reset(this.initialUserData);

      if (res.schedule) {
        this.scheduleFormGroup.reset({
          scheduleType: res.schedule.scheduleType,
          weekDays: res.schedule.weekDays
        });
        this.weekTimeFormGroup.reset(res.schedule.weekTime);
      } else if (
        res._id.toString() === this._authenticationService.user._id.toString()
      ) {
        this._authenticationService.setUser({
          ...this._authenticationService.user,
          schedule: {
            ...this.scheduleFormGroup.value,
            weekTime: Object.values(this.weekTimeFormGroup.value)
          }
        });
      }

      loading.dismiss();
    });
  }

  protected async manageTasks() {
    const manageUserTasksModal = await this._modalCtrl.create({
      id: 'manage-user-tasks-modal',
      component: ManageUserTasksModalComponent,
      componentProps: {
        userId: this.userId
      },
      handle: false,
      breakpoints: [0, 1],
      initialBreakpoint: 1
    });
    manageUserTasksModal.present();
  }

  protected async openSkillMatrix() {
    const skillMatrixForUserModal = await this._modalCtrl.create({
      id: 'skill-matrix-for-user-modal',
      component: SkillMatrixForUserModalComponent,
      componentProps: {
        userId: this.userId
      },
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      handle: false
    });
    skillMatrixForUserModal.present();
  }
}
