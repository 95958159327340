<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">{{ item.task.title }}</ion-title>
    <ion-buttons slot="end" *ngIf="isAdmin">
      <ion-button
        color="primary"
        (click)="onEditTaskClick(item.task)"
        appHaptics
      >
        Edit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <div class="summary-container">
        <div class="summary">
          <div class="summary-label">LAST COMPLETE</div>
          <div class="summary-data">
            <div *ngIf="lastPerformance">
              <ion-button
                fill="clear"
                class="ion-no-margin ion-no-padding task-history-button"
                (click)="showTaskHistory()"
                appHaptics
              >
                {{ lastPerformance.user.firstName }}
                {{ lastPerformance.user.lastName }}
              </ion-button>
              |
              {{ lastPerformance.completeTime | date : 'h:mm a M/d/yy' }}
            </div>
            <div *ngIf="!lastPerformance">Not performed yet</div>
          </div>
          <div class="summary-label">TARGET DURATION</div>
          <div class="summary-data">
            {{
              item.task.performanceEvaluation ===
              taskPerformanceEvaluationType.AVERAGE
                ? averageTaskTime
                : (item.task.performanceDuration | formatDuration)
            }}
          </div>
          <div class="summary-label">FREQUENCY</div>
          <div class="summary-data">
            <ng-container
              *ngIf="
                item.task.schedule[0].scheduleType === taskScheduleType.DAILY
              "
            >
              Every day
            </ng-container>
            <ng-container
              *ngIf="
                item.task.schedule[0].scheduleType === taskScheduleType.NEVER
              "
            >
              As needed
            </ng-container>
            <ng-container
              *ngIf="
                item.task.schedule[0].scheduleType ===
                taskScheduleType.SPECIFIC_DATES
              "
            >
              On specified dates
            </ng-container>
            <ng-container
              *ngIf="
                item.task.schedule[0].scheduleType === taskScheduleType.WEEKLY
              "
            >
              On
              {{ item.task.schedule[0].weekDays | daysOfWeeklySchedule }}
            </ng-container>
          </div>
          <div class="summary-label">POINTS</div>
          <div class="summary-data">
            {{ item.task.points }}
            <ng-container
              *ngIf="item.task.pointsEarns === taskPointsEarns.PER_UNIT"
            >
              for each {{ $any(item.task).pointsUnit }}
            </ng-container>
          </div>
        </div>
        <div class="app-divider-line"></div>

        <div *ngIf="item.task.extraAppearDate && isAdminOrManager">
          <div class="extra-date-warning">
            <span class="extra-date-warning__message">
              <b>*</b> Forced to Show in the Checklist on
              {{ addToChecklistDateText }}
            </span>
            <ion-buttons slot="end">
              <ion-button
                color="danger"
                (click)="removeFromChecklist()"
                appHaptics
              >
                Remove
              </ion-button>
            </ion-buttons>
          </div>
          <div class="app-divider-line"></div>
        </div>
      </div>

      <ng-container
        *ngIf="
          item.task.pointsEarns === taskPointsEarns.PER_UNIT &&
          !addToChecklistMode &&
          !item.task.isAuditTask
        "
      >
        <div class="points-unit">
          {{ perUnitQuestion }}
        </div>
        <div class="units-value-input">
          <button
            appUnitChangeButton
            (click)="onUnitsMinusClick()"
            [disabled]="cantMinus"
            appHaptics
            [disableHaptics]="cantMinus"
          >
            –
          </button>
          <ion-input
            class="selected-units"
            type="text"
            placeholder="1"
            [maxlength]="6"
            inputmode="numeric"
            pattern="[1-9][0-9]*"
            digitOnly
            [(ngModel)]="selectedUnits"
          />
          <button
            appUnitChangeButton
            (click)="onUnitsPlusClick()"
            [disabled]="cantPlus"
            appHaptics
            [disableHaptics]="cantPlus"
          >
            +
          </button>
        </div>
      </ng-container>

      <div *ngIf="item.task.auditSuccessCriteria.length">
        <div class="body-heading">Success Criteria:</div>
        <div class="body-text ion-padding">
          <ul>
            <li *ngFor="let successCriterion of item.task.auditSuccessCriteria">
              {{ successCriterion }}
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="!item.task.isAuditTask && body">
        <div class="body-heading">Description:</div>
        <app-wysiwyg-editor [value]="body" mode="view" />
      </div>

      <div *ngIf="item.task.isAuditTask && tasksForAudit.length">
        <div class="body-heading">Tasks for Audit:</div>
        <ion-list class="ion-padding-vertical">
          <ion-item
            *ngFor="let taskForAudit of tasksForAudit"
            button
            (click)="onTaskForAuditClick(taskForAudit)"
            class="task-for-audit"
            [disabled]="taskForAudit.criteria.length === 0"
          >
            <div class="task-for-audit__text">
              <ion-label class="ion-text-capitalize">
                {{ taskForAudit.title }}
              </ion-label>
              <ion-label
                *ngIf="checklistSettings.showLastCompleteInTasksForAudit"
                class="ion-text-capitalize task-for-audit__last-complete"
              >
                Last Complete:
                {{ taskForAudit.lastPerformance.performer.firstName }}
                {{ taskForAudit.lastPerformance.performer.lastName }} |
                {{
                  taskForAudit.lastPerformance.completeTime
                    | date : 'h:mm a M/d/yy'
                }}
              </ion-label>
            </div>
            <ion-icon
              slot="end"
              *ngIf="isTaskForAuditReviewed(taskForAudit)"
              name="checkmark-circle"
              class="task-for-audit__success-icon"
              color="primary"
            ></ion-icon>
          </ion-item>
        </ion-list>
      </div>
    </div>

    <div
      class="bottom-content ion-padding"
      *ngIf="isTaskLoading || isLastPerformanceLoading"
    >
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-spinner name="lines-sharp-small"></ion-spinner>
        </ion-row>
      </ion-grid>
    </div>

    <div
      class="bottom-content"
      *ngIf="!isTaskLoading && !isLastPerformanceLoading"
    >
      <ion-grid *ngIf="showAddToChecklistButton && !item.task.extraAppearDate">
        <ion-row>
          <ion-col class="ion-no-padding">
            <div class="summary-data">Checklist Date:</div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col class="ion-no-padding">
            <ion-button
              expand="block"
              class="ion-no-margin add-to-checklist-date-btn"
              fill="clear"
              (click)="onDateButtonClick($event)"
              appHaptics
            >
              {{ addToChecklistDateText }}
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              color="dark"
              (click)="addToChecklist()"
              expand="block"
              appHaptics
            >
              Add to Checklist
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid *ngIf="!showAddToChecklistButton">
        <ion-row
          class="ion-justify-content-center ion-padding"
          *ngIf="!myInProgressPerformance; else inProgressActions"
        >
          <ion-col
            size="6"
            *ngIf="
              tenantFeatures.timeAttack &&
              item.task.timeAttack !== taskTimeAttackType.OFF
            "
          >
            <ion-button
              color="dark"
              expand="block"
              [disabled]="item.isCompleted()"
              (click)="onTimeAttackClick()"
              appHaptics
              [disableHaptics]="item.isCompleted()"
              fill="outline"
            >
              Time Attack
            </ion-button>
          </ion-col>
          <ion-col
            size="6"
            *ngIf="
              !tenantFeatures.timeAttack ||
              item.task.timeAttack !== taskTimeAttackType.REQUIRED
            "
          >
            <ion-button
              color="dark"
              expand="block"
              [disabled]="item.isCompleted() || !isAuditDone"
              (tap)="onCompleteClick()"
              appHaptics
              [disableHaptics]="item.isCompleted() || !isAuditDone"
              [enableLongPress]="true"
              (longPress)="onCompleteBySomeoneClick()"
            >
              Complete
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-popover
  [keepContentsMounted]="true"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
  cssClass="popover-datetime"
  #popover
>
  <ng-template>
    <ion-datetime
      presentation="date"
      class="date-time-picker"
      [min]="minAddToChecklistDate"
      (ionChange)="onCalendarChange($event)"
    />
  </ng-template>
</ion-popover>

<ng-template #inProgressActions>
  <ng-container
    *ngIf="
      myInProgressPerformance.status === taskPerformanceStatus.IN_PROGRESS;
      else waitingForApproval
    "
  >
    <ion-row class="ion-justify-content-center">
      <ion-col size="12">
        <div class="in-progress-time" [innerHtml]="myInProgressTime"></div>
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding">
      <ion-col size="6">
        <ion-button
          color="dark"
          expand="block"
          (click)="onCancelTaskPerformanceClick()"
          appHaptics
          fill="outline"
        >
          Cancel
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button
          color="dark"
          expand="block"
          (click)="onStopTaskPerformanceClick()"
          appHaptics
        >
          Finish
        </ion-button>
      </ion-col>
    </ion-row>
  </ng-container>
</ng-template>

<ng-template #waitingForApproval>
  <ion-row class="ion-justify-content-center">
    <ion-col size="12">
      <div class="in-progress-time">Waiting for manager to approve</div>
    </ion-col>
  </ion-row>
</ng-template>

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Who Completed This Task?"
      [focusSearchbar]="true"
      class="ion-page"
      [users]="allUsers"
      (selectionChange)="onCompleteBySomeone($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>
