<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>

    <ion-title class="ion-text-capitalize">{{ title.toLowerCase() }}</ion-title>

    <ion-buttons slot="end" *ngIf="multiple">
      <ion-button
        (click)="confirmChanges()"
        color="primary"
        [disabled]="!canSave"
        appHaptics
        [disableHaptics]="!canSave"
      >
        Done
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-common-background">
    <ion-searchbar
      #ionSearchbar
      (ionInput)="searchbarInput($event)"
      (keyup.enter)="selectFirst()"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container>
    <ion-list>
      <ion-item button [detail]="false" *ngIf="multiple">
        <ion-checkbox
          [checked]="isAllChecked"
          [indeterminate]="isIndeterminate"
          [disabled]="filter.length > 0"
          (ionChange)="allTasksChange($event)"
          appHaptics
          [disableHaptics]="filter.length > 0"
        >
          <span class="task-name">All Tasks</span>
        </ion-checkbox>
      </ion-item>
      <ion-item
        button
        [detail]="!multiple"
        *ngFor="
          let task of selected | filterTasks : filter;
          trackBy: trackItems
        "
        (click)="checkboxClick($event, task.id)"
        appHaptics
        [hidden]="hiddenTasks.length && isTaskHidden(task.id)"
        [ngClass]="{ 'highlighted-item': getFirst()?.id === task.id }"
      >
        <ion-checkbox
          *ngIf="multiple; else label"
          [value]="task.id"
          [checked]="task.checked"
        >
          <span class="task-name">
            {{ task.name }}
          </span>
          <ion-chip *ngIf="task.isDeleted" color="warning" disabled>
            Archived
          </ion-chip>
        </ion-checkbox>
        <ng-template #label>
          <ion-label class="task-name">
            {{ task.name }}
          </ion-label>
          <ion-chip *ngIf="task.isDeleted" color="warning" disabled>
            Archived
          </ion-chip>
        </ng-template>
      </ion-item>
    </ion-list>
    <ng-container *ngIf="selected.length === 0">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub [text]="emptyText"></app-empty-stub>
</ng-template>
