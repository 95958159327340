<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>Global Settings</ion-title>
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container ion-padding">
    <div class="top-content">
      <ion-grid [formGroup]="mainFormGroup">
        <ion-row>
          <ion-col size="12">
            <app-divider>Super Admins</app-divider>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-list>
              <ion-item
                *ngFor="let user of superAdmins; let i = index"
                [lines]="i === superAdmins.length - 1 ? 'none' : 'full'"
              >
                {{ user.firstName }} {{ user.lastName }}
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <app-divider>Instant Feedback</app-divider>
          </ion-col>
        </ion-row>
        <div formGroupName="instantFeedback">
          <ion-row>
            <ion-col size="12">
              <ion-input
                label="Email"
                class="app-w100 input-with-background"
                formControlName="email"
                labelPlacement="floating"
                fill="solid"
              ></ion-input>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-input
                class="app-w100 input-with-background"
                type="text"
                placeholder="1"
                [maxlength]="6"
                inputmode="numeric"
                pattern="[1-9][0-9]*"
                digitOnly
                formControlName="inactivityTime"
                label="Inactivity time before the feedback button appears"
                labelPlacement="floating"
                fill="solid"
              />
            </ion-col>
          </ion-row>
        </div>

        <ion-row>
          <ion-col size="12">
            <app-divider>Pulse</app-divider>
          </ion-col>
        </ion-row>
        <ion-row formGroupName="psychologicalSurvey">
          <ion-col size="12">
            <ion-input
              label="Email For Suggested Options"
              class="app-w100 input-with-background"
              formControlName="emailForSuggestedOptions"
              labelPlacement="floating"
              fill="solid"
            ></ion-input>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <app-divider> AI </app-divider>
          </ion-col>
        </ion-row>
        <ion-row formGroupName="aiPrompts">
          <ion-col size="12">
            <ion-input
              label="Suggestion Title"
              class="app-w100 input-with-background"
              formControlName="suggestionTitle"
              labelPlacement="floating"
              fill="solid"
              autocapitalize="on"
              [spellcheck]="true"
            ></ion-input>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              color="dark"
              expand="block"
              [disabled]="!canSave"
              (click)="save()"
              appHaptics
              [disableHaptics]="!canSave"
            >
              Save
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>
