import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserScheduleEditorComponent } from './user-schedule-editor.component';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormatTimeModule } from '@app/modules/format-time/format-time.module';
import { ScheduleWeeklyModule } from '@app/modules/schedule-weekly/schedule-weekly.module';
import { TimeFormControlModule } from '@app/modules/time-form-control/time-form-control.module';

@NgModule({
  declarations: [UserScheduleEditorComponent],
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    FormatTimeModule,
    ScheduleWeeklyModule,
    TimeFormControlModule
  ],
  exports: [UserScheduleEditorComponent]
})
export class UserScheduleEditorModule {}
