import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import { GlobalSettings } from '@app/types/global-settings';

@Injectable({ providedIn: 'root' })
export class GlobalSettingsApiService {
  public constructor(private _httpClient: HttpClient) {}

  public get(): Observable<GlobalSettings> {
    return this._httpClient.get<MongoStoredObject<GlobalSettings>>(
      '/global-settings'
    );
  }

  public update(
    data: Omit<GlobalSettings, 'superAdmins'>
  ): Observable<GlobalSettings> {
    return this._httpClient.put<MongoStoredObject<GlobalSettings>>(
      '/global-settings',
      data
    );
  }
}
