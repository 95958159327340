import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(): boolean | UrlTree {
    if (this.authenticationService.isAuthenticated()) {
      return true;
    }
    return this.router.createUrlTree(['/', 'login']);
  }
}
