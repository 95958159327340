import { Injectable } from '@angular/core';
import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo
} from '@capacitor/camera';
import { Observable, from } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CameraService {
  public takePicture(): Observable<Photo | false> {
    return from(this._getPhoto());
  }

  private async _getPhoto() {
    try {
      return await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        presentationStyle: 'fullscreen'
      });
    } catch {
      return false;
    }
  }
}
