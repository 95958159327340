<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="tag"> Tag: #{{ tag.tagNumber }} </ion-title>
    <ion-buttons slot="end">
      <ion-button color="primary" (click)="editTag()" appHaptics>
        Edit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading; else tagInfo">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>
  </app-width-restricted-container>
</ion-content>

<ng-template #tagInfo>
  <ion-list>
    <ion-item>
      <ion-label class="ion-text-capitalize">
        {{ tagsSettings.pickupDateFieldName }}
      </ion-label>
      <ion-note slot="end">
        {{ tag.pickupDate }}
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Server</ion-label>
      <ion-note slot="end">
        {{ server.firstName }} {{ server.lastName }}
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Uploaded by</ion-label>
      <ion-note slot="end">
        {{ tag.user.firstName }} {{ tag.user.lastName }}
      </ion-note>
    </ion-item>
    <ion-item *ngIf="tag.customerCell">
      <ion-label>Customer Cell</ion-label>
      <ion-note slot="end">
        {{ tag.customerCell }}
      </ion-note>
    </ion-item>
    <ion-item *ngIf="tag.customerName">
      <ion-label>Customer Name</ion-label>
      <ion-note slot="end" class="ion-text-capitalize">
        {{ tag.customerName }}
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-label>Number of Items</ion-label>
      <ion-note slot="end">
        {{ tag.count }}
      </ion-note>
    </ion-item>
    <ion-item *ngIf="tag.notes">
      <ion-label>Notes</ion-label>
      <ion-note slot="end">
        {{ tag.notes }}
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-select
        label="Status"
        interface="popover"
        placeholder="Select Status"
        aria-label="Status"
        [value]="tag.pickupStatus"
        (ionChange)="onStatusChange($event)"
      >
        <ion-select-option
          *ngFor="let option of tag.possiblePickupStatuses"
          [value]="option"
        >
          {{ option }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item
      lines="none"
      *ngIf="tag.pickupStatusHistory && tag.pickupStatusHistory.length"
    >
      <ion-label>History</ion-label>
    </ion-item>
    <div
      class="status-history"
      *ngIf="tag.pickupStatusHistory && tag.pickupStatusHistory.length"
    >
      <div class="status-history-col">
        <div
          class="status-history-date"
          *ngFor="let status of tag.pickupStatusHistory"
        >
          {{ status.date | date : 'M-d-yy' }}
        </div>
      </div>
      <div class="status-history-col">
        <div
          class="status-history-status"
          *ngFor="let status of tag.pickupStatusHistory"
        >
          {{ status.pickupStatus }}
        </div>
      </div>
    </div>
    <ion-item lines="none" *ngIf="images.length">
      <ion-label>Photos</ion-label>
    </ion-item>
    <div class="tag-images">
      <button
        *ngFor="let image of images; let i = index"
        class="image-btn"
        (click)="viewPhoto(i)"
        appHaptics
      >
        <img class="image" [src]="image.url" />
      </button>
    </div>
  </ion-list>
</ng-template>
