import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ToastService } from '@app/core/service/toast.service';
import { LoadingController } from '@ionic/angular';
import { FormControl, FormGroup } from '@angular/forms';
import { TenantService } from '@app/core/service/tenant.service';
import { UploadFileApiService } from '@app/core/service/upload-file-api.service';
import { SuggestionApiService } from '@app/core/service/suggestion-api.service';
import { AuthenticationService } from '@app/core';
import { UserEntityService } from '@app/core/service/user-entity.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-suggestion-form',
  templateUrl: './suggestion-form.component.html',
  styleUrls: ['../login/login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionFormComponent implements OnInit {
  protected mainFormGroup = new FormGroup({
    author: new FormControl<string>('')
  });
  protected body = '';
  protected disableAttachments = false;
  protected tenantId = '';

  public constructor(
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _tenantService: TenantService,
    private readonly _uploadFileApiService: UploadFileApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _userEntityService: UserEntityService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.disableAttachments = !tenant.features.suggestionAttachments;
    });

    this._route.params.subscribe((params) => {
      if (params.tenant && params.tenant.length) {
        this.tenantId = params.tenant;
      } else {
        this._router.navigate(['/']);
      }
    });
  }

  public ngOnInit(): void {
    if (this._authenticationService.user) {
      this.mainFormGroup.patchValue({
        author: `${this._authenticationService.user.firstName} ${this._authenticationService.user.lastName}`
      });
    }
  }

  protected async onFormSubmit() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._uploadFileApiService
      .extractAttachmentsFromBody(this.body || '', [])
      .subscribe({
        next: ({ body, newAttachments, keptAttachments }) => {
          this._suggestionApiService
            .postOpportunity({
              body,
              attachments: [
                ...keptAttachments,
                ...newAttachments.map((a) => a._id.toString())
              ],
              tenant: this.tenantId,
              author: this._authenticationService.user
                ? this._authenticationService.user._id
                : undefined,
              authorName: this.mainFormGroup.get('author').value
            })
            .subscribe(() => {
              loading.dismiss();

              this._toastService.presentToast(
                'Suggestion Successfully Submitted!'
              );

              if (this._authenticationService.user) {
                this._userEntityService.update();
              }

              this._router.navigate(['/']);
            });
        },
        error: (e) => {
          console.log(e);
          loading.dismiss();
          this._toastService.presentToast('Unable to upload attachments');
        }
      });
  }

  protected get canSave() {
    return this.body && !this.mainFormGroup.invalid;
  }
}
