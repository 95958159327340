import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SwiperContainer } from 'swiper/element';

@Component({
  selector: 'app-image-viewer-modal',
  templateUrl: './image-viewer-modal.component.html',
  styleUrls: ['./image-viewer-modal.component.scss']
})
export class ImageViewerModalComponent implements AfterViewInit {
  @Input()
  public images: string[];

  @Input()
  public initialIndex: number;

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  @ViewChild('swiperThumbs') swiperThumbs!: ElementRef<SwiperContainer>;

  protected swiperConfig: any = {
    spaceBetween: 10
  };
  protected swiperThumbsConfig: any = {
    spaceBetween: 10,
    slidesPerView: 'auto',
    freeMode: true,
    watchSlidesProgress: true,
    centeredSlides: true
  };

  public constructor(private readonly _modalCtrl: ModalController) {}

  public ngAfterViewInit() {
    this.swiper.nativeElement.swiper.activeIndex = this.initialIndex;
    this.swiperThumbs.nativeElement.swiper.activeIndex = this.initialIndex;

    this.swiper.nativeElement.swiper.on(
      'slideChange',
      (e: { activeIndex: number }) => {
        this.swiperThumbs.nativeElement.swiper.slideTo(e.activeIndex);
      }
    );
    this.swiper.nativeElement.swiper.on(
      'zoomChange',
      (_: any, scale: number) => {
        this.swiper.nativeElement.swiper.allowTouchMove = scale === 1;
      }
    );

    this.swiperThumbs.nativeElement.swiper.on(
      'touchEnd',
      (e: { activeIndex: number }) => {
        this.swiper.nativeElement.swiper.slideTo(e.activeIndex);
      }
    );
  }

  protected onThumbClick(index: number) {
    this.swiper.nativeElement.swiper.slideTo(index);
  }

  protected close() {
    this._modalCtrl.dismiss(null, 'destructive', 'image-viewer-modal');
  }
}
