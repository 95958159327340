import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { BehaviorApiService } from '@app/core';
import { BehaviorModel } from '@app/core/model/behavior.model';

@Component({
  selector: 'app-values-accordion',
  templateUrl: './values-accordion.component.html',
  styleUrls: ['./values-accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValuesAccordionComponent {
  protected valueBehaviors: BehaviorModel[] = [];
  protected isLoading = true;

  public constructor(
    private readonly _behaviorApiService: BehaviorApiService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this._behaviorApiService.getValueBehaviors().subscribe((valueBehaviors) => {
      this.valueBehaviors = valueBehaviors;
      this.isLoading = false;
      this._cdr.markForCheck();
    });
  }
}
