import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { TagApiService } from '@app/core/service/tag-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITag } from '@backend/models/types/tag';
import {
  IonNav,
  IonInfiniteScroll,
  ViewWillEnter,
  LoadingController,
  ViewDidEnter,
  IonSearchbar
} from '@ionic/angular';
import { TagViewComponent } from '../tag-view/tag-view.component';
import { ObjectId } from '@app/types/object-id';
import { TenantService } from '@app/core/service/tenant.service';
import { TagsDefinition } from '@backend/types/tags-definition';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html'
})
export class TagsComponent
  implements ViewWillEnter, ViewDidEnter, AfterViewInit
{
  @ViewChild('infiniteScroll', { static: true })
  public infiniteScroll!: IonInfiniteScroll;

  @ViewChild('ionSearchbar')
  public ionSearchbar!: IonSearchbar;

  protected tags: MongoStoredObject<ITag>[] = [];
  protected isLoading = true;
  protected hasNextPage = true;
  protected searchTerm = '';
  protected page = 1;
  protected pageSize = 20;
  protected statuses: {
    _id: ObjectId;
    pickupStatus: string;
    possiblePickupStatuses: string[];
  }[] = [];
  protected isStatusesLoading = true;
  protected tagsSettings: TagsDefinition;

  public constructor(
    private readonly _tagApiService: TagApiService,
    private readonly _ionNav: IonNav,
    private readonly _loadingCtrl: LoadingController,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.tagsSettings = tenant.settings.tags;
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.ionSearchbar.setFocus();
    }, 600);
  }

  public ionViewWillEnter(): void {
    this._fetchTags(true);
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();
    }
  }

  protected _fetchTags(reset = false) {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.tags = [];
    }

    this._tagApiService
      .getAllTags(this.page, this.pageSize, this.searchTerm)
      .subscribe((fetchedTags) => {
        this.hasNextPage = fetchedTags.length === this.pageSize;
        this.tags = this.tags.concat(fetchedTags);
        this.isLoading = false;
        this.infiniteScroll.complete();

        this.isStatusesLoading = true;
        if (reset) {
          this.statuses = [];
        }
        this._tagApiService
          .getStatusForAllTags(fetchedTags.map(({ _id }) => _id.toString()))
          .subscribe((statuses) => {
            this.statuses = this.statuses.concat(statuses);
            this.isStatusesLoading = false;
          });
      });
  }

  protected getTagPickupStatus(tag: MongoStoredObject<ITag>) {
    const status = this.statuses.find(
      ({ _id }) => tag._id.toString() === _id.toString()
    );

    const statusName = status?.pickupStatus || tag.pickupStatus;
    let statusColor = 'warning';

    switch (statusName) {
      case this.tagsSettings.defaultStatusName:
        statusColor = 'primary';
        break;
      case this.tagsSettings.readyForPickupStatusName:
        statusColor = 'tertiary';
        break;
      case this.tagsSettings.completedStatusName:
        statusColor = 'success';
        break;
    }

    return {
      name: statusName,
      color: statusColor
    };
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchTags();
  }

  protected handleSearchInput(event: any) {
    this.searchTerm = event.detail.value;
    this._fetchTags(true);
  }

  protected trackTags(_: number, item: MongoStoredObject<ITag>) {
    return item._id;
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected onTagClick(tagId: string) {
    this._ionNav.push(TagViewComponent, { tagId });
  }
}
