import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ExternalauthComponent } from './externalauth.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: ExternalauthComponent
      }
    ])
  ],
  declarations: [ExternalauthComponent]
})
export class ExternalauthModule {}
