import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ObjectId } from '@app/types/object-id';
import { CommonModule } from '@angular/common';
import { FilterTaskFoldersPipe } from './filter-task-folders.pipe';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { DirectivesModule } from '@app/modules/directives.module';
import { ITaskFolder } from '@backend/models/types/task-folder';

@Component({
  selector: 'app-task-folder-selector',
  templateUrl: './task-folder-selector.component.html',
  styleUrls: ['./task-folder-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule, FilterTaskFoldersPipe, DirectivesModule]
})
export class TaskFolderSelectorComponent {
  @Input()
  public readonly taskFolders: MongoStoredObject<ITaskFolder>[];

  @Output()
  public selectionCancel = new EventEmitter<void>();

  @Output()
  public selectionChange = new EventEmitter<MongoStoredObject<ITaskFolder>>();

  protected selectedTaskFolderId: ObjectId;
  public filter = '';

  protected trackItems(_: number, item: MongoStoredObject<ITaskFolder>) {
    return item._id;
  }

  protected cancelChanges() {
    this.selectionCancel.emit();
  }

  protected confirmChanges() {
    this.selectionChange.emit(
      this.taskFolders.find((t) => t._id === this.selectedTaskFolderId)
    );
  }

  protected checkboxClick(event: any, value: ObjectId) {
    event.preventDefault();

    this.selectedTaskFolderId = value;

    this.confirmChanges();
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected getFirst() {
    if (!this.filter) return null;

    const folders = new FilterTaskFoldersPipe().transform(
      this.taskFolders,
      this.filter
    );

    if (folders.length > 0) {
      return folders[0];
    }

    return null;
  }

  protected selectFirst() {
    const first = this.getFirst();

    if (first) {
      this.selectedTaskFolderId = first._id;

      this.confirmChanges();
    }
  }
}
