import { TaskPointsEarns } from '@backend/models/types/task';
import { ChecklistItem } from '../types/checklist-item';
import { isAfter } from 'date-fns';
import { TaskPerformanceStatus } from '@backend/models/types/task-performance';

export function isOverdue(item: ChecklistItem, time: Date): boolean {
  if (item.task.pointsEarns === TaskPointsEarns.PER_COMPLETION) {
    return !item.isCompleted() && isAfter(time, item.interval.end);
  }
  return (
    isAfter(time, item.interval.end) &&
    !item.performances.some((p) => p.status === TaskPerformanceStatus.COMPLETE)
  );
}
