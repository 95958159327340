import { Pipe, PipeTransform } from '@angular/core';
import { PerformanceWithUser } from '../../types/performance-with-user';
import { TaskPerformanceStatus } from '@backend/models/types/task-performance';
import { initials } from '@app/utils/initials';

@Pipe({
  name: 'userInitials'
})
export class UserInitialsPipe implements PipeTransform {
  public transform(performances: PerformanceWithUser[]): string {
    if (performances.length === 0) {
      return '';
    }
    const inProgress = performances.find(
      (p) => p.status === TaskPerformanceStatus.IN_PROGRESS
    );
    if (inProgress) {
      return initials(inProgress.user.firstName, inProgress.user.lastName);
    }
    const completed = performances
      .filter((p) => p.status === TaskPerformanceStatus.COMPLETE)
      .sort((a, b) => b.completeTime.getTime() - a.completeTime.getTime());
    if (completed.length > 0) {
      return initials(completed[0].user.firstName, completed[0].user.lastName);
    }
    return '';
  }
}
