import {
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core';
import { EnvironmentService } from '@app/core/service/environment.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private version = '';
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject = new BehaviorSubject<any>(null);

  public constructor(
    private readonly authService: AuthenticationService,
    private readonly _environmentService: EnvironmentService
  ) {
    this.version = this._environmentService.version;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(this.addAuthenticationToken(request)).pipe(
      catchError((error) => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (
          request.url.includes('user/login') ||
          request.url.includes('user/refresh')
        ) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page
          if (request.url.includes('user/refresh')) {
            this.authService.logout();
          }
          return throwError(error);
        }

        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (
          error.status !== 401 &&
          !(error.status === 500 && error.error.includes('jwt expired'))
        ) {
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request)))
          );
        } else {
          this.refreshTokenInProgress = true;
          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);
          // Call authService.refreshToken(this is an Observable that will be returned)
          return this.authService.refreshToken().pipe(
            switchMap(() => {
              // When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(true);
              return next.handle(this.addAuthenticationToken(request));
            }),
            catchError((e: any) => {
              this.refreshTokenInProgress = false;
              this.authService.logout();
              return throwError(e);
            })
          );
        }
      })
    );
  }

  addAuthenticationToken(request: HttpRequest<any>) {
    // Get access token from Local Storage
    const authToken = this.authService.getAuthToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!authToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      headers: request.headers.set(
        'Authorization',
        `Bearer ${authToken?.token || ''}`
      ),
      params: request.params.set('AppVersion', this.version)
    });
  }
}
