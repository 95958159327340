import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { ROLES } from '../constants';
import { UserEntityService } from '../service/user-entity.service';

export const adminGuard = () => {
  const router = inject(Router);
  const userEntity = inject(UserEntityService);
  return userEntity.user$.pipe(
    map((user) => {
      if (user.roles.includes(ROLES.adminName)) {
        return true;
      }
      return router.createUrlTree(['/']);
    })
  );
};
