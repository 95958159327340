import { format } from 'date-fns-tz';

export function formatZonedTime(
  zonedTime: Date,
  userTimeZone?: string
): string {
  // Create a formatted string from the zoned time
  return format(zonedTime, "yyyy-MM-dd'T'HH:mm:ssXXX", {
    timeZone: userTimeZone
  });
}
