import { addDays, addMilliseconds } from 'date-fns';
import { DateInterval } from '../../../backend/src/types/date-interval';
import { setTimeForDate } from './set-time-for-date';

export function createTaskInterval(
  date: Date,
  appearsAt: string,
  disappearsAt: string,
  allowTasksToBeDisplayedOnlyDuringCertainHours: boolean
): DateInterval {
  const start = setTimeForDate(
    date,
    allowTasksToBeDisplayedOnlyDuringCertainHours ? appearsAt : '0:0'
  );
  let end = setTimeForDate(
    date,
    allowTasksToBeDisplayedOnlyDuringCertainHours ? disappearsAt : '0:0'
  );

  if (allowTasksToBeDisplayedOnlyDuringCertainHours) {
    if (start.getTime() > end.getTime()) {
      end = addDays(end, 1);
    }

    if (start.getTime() === end.getTime()) {
      end = addMilliseconds(end, 2);
    }
  } else {
    end = addDays(end, 1);
  }

  end = addMilliseconds(end, -1);

  return {
    start,
    end
  };
}
