import { Pipe, PipeTransform } from '@angular/core';
import { UserListService } from '@app/core/service/user-list.service';
import { Observable, map } from 'rxjs';

@Pipe({
  name: 'personName$',
  standalone: true
})
export class PersonNamePipe implements PipeTransform {
  public constructor(private readonly _userList: UserListService) {}

  public transform(id: string, fallbackValue?: string): Observable<string> {
    return this._userList.users$.pipe(
      map((users) => {
        const user = users.find(({ _id }) => _id === id);
        if (user) {
          return `${user.firstName} ${user.lastName}`;
        }
        return fallbackValue || 'Unknown';
      })
    );
  }
}
