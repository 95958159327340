import {
  Component,
  Input,
  Output,
  ViewChild,
  HostListener,
  HostBinding,
  EventEmitter,
  ElementRef
} from '@angular/core';
import { IonPopover } from '@ionic/angular';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { LedgerModel } from '@app/core/model/ledger.model';

@Component({
  selector: 'app-recognition-item',
  templateUrl: './recognition-item.component.html',
  styleUrls: ['./recognition-item.component.scss']
})
export class RecognitionItemComponent {
  @Input()
  public readonly item: LedgerModel;

  @Input()
  public readonly showOptions: boolean = false;

  @Input()
  public readonly showPrivateButton: boolean = true;

  @Input()
  public readonly showEditButton: boolean = true;

  @Input()
  public readonly showMarkAsReadButton: boolean = false;

  @Input()
  public animationOrder?: number = -1;

  @Output()
  public privateToggle = new EventEmitter<boolean>();

  @Output()
  public editClicked = new EventEmitter<LedgerModel>();

  @Output()
  public deleteClicked = new EventEmitter<LedgerModel>();

  @Output()
  public markAsReadClicked = new EventEmitter<LedgerModel>();

  @ViewChild('popover')
  public popover!: IonPopover;

  @HostListener('click', ['$event'])
  protected onClick(event: any): void {
    if (this.showOptions) {
      this.presentPopover({ ...event, target: this.nativeElement });
    }
  }

  @HostBinding('class.clickable')
  get isClickable() {
    return this.showOptions;
  }

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  protected isPopoverOpen = false;
  protected nativeElement: any;

  public constructor(
    private readonly _elementRef: ElementRef,
    private readonly _sanitizer: DomSanitizer
  ) {
    this.nativeElement = this._elementRef.nativeElement;
  }

  protected get body() {
    return this.item.description.replace('\n', '<br/>');
  }

  protected presentPopover(e: Event) {
    this.popover.event = e;
    this.isPopoverOpen = true;
  }

  protected onPrivateToggle(e: any) {
    this.privateToggle.emit(e.detail.checked);
  }

  protected onPrivateClick(e: any) {
    e.preventDefault();
  }

  protected onEditClick() {
    this.editClicked.emit(this.item);
    this.popover.dismiss();
  }

  protected onDeleteClick() {
    this.deleteClicked.emit(this.item);
    this.popover.dismiss();
  }

  protected onMarkAsReadClick(event: any) {
    event.stopPropagation();

    this.markAsReadClicked.emit(this.item);
  }
}
