<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background" *ngIf="cancelButton === 'menu'">
    <ion-buttons
      slot="start"
      class="ion-hide-lg-up"
      *ngIf="
        question.orderIndex === 0 &&
          !selectedUser &&
          !selectedGeneralFeedbackId;
        else goBackBtn
      "
    >
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>

  <ion-toolbar
    class="app-common-background survey-preview-toolbar"
    *ngIf="cancelButton === 'cancel'"
  >
    <ion-buttons
      slot="start"
      *ngIf="
        question.orderIndex === 0 &&
          !selectedUser &&
          !selectedGeneralFeedbackId;
        else goBackBtn
      "
    >
      <ion-button (click)="onCancelClick()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-width-restricted-container
  class="question-holder"
  *ngIf="!selectedUser && !selectedGeneralFeedbackId"
>
  <div class="top-content ion-padding-top">
    <ion-text>
      <h2
        class="ion-text-center heading"
        [innerHTML]="
          questionTexting === 'LAST_SHIFT'
            ? question.params.questionTitleLastShift
            : question.params.questionTitle
        "
      ></h2>
    </ion-text>
    <ion-text>
      <h3 class="ion-text-center emoji">{{ question.params.emoji }}</h3>
    </ion-text>
    <div class="users-list users-list--same-width">
      <ion-chip
        *ngIf="result.usersFeedback.length || result.generalFeedback.length"
        outline
        (click)="selectIndividuals()"
        appHaptics
      >
        <ion-icon name="add" class="add-icon"></ion-icon>
        <ion-label class="user-name">Select Individuals</ion-label>
      </ion-chip>
      <ion-chip outline (click)="leaveGeneralFeedback()" appHaptics>
        <ion-icon name="add" class="add-icon"></ion-icon>
        <ion-label class="user-name">Nameless Feedback</ion-label>
      </ion-chip>
    </div>
    <div class="users-divider">
      <div class="app-divider-line"></div>
    </div>
    <div class="users-list">
      <ion-chip
        *ngFor="let user of result.usersFeedback"
        outline
        (click)="editUser(user.userId)"
        class="user-chip"
        appHaptics
      >
        <div class="avatar">
          <div>{{ getUserInitials(user.userId) }}</div>
        </div>
        <ion-label class="user-name">{{ getUserName(user.userId) }}</ion-label>
        <ion-button
          (click)="removeUser($event, user.userId)"
          class="remove-btn ion-no-margin"
          fill="clear"
          appHaptics
        >
          <ion-icon
            slot="icon-only"
            name="close"
            class="remove-icon"
          ></ion-icon>
        </ion-button>
      </ion-chip>
      <ion-chip
        *ngFor="let generalFeedback of result.generalFeedback; let i = index"
        outline
        (click)="editGeneralFeedback(generalFeedback.generalFeedbackId)"
        class="user-chip"
        appHaptics
      >
        <ion-icon name="information-outline" class="add-icon"></ion-icon>
        <ion-label class="user-name">Nameless Feedback {{ i + 1 }}</ion-label>
        <ion-button
          (click)="
            removeGeneralFeedback($event, generalFeedback.generalFeedbackId)
          "
          class="remove-btn ion-no-margin"
          fill="clear"
          appHaptics
        >
          <ion-icon
            slot="icon-only"
            name="close"
            class="remove-icon"
          ></ion-icon>
        </ion-button>
      </ion-chip>
    </div>
    <ion-grid
      *ngIf="
        result.usersFeedback.length === 0 && result.generalFeedback.length === 0
      "
    >
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="5">
          <app-emoji-button
            label="Yes"
            emoji="😕"
            appHaptics
            (click)="selectIndividuals()"
          />
        </ion-col>
        <ion-col size="5">
          <app-emoji-button
            label="No"
            emoji="🙂"
            appHaptics
            (click)="askSkipQuestion()"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="bottom-content">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ion-button fill="clear" (click)="showConfidential()" expand="block">
            <ng-container *ngTemplateOutlet="notice"></ng-container>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="6">
          <ion-button
            *ngIf="
              result.usersFeedback.length > 0 ||
              result.generalFeedback.length > 0
            "
            color="dark"
            (click)="completeQuestion()"
            appHaptics
            expand="block"
          >
            {{ question.params.submitButtonText }}
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</app-width-restricted-container>

<app-width-restricted-container
  class="question-holder"
  *ngIf="selectedUser || selectedGeneralFeedbackId"
>
  <div class="top-content ion-padding-top">
    <ion-text>
      <h2
        class="ion-text-center heading"
        [innerHtml]="
          selectedGeneralFeedbackId
            ? question.params.generalFeedbackTitle
            : behaviorsSelectorTitle
        "
      ></h2>
    </ion-text>
    <div class="behaviors-list">
      <ion-list>
        <ion-item
          button
          [detail]="false"
          *ngFor="let behavior of selectedBehaviors; trackBy: trackBehaviors"
          (click)="checkboxClick($event, behavior.id)"
          appHaptics
        >
          <ion-checkbox [value]="behavior.id" [checked]="behavior.checked">
            <span class="behavior-name">
              {{ behavior.name }}
            </span>
          </ion-checkbox>
        </ion-item>
        <ion-item
          button
          [detail]="false"
          (click)="suggestedOptionCheckboxClick($event)"
          appHaptics
        >
          <ion-checkbox
            value="suggested-option"
            [checked]="isSuggestedOptionChecked"
          >
            <span class="behavior-name"> Suggest a new option </span>
          </ion-checkbox>
        </ion-item>
      </ion-list>
      <ion-grid [hidden]="!isSuggestedOptionChecked">
        <ion-row>
          <ion-col size="12">
            <ion-textarea
              #suggestionTextarea
              class="app-w100 input-with-background fix-textarea"
              fill="solid"
              [autoGrow]="true"
              placeholder="Your suggestion will only be shared with the app developer and will not be visible to anyone else in your organization."
              [rows]="3"
              [value]="suggestedOption"
              (ionInput)="onSuggestedOptionChange($event)"
              enterkeyhint="done"
              (keypress)="onSuggestionTextareaKeyPress($event)"
              autocapitalize="on"
            ></ion-textarea>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  <div class="bottom-content">
    <ion-grid>
      <ion-row>
        <ion-col size="12">
          <ng-container *ngTemplateOutlet="confidentialNotice"></ng-container>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="6">
          <ion-button
            color="dark"
            (click)="submit()"
            appHaptics
            expand="block"
            [disabled]="!canSave"
            [disableHaptics]="!canSave"
          >
            Continue
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</app-width-restricted-container>

<!-- <ng-template #skipTemplate>
  <ion-button
    color="dark"
    (click)="askSkipQuestion()"
    appHaptics
    expand="block"
  >
    {{
      questionTexting === 'LAST_SHIFT'
        ? question.params.skipButtonTextLastShift
        : question.params.skipButtonText
    }}
  </ion-button>
</ng-template> -->

<ion-modal
  #selectIndividualsModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      class="ion-page"
      title=""
      [users]="allUsers"
      (selectionChange)="onUserSelect($event)"
      (selectionCancel)="selectIndividualsModal.dismiss()"
      [focusSearchbar]="true"
      [hiddenUsers]="hiddenUsers"
    ></app-user-selector>
  </ng-template>
</ion-modal>

<ng-template #goBackBtn>
  <ion-buttons slot="start">
    <ion-button (click)="onBackButtonClick()" appHaptics>
      <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ng-template>

<ion-modal
  #confidentialModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <div class="confidential-modal">
      <div class="scrollable-top-content">
        <div class="ion-padding-vertical ion-text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="67"
            viewBox="0 0 48 67"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M33.4241 26.4147C34.1201 27.0292 34.1959 28.1028 33.5934 28.8126L22.0518 42.4126C21.7508 42.7673 21.3196 42.9793 20.8601 42.9986C20.4006 43.0178 19.9538 42.8426 19.6253 42.5144L14.5003 37.3931C13.8428 36.7361 13.832 35.6598 14.4761 34.9891C15.1203 34.3184 16.1755 34.3074 16.833 34.9644L20.6917 38.8203L31.0732 26.5874C31.6756 25.8775 32.7282 25.8002 33.4241 26.4147Z"
              fill="#FFD500"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23.2944 12.1519C23.743 11.9494 24.257 11.9494 24.7056 12.1519C33.2284 16.0011 37.0543 17.2309 46.4915 18.9237C47.2686 19.0631 47.85 19.7152 47.8997 20.5031C49.7114 49.2188 26.4081 59.164 24.6404 59.8759C24.2295 60.0414 23.7705 60.0414 23.3596 59.8759C21.5919 59.164 -1.71142 49.2188 0.100329 20.5031C0.150041 19.7152 0.731435 19.0631 1.50851 18.9237C10.9457 17.2309 14.7716 16.0011 23.2944 12.1519ZM3.45676 22.0535C2.64846 45.4421 20.1796 54.6867 24 56.4193C27.8204 54.6867 45.3515 45.4421 44.5432 22.0535C36.015 20.4703 31.8901 19.1295 24 15.5941C16.1099 19.1295 11.985 20.4703 3.45676 22.0535Z"
              fill="url(#paint0_linear_4792_6102)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_4792_6102"
                x1="0"
                y1="18"
                x2="48"
                y2="60"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#FFE100" />
                <stop offset="1" stop-color="#FFC800" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <ion-text>
          <h2
            class="ion-text-center heading"
            [innerHtml]="question.params.confidentialTitleHtml"
          ></h2>
        </ion-text>
        <div
          class="confidential-body"
          [innerHtml]="question.params.confidentialBodyHtml"
        ></div>
      </div>
      <div class="fixed-bottom-content">
        <ion-grid>
          <ion-row class="ion-justify-content-center ion-padding">
            <ion-col size="6">
              <ion-button
                color="dark"
                (click)="acceptConfidential()"
                appHaptics
                expand="block"
              >
                Continue
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </ng-template>
</ion-modal>

<ng-template #notice>
  <div class="confidential-notice ion-text-center ion-padding-horizontal">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9254 10.6448C14.2154 10.8924 14.247 11.325 13.996 11.6111L9.18694 17.0917C9.06154 17.2346 8.88186 17.3201 8.69041 17.3278C8.49897 17.3356 8.31278 17.265 8.17591 17.1327L6.0405 15.0689C5.76653 14.8041 5.76202 14.3704 6.03041 14.1001C6.29881 13.8299 6.73849 13.8254 7.01245 14.0902L8.62025 15.644L12.9459 10.7144C13.1969 10.4283 13.6354 10.3971 13.9254 10.6448Z"
        fill="#FFD500"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.70601 4.89704C9.89292 4.81541 10.1071 4.81541 10.294 4.89704C13.8452 6.44819 15.4393 6.94377 19.3715 7.62597C19.6952 7.68214 19.9375 7.94493 19.9582 8.26245C20.7131 19.8344 11.0034 23.8422 10.2668 24.1291C10.0956 24.1958 9.90438 24.1958 9.73317 24.1291C8.99664 23.8422 -0.713092 19.8344 0.0418037 8.26245C0.0625171 7.94493 0.304764 7.68214 0.628545 7.62597C4.5607 6.94377 6.15484 6.44819 9.70601 4.89704ZM1.44032 8.88723C1.10353 18.3125 8.40815 22.0379 10 22.7361C11.5918 22.0379 18.8965 18.3125 18.5597 8.88723C15.0063 8.24921 13.2875 7.70888 10 6.28417C6.71248 7.70888 4.99373 8.24921 1.44032 8.88723Z"
        fill="url(#paint0_linear_4818_6698)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4818_6698"
          x1="0"
          y1="7.25373"
          x2="19.4185"
          y2="24.8218"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE100" />
          <stop offset="1" stop-color="#FFC800" />
        </linearGradient>
      </defs>
    </svg>
    <ion-text>
      <p
        class="confidential-notice-text"
        [innerHTML]="question.params.noticeHtml"
      ></p>
    </ion-text>
  </div>
</ng-template>

<ng-template #confidentialNotice>
  <div class="confidential-notice ion-text-center ion-padding-horizontal">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.9254 10.6448C14.2154 10.8924 14.247 11.325 13.996 11.6111L9.18694 17.0917C9.06154 17.2346 8.88186 17.3201 8.69041 17.3278C8.49897 17.3356 8.31278 17.265 8.17591 17.1327L6.0405 15.0689C5.76653 14.8041 5.76202 14.3704 6.03041 14.1001C6.29881 13.8299 6.73849 13.8254 7.01245 14.0902L8.62025 15.644L12.9459 10.7144C13.1969 10.4283 13.6354 10.3971 13.9254 10.6448Z"
        fill="#FFD500"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.70601 4.89704C9.89292 4.81541 10.1071 4.81541 10.294 4.89704C13.8452 6.44819 15.4393 6.94377 19.3715 7.62597C19.6952 7.68214 19.9375 7.94493 19.9582 8.26245C20.7131 19.8344 11.0034 23.8422 10.2668 24.1291C10.0956 24.1958 9.90438 24.1958 9.73317 24.1291C8.99664 23.8422 -0.713092 19.8344 0.0418037 8.26245C0.0625171 7.94493 0.304764 7.68214 0.628545 7.62597C4.5607 6.94377 6.15484 6.44819 9.70601 4.89704ZM1.44032 8.88723C1.10353 18.3125 8.40815 22.0379 10 22.7361C11.5918 22.0379 18.8965 18.3125 18.5597 8.88723C15.0063 8.24921 13.2875 7.70888 10 6.28417C6.71248 7.70888 4.99373 8.24921 1.44032 8.88723Z"
        fill="url(#paint0_linear_4818_6698)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4818_6698"
          x1="0"
          y1="7.25373"
          x2="19.4185"
          y2="24.8218"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFE100" />
          <stop offset="1" stop-color="#FFC800" />
        </linearGradient>
      </defs>
    </svg>
    <ion-text>
      <p class="confidential-notice-text">
        {{
          selectedGeneralFeedbackId
            ? question.params.generalFeedbackNotice
            : behaviorsSelectorNotice
        }}
      </p>
    </ion-text>
  </div>
</ng-template>
