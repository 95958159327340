import { Types } from 'mongoose';

export enum EIntegrationProvider {
  AIRTABLE = 'AIRTABLE',
  GOHIGHLEVEL = 'GOHIGHLEVEL'
}

export interface IIntegrationBase {
  tenant: Types.ObjectId;
  publicSettingsFields: string[];
  isInitializing: boolean;
}

export interface IIntegrationAirtableFieldNames {
  tag: string;
  phone: string;
  pickupDate: string;
  photos: string;
  server: string;
  notes: string;
  numberOfItems: string;
  status: string;
}

export interface IIntegrationAirtableSettings {
  apiKey: string;
  baseId: string;
  tableName: string;
  fieldNames: IIntegrationAirtableFieldNames;
}

export interface IIntegrationAirtable {
  provider: EIntegrationProvider.AIRTABLE;
  settings: IIntegrationAirtableSettings;
}

export interface IIntegrationGoHighLevelSettings {
  clientId: string;
  clientSecret: string;
  pipelineName: string;
  accessToken: string;
  refreshToken: string;
  companyId: string;
  locationId: string;
}

export interface IIntegrationGoHighLevel {
  provider: EIntegrationProvider.GOHIGHLEVEL;
  settings: IIntegrationGoHighLevelSettings;
}

export type IIntegration = IIntegrationBase &
  (IIntegrationAirtable | IIntegrationGoHighLevel);
