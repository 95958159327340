<ion-grid>
  <ion-row>
    <ion-col>
      <ion-button
        color="light"
        expand="block"
        (click)="onDateButtonClick($event)"
        appHaptics
      >
        <ng-container *ngIf="form.controls.date.value !== null; else zeroState">
          {{ form.controls.date.value | date : 'mediumDate' }}
        </ng-container>
      </ion-button>
      <ion-popover
        [keepContentsMounted]="true"
        [isOpen]="isOpen"
        (didDismiss)="isOpen = false"
        cssClass="popover-datetime"
        #popover
      >
        <ng-template>
          <ion-datetime
            [showDefaultButtons]="true"
            (ionChange)="onDateChange($event)"
            presentation="date"
          />
        </ng-template>
      </ion-popover>
    </ion-col>
  </ion-row>
</ion-grid>

<ng-template #zeroState>Select next due date</ng-template>
