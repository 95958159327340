import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ModalController, IonModal } from '@ionic/angular';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import {
  ECompensationFieldType,
  ICompensationField
} from '@backend/models/types/compensation-field';

@Component({
  selector: 'app-compensation-field-modal',
  templateUrl: './compensation-field-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompensationFieldModalComponent implements OnInit {
  @Input()
  public readonly compensationField: MongoStoredObject<ICompensationField> =
    null;

  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  protected readonly compensationFieldType = ECompensationFieldType;

  protected form = new FormGroup({
    name: new FormControl<string>('', Validators.required),
    type: new FormControl<ECompensationFieldType>(
      ECompensationFieldType.HOURS,
      Validators.required
    )
  });

  public constructor(private readonly _modalCtrl: ModalController) {}

  public ngOnInit(): void {
    if (this.compensationField) {
      this.form.reset({
        name: this.compensationField.name,
        type: this.compensationField.type
      });
    }
  }

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(null, 'cancel', 'compensation-field-modal');
  }

  protected save(): void {
    this._modalCtrl.dismiss(
      this.form.value,
      'confirm',
      'compensation-field-modal'
    );
  }
}
