import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild
} from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  delay,
  expand,
  map,
  of,
  take,
  tap
} from 'rxjs';
import { ChecklistItem, ChecklistWithTime } from '@app/types/checklist-item';
import {
  addMinutes,
  isAfter,
  isWithinInterval,
  setMilliseconds,
  setSeconds
} from 'date-fns';
import { DateInterval } from '@backend/types/date-interval';
import { IonNav } from '@ionic/angular';
import { ChecklistItemPickupComponent } from '../checklist-item-pickup/checklist-item-pickup.component';
import { TaskManagementComponent } from '../task-management/task-management.component';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { ROLES } from '@app/core/constants';
import { TODAY_INTERVAL } from '@app/core/tokens/today-interval';
import { TODAY_CHECKLIST } from '@app/core/tokens/today-checklist';
import { TaskFolderListService } from '@app/core/service/task-folder-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { TasksCompletedComponent } from '@app/dashboard/dashboard/insights/tasks-completed/tasks-completed.component';
import { InsightsDateRangeHelperService } from '@app/core/service/insights-date-range-helper.service';
import { RangePresets } from '@app/types/range-presets';
import { PullToSearchComponent } from '@app/modules/pull-to-search/pull-to-search.component';
import { ActivatedRoute } from '@angular/router';
import { DailyReportComponent } from './daily-report/daily-report.component';
import { ITenantFeatures } from '@backend/models/types/tenant';
import { TenantService } from '@app/core/service/tenant.service';

interface ChecklistFilter {
  search: string;
}

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChecklistComponent implements AfterViewInit {
  @ViewChild('pullToSearch', { static: true })
  public pullToSearch!: PullToSearchComponent;

  protected readonly checklist$: Observable<
    ChecklistWithTime & {
      folders: (MongoStoredObject<ITaskFolder> & {
        items: ChecklistItem[];
      })[];
    }
  >;
  protected readonly filter$ = new BehaviorSubject<ChecklistFilter>({
    search: ''
  });
  private readonly _minuteEmitter$ = of(new Date()).pipe(
    expand((date) => {
      const newDate = setMilliseconds(setSeconds(addMinutes(date, 1), 0), 0);
      return of(newDate).pipe(delay(newDate));
    })
  );
  protected isAdminOrManager: boolean;
  protected tenantFeatures: Pick<ITenantFeatures, 'dailyReport'> = {
    dailyReport: false
  };
  protected isLoading = true;

  public constructor(
    private readonly _tenantService: TenantService,
    private readonly _insightsDateRangeHelperService: InsightsDateRangeHelperService,
    private readonly _taskFolderListService: TaskFolderListService,
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService,
    private readonly _route: ActivatedRoute,
    @Inject(TODAY_INTERVAL)
    protected readonly interval$: Observable<DateInterval>,
    @Inject(TODAY_CHECKLIST)
    protected readonly todayChecklist$: Observable<ChecklistItem[]>
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenantFeatures = tenant.features;
    });

    this.isAdminOrManager =
      (this._authenticationService.user?.roles.includes(ROLES.adminName) ||
        this._authenticationService.user?.roles.includes(ROLES.managerName)) ??
      false;

    this.checklist$ = combineLatest([
      todayChecklist$,
      this._taskFolderListService.taskFolders$,
      this.filter$,
      this._minuteEmitter$
    ]).pipe(
      map(([checklist, taskFolders, filter, currentMinute]) => {
        const textFiltered = checklist.filter((item) => {
          const search = filter.search.trim().toLowerCase();
          if (search === '') {
            return true;
          }
          const text = `${item.task.title} ${
            item.task.details.description ?? ''
          }`
            .trim()
            .toLowerCase();
          return text.includes(search);
        });
        const completionFiltered = textFiltered.filter((item) => {
          return !item.isCompleted();
        });
        const timeFilteredList = completionFiltered.filter((item) => {
          return (
            isWithinInterval(currentMinute, item.interval) ||
            (isAfter(currentMinute, item.interval.end) &&
              !item.isCompleted() &&
              item.task.shouldReappear)
          );
        });
        const sorted = timeFilteredList.sort(
          (a, b) => a.task.orderIndex - b.task.orderIndex
        );

        const folders = taskFolders
          .map((folder) => ({
            ...folder,
            items: sorted.filter(
              (item) =>
                item.task.taskFolder?.toString() === folder._id.toString()
            )
          }))
          .filter((folder) => folder.items.length > 0)
          .sort((a, b) => a.orderIndex - b.orderIndex);

        return {
          checklist: sorted,
          time: currentMinute,
          folders
        };
      }),
      tap(() => {
        this.isLoading = false;
        this.pullToSearch.initSearch();
      })
    );
  }

  public ngAfterViewInit(): void {
    this._route.queryParams.subscribe((params) => {
      setTimeout(() => {
        if (params && params.action) {
          switch (params.action) {
            case 'openDailyReport':
              this.showDailyReport();
              break;
          }
        }
      }, 500);
    });
  }

  protected listFolderTrackBy(
    _index: number,
    { _id }: MongoStoredObject<ITaskFolder>
  ) {
    return _id;
  }

  protected onItemClick(
    item: ChecklistItem,
    folder?: MongoStoredObject<ITaskFolder> & {
      items: ChecklistItem[];
    }
  ): void {
    const uncompletedCriticalTasks =
      folder?.items.filter(
        (task) => task.task.isCritical && !task.isCompletedAtLeastOnce()
      ) || [];

    const isLastCriticalTask =
      uncompletedCriticalTasks.length === 1 &&
      uncompletedCriticalTasks[0].task._id === item.task._id;

    this._ionNav.push(ChecklistItemPickupComponent, {
      item,
      isLastCriticalTask
    });
  }

  protected async showDailyReport() {
    this._ionNav.push(DailyReportComponent);
  }

  protected onSearchChange(value: string): void {
    this.filter$.next({ ...this.filter$.value, search: value });
  }

  protected onAddTaskClick(): void {
    this._ionNav.push(TaskManagementComponent);
  }

  protected showTasksCompletedOfUser() {
    this._insightsDateRangeHelperService
      .getIntervalFromPreset(RangePresets.Today)
      .subscribe((range) => {
        this._ionNav.push(TasksCompletedComponent, {
          userId: this._authenticationService.user._id,
          fullName: `${this._authenticationService.user.firstName} ${this._authenticationService.user.lastName}`,
          defaultRange: range
        });
      });
  }
}
