export enum EDailySurveyMode {
  'OFF' = 'OFF',
  'PUSH_NOTIFICATION' = 'PUSH_NOTIFICATION',
  'REQUIRED' = 'REQUIRED'
}

export interface DailySurveyDefinition {
  dailySurveyMode: EDailySurveyMode;
  dailySurveyPNTime: number;
}
