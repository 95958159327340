import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  selector: 'app-width-restricted-container',
  templateUrl: './width-restricted-container.component.html',
  styleUrls: ['./width-restricted-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidthRestrictedContainerComponent {
  @Input()
  @HostBinding('style.maxWidth')
  public maxWidth: string = '800px';
}
