import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest, tap } from 'rxjs';
import {
  ConnectedAccountsApiService,
  IConnectedAccount
} from './connected-accounts-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { AuthenticationService } from '../authentication';
import { GlobalSettingsService } from './global-settings.service';

@Injectable({ providedIn: 'root' })
export class ConnectedAccountsListService {
  private _myConnectedAccounts$ = new BehaviorSubject<IConnectedAccount[]>([]);
  private _connectedAccountsGroups$ = new BehaviorSubject<
    MongoStoredObject<{ accounts: IConnectedAccount[] }>[]
  >([]);

  public myConnectedAccounts$: Observable<IConnectedAccount[]> =
    this._myConnectedAccounts$.asObservable();
  public connectedAccountsGroups$: Observable<
    MongoStoredObject<{ accounts: IConnectedAccount[] }>[]
  > = this._connectedAccountsGroups$.asObservable();

  protected isUser: boolean = null;
  protected isSuperAdmin: boolean = null;

  public constructor(
    private readonly _connectedAccountsApiService: ConnectedAccountsApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _globalSettingsService: GlobalSettingsService
  ) {
    combineLatest([
      this._authenticationService.user$,
      this._globalSettingsService.globalSettings$
    ]).subscribe(([user, globalSettings]) => {
      if (user && this.isUser === null && this.isSuperAdmin === null) {
        this.isUser = !!user;
        this.isSuperAdmin = !!globalSettings.superAdmins.find(
          (user) =>
            user._id.toString() ===
            this._authenticationService.user._id.toString()
        );

        this.fetchMyConnectedAccounts();
        this.fetchConnectedAccountsGroups();
      }
    });
  }

  public fetchMyConnectedAccounts(): void {
    if (this.isUser) {
      this._connectedAccountsApiService.getMyConnectedAccounts().subscribe({
        next: (accounts) => {
          this._myConnectedAccounts$.next(accounts);
        }
      });
    }
  }

  public fetchConnectedAccountsGroups(): void {
    if (this.isSuperAdmin) {
      this._connectedAccountsApiService.getConnectedAccountsGroups().subscribe({
        next: (groups) => {
          this._connectedAccountsGroups$.next(
            groups.map((g) => ({
              ...g,
              accounts: g.accounts.sort((a, b) => {
                if (a.email < b.email) {
                  return -1;
                }
                if (a.email > b.email) {
                  return 1;
                }
                return 0;
              })
            }))
          );
        }
      });
    }
  }

  public removeMyConnectedAccount(userId: string) {
    return this._connectedAccountsApiService
      .removeMyConnectedAccount(userId)
      .pipe(
        tap((accounts) => {
          this._myConnectedAccounts$.next(accounts);
          this.fetchConnectedAccountsGroups();
        })
      );
  }

  public removeConnectedAccountFromGroup(groupId: string, userId: string) {
    return this._connectedAccountsApiService
      .removeConnectedAccountFromGroup(groupId, userId)
      .pipe(
        tap((groups) => {
          this._connectedAccountsGroups$.next(groups);
          this.fetchMyConnectedAccounts();
        })
      );
  }
}
