import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  IonNav,
  ModalController,
  IonModal,
  IonInfiniteScroll,
  ViewWillLeave
} from '@ionic/angular';
import { InsightsRangeModalComponent } from '@app/modules/insights-range-modal/insights-range-modal.component';
import {
  EditTaskPerformanceModalComponent,
  IEditTaskPerformanceModalResult
} from '@app/modules/edit-task-performance-modal/edit-task-performance-modal.component';
import { DateInterval } from '@backend/types/date-interval';
import { AuthenticationService, ROLES, UserApiService } from '@app/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { TaskPerformanceApiService } from '@app/core/service/task-performance-api.service';
import { ObjectId } from '@app/types/object-id';
import { ITask } from '@backend/models/types/task';
import { UserModel } from '@app/core/model/user.model';
import { TaskDto } from '@app/types/task-dto';
import { TaskListService } from '@app/core/service/task-list.service';
import { isSameDay } from 'date-fns';
import { ILedger } from '@backend/models/types/ledger';
import { UserEntityService } from '@app/core/service/user-entity.service';

@Component({
  selector: 'app-tasks-completed',
  templateUrl: './tasks-completed.component.html',
  styleUrls: ['./tasks-completed.component.scss']
})
export class TasksCompletedComponent implements OnInit, ViewWillLeave {
  @Input()
  public userId: ObjectId;

  @Input()
  public fullName: string;

  @Input()
  public defaultRange: DateInterval;

  @ViewChild('taskSelectorModal', { static: true })
  public taskSelectorModal!: IonModal;

  @ViewChild('infiniteScroll', { static: true })
  public infiniteScroll!: IonInfiniteScroll;

  protected range: DateInterval;
  protected user: UserModel;
  protected taskPerformances: MongoStoredObject<
    ITaskPerformance & {
      task: MongoStoredObject<ITask>;
      ledgers: MongoStoredObject<ILedger>[];
    }
  >[] = [];
  protected tasks: MongoStoredObject<TaskDto>[] = [];
  protected page = 1;
  protected pageSize = 20;
  protected isLoading = true;
  protected hasNextPage = true;
  protected isAdminOrManager = false;

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _modalCtrl: ModalController,
    private readonly _userApiService: UserApiService,
    private readonly _taskPerformanceApiService: TaskPerformanceApiService,
    private readonly _taskListService: TaskListService,
    private readonly _userEntityService: UserEntityService,
    private readonly _authenticationService: AuthenticationService
  ) {
    this.isAdminOrManager =
      (this._authenticationService.user?.roles.includes(ROLES.adminName) ||
        this._authenticationService.user?.roles.includes(ROLES.managerName)) ??
      false;
  }

  protected get isOneDayRange() {
    return isSameDay(this.range.start, this.range.end);
  }

  public ngOnInit(): void {
    this._userApiService.getAllUsersWithDeleted().subscribe((users) => {
      this.user = users.find((u) => u._id === this.userId);
      this.range = this.defaultRange;
      this._fetchTaskPerformances();
    });

    this._taskListService.list$.subscribe((tasks) => {
      this.tasks = tasks.filter((task) => !task.isDeleted);
    });
  }

  public async ionViewWillLeave() {
    const curPage = await this._ionNav.getActive();
    curPage.params = { range: this.range };
  }

  protected onIonInfinite() {
    this.page = this.page + 1;
    this._fetchTaskPerformances();
  }

  private _fetchTaskPerformances(reset = false): void {
    if (reset) {
      this.isLoading = true;
      this.page = 1;
      this.taskPerformances = [];
    }

    this._userApiService
      .getUserTaskPerformances(
        this.userId,
        this.range,
        this.page,
        this.pageSize
      )
      .subscribe((taskPerformances) => {
        this.hasNextPage = taskPerformances.length === this.pageSize;
        this.taskPerformances = this.taskPerformances.concat(taskPerformances);
        this.isLoading = false;
        this.infiniteScroll.complete();
      });
  }

  protected async onRangeClick() {
    const dateRangeModal = await this._modalCtrl.create({
      id: 'insights-range-modal',
      component: InsightsRangeModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        range: this.range
      },
      handle: false
    });
    dateRangeModal.present();

    const { data, role } = await dateRangeModal.onWillDismiss();

    if (role === 'confirm') {
      this.range = data;
      this._fetchTaskPerformances(true);
    }
  }

  protected listItemTrackBy(
    _index: number,
    item: MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        ledgers: MongoStoredObject<ILedger>[];
      }
    >
  ) {
    return item._id;
  }

  protected async onItemClick(
    item: MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        ledgers: MongoStoredObject<ILedger>[];
      }
    >
  ) {
    const bonusLedger = item.ledgers.find((l) =>
      l.description.endsWith(' (BONUS)')
    );

    const editTaskPerformanceModal = await this._modalCtrl.create({
      id: 'edit-task-performance-modal',
      component: EditTaskPerformanceModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        taskId: item.task._id,
        initialData: {
          userId: this.user._id,
          dateCompleted: new Date(item.completeTime),
          quantity: item.quantity,
          timeAttackBonusPoints: bonusLedger?.points || 0,
          isDeleted: item.isDeleted
        },
        createMode: false,
        showTimeAttackBonusPoints: !!bonusLedger
      },
      handle: false
    });
    editTaskPerformanceModal.present();

    const { data, role } = await editTaskPerformanceModal.onWillDismiss();

    if (role === 'confirm') {
      const editedPerformance: IEditTaskPerformanceModalResult = data;

      this._taskPerformanceApiService
        .patchTaskPerformances(item.task._id, [
          {
            _id: item._id,
            user: editedPerformance.userId,
            body: {
              isDeleted: editedPerformance.isDeleted,
              completeTime: editedPerformance.dateCompleted.toISOString(),
              quantity: editedPerformance.quantity,
              timeAttackBonusPoints: editedPerformance.timeAttackBonusPoints
            },
            isNew: false
          }
        ])
        .subscribe(() => {
          this._fetchTaskPerformances(true);
          this._userEntityService.update();
        });
    }
  }

  protected async onTaskSelect(value: ObjectId[]) {
    if (value.length) {
      this.taskSelectorModal.dismiss();

      const createTaskPerformanceModal = await this._modalCtrl.create({
        id: 'edit-task-performance-modal',
        component: EditTaskPerformanceModalComponent,
        cssClass: 'modal-auto-height',
        breakpoints: [0, 1],
        initialBreakpoint: 1,
        componentProps: {
          taskId: value[0],
          initialData: {
            userId: this.user._id,
            dateCompleted: this.range.end,
            quantity: 1,
            timeAttackBonusPoints: 0,
            isDeleted: false
          },
          createMode: true,
          showTimeAttackBonusPoints: false
        },
        handle: false
      });
      createTaskPerformanceModal.present();

      const { data, role } = await createTaskPerformanceModal.onWillDismiss();

      if (role === 'confirm') {
        const createdPerformance: IEditTaskPerformanceModalResult = data;

        this._taskPerformanceApiService
          .patchTaskPerformances(value[0], [
            {
              _id: 'NEW_TASK_PERFORMACE',
              user: createdPerformance.userId,
              body: {
                completeTime: createdPerformance.dateCompleted.toISOString(),
                quantity: createdPerformance.quantity
              },
              isNew: true
            }
          ])
          .subscribe(() => {
            this._fetchTaskPerformances(true);
          });
      }
    }
  }

  protected async onBackButtonClick() {
    this._ionNav.pop();
  }
}
