import { Pipe, PipeTransform } from '@angular/core';
import { ObjectId } from '@app/types/object-id';
import FuzzySearch from 'fuzzy-search';

@Pipe({
  name: 'filterTasks',
  standalone: true
})
export class FilterTasksPipe implements PipeTransform {
  public transform(
    items: {
      id: ObjectId;
      name: string;
      checked: boolean;
      isDeleted: boolean;
    }[],
    filter: string
  ): { id: ObjectId; name: string; checked: boolean; isDeleted: boolean }[] {
    if (filter.length === 0) {
      return items;
    }
    const searcher = new FuzzySearch(items, ['name'], {
      caseSensitive: false
    });
    return searcher.search(filter);
  }
}
