<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title> Tags </ion-title>
    <ion-spinner
      name="lines-sharp-small"
      *ngIf="isStatusesLoading"
      slot="end"
    ></ion-spinner>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar ion-no-padding">
    <app-width-restricted-container>
      <ion-searchbar
        #ionSearchbar
        class="searchbar"
        [debounce]="700"
        (ionInput)="handleSearchInput($event)"
      />
    </app-width-restricted-container>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="tags.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <ng-container *ngIf="!isLoading">
      <app-tag-item
        *ngFor="let tag of tags; let i = index; trackBy: trackTags"
        [tag]="tag"
        (click)="onTagClick(tag._id)"
        appHaptics
        [animationOrder]="page === 1 ? i : -1"
        [status]="getTagPickupStatus(tag)"
      />
    </ng-container>

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      [disabled]="tags.length === 0 || isLoading || !hasNextPage"
      class="ion-padding-top"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </app-width-restricted-container>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="List Is Empty"></app-empty-stub>
</ng-template>
