<div class="date-buttons">
  <app-day-toggle-button
    *ngFor="let dayName of days; let index = index"
    [(checked)]="dayValues[index]"
    (checkedChange)="onCheckedChange()"
    >{{ dayName }}</app-day-toggle-button
  >
  <app-day-toggle-button
    class="last-button"
    [(checked)]="lastDay"
    (checkedChange)="onCheckedChange()"
    >Last day</app-day-toggle-button
  >
</div>
<mat-checkbox
  [(ngModel)]="isWorkingDay"
  [ngModelOptions]="{ standalone: true }"
  (change)="onCheckedChange()"
  class="checkbox"
>
  Adjust to working day
</mat-checkbox>
