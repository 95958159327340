<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      {{
        compensationPool
          ? 'Update Compensation Pool'
          : 'Create Compensation Pool'
      }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<div [formGroup]="form">
  <ion-grid>
    <ion-row>
      <ion-col size="12">
        <ion-input
          label="Name"
          class="app-w100 input-with-background"
          formControlName="name"
          labelPlacement="floating"
          fill="solid"
          autocapitalize="on"
          [spellcheck]="true"
        ></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-select
          formControlName="type"
          labelPlacement="floating"
          fill="solid"
          aria-label="Pool Type"
          interface="popover"
          label="Pool Type"
          class="app-w100 input-with-background select-round-fix"
          appHaptics
        >
          <ion-select-option [value]="compensationPoolType.CURRENCY">
            Currency
          </ion-select-option>
          <ion-select-option [value]="compensationPoolType.HOURS">
            Hours
          </ion-select-option>
          <ion-select-option [value]="compensationPoolType.OTHER">
            Other
          </ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-input
          class="app-w100 input-with-background"
          labelPlacement="floating"
          label="Recipients"
          id="selectRecipients"
          appHaptics
          [value]="formattedRecipients"
          [readonly]="true"
        />
      </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center ion-padding">
      <ion-col size="4">
        <ion-button
          color="dark"
          expand="block"
          [disabled]="form.invalid"
          appHaptics
          [disableHaptics]="form.invalid"
          (click)="save()"
        >
          {{ compensationPool ? 'Update' : 'Create' }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<ion-modal
  trigger="selectRecipients"
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="selectedIdChanged($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
      [multiple]="true"
    ></app-user-selector>
  </ng-template>
</ion-modal>
