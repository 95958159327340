<ion-grid *ngIf="isLoading">
  <ion-row class="ion-justify-content-center">
    <ion-spinner name="lines-sharp-small"></ion-spinner>
  </ion-row>
</ion-grid>

<ng-container *ngIf="valueBehaviors.length === 0 && !isLoading">
  <ng-container *ngTemplateOutlet="emptyList"></ng-container>
</ng-container>

<ion-accordion-group class="values-accordion" *ngIf="valueBehaviors.length">
  <ion-accordion
    *ngFor="let behavior of valueBehaviors"
    [value]="behavior._id.toString()"
  >
    <ion-item slot="header" appHaptics>
      <ion-label class="value-name">{{ behavior.name }}</ion-label>
    </ion-item>
    <div class="ion-padding" slot="content">
      {{ behavior.description || 'No description' }}
    </div>
  </ion-accordion>
</ion-accordion-group>

<ng-template #emptyList>
  <app-empty-stub text="List Is Empty"></app-empty-stub>
</ng-template>
