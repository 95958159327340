import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { UserApiService } from '@app/core';
import { UserModel } from '@app/core/model/user.model';
import { TenantApiService } from '@app/core/service/tenant-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IUserDeviceInfo } from '@backend/models/types/device-info';
import { ITenant } from '@backend/models/types/tenant';
import { IonModal, IonNav } from '@ionic/angular';
import { Observable, Subject, repeat } from 'rxjs';

@Component({
  selector: 'app-tenant-users',
  templateUrl: 'tenant-users.component.html',
  styleUrls: ['./tenant-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantUsersComponent implements OnInit {
  @Input()
  public readonly tenant: MongoStoredObject<ITenant>;

  @ViewChild(IonModal)
  public modal: IonModal;

  protected userList$: Observable<
    (UserModel & { deviceInfos: MongoStoredObject<IUserDeviceInfo>[] })[]
  >;
  protected isModalOpen = false;
  protected selectedUser: UserModel & {
    deviceInfos: MongoStoredObject<IUserDeviceInfo>[];
  } = null;
  protected emailModel: string;

  private _listUpdate$ = new Subject<void>();

  public constructor(
    private readonly _api: UserApiService,
    private readonly _ionNav: IonNav,
    private readonly _tenantApi: TenantApiService
  ) {}

  public ngOnInit(): void {
    this.userList$ = this._api
      .getAllUsers(this.tenant._id)
      .pipe(repeat({ delay: () => this._listUpdate$ }));
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected onModalDismiss() {
    this.isModalOpen = false;
    this.selectedUser = null;
  }

  protected onUserClick(
    user: UserModel & { deviceInfos: MongoStoredObject<IUserDeviceInfo>[] }
  ) {
    this.isModalOpen = true;
    this.selectedUser = user;
    this.emailModel = user.email;
  }

  protected confirm() {
    this._tenantApi
      .patchUser(this.tenant._id, this.selectedUser._id, {
        email: this.emailModel
      })
      .subscribe({
        next: () => {
          this._listUpdate$.next();
          this.onModalDismiss();
        },
        error: (e) => console.error(e)
      });
  }

  protected trackUserBy(_index: number, user: MongoStoredObject<UserModel>) {
    return user._id;
  }

  protected devicesStr(deviceInfos: MongoStoredObject<IUserDeviceInfo>[]) {
    return deviceInfos.reduce(
      (prev, cur) =>
        `${prev ? `${prev}, ` : ''}v${cur.version} (${
          cur.deviceInfo.platform
        })`,
      ''
    );
  }
}
