import { NgModule } from '@angular/core';
import { SkillMatrixForUserModalComponent } from './skill-matrix-for-user-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';
import { SkillMatrixSelectModule } from '../skill-matrix-status-select/skill-matrix-status-select.module';
import { TaskSelectorComponent } from '../task-selector/task-selector.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    WidthRestrictedContainerModule,
    SkillMatrixSelectModule,
    TaskSelectorComponent
  ],
  exports: [SkillMatrixForUserModalComponent],
  declarations: [SkillMatrixForUserModalComponent]
})
export class SkillMatrixForUserModalModule {}
