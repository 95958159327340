<ion-item button (click)="onStatusClick($event)" [detail]="false">
  <div class="status-name">{{ label }}</div>
  <ion-chip
    slot="end"
    [color]="getStatus.color"
    class="skill-matrix-status-chip"
  >
    {{ getStatus.title }}
  </ion-chip>
</ion-item>

<ion-popover #popover [keepContentsMounted]="true">
  <ng-template>
    <div class="skill-matrix-statuses">
      <div *ngFor="let st of skillStatuses" class="skill-matrix-status">
        <ion-chip
          [color]="st.color"
          (click)="onStatusChange(st.status)"
          class="skill-matrix-status-chip"
        >
          {{ st.title }}
        </ion-chip>
      </div>
    </div>
  </ng-template>
</ion-popover>
