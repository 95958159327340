<ion-content color="light">
  <app-width-restricted-container class="recognition-container">
    <div class="top-content">
      <ion-text>
        <h1 class="ion-text-center heading">
          {{ isRecognitionForMe ? 'Congratulations!' : 'Good news!' }}
        </h1>
      </ion-text>
      <ion-text>
        <h3 class="ion-text-center subheading">
          Someone recognized
          {{ isRecognitionForMe ? 'your efforts' : recipientFullName }}.
        </h3>
      </ion-text>
      <ion-text>
        <h3 class="ion-text-center emoji">👏</h3>
      </ion-text>
      <app-recognition-item
        *ngIf="post"
        [item]="post"
        [showOptions]="authenticatedUser._id === post.sender?._id || isAdmin"
        (privateToggle)="updatePrivate($event, post)"
        (editClicked)="onEditRecognition($event)"
        (deleteClicked)="onDeleteRecognition($event)"
        appHaptics
        [disableHaptics]="
          !(authenticatedUser._id === post.sender?._id || isAdmin)
        "
      ></app-recognition-item>
      <div *ngIf="!post" class="ion-text-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </div>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="4">
            <ion-button color="dark" expand="block" (click)="done()">
              Done
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>
