<ion-content class="content">
  <ion-header class="ion-no-border">
    <ion-toolbar class="app-common-background announcement-preview-toolbar">
      <ion-buttons slot="start">
        <ion-button (click)="onCancelClicked()" color="primary" appHaptics>
          Cancel
        </ion-button>
      </ion-buttons>
      <ion-title class="ion-text-capitalize">
        {{ announcement?.title }}
      </ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="layout-container">
    <div class="top-content">
      <div class="announcement-view__header">
        <h2 class="announcement-view__subheading">
          <span>
            {{ announcement ? fDateAndTime(announcement.date) : '' }} |
            {{ announcement?.authorName }}
          </span>
          <span>{{ editedString }}</span>
        </h2>
        <div class="app-divider-line"></div>
      </div>
      <app-wysiwyg-editor *ngIf="announcement" [value]="body" mode="view" />
      <app-announcement-thread [announcements]="thread" />
    </div>
    <div class="bottom-content" *ngIf="announcement">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col
            size="6"
            *ngIf="announcement.actions !== announcementActions.ONLY_RESPOND"
          >
            <ion-button
              color="dark"
              (click)="acknowledge()"
              appHaptics
              expand="block"
              [disabled]="announcementIndex === -1"
              [disableHaptics]="announcementIndex === -1"
            >
              {{
                announcement.acknowledgeButtonText ===
                announcementAcknowledgeButtonText.UNDERSTAND_AND_AGREE
                  ? 'Understand and Agree'
                  : 'Acknowledge'
              }}
            </ion-button>
          </ion-col>
          <ion-col
            size="6"
            *ngIf="
              announcement.actions !== announcementActions.ONLY_ACKNOWLEDGE &&
              announcement.author
            "
          >
            <ion-button
              color="dark"
              (click)="respond()"
              appHaptics
              expand="block"
              fill="outline"
              [disabled]="announcementIndex === -1"
              [disableHaptics]="announcementIndex === -1"
            >
              {{ respondButtonText }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
