import { Types } from 'mongoose';

export interface ITaskPerformanceNote {
  body: string;
  attachments: Types.ObjectId[];
  acknowledgedBy: Types.ObjectId[];
  acknowledgedForAll: boolean;
}

export enum TaskPerformanceStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  COMPLETE = 'COMPLETE',
  SKIPPED = 'SKIPPED'
}

export enum TaskPerformanceBonus {
  NEEDS_IMPROVEMENT = 'NEEDS_IMPROVEMENT',
  ACCEPTABLE = 'ACCEPTABLE',
  EXCELLENT = 'EXCELLENT'
}

export interface ITaskPerformanceCompleted {
  status: TaskPerformanceStatus.COMPLETE;
  startTime: Date | null;
  completeTime: Date;
  user: Types.ObjectId;
  quantity: number;
}

export interface ITaskPerformanceWaitingForApproval {
  status: TaskPerformanceStatus.WAITING_FOR_APPROVAL;
  startTime: Date | null;
  completeTime: Date;
  user: Types.ObjectId;
  quantity: number;
}

export interface ITaskPerformanceNotStarted {
  status: TaskPerformanceStatus.NOT_STARTED;
  startTime: null;
  completeTime: null;
  user: Types.ObjectId;
  quantity: null;
}

export interface ITaskPerformanceInProgress {
  status: TaskPerformanceStatus.IN_PROGRESS;
  startTime: Date;
  completeTime: null;
  user: Types.ObjectId;
  quantity: null;
}

export interface ITaskPerformanceSkipped {
  status: TaskPerformanceStatus.SKIPPED;
  startTime: null;
  completeTime: null;
  user: null;
  quantity: null;
}

export interface ITaskPerformanceBase {
  task: Types.ObjectId;
  savedTaskPoints: number;
  taskAppearTime: Date;
  tenant: Types.ObjectId;
  isDeleted: boolean;
  note?: ITaskPerformanceNote;
}

export type ITaskPerformance = ITaskPerformanceBase &
  (
    | ITaskPerformanceCompleted
    | ITaskPerformanceWaitingForApproval
    | ITaskPerformanceInProgress
    | ITaskPerformanceNotStarted
    | ITaskPerformanceSkipped
  );
