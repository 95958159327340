import { IEnvironment } from './ienvironment';

export const environment: IEnvironment = {
  production: true,
  enableDebugTools: false,
  logLevel: 'info',
  apiHost: '',
  env: 'PROD',
  serverUrl: 'https://api.valchemy.app',
  webAppUrl: 'https://valchemy.app'
};
