import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import {
  TaskForAuditCriterionModel,
  TaskForAuditModel
} from '@app/core/model/task-for-audit.model';
import { TenantService } from '@app/core/service/tenant.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-check-task-criteria-modal',
  templateUrl: './check-task-criteria-modal.component.html',
  styleUrls: ['./check-task-criteria-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckTaskCriteriaModalComponent {
  @Input()
  public readonly taskForAudit: TaskForAuditModel;

  @Input()
  public readonly showLastComplete?: boolean = false;

  protected disableAttachments = false;

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.disableAttachments = !tenant.features.auditAttachments;
    });
  }

  protected onCloseClick() {
    this._modalCtrl.dismiss(null, 'cancel', 'check-task-criteria-modal');
  }

  protected onValueChange(criterion: TaskForAuditCriterionModel, event: any) {
    criterion.value = event.detail.value;
  }

  protected onDoneClick() {
    this._modalCtrl.dismiss(
      this.taskForAudit,
      'confirm',
      'check-task-criteria-modal'
    );
  }

  protected get canDone() {
    return (
      this.taskForAudit.criteria
        .map((c) => c.value === 'TRUE' || (c.value === 'FALSE' && c.comment))
        .filter(Boolean).length === this.taskForAudit.criteria.length
    );
  }
}
