import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';
import { DayToggleButtonComponent } from './day-toggle-button.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DayToggleButtonComponent],
  imports: [CommonModule, MatRippleModule, FormsModule],
  exports: [DayToggleButtonComponent]
})
export class DayToggleButtonModule {}
