<div class="table-row-performance-time">
  {{ performance.completeTime | date : 'h:mm a' }}
</div>
<div
  class="table-row-performance-comment"
  *ngIf="performance.task.verbPastSimple"
>
  {{ performance.task.verbPastSimple }}
  <b *ngIf="performance.task.pointsEarns === taskPointsEarns.PER_COMPLETION">
    {{ performance.task.pointsUnit }}
  </b>
  <b *ngIf="performance.task.pointsEarns === taskPointsEarns.PER_UNIT">
    {{ performance.quantity }}
    {{
      performance.quantity === 1
        ? performance.task.pointsUnit
        : performance.task.nounPlural
    }}
  </b>
</div>
<div
  class="table-row-performance-comment"
  *ngIf="!performance.task.verbPastSimple"
>
  {{ performance.task.title }}
  <b *ngIf="performance.task.pointsEarns === taskPointsEarns.PER_COMPLETION">
    {{ performance.task.pointsUnit }}
  </b>
  <b
    *ngIf="
      performance.task.pointsEarns === taskPointsEarns.PER_UNIT ||
      performance.quantity > 1
    "
  >
    (x{{ performance.quantity }})
  </b>
</div>
<div class="table-row-performance-icon" *ngIf="performance.note">
  <ion-icon name="warning-outline" />
</div>
