import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
  OnInit
} from '@angular/core';
import {
  IonNav,
  LoadingController,
  ModalController,
  IonPopover,
  IonModal
} from '@ionic/angular';
import { LibraryDocAddComponent } from '../library-doc-add/library-doc-add.component';
import {
  AnnouncementApiService,
  AuthenticationService,
  ROLES
} from '@app/core';
import { LibraryApiService } from '@app/core/service/library-api.service';
import { ToastService } from '@app/core/service/toast.service';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import { LibraryDocModel } from '@app/core/model/library-doc.model';
import { ObjectId } from '@app/types/object-id';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';

@Component({
  selector: 'app-library-doc-view',
  templateUrl: './library-doc-view.component.html',
  styleUrls: ['./library-doc-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryDocViewComponent implements OnInit {
  @Input()
  public libraryDoc: LibraryDocModel = null;

  @ViewChild('popover')
  public popover: IonPopover;

  @ViewChild('userSelectorModal', { static: true })
  public userSelectorModal!: IonModal;

  protected isAdmin = false;
  protected isLoading = false;
  protected body = '';
  protected isPopoverOpen = false;
  protected allUsers: UserModel[] = [];

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService,
    private readonly _libraryApiService: LibraryApiService,
    private readonly _cdr: ChangeDetectorRef,
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _announcementApiService: AnnouncementApiService,
    private readonly _environmentService: EnvironmentService,
    private readonly _userListService: UserListService
  ) {
    this.isAdmin = this._authenticationService.user.roles.includes(
      ROLES.adminName
    );
    this._userListService.users$.subscribe((users) => {
      this.allUsers = users;
    });
  }

  public ngOnInit(): void {
    this.body = formatUploadedBody(
      this.libraryDoc.body,
      this.libraryDoc.attachments,
      this._environmentService.serverUrl
    );
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected onEditButtonClick(e: Event) {
    this.popover.event = e;
    this.isPopoverOpen = true;
  }

  protected editDoc() {
    this._ionNav.push(LibraryDocAddComponent, {
      libraryDoc: this.libraryDoc
    });
  }

  protected async requestDocToSign() {
    this.userSelectorModal.present();
  }

  protected get mySign() {
    return this.libraryDoc.signed.find(
      (u) =>
        u.user._id.toString() ===
        this._authenticationService.user._id.toString()
    );
  }

  protected get signedUsersIds() {
    return this.libraryDoc.signed.map((user) => user.user._id.toString());
  }

  protected get signedUsers() {
    return this.libraryDoc.signed;
  }

  protected async signLibraryDoc() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._libraryApiService.signLibraryDoc(this.libraryDoc._id).subscribe({
      next: (doc) => {
        this.libraryDoc = doc;
        loading.dismiss();
        this._toastService.presentToast('Document Signed Successfully!');
      },
      error: () => {
        loading.dismiss();
        this._toastService.presentToast('Unable to Sign Document');
      }
    });
  }

  protected onPopoverDismiss(): void {
    this.isPopoverOpen = false;
  }

  protected async onUsersSelect(event: ObjectId[]) {
    this.userSelectorModal.dismiss();

    if (event.length) {
      const loading = await this._loadingCtrl.create({
        message: 'Loading...'
      });

      loading.present();
      this._libraryApiService
        .requestUsersToSignLibraryDoc(this.libraryDoc._id, event)
        .subscribe({
          next: () => {
            loading.dismiss();
            this._toastService.presentToast(
              'Announcement Posted Successfully!'
            );
          },
          error: () => {
            loading.dismiss();
            this._toastService.presentToast('Unable to Post Announcement');
          }
        });
    }
  }
}
