<div
  [ngClass]="{
    'independent-item': true,
    'independent-item--unread': isUnread(doc.latestVersion || doc.originalDoc)
  }"
>
  <ion-item
    lines="none"
    button
    (click)="onVersionClick(doc.latestVersion || doc.originalDoc)"
    appHaptics
    [detail]="false"
  >
    <ion-icon class="doc-icon" name="document-outline"></ion-icon>
    <ion-label class="ion-text-capitalize">
      {{
        doc.latestVersion
          ? doc.latestVersion.title.toLowerCase()
          : doc.originalDoc.title.toLowerCase()
      }}
    </ion-label>
    <ion-button
      slot="end"
      fill="clear"
      (click)="expandLibraryDoc($event)"
      [ngClass]="{
        'ion-no-margin expand-btn': true,
        'expand-btn--opened': isSelected
      }"
      color="dark"
    >
      <ion-icon name="chevron-down-outline" />
    </ion-button>
  </ion-item>
  <div class="version-list" *ngIf="isSelected">
    <ion-list>
      <ion-item
        *ngFor="let version of doc.versions"
        lines="none"
        button
        appHaptics
        (click)="onVersionClick(version)"
        [detail]="false"
      >
        <ion-label class="ion-text-capitalize">
          {{ version.title.toLowerCase() }}
        </ion-label>
        <ion-chip
          slot="end"
          class="ion-no-margin"
          [color]="
            version._id === doc.latestVersion._id ? 'success' : 'primary'
          "
        >
          {{
            version._id === doc.latestVersion._id
              ? 'Current'
              : (version.dateCreated | date : 'MMM d, y')
          }}
        </ion-chip>
      </ion-item>
      <ion-item
        lines="none"
        button
        appHaptics
        (click)="onVersionClick(doc.originalDoc)"
        [detail]="false"
      >
        <ion-label class="ion-text-capitalize">
          {{ doc.originalDoc.title.toLowerCase() }}
        </ion-label>
        <ion-chip
          slot="end"
          class="ion-no-margin"
          [color]="doc.versions.length === 0 ? 'success' : 'primary'"
        >
          {{
            doc.versions.length === 0
              ? 'Current'
              : (doc.originalDoc.dateCreated | date : 'MMM d, y')
          }}
        </ion-chip>
      </ion-item>
    </ion-list>
  </div>
</div>
