import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidthRestrictedContainerComponent } from './width-restricted-container.component';

@NgModule({
  declarations: [WidthRestrictedContainerComponent],
  imports: [CommonModule],
  exports: [WidthRestrictedContainerComponent]
})
export class WidthRestrictedContainerModule {}
