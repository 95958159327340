<div class="title">{{ title }}</div>
<div class="footer">
  <ion-button
    *ngFor="let button of buttons"
    fill="outline"
    size="small"
    class="ion-no-margin"
    appHaptics
    (click)="onButtonClick(button.id)"
  >
    {{ button.text }}
  </ion-button>
</div>
