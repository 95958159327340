import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-stub',
  templateUrl: './empty-stub.component.html',
  styleUrls: ['./empty-stub.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStubComponent {
  @Input()
  public readonly text: string = '';

  @Input()
  public readonly icon?: string;
}
