import { IEnvironment } from '@env/ienvironment';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import packageJson from '@root/package.json';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {
  // production: boolean;
  // enableDebugTools: boolean;
  // logLevel: import("../../../environments/ienvironment").LogLevel;
  // apiUrl: string;
  // apiHost: string;

  public version: string = packageJson.version;

  get production() {
    return environment.production;
  }

  get enableDebugTools() {
    return environment.enableDebugTools;
  }

  get logLevel() {
    return environment.logLevel;
  }

  get apiHost() {
    return environment.apiHost;
  }

  get serverUrl() {
    console.log('using env service: serverUrl = ' + environment.serverUrl);
    return environment.serverUrl;
  }

  get webAppUrl() {
    console.log('using env service: webAppUrl = ' + environment.webAppUrl);
    return environment.webAppUrl;
  }

  get env() {
    return environment.env;
  }

  /**
   *
   */
  constructor() {}
}
