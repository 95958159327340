import { NgModule } from '@angular/core';
import { RecognitionItemComponent } from './recognition-item.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [CommonModule, IonicModule, DirectivesModule],
  exports: [RecognitionItemComponent],
  declarations: [RecognitionItemComponent]
})
export class RecognitionItemModule {}
