import { Injectable } from '@angular/core';
import { Observable, Subject, repeat, shareReplay } from 'rxjs';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { TaskPerformanceApiService } from './task-performance-api.service';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { ITask } from '@backend/models/types/task';
import { UserModel } from '../model/user.model';

@Injectable({ providedIn: 'root' })
export class ApprovalsListService {
  private readonly _update$ = new Subject<void>();

  public approvals$: Observable<
    MongoStoredObject<
      ITaskPerformance & {
        user: UserModel;
        task: MongoStoredObject<ITask>;
      }
    >[]
  > = this._taskPerformanceApiService
    .getWaitingForApprovalTaskPerformances()
    .pipe(repeat({ delay: () => this._update$ }), shareReplay(1));

  public constructor(
    private readonly _taskPerformanceApiService: TaskPerformanceApiService
  ) {}

  public update(): void {
    this._update$.next();
  }
}
