import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskFolderFormComponent } from './task-folder-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { UserSelectorComponent } from '../user-selector/user-selector.component';
import { DividerModule } from '../divider/divider.module';

@NgModule({
  declarations: [TaskFolderFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    DirectivesModule,
    UserSelectorComponent,
    DividerModule
  ],
  exports: [TaskFolderFormComponent]
})
export class TaskFolderFormModule {}
