<h2 class="ion-text-center heading" [innerHTML]="titleHtml"></h2>
<ion-grid>
  <ion-row class="ion-justify-content-center">
    <ion-col size="5">
      <app-emoji-button
        label="Yes"
        emoji="🙂"
        appHaptics
        (click)="yesClicked.emit()"
      />
    </ion-col>
    <ion-col size="5">
      <app-emoji-button
        label="No"
        emoji="🙁"
        appHaptics
        (click)="noClicked.emit()"
      />
    </ion-col>
  </ion-row>
</ion-grid>
