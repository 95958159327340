<app-login-page-wrapper>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()" novalidate>
    <div class="error-place error-place-persistent">
      Enter and verify your new password
    </div>

    <ion-input
      type="email"
      class="login-form-control"
      [value]="email"
      placeholder="Email"
      [hidden]="true"
      email
      [readonly]="true"
      [disabled]="true"
    />

    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="resetPasswordForm.controls['password1']"
          [controlName]="'Password'"
        >
          <ion-input
            #password1Input
            appAutofill
            type="password"
            class="login-form-control"
            formControlName="password1"
            placeholder="New password"
            autocomplete="new-password"
            name="password1"
            (ionInput)="onInput()"
            (ionChange)="onInput()"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="resetPasswordForm.controls['password2']"
          [controlName]="'Password'"
        >
          <ion-input
            #password2Input
            appAutofill
            type="password"
            class="login-form-control"
            formControlName="password2"
            placeholder="Re-enter new password"
            autocomplete="new-password"
            name="password2"
            (ionInput)="onInput()"
            (ionChange)="onInput()"
          />
        </app-form-error-wrapper>
        <div class="input-error" *ngIf="resetPasswordForm.errors?.['notSame']">
          Passwords do not match
        </div>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-button
          type="submit"
          color="primary"
          expand="block"
          [disabled]="resetPasswordForm.invalid"
          appHaptics
          [disableHaptics]="resetPasswordForm.invalid"
          class="ion-no-margin"
        >
          Confirm
        </ion-button>
      </ion-col>
    </ion-row>

    <div class="controls">
      <ion-button
        type="button"
        color="medium"
        fill="clear"
        size="small"
        appHaptics
        (click)="goToLogin()"
      >
        Cancel and return to login
      </ion-button>
      <input type="submit" class="hidden-control" />
    </div>
  </form>
</app-login-page-wrapper>
