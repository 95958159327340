<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title class="ion-no-padding"> When are you off today? </ion-title>
  </ion-toolbar>
</ion-header>

<div>
  <ion-grid [formGroup]="form" class="ion-padding-bottom">
    <ion-row class="ion-justify-content-center">
      <!-- <ion-col size="4">
        <app-time-form-control
          class="app-w100"
          formControlName="start"
          label="Start"
          [minuteStep]="1"
        ></app-time-form-control>
      </ion-col> -->
      <ion-col size="4">
        <app-time-form-control
          class="app-w100"
          formControlName="end"
          [minuteStep]="1"
        ></app-time-form-control>
      </ion-col>
    </ion-row>
    <ion-row
      class="ion-justify-content-center ion-padding-top ion-padding-horizontal"
    >
      <ion-col size="6">
        <ion-button
          type="submit"
          color="dark"
          expand="block"
          (click)="onSubmitClick()"
          appHaptics
        >
          Submit
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row class="ion-padding-vertical">
      <ion-col siz="12" class="ion-text-center">
        <ion-button
          class="ion-no-margin"
          type="button"
          color="medium"
          fill="clear"
          size="small"
          (click)="iDontWorkToday()"
          appHaptics
        >
          I don't work today
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
