import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  HostBinding
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@app/modules/directives.module';

@Component({
  selector: 'app-wide-button',
  templateUrl: './wide-button.component.html',
  styleUrls: ['./wide-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule, DirectivesModule]
})
export class WideButtonComponent {
  @Input()
  public readonly label: string;

  @Input()
  public readonly overflow?: boolean = true;

  @Input()
  public readonly disabled?: boolean = false;

  @Output()
  public readonly buttonClicked = new EventEmitter<Event>();

  @HostBinding('class.disabled')
  public get isDisabled() {
    return this.disabled;
  }
}
