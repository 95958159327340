import { utcToZonedTime } from 'date-fns-tz';
import { formatZonedTime } from './format-zoned-time';
import { getClientTimeZone } from './get-client-time-zone';

export function getFormattedZonedTime(date: Date): string {
  // Get the time zone set on the user's device
  const userTimeZone = getClientTimeZone();

  // Use date-fns-tz to convert from UTC to a zoned time
  const zonedTime = utcToZonedTime(date, userTimeZone);

  // Create a formatted string from the zoned time
  return formatZonedTime(zonedTime, userTimeZone);
}
