import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { ESkillMatrixStatus } from '@backend/models/types/task';
import { skillMatrixStatuses } from '@app/utils/skill-matrix';
import { IonPopover } from '@ionic/angular';

@Component({
  selector: 'app-skill-matrix-status-select',
  templateUrl: './skill-matrix-status-select.component.html',
  styleUrls: ['./skill-matrix-status-select.component.scss']
})
export class SkillMatrixStatusSelectComponent {
  @Input()
  public readonly status: ESkillMatrixStatus;

  @Input()
  public readonly label: string;

  @Output()
  public statusChanged = new EventEmitter<ESkillMatrixStatus>();

  @ViewChild('popover')
  public popover: IonPopover;

  protected skillStatuses = skillMatrixStatuses;

  protected onStatusChange(status: ESkillMatrixStatus) {
    this.statusChanged.emit(status);
    this.popover.dismiss();
  }

  protected get getStatus() {
    return (
      this.skillStatuses.find((s) => s.status === this.status) ||
      this.skillStatuses[0]
    );
  }

  protected onStatusClick(e: Event) {
    this.popover.event = e;
    this.popover.present();
  }
}
