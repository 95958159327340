<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">Users: {{ tenant.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container
    class="layout-container"
    *ngIf="userList$ | async as userList"
  >
    <ng-container *ngIf="userList.length === 0">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <ion-list>
      <ion-item
        *ngFor="let user of userList; trackBy: trackUserBy"
        button
        (click)="onUserClick(user)"
        appHaptics
      >
        <ion-label>
          <h2>{{ user.firstName }} {{ user.lastName }}</h2>
          <h3>{{ user.email }}</h3>
          <h3 *ngIf="user.deviceInfos.length">
            Last sessions: {{ devicesStr(user.deviceInfos) }}
          </h3>
        </ion-label>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>

  <ion-modal
    [isOpen]="isModalOpen"
    (didDismiss)="onModalDismiss()"
    [handle]="false"
    [breakpoints]="[0, 1]"
    [initialBreakpoint]="1"
  >
    <ng-template>
      <ion-header>
        <ion-toolbar class="app-toolbar">
          <ion-buttons slot="start">
            <ion-button (click)="onModalDismiss()" appHaptics color="primary">
              Cancel
            </ion-button>
          </ion-buttons>
          <ion-title>
            {{ selectedUser?.firstName }} {{ selectedUser?.lastName }}
          </ion-title>
          <ion-buttons slot="end">
            <ion-button
              (click)="confirm()"
              [disabled]="email.invalid"
              appHaptics
              [disableHaptics]="email.invalid"
              color="primary"
            >
              Confirm
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content class="ion-padding" color="light">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <ion-item class="device-info-card" lines="none">
                <ion-input
                  label="Email"
                  type="email"
                  labelPlacement="stacked"
                  placeholder="User email"
                  [(ngModel)]="emailModel"
                  required
                  email
                  #email="ngModel"
                ></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="selectedUser?.deviceInfos.length">
            <ion-col size="12">
              <ion-text>
                <h5 class="ion-text-center">Last sessions</h5>
              </ion-text>
              <ion-accordion-group class="device-info-card">
                <ion-accordion
                  *ngFor="let deviceInfo of selectedUser.deviceInfos"
                  [value]="deviceInfo._id"
                >
                  <ion-item slot="header" appHaptics>
                    <ion-label class="properties">
                      {{ deviceInfo.deviceInfo.platform }}
                      v{{ deviceInfo.version }} ({{
                        deviceInfo.dateUpdated || deviceInfo.dateCreated
                          | date : 'h:mm a M/d/yy'
                      }})
                    </ion-label>
                  </ion-item>
                  <div slot="content">
                    <ion-item>
                      <ion-label>Platform</ion-label>
                      <ion-note slot="end">
                        {{ deviceInfo.deviceInfo.platform }}
                      </ion-note>
                    </ion-item>
                    <ion-item>
                      <ion-label>Manufacturer</ion-label>
                      <ion-note slot="end">
                        {{ deviceInfo.deviceInfo.manufacturer }}
                      </ion-note>
                    </ion-item>
                    <ion-item>
                      <ion-label>Model</ion-label>
                      <ion-note slot="end">
                        {{ deviceInfo.deviceInfo.model }}
                      </ion-note>
                    </ion-item>
                    <ion-item>
                      <ion-label>Operating System</ion-label>
                      <ion-note slot="end">
                        {{ deviceInfo.deviceInfo.operatingSystem }}
                      </ion-note>
                    </ion-item>
                    <ion-item>
                      <ion-label>OS Version</ion-label>
                      <ion-note slot="end">
                        {{ deviceInfo.deviceInfo.osVersion }}
                      </ion-note>
                    </ion-item>
                  </div>
                </ion-accordion>
              </ion-accordion-group>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-content>
    </ng-template>
  </ion-modal>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="No Users"></app-empty-stub>
</ng-template>
