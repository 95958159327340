import { Pipe, PipeTransform } from '@angular/core';
import { ChecklistItem } from '@app/types/checklist-item';
import { isOverdue } from '@app/utils/is-overdue';

@Pipe({
  name: 'isOverdue',
  standalone: true
})
export class IsOverduePipe implements PipeTransform {
  public transform(item: ChecklistItem, time: Date): any {
    return isOverdue(item, time);
  }
}
