import { NgModule } from '@angular/core';
import { CompensationAnnouncementPreviewModalComponent } from './compensation-announcement-preview-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { WysiwygEditorComponent } from '../wysiwyg-editor/wysiwyg-editor.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    WysiwygEditorComponent
  ],
  exports: [CompensationAnnouncementPreviewModalComponent],
  declarations: [CompensationAnnouncementPreviewModalComponent]
})
export class CompensationAnnouncementPreviewModalModule {}
