import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { UsersComponent } from './users.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { FormatRolesPipe } from './format-roles.pipe';
import { FilterUsersPipe } from './filter-users.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { InviteUserComponent } from './components/invite-user/invite-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';
import { ManageUserTasksModalModule } from '@app/modules/manage-user-tasks-modal/manage-user-tasks-modal.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UserScheduleEditorModule } from '@app/modules/user-schedule-editor/user-schedule-editor.module';
import { SkillMatrixForUserModalModule } from '@app/modules/skill-matrix-for-user-modal/skill-matrix-for-user-modal.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Users',
      rootComponent: UsersComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    ReactiveFormsModule,
    FormsModule,
    SlimMenuButtonComponent,
    DirectivesModule,
    FormatRolesPipe,
    FilterUsersPipe,
    MatTableModule,
    MatSortModule,
    UserSelectorComponent,
    ManageUserTasksModalModule,
    ScrollingModule,
    UserScheduleEditorModule,
    SkillMatrixForUserModalModule
  ],
  declarations: [UsersComponent, InviteUserComponent, EditUserComponent]
})
export class UsersModule {}
