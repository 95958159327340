import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../authentication';
import { UserModel } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class MembershipService {
  currentUser: UserModel;
  tenantId: any;

  constructor(
    protected http: HttpClient,
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = this.authenticationService.user;
    this.tenantId = this.currentUser.tenant;
    console.log({ TenantId: this.tenantId });
  }

  getTenantSubscriptionStatus(): Observable<any> {
    return this.http.get(`/stripe/subscriptions/tenants/${this.tenantId}`).pipe(
      map((res) => {
        console.log(res);
        return res;
      })
    );
  }

  initiateCheckoutSession(): Observable<any> {
    return this.http
      .post<any>(`/stripe/create-checkout-session/${this.tenantId}`, '')
      .pipe(
        map((res) => {
          return res;
        })
      );
  }
}
