<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-title> Waiting for Approval </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ng-container *ngIf="waitingForApprovalsTaskPerformances.length === 0">
      <ng-container *ngTemplateOutlet="successStub"></ng-container>
    </ng-container>

    <app-performance-item
      *ngFor="
        let item of waitingForApprovalsTaskPerformances;
        trackBy: listItemTrackBy
      "
      [performance]="item"
      [user]="item.user"
      (click)="onItemClick(item)"
      appHaptics
    ></app-performance-item>
  </app-width-restricted-container>
</ion-content>

<ng-template #successStub>
  <app-empty-stub
    class="question-animation"
    icon="checkmark-circle-outline"
    text="You're done. Thank you!"
  ></app-empty-stub>
</ng-template>
