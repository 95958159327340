import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  IBackdoorInfo,
  IBackdoorInfoLink
} from '@backend/models/types/backdoor-info';
import { Observable, from, switchMap } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { ObjectId } from '@app/types/object-id';
import { Device } from '@capacitor/device';

@Injectable()
export class BackdoorApiService {
  private version = '';

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _environmentService: EnvironmentService
  ) {
    this.version = this._environmentService.version;
  }

  public getBackdoorInfo(): Observable<MongoStoredObject<IBackdoorInfo> | null> {
    return from(Device.getInfo()).pipe(
      switchMap((deviceInfo) =>
        this._httpClient.get<MongoStoredObject<IBackdoorInfo> | null>(
          '/backdoor/current-info',
          {
            params: {
              version: this.version,
              platform: deviceInfo.platform,
              manufacturer: deviceInfo.manufacturer,
              model: deviceInfo.model,
              osVersion: deviceInfo.osVersion,
              operatingSystem: deviceInfo.operatingSystem
            }
          }
        )
      )
    );
  }

  public getAllBackdoorMessages(): Observable<
    MongoStoredObject<IBackdoorInfo>[]
  > {
    return this._httpClient.get<MongoStoredObject<IBackdoorInfo>[]>(
      '/backdoor/info'
    );
  }

  public addBackdoorInfo(backdoorInfo: {
    title: string;
    message: string;
    versionRange: string;
    canDismiss: boolean;
    showToAllTenants: boolean;
    tenantsToShow: ObjectId[];
    links: IBackdoorInfoLink[];
  }): Observable<MongoStoredObject<IBackdoorInfo>> {
    return this._httpClient.post<MongoStoredObject<IBackdoorInfo>>(
      '/backdoor/info',
      backdoorInfo
    );
  }

  public updateBackdoorInfo(
    backdoorInfoId: string,
    backdoorInfo: {
      title: string;
      message: string;
      versionRange: string;
      canDismiss: boolean;
      showToAllTenants: boolean;
      tenantsToShow: ObjectId[];
      links: IBackdoorInfoLink[];
    }
  ): Observable<MongoStoredObject<IBackdoorInfo>> {
    return this._httpClient.put<MongoStoredObject<IBackdoorInfo>>(
      `/backdoor/info/${backdoorInfoId}`,
      backdoorInfo
    );
  }

  public deleteBackdoorInfo(backdoorInfoId: string) {
    return this._httpClient.delete(`/backdoor/info/${backdoorInfoId}`);
  }
}
