import { ESkillMatrixStatus } from '@backend/models/types/task';

export const skillMatrixStatuses = [
  {
    sortIndex: 0,
    status: ESkillMatrixStatus.CANNOT_PERFORM,
    title: 'Cannot Perform',
    color: 'medium'
  },
  {
    sortIndex: 1,
    status: ESkillMatrixStatus.CAN_PERFORM_WITH_SUPERVISON,
    title: 'Can Perform with Supervision',
    color: 'warning'
  },
  {
    sortIndex: 2,
    status: ESkillMatrixStatus.CAN_PERFORM_WITH_REVIEW,
    title: 'Can Perform with Review',
    color: 'warning'
  },
  {
    sortIndex: 3,
    status: ESkillMatrixStatus.CAN_PERFORM_WITHOUT_REVIEW,
    title: 'Can Perform without Review',
    color: 'success'
  },
  {
    sortIndex: 4,
    status: ESkillMatrixStatus.CAN_PERFORM_AND_TRAIN,
    title: 'Can Perform and Train',
    color: 'primary'
  },
  {
    sortIndex: 5,
    status: ESkillMatrixStatus.MUST_REVIEW_NEW_VERSION,
    title: 'Must Review New Version',
    color: 'tertiary'
  },
  {
    sortIndex: 6,
    status: ESkillMatrixStatus.REVOKED,
    title: 'Revoked',
    color: 'danger'
  }
];
