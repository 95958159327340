<div>
  <div class="lottery-machine__dice-container">
    <div
      class="lottery-machine__dice"
      [ngClass]="{ 'lottery-machine__dice--hidden': !isRolling }"
    >
      <app-dice #diceComponent />
    </div>
    <div
      class="lottery-machine__roll-btn"
      [ngClass]="{ 'lottery-machine__roll-btn--hidden': isRolling }"
    >
      <ion-buttons>
        <ion-button
          [disabled]="!this.lotteryTickets || isRolling"
          (click)="!isRolling && roll()"
          color="dark"
          fill="solid"
          expand="block"
        >
          {{ winners.length ? 'Get one more winner' : 'Roll' }}
        </ion-button>
      </ion-buttons>
    </div>
  </div>

  <div class="lottery-machine__winners">
    <app-lottery-machine-winner-item
      *ngFor="let winner of winners; let itemIndex = index; trackBy: trackItems"
      [winner]="winner"
      [animationOrder]="itemIndex"
    />
  </div>
</div>
