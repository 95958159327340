<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>{{ taskForAudit.title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <div
        class="ion-text-capitalize ion-padding last-complete"
        *ngIf="showLastComplete"
      >
        Last Complete:
        {{ taskForAudit.lastPerformance.performer.firstName }}
        {{ taskForAudit.lastPerformance.performer.lastName }} |
        {{ taskForAudit.lastPerformance.completeTime | date : 'h:mm a M/d/yy' }}
      </div>
      <div
        *ngFor="let criterion of taskForAudit.criteria"
        class="criterion-card ion-padding"
      >
        <div class="criterion-card__title">
          {{ criterion.title }}
        </div>
        <ion-segment
          [value]="criterion.value"
          (ionChange)="onValueChange(criterion, $event)"
        >
          <ion-segment-button value="TRUE">
            <ion-label>True</ion-label>
          </ion-segment-button>
          <ion-segment-button value="NOT_RESPONDED">
            <ion-label></ion-label>
          </ion-segment-button>
          <ion-segment-button value="FALSE">
            <ion-label>False</ion-label>
          </ion-segment-button>
        </ion-segment>
        <div *ngIf="criterion.value === 'FALSE'" class="criterion-card__editor">
          <app-wysiwyg-editor
            [(value)]="criterion.comment"
            [disableAttachments]="disableAttachments"
            bodyPlaceholder="Describe the changes needed to meet the criteria"
            toolbarMode="image-only"
            minHeight="0"
          />
        </div>
      </div>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              color="dark"
              expand="block"
              (click)="onDoneClick()"
              appHaptics
              [disabled]="!canDone"
              [disableHaptics]="!canDone"
            >
              Continue
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>
