import { Pipe, PipeTransform } from '@angular/core';
import { ITimezone } from '@backend/types/timezone';
import FuzzySearch from 'fuzzy-search';

@Pipe({
  name: 'filterTimezones',
  standalone: true
})
export class FilterTimezonesPipe implements PipeTransform {
  public transform(items: ITimezone[], filter: string): ITimezone[] {
    if (filter.length === 0) {
      return items;
    }
    const searcher = new FuzzySearch(
      items,
      [
        'standard.abbr',
        'daylight.abbr',
        'tzCode',
        'standard.name',
        'daylight.name'
      ],
      {
        caseSensitive: false
      }
    );
    return searcher.search(filter);
  }
}
