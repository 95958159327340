import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@app/modules/directives.module';

@Component({
  selector: 'app-tab-switch',
  templateUrl: './tab-switch.component.html',
  styleUrls: ['./tab-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule, DirectivesModule]
})
export class TabSwitchComponent {
  @Input()
  public readonly tabs?: { text: string; value: string }[] = [];

  @Input()
  public readonly value?: string = '';

  @Input()
  public readonly disabled?: boolean = false;

  @Output()
  public tabChange = new EventEmitter<string>();

  protected onChange(event: any, tabId: string) {
    event.preventDefault();

    if (this.value !== tabId) {
      this.tabChange.emit(tabId);
    }
  }
}
