import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IAttachment } from '@backend/models/types/attachment';

const IMAGE_OPEN_TAG = '<img src="';
const IMAGE_CLOSE_TAG = '">';

const attachmentIndexStr = (attachmentIndex: number) => {
  return `$[ATTACHMENT_INDEX=${attachmentIndex}]`;
};

const attachmentIdStr = (attachmentId: string) => {
  return `$[ATTACHMENT_ID=${attachmentId}]`;
};

const imageAttachmentStr = (
  url: string | undefined,
  serverUrl: string,
  filePath: string,
  attachmentId: string
) => {
  return `<img class="attachment--${attachmentId}" src="${
    url || `${serverUrl}/${filePath}`
  }">`;
};

export const replaceIndexesByIds = (
  body: string,
  indexes: { index: number; id: string }[]
) => {
  let resBody = body;
  indexes.map((i) => {
    resBody = resBody.replace(
      attachmentIndexStr(i.index),
      attachmentIdStr(i.id)
    );
  });
  return resBody;
};

export const prepareBodyForUpload = (
  body: string,
  prevAttachments: MongoStoredObject<IAttachment>[] = [],
  serverUrl: string
) => {
  let resBody = body || '';
  const dataImages: {
    attachmentIndex: number;
    dataImage: string;
    positionToInsert: number;
  }[] = [];

  const keptAttachments: string[] = [];
  prevAttachments.map((a) => {
    if (
      resBody.indexOf(
        imageAttachmentStr(a.url, serverUrl, a.filePath, a._id.toString())
      ) !== -1
    ) {
      keptAttachments.push(a._id.toString());
    }

    resBody = resBody.replace(
      imageAttachmentStr(a.url, serverUrl, a.filePath, a._id.toString()),
      attachmentIdStr(a._id.toString())
    );
  });

  let attachmentIndex = 0;
  while (resBody.indexOf(IMAGE_OPEN_TAG) !== -1) {
    const openIndex = resBody.indexOf(IMAGE_OPEN_TAG);
    const closeIndex =
      resBody.slice(openIndex).indexOf(IMAGE_CLOSE_TAG) + openIndex;

    if (closeIndex !== -1) {
      const dataImage = resBody.slice(
        openIndex + IMAGE_OPEN_TAG.length,
        closeIndex
      );
      dataImages.push({
        attachmentIndex,
        dataImage,
        positionToInsert: openIndex
      });

      resBody =
        resBody.slice(0, openIndex) +
        attachmentIndexStr(attachmentIndex) +
        resBody.slice(closeIndex + IMAGE_CLOSE_TAG.length);

      attachmentIndex++;
    }
  }

  return {
    body: resBody,
    dataImages,
    keptAttachments
  };
};

export const formatUploadedBody = (
  body: string,
  attachments: MongoStoredObject<IAttachment>[] = [],
  serverUrl: string
) => {
  let resBody = body || '';

  attachments.map((a) => {
    resBody = resBody.replace(
      attachmentIdStr(a._id.toString()),
      imageAttachmentStr(a.url, serverUrl, a.filePath, a._id.toString())
    );
  });

  resBody = resBody.replace(/\n/g, '<br>');

  return resBody;
};
