<div *ngIf="backBtn" class="login-back">
  <ion-button class="login-back-btn ion-no-margin" (click)="onBackBtnClick()">
    <ion-icon name="chevron-back-outline" />
    Back
  </ion-button>
</div>

<div class="login-graphic">
  <ion-icon
    name="heart"
    src="../../../assets/images/logo.svg"
    class="logo"
    [ngClass]="{ 'logo--small': backBtn }"
  ></ion-icon>
</div>

<div class="login-box">
  <ng-content></ng-content>
</div>
