import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { CommonModule } from '@angular/common';
import { FilterTimezonesPipe } from './filter-timezones.pipe';
import { FormsModule } from '@angular/forms';
import { ITimezone } from '@backend/types/timezone';
import { DirectivesModule } from '@app/modules/directives.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-timezone-selector',
  templateUrl: './timezone-selector.component.html',
  styleUrls: ['./timezone-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    WidthRestrictedContainerModule,
    FilterTimezonesPipe,
    FormsModule,
    DirectivesModule,
    ScrollingModule
  ]
})
export class TimezoneSelectorComponent implements OnInit {
  @Input()
  public readonly timezones: ITimezone[];

  @Input()
  public readonly initialTzCode: string;

  @Output()
  public selectionCancel = new EventEmitter<void>();

  @Output()
  public selectionChange = new EventEmitter<ITimezone>();

  protected cdkItemSize = 69;
  protected selectedTimezoneId: string;
  public filter = '';

  public ngOnInit(): void {
    this.selectedTimezoneId = this.initialTzCode;
  }

  protected trackItems(_: number, item: ITimezone) {
    return item.tzCode;
  }

  protected cancelChanges() {
    this.selectionCancel.emit();
  }

  protected confirmChanges() {
    this.selectionChange.emit(
      this.timezones.find((t) => t.tzCode === this.selectedTimezoneId)
    );
  }

  protected checkboxClick(event: any, value: ITimezone) {
    event.preventDefault();

    this.selectedTimezoneId = value.tzCode;
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected getFirst() {
    if (!this.filter) return null;

    const timezones = new FilterTimezonesPipe().transform(
      this.timezones,
      this.filter
    );

    if (timezones.length > 0) {
      return timezones[0];
    }

    return null;
  }

  protected selectFirst() {
    const first = this.getFirst();

    if (first) {
      this.selectedTimezoneId = first.tzCode;

      this.confirmChanges();
    }
  }
}
