import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonPopover } from '@ionic/angular';
import { endOfDay, startOfDay } from 'date-fns';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateSelectorComponent
    }
  ]
})
export class DateSelectorComponent implements ControlValueAccessor {
  @Input()
  public label: string;

  @Input()
  public endOfDay = false;

  @Input()
  public min?: string;

  @Input()
  public max?: string;

  @ViewChild('popover')
  protected popover: IonPopover;

  protected isOpen = false;

  protected onDateButtonClick(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  protected value: Date | null;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onChange = (value: Date) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouched = () => {};

  private _touched = false;

  protected disabled = false;

  public constructor(private _cdr: ChangeDetectorRef) {}

  public writeValue(value: Date) {
    this.value = value;
    this._cdr.markForCheck();
  }

  public registerOnChange(onChange: any) {
    this._onChange = onChange;
  }

  public registerOnTouched(onTouched: any) {
    this._onTouched = onTouched;
  }

  public markAsTouched() {
    if (!this._touched) {
      this._onTouched();
      this._touched = true;
    }
  }

  public setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  protected onCalendarChange(event: any) {
    if (event.detail.value && typeof event.detail.value === 'string') {
      let value = startOfDay(new Date(event.detail.value as string));
      if (this.endOfDay) {
        value = endOfDay(value);
      }
      this.value = value;
      this._touched = true;
      this._onTouched();
      this._onChange(this.value);
      this.isOpen = false;
    }
  }
}
