<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Lottery</ion-title>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-title>{{ rangeStr }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <app-lottery-machine [lotteryTickets]="lotteryTickets" />
  </app-width-restricted-container>
</ion-content>
