import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import { ScheduleFormBaseComponent } from '../shcedule-form-base.component';
import { HapticsService } from '@app/core/service/haptics.service';

@Component({
  selector: 'app-schedule-never-form',
  templateUrl: './schedule-never-form.component.html',
  styleUrls: ['./schedule-never-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleNeverFormComponent extends ScheduleFormBaseComponent {
  @ViewChild('popover')
  protected popover: IonPopover;

  protected isOpen = false;

  public constructor(private readonly _hapticsService: HapticsService) {
    super();
  }

  protected onDateButtonClick(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  protected onDateChange({ detail: { value } }: any): void {
    this._hapticsService.hapticsImpact();

    this.form.controls.date.setValue(value);
    this.form.controls.date.markAsDirty();
    this.isOpen = false;
  }
}
