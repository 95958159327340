import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OpportunityModel, SolutionModel } from '../model/suggestion.model';
import { ESuggestionStatus } from '@backend/models/types/suggestion';

@Injectable()
export class SuggestionApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getPublicOpportunities(): Observable<OpportunityModel[]> {
    return this._httpClient.get<OpportunityModel[]>(
      '/suggestions/opportunities/public'
    );
  }

  public getAllOpportunities(): Observable<OpportunityModel[]> {
    return this._httpClient.get<OpportunityModel[]>(
      '/suggestions/opportunities'
    );
  }

  public postOpportunity(data: {
    isPublic?: boolean;
    body: string;
    attachments: string[];
    status?: ESuggestionStatus;
    author?: string;
    authorName?: string;
    tenant: string;
  }): Observable<OpportunityModel> {
    return this._httpClient.post<OpportunityModel>(
      '/suggestions/opportunities',
      data
    );
  }

  public updateOpportunity(
    opportunityId: string,
    data: {
      body: string;
      attachments: string[];
      isPublic: boolean;
      status: ESuggestionStatus;
      author?: string;
    }
  ): Observable<OpportunityModel> {
    return this._httpClient.put<OpportunityModel>(
      `/suggestions/opportunities/${opportunityId}`,
      data
    );
  }

  public postSolution(
    opportunityId: string,
    data: {
      isPublic: boolean;
      body: string;
      attachments: string[];
    }
  ): Observable<OpportunityModel> {
    return this._httpClient.post<OpportunityModel>(
      `/suggestions/opportunities/${opportunityId}/solutions`,
      data
    );
  }

  public updateSolution(
    opportunityId: string,
    solutionId: string,
    data: {
      isPublic: boolean;
    }
  ): Observable<OpportunityModel> {
    return this._httpClient.put<OpportunityModel>(
      `/suggestions/opportunities/${opportunityId}/solutions/${solutionId}`,
      data
    );
  }

  public getPublicSolutionsOfOpportunity(
    opportunityId: string
  ): Observable<SolutionModel[]> {
    return this._httpClient.get<SolutionModel[]>(
      `/suggestions/opportunities/${opportunityId}/solutions/public`
    );
  }

  public getAllSolutionsOfOpportunity(
    opportunityId: string
  ): Observable<SolutionModel[]> {
    return this._httpClient.get<SolutionModel[]>(
      `/suggestions/opportunities/${opportunityId}/solutions`
    );
  }

  public deleteOpportunity(opportunityId: string): Observable<null> {
    return this._httpClient.delete<null>(
      `/suggestions/opportunities/${opportunityId}`
    );
  }

  public deleteSolution(
    opportunityId: string,
    solutionId: string
  ): Observable<null> {
    return this._httpClient.delete<null>(
      `/suggestions/opportunities/${opportunityId}/solutions/${solutionId}`
    );
  }
}
