<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="close()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title>Notifications</ion-title>
    <ion-buttons slot="end">
      <ion-button
        (click)="save()"
        color="primary"
        appHaptics
        [disabled]="result.length === 0"
        [disableHaptics]="result.length === 0"
      >
        Save
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-common-background">
    <ion-searchbar (ionInput)="searchbarInput($event)"></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="table-head" *ngIf="tasks.length">
    <div class="table-head-col">Task</div>
    <div class="table-head-col">Notify upon completion</div>
  </div>

  <ion-list *ngIf="tasks.length">
    <ion-item [disabled]="filter.length > 0" [detail]="false">
      <ion-label class="task-name"> All Tasks </ion-label>
      <div slot="end" class="task-checkboxes">
        <ion-checkbox
          aria-label="Notify upon completion"
          [checked]="isAllNotificationChecked"
          [indeterminate]="isNotificationIndeterminate"
          (ionChange)="allNotificationChange($event)"
          appHaptics
          [disableHaptics]="filter.length > 0"
        >
        </ion-checkbox>
      </div>
    </ion-item>
    <ion-item
      *ngFor="let task of tasks | filterTasks : filter; trackBy: trackItems"
    >
      <ion-label class="task-name ion-text-capitalize">
        {{ task.title }}
      </ion-label>
      <div slot="end" class="task-checkboxes">
        <ion-checkbox
          aria-label="Notify upon completion"
          [checked]="task.isNotificationUponCompletion"
          (ionChange)="onNotificationChange($event, task)"
          appHaptics
        >
        </ion-checkbox>
      </div>
    </ion-item>
  </ion-list>
</ion-content>
