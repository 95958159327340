import { Injectable } from '@angular/core';
import { PatchInput, TenantApiService } from './tenant-api.service';
import { UserEntityService } from './user-entity.service';
import {
  Subject,
  distinctUntilChanged,
  map,
  repeat,
  shareReplay,
  switchMap,
  take
} from 'rxjs';
import { ObjectId } from '../../types/object-id';
import { ITenant } from '@backend/models/types/tenant';
import { ToastService } from './toast.service';

@Injectable({ providedIn: 'root' })
export class TenantService {
  protected refetchTenant = false;

  private readonly _update$ = new Subject<void>();

  public readonly tenant$ = this._user.user$.pipe(
    map(({ tenant }) => tenant),
    distinctUntilChanged((prev, cur) => {
      if (this.refetchTenant) return false;

      return prev.toString() === cur.toString();
    }),
    switchMap((tenant) => {
      this.refetchTenant = false;

      return this._api
        .getById(tenant)
        .pipe(repeat({ delay: () => this._update$ }));
    }),
    shareReplay(1)
  );

  public constructor(
    private readonly _api: TenantApiService,
    private readonly _user: UserEntityService,
    private readonly _toastService: ToastService
  ) {}

  public update(): void {
    this.refetchTenant = true;
    this._user.update();
  }

  public setBehaviorPurpose(
    purpose: keyof ITenant['behaviors'],
    behaviorId: ObjectId | null
  ) {
    this.tenant$
      .pipe(
        take(1),
        switchMap((tenant) =>
          this._api.setBehaviorPurpose(tenant._id, purpose, behaviorId)
        )
      )
      .subscribe({ next: () => this._update$.next() });
  }

  public patch(data: PatchInput) {
    this._api.patch(data).subscribe({
      next: () => this._update$.next(),
      error: (e: { error: { message: string } }) => {
        console.log('[ERR]', e);
        this._toastService.presentToast(
          e.error?.message || 'Unable to change the tenant settings'
        );
      }
    });
  }
}
