import { NgModule } from '@angular/core';
import { PullToSearchComponent } from './pull-to-search.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';

@NgModule({
  imports: [CommonModule, IonicModule, WidthRestrictedContainerModule],
  exports: [PullToSearchComponent],
  declarations: [PullToSearchComponent]
})
export class PullToSearchModule {}
