import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { IonNav, LoadingController, ViewDidEnter } from '@ionic/angular';
import { AuthenticationService, ROLES } from '@app/core';
import { formatUploadedBody } from '@app/utils/wysiwyg-editor';
import { EnvironmentService } from '@app/core/service/environment.service';
import { SuggestionAddComponent } from '../suggestion-add/suggestion-add.component';
import {
  OpportunityModel,
  SolutionModel
} from '@app/core/model/suggestion.model';
import { SuggestionApiService } from '@app/core/service/suggestion-api.service';
import { ObjectId } from '@app/types/object-id';

@Component({
  selector: 'app-suggestion-view',
  templateUrl: './suggestion-view.component.html',
  styleUrls: ['./suggestion-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuggestionViewComponent implements OnInit, ViewDidEnter {
  @Input()
  public suggestion: OpportunityModel = null;

  protected isAdmin = false;
  protected body = '';
  protected serverUrl = '';

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService,
    private readonly _environmentService: EnvironmentService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _suggestionApiService: SuggestionApiService,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this.serverUrl = this._environmentService.serverUrl;
    this.isAdmin = this._authenticationService.user.roles.includes(
      ROLES.adminName
    );
  }

  public ngOnInit(): void {
    this.body = formatUploadedBody(
      this.suggestion.body,
      this.suggestion.attachments,
      this.serverUrl
    );
  }

  public async ionViewDidEnter() {
    const topLoading = await this._loadingCtrl.getTop();
    if (topLoading) {
      topLoading.dismiss();

      (this.isAdmin
        ? this._suggestionApiService.getAllSolutionsOfOpportunity(
            this.suggestion._id
          )
        : this._suggestionApiService.getPublicSolutionsOfOpportunity(
            this.suggestion._id
          )
      ).subscribe((solutions) => {
        this.suggestion.solutions = solutions;
        this._cdr.markForCheck();
      });
    }
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }

  protected editDoc() {
    this._ionNav.push(SuggestionAddComponent, {
      suggestion: this.suggestion
    });
  }

  protected get isMine() {
    return (
      !!this.suggestion &&
      (this.suggestion.author
        ? this.suggestion.author._id.toString() ===
          this._authenticationService.user._id.toString()
        : false)
    );
  }

  protected trackItems(_: number, item: SolutionModel) {
    return item._id.toString();
  }

  protected addSolution() {
    this._ionNav.push(SuggestionAddComponent, {
      parentId: this.suggestion._id.toString()
    });
  }

  protected solutionDeleted(solutionId: ObjectId) {
    this.suggestion.solutions = this.suggestion.solutions.filter(
      (s) => s._id.toString() !== solutionId.toString()
    );
  }

  protected get authorName() {
    return (
      (this.suggestion.author
        ? `${this.suggestion.author.firstName} ${this.suggestion.author.lastName}`
        : this.suggestion.authorName) || 'Anonymous'
    );
  }
}
