import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskAudit } from '@backend/models/types/task-audit';
import { Observable } from 'rxjs';

@Injectable()
export class TaskAuditApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public submitTaskAudit(input: {
    auditTask: string;
    tasksForAudit: {
      title: string;
      lastPerformance?: {
        taskPerformance: string;
        performer: string;
      };
      criteria: {
        title: string;
        value: boolean;
        comment: string;
        attachments: string[];
      }[];
    }[];
  }): Observable<MongoStoredObject<ITaskAudit>> {
    return this._httpClient.post<MongoStoredObject<ITaskAudit>>(
      '/task-audit',
      input
    );
  }
}
