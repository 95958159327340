<div class="tag-item-title">
  <div class="tag-item-number">
    <b>#{{ tag.tagNumber }}</b>
  </div>
  <div class="tag-item-statuses">
    <div class="tag-item-status" *ngIf="status">
      <ion-chip class="ion-no-margin" [color]="status.color">
        {{ status.name }}
      </ion-chip>
    </div>
  </div>
</div>

<div class="tag-item-body">
  <div *ngIf="tag.customerCell">
    Customer Cell: <b>{{ tag.customerCell }}</b>
  </div>
  <div *ngIf="tag.customerName" class="ion-text-capitalize">
    Customer Name: <b>{{ tag.customerName }}</b>
  </div>
  <div class="ion-text-capitalize">
    {{ pickupDateFieldName }}: <b>{{ tag.pickupDate }}</b>
  </div>
</div>
