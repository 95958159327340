import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITenant, ITenantFeatures } from '@backend/models/types/tenant';
import { IonNav, LoadingController } from '@ionic/angular';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TenantListService } from '@app/core/service/tenant-list.service';
import { LocalStorageService } from '@app/core';
import { TenantService } from '@app/core/service/tenant.service';

@Component({
  selector: 'app-features-users',
  templateUrl: './tenant-features.component.html',
  styleUrls: ['./tenant-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TenantFeaturesComponent implements OnInit {
  @Input()
  public tenant: MongoStoredObject<ITenant>;

  protected myTenant: MongoStoredObject<ITenant>;
  protected mainFormGroup: FormGroup = new FormGroup({
    checklist: new FormControl<boolean>(false, Validators.required),
    actionsUponTaskCompletion: new FormControl<boolean>(
      false,
      Validators.required
    ),
    requestTaskFeedbackAttachments: new FormControl<boolean>(
      false,
      Validators.required
    ),
    leaveTaskNoteAttachments: new FormControl<boolean>(
      false,
      Validators.required
    ),
    timeAttack: new FormControl<boolean>(false, Validators.required),
    auditTasks: new FormControl<boolean>(false, Validators.required),
    auditAttachments: new FormControl<boolean>(false, Validators.required),
    tags: new FormControl<boolean>(false, Validators.required),
    announcements: new FormControl<boolean>(false, Validators.required),
    survey: new FormControl<boolean>(false, Validators.required),
    surveyFeedback: new FormControl<boolean>(false, Validators.required),
    insights: new FormControl<boolean>(false, Validators.required),
    dailyReport: new FormControl<boolean>(false, Validators.required),
    multiUserMode: new FormControl<boolean>(false, Validators.required),
    announcementAttachments: new FormControl<boolean>(
      false,
      Validators.required
    ),
    taskAttachments: new FormControl<boolean>(false, Validators.required),
    connectedAccounts: new FormControl<boolean>(false, Validators.required),
    calculateRewards: new FormControl<boolean>(false, Validators.required),
    lottery: new FormControl<boolean>(false, Validators.required),
    library: new FormControl<boolean>(false, Validators.required),
    libraryDocAttachments: new FormControl<boolean>(false, Validators.required),
    userSchedule: new FormControl<boolean>(false, Validators.required),
    askTodaysShiftTime: new FormControl<boolean>(false, Validators.required),
    instantFeedback: new FormControl<boolean>(false, Validators.required),
    suggestions: new FormControl<boolean>(false, Validators.required),
    suggestionAttachments: new FormControl<boolean>(false, Validators.required)
  });

  public constructor(
    private readonly _ionNav: IonNav,
    private readonly _loadingCtrl: LoadingController,
    private readonly _tenantListService: TenantListService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.myTenant = tenant;
    });
  }

  public ngOnInit(): void {
    this.mainFormGroup.reset(this.tenant.features);
  }

  protected async onFeatureToggle(
    {
      detail: { checked }
    }: {
      detail: { checked: boolean };
    },
    feature: keyof ITenantFeatures
  ) {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    // change passcodeRedirectsTo if corresponding feature is disabled
    if (
      this.tenant._id.toString() === this.myTenant._id.toString() &&
      !checked
    ) {
      const passcodeRedirectsTo = this._localStorageService.getItem(
        'passcodeRedirectsTo'
      );
      if (passcodeRedirectsTo && typeof passcodeRedirectsTo === 'string') {
        const passcodeRedirectsToSplitted = passcodeRedirectsTo.split('/');
        const redirectTo =
          passcodeRedirectsToSplitted[passcodeRedirectsToSplitted.length - 1];

        if (feature === redirectTo) {
          this._localStorageService.setItem(
            'passcodeRedirectsTo',
            '/i/recognition'
          );
        }
      }
    }

    this._tenantListService
      .patchTenantById(this.tenant._id, {
        features: { ...this.mainFormGroup.value, [feature]: checked }
      })
      .subscribe(() => {
        loading.dismiss();

        if (this.tenant._id.toString() === this.myTenant._id.toString()) {
          this._tenantService.update();
        }
      });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }
}
