import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DateInterval } from '@backend/types/date-interval';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { PerformanceWithUser } from '@app/types/performance-with-user';
import {
  ITaskPerformance,
  TaskPerformanceBonus
} from '@backend/models/types/task-performance';
import { ITask } from '@backend/models/types/task';
import { UserModel } from '../model/user.model';

@Injectable({ providedIn: 'root' })
export class TaskPerformanceApiService {
  public constructor(private _httpClient: HttpClient) {}

  public getTaskPerformance(
    interval: DateInterval
  ): Observable<MongoStoredObject<PerformanceWithUser>[]> {
    let params = {} as Record<string, string>;
    for (const key in interval) {
      params = {
        ...params,
        [key]: (interval as unknown as { [key: string]: Date })[
          key
        ].toISOString()
      };
    }
    return this._httpClient.get<MongoStoredObject<PerformanceWithUser>[]>(
      '/task-performance',
      { params }
    );
  }

  public patchTaskPerformances(
    taskId: string,
    performances: {
      _id: string;
      user: string;
      body: {
        isDeleted?: boolean;
        completeTime?: string;
        quantity?: number;
        timeAttackBonusPoints?: number;
      };
      isNew: boolean;
    }[]
  ): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.patch<MongoStoredObject<ITaskPerformance>>(
      '/task-performance',
      {
        taskId,
        performances
      }
    );
  }

  public cancelTaskPerformance(
    taskPerformanceId: string
  ): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.post<MongoStoredObject<ITaskPerformance>>(
      `/task-performance/${taskPerformanceId}/cancel`,
      {}
    );
  }

  public stopTaskPerformance(
    taskPerformanceId: string,
    taskAppearTime: string,
    quantity?: number,
    completeTime?: string
  ): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.post<MongoStoredObject<ITaskPerformance>>(
      `/task-performance/${taskPerformanceId}/stop`,
      {
        taskAppearTime,
        quantity,
        completeTime
      }
    );
  }

  public approveTaskPerformance(
    taskPerformanceId: string,
    bonus?: TaskPerformanceBonus,
    isFasterThanAverage?: boolean,
    startTime?: string,
    completeTime?: string
  ): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.post<MongoStoredObject<ITaskPerformance>>(
      `/task-performance/${taskPerformanceId}/approve`,
      { bonus, isFasterThanAverage, startTime, completeTime }
    );
  }

  public getWaitingForApprovalTaskPerformances(): Observable<
    MongoStoredObject<
      ITaskPerformance & {
        user: UserModel;
        task: MongoStoredObject<ITask>;
      }
    >[]
  > {
    return this._httpClient.get<
      MongoStoredObject<
        ITaskPerformance & {
          user: UserModel;
          task: MongoStoredObject<ITask>;
        }
      >[]
    >('/task-performance/waiting-for-approval');
  }

  public acknowledgeTaskPerformanceNote(
    taskPerformanceId: string,
    forAllUsers = false
  ): Observable<MongoStoredObject<ITaskPerformance>> {
    return this._httpClient.post<MongoStoredObject<ITaskPerformance>>(
      `/task-performance/${taskPerformanceId}/acknowledge-note`,
      { forAllUsers }
    );
  }
}
