import {
  Component,
  Input,
  ViewChild,
  forwardRef,
  HostListener,
  HostBinding
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IonInput } from '@ionic/angular';

@Component({
  selector: 'app-inline-input',
  templateUrl: './inline-input.component.html',
  styleUrls: ['./inline-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InlineInputComponent),
      multi: true
    }
  ]
})
export class InlineInputComponent implements ControlValueAccessor {
  @Input()
  public readonly type?: 'text' | 'number' = 'text';

  @Input()
  public readonly appendText?: string = '';

  @Input()
  public readonly appendTextPlural?: string = '';

  @Input()
  public readonly placeholder?: string = '';

  @Input()
  public readonly disabled?: boolean = false;

  @ViewChild('input')
  public input!: IonInput;

  @HostListener('click')
  protected onClick(): void {
    this.input.setFocus();
  }

  @HostBinding('class.disabled')
  get isClickable() {
    return this.disabled;
  }

  protected innerValue = '';

  protected get size() {
    return this.innerValue && this.innerValue.length > 0
      ? this.innerValue.length
      : this.placeholder.length;
  }

  protected get appendedText() {
    return this.innerValue
      ? this.type === 'number' &&
        Number(this.innerValue) > 1 &&
        this.appendTextPlural
        ? this.appendTextPlural
        : this.appendText
      : this.appendTextPlural || this.appendText;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  private _onChange = (value: string) => {};

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private _onTouched = () => {};

  public writeValue(value: string) {
    this.innerValue = value ? value.toString() : '';
  }

  public registerOnChange(onChange: (v: string) => void) {
    this._onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void) {
    this._onTouched = onTouched;
  }

  protected valueChange() {
    this._onChange(this.input.value.toString());
  }
}
