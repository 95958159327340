import { Component, OnInit } from '@angular/core';
import { UserApiService } from '@app/core';
import { AuthenticationService } from '@app/core';
import { ToastService } from '@app/core/service/toast.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { UserEntityService } from '@app/core/service/user-entity.service';
import fastDeepEqual from 'fast-deep-equal';
import { EUserScheduleType } from '@backend/models/types/user';
import {
  defaultScheduleFormGroup,
  defaultWeekTimeFormGroup
} from '@app/utils/user-schedule';
import { UserModel } from '@app/core/model/user.model';
import { TenantService } from '@app/core/service/tenant.service';
import { ITenantFeatures } from '@backend/models/types/tenant';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public readonly userScheduleType = EUserScheduleType;

  protected emailRegex = /^\S+@\S+\.\S+$/;
  protected weekTimeFormGroup: FormGroup = new FormGroup(
    defaultWeekTimeFormGroup
  );
  protected scheduleFormGroup: FormGroup = new FormGroup(
    defaultScheduleFormGroup
  );
  protected mainFormGroup: FormGroup = new FormGroup({
    firstName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('[a-zA-Z -]*')
    ]),
    lastName: new FormControl<string>('', [
      Validators.required,
      Validators.pattern('[a-zA-Z -]*')
    ]),
    email: new FormControl<string>('', [
      Validators.required,
      Validators.pattern(this.emailRegex)
    ]),
    receivePNs: new FormControl<boolean>(true, Validators.required)
  });
  protected user: UserModel | null;
  protected tenantFeatures: Pick<ITenantFeatures, 'userSchedule'> = {
    userSchedule: false
  };

  public constructor(
    private readonly _userApiService: UserApiService,
    private readonly _authenticationService: AuthenticationService,
    private readonly _toastService: ToastService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _userEntityService: UserEntityService,
    private readonly _tenantService: TenantService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.tenantFeatures = tenant.features;
    });
  }

  protected get firstNameError() {
    if (
      this.mainFormGroup.get('firstName').dirty &&
      this.mainFormGroup.get('firstName').errors
    ) {
      if (this.mainFormGroup.get('firstName').errors.required) {
        return 'First name is required';
      } else if (this.mainFormGroup.get('firstName').errors.pattern) {
        return 'First name contains invalid characters';
      }
    }
    return '';
  }

  protected get lastNameError() {
    if (
      this.mainFormGroup.get('lastName').dirty &&
      this.mainFormGroup.get('lastName').errors
    ) {
      if (this.mainFormGroup.get('lastName').errors.required) {
        return 'Last name is required';
      } else if (this.mainFormGroup.get('lastName').errors.pattern) {
        return 'Last name contains invalid characters';
      }
    }
    return '';
  }

  protected get emailError() {
    if (
      this.mainFormGroup.get('email').dirty &&
      this.mainFormGroup.get('email').errors
    ) {
      if (this.mainFormGroup.get('email').errors.required) {
        return 'Email is required';
      } else if (this.mainFormGroup.get('email').errors.pattern) {
        return 'Wrong email format';
      }
    }
    return '';
  }

  protected get canSave() {
    const isValid =
      this.mainFormGroup.valid &&
      this.scheduleFormGroup.valid &&
      this.weekTimeFormGroup.valid &&
      this.user.schedule;
    const mainDataChanged = !fastDeepEqual(this.mainFormGroup.value, {
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      email: this.user.email,
      receivePNs: this.user.receivePNs
    });
    const scheduleDataChanged = !fastDeepEqual(this.scheduleFormGroup.value, {
      scheduleType: this.user.schedule.scheduleType,
      weekDays: this.user.schedule.weekDays
    });
    const weekTimeDataChanged = !fastDeepEqual(
      Object.values(this.weekTimeFormGroup.value),
      this.user.schedule.weekTime.map((time) => ({
        from: time.from,
        to: time.to
      }))
    );

    return (
      isValid && (mainDataChanged || scheduleDataChanged || weekTimeDataChanged)
    );
  }

  public ngOnInit() {
    this._loadInfo();
  }

  protected _loadInfo() {
    this.mainFormGroup.reset({
      firstName: this._authenticationService.user.firstName,
      lastName: this._authenticationService.user.lastName,
      email: this._authenticationService.user.email,
      receivePNs: this._authenticationService.user.receivePNs
    });

    if (this._authenticationService.user.schedule) {
      this.scheduleFormGroup.reset({
        scheduleType: this._authenticationService.user.schedule.scheduleType,
        weekDays: this._authenticationService.user.schedule.weekDays
      });
      this.weekTimeFormGroup.reset(
        this._authenticationService.user.schedule.weekTime
      );
    } else {
      this._authenticationService.setUser({
        ...this._authenticationService.user,
        schedule: {
          ...this.scheduleFormGroup.value,
          weekTime: Object.values(this.weekTimeFormGroup.value)
        }
      });
    }

    this.user = this._authenticationService.user;
  }

  protected async submit() {
    const loading = await this._loadingCtrl.create({
      message: 'Saving...'
    });
    loading.present();

    const newMainValue = this.mainFormGroup.value;
    const newScheduleValue = this.scheduleFormGroup.value;

    this._userApiService
      .editUser(this.user._id, {
        ...newMainValue,
        email: newMainValue.email.toLowerCase(),
        schedule: {
          ...newScheduleValue,
          weekTime: Object.values(this.weekTimeFormGroup.value)
        }
      })
      .subscribe({
        next: () => {
          this._loadInfo();
          this._userEntityService.update();
          loading.dismiss();
          this._toastService.presentToast('Changes saved successfully!');
        },
        error: () => {
          loading.dismiss();
          this._toastService.presentToast(
            'There was an error while updating the user'
          );
        }
      });
  }
}
