<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Insights</ion-title>
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button id="popover-button" appHaptics>
        <ion-icon slot="icon-only" name="options-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar
    class="app-toolbar toolbar-range"
    [ngClass]="{ 'toolbar-range--show': showRange }"
  >
    <ion-buttons>
      <ion-button
        *ngIf="range"
        color="primary"
        class="toolbar-range-button"
        (click)="onRangeClick()"
        appHaptics
      >
        <ng-container *ngIf="isOneDayRange; else rangeTwoDays">
          {{ range.start | date : 'MMM d, y' }}
        </ng-container>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container>
    <ion-buttons class="ion-justify-content-center insights-actions">
      <ion-button
        fill="outline"
        color="primary"
        size="small"
        shape="round"
        (click)="onCopyDataClick()"
        appHaptics
      >
        <ion-icon slot="start" name="copy-outline"></ion-icon>
        Copy Data
      </ion-button>
      <ion-button
        *ngIf="isAdmin && tenantFeatures.lottery"
        fill="outline"
        color="primary"
        size="small"
        shape="round"
        (click)="onLotteryClick()"
        appHaptics
      >
        <ion-icon slot="start" name="ticket-outline"></ion-icon>
        Lottery
      </ion-button>
      <ion-button
        *ngIf="isAdmin && tenantFeatures.calculateRewards"
        fill="outline"
        color="primary"
        size="small"
        shape="round"
        (click)="onCalculateRewardsClick()"
        appHaptics
      >
        <ion-icon slot="start" name="calculator-outline"></ion-icon>
        Calculate Rewards
      </ion-button>
    </ion-buttons>

    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="onSortChange($event)"
      matSortStart="desc"
    >
      <ng-container matColumnDef="fullName">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-cell"
          appHaptics
        >
          Name
        </th>
        <td mat-cell *matCellDef="let data">
          {{ data.fullName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="tasksCompleted">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-cell"
          appHaptics
        >
          Task<br />Points
        </th>
        <td
          mat-cell
          *matCellDef="let data"
          (click)="isAdminOrManager && onTaskCompletedClick(data)"
          [ngClass]="{ 'cell-clickable': isAdminOrManager }"
          appHaptics
          [disableHaptics]="!isAdminOrManager"
        >
          <div class="numeric-cell-container">
            <div
              class="chart-bar"
              [style.width.%]="data.tasksCompletedShare"
            ></div>
            <div class="cell-value">
              {{ data.tasksCompleted }}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="pointsReceived">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-cell"
          appHaptics
        >
          Praise<br />Received
        </th>
        <td
          mat-cell
          *matCellDef="let data"
          (click)="isAdminOrManager && onPraiseReceivedClick(data)"
          [ngClass]="{ 'cell-clickable': isAdminOrManager }"
          appHaptics
          [disableHaptics]="!isAdminOrManager"
        >
          <div class="numeric-cell-container">
            <div
              class="chart-bar"
              [style.width.%]="data.pointsReceivedShare"
            ></div>
            <div class="cell-value">
              {{ data.pointsReceived }}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="pointsSent">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-cell"
          appHaptics
        >
          Praise<br />Sent
        </th>
        <td
          mat-cell
          *matCellDef="let data"
          (click)="isAdminOrManager && onPraiseSentClick(data)"
          [ngClass]="{ 'cell-clickable': isAdminOrManager }"
          appHaptics
          [disableHaptics]="!isAdminOrManager"
        >
          <div class="numeric-cell-container">
            <div class="chart-bar" [style.width.%]="data.pointsSentShare"></div>
            <div class="cell-value">
              {{ data.pointsSent }}
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="pointsSum">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          class="header-cell"
        >
          Points
        </th>
        <td mat-cell *matCellDef="let data">
          <div class="numeric-cell-container">
            <div class="chart-bar" [style.width.%]="data.pointsSumShare"></div>
            <div class="cell-value">
              {{ data.pointsSum }}
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
    </table>
  </app-width-restricted-container>
</ion-content>

<ion-popover trigger="popover-button">
  <ng-template>
    <ion-content>
      <div class="filter-header">Show Only</div>
      <ion-list lines="none">
        <ion-item>
          <ion-checkbox
            labelPlacement="end"
            justify="start"
            [checked]="fTasksCompleted"
            (ionChange)="onFilterChange($event, 'tasksCompleted')"
            appHaptics
          >
            Task Points
          </ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-checkbox
            labelPlacement="end"
            justify="start"
            [checked]="fPointsReceived"
            (ionChange)="onFilterChange($event, 'pointsReceived')"
            appHaptics
          >
            Praise Received
          </ion-checkbox>
        </ion-item>
        <ion-item lines="full">
          <ion-checkbox
            labelPlacement="end"
            justify="start"
            [checked]="fPointsSent"
            (ionChange)="onFilterChange($event, 'pointsSent')"
            appHaptics
          >
            Praise Sent
          </ion-checkbox>
        </ion-item>
        <ion-item>
          <ion-toggle
            [checked]="lastNameFirst"
            (ionChange)="onLastNameFirstChange($event)"
            appHaptics
          >
            Name Order: Last, First
          </ion-toggle>
        </ion-item>
        <ion-item>
          <ion-toggle
            [checked]="!splitCategories"
            (ionChange)="onSplitCategoriesChange($event)"
            appHaptics
          >
            Summarize Points
          </ion-toggle>
        </ion-item>
      </ion-list>
    </ion-content>
  </ng-template>
</ion-popover>

<ng-template #rangeTwoDays>
  {{ range.start | date : 'MMM d, y' }} -
  {{ range.end | date : 'MMM d, y' }}
</ng-template>
