import { NgModule } from '@angular/core';
import { PinCodeComponent } from './pin-code.component';
import { RouterModule, Routes } from '@angular/router';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { DirectivesModule } from '@app/modules/directives.module';
import { PasscodeApiService } from '@app/core/service/passcode-api.service';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'PIN',
      rootComponent: PinCodeComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    DirectivesModule,
    UserSelectorComponent
  ],
  declarations: [PinCodeComponent],
  providers: [PasscodeApiService]
})
export class PinCodeModule {}
