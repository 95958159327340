<div [ngClass]="{ 'has-error': hasError() }">
  <ng-content></ng-content>
  <ng-container *ngFor="let errorKey of errorKeys">
    <span
      *ngIf="control.hasError(errorKey) && control.touched"
      class="input-error"
    >
      {{ formateError(errorObject[errorKey], control.errors[errorKey]) }}
    </span>
  </ng-container>
</div>
