import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication';
import { LocalStorageService } from '../local-storage.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';
import { IfVisible } from '@root/src/client/static_modules/@rosskevin/ifvisible';

const WHITELISTED_IDLE_PAGES = ['/login', '/pin', '/404'];
const DEFAULT_PASSCODE_TIMEOUT = 60;
const IFVISIBLE_KEY = 'ifvisible_passcodeTimeout';

@Injectable()
export class PasscodeTimeoutService {
  private _multiUserModeLogoutTimeout: any = null;
  private _timeToIdle$ = new BehaviorSubject(DEFAULT_PASSCODE_TIMEOUT);
  private _timeToIdleLeft$ = new BehaviorSubject(this._timeToIdle$.value);

  public timeToIdle$ = this._timeToIdle$.asObservable();
  public timeToIdleLeft$ = this._timeToIdleLeft$.asObservable();

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router,
    private readonly _toastService: ToastService
  ) {
    (window as any)[IFVISIBLE_KEY] = new IfVisible(window, document);

    (window as any)[IFVISIBLE_KEY].on('idle', () => {
      const multiUserMode =
        !!this._localStorageService.getItem('multiUserMode');

      if (multiUserMode && !WHITELISTED_IDLE_PAGES.includes(this._router.url)) {
        if (this._timeToIdleLeft$.value < 2) {
          this._authenticationService.logout({ navigateTo: 'pin' });
        } else {
          this._multiUserModeLogoutTimeout = setTimeout(() => {
            this._authenticationService.logout({ navigateTo: 'pin' });
          }, this._timeToIdle$.value * 1000);
        }
      }

      this._timeToIdleLeft$.next(0);
    });

    (window as any)[IFVISIBLE_KEY].on('wakeup', () => {
      this._timeToIdleLeft$.next(this._timeToIdle$.value);

      if (this._multiUserModeLogoutTimeout) {
        clearTimeout(this._multiUserModeLogoutTimeout);
      }
    });

    (window as any)[IFVISIBLE_KEY].onEvery(2, () => {
      const idleInfo = (window as any)[IFVISIBLE_KEY].getIdleInfo();

      this._timeToIdleLeft$.next(idleInfo.timeLeft / 1000);
    });
  }

  public initPasscodeTimeout() {
    let passcodeTimeout = parseFloat(
      this._localStorageService.getItem('passcodeTimeout')
    );

    if (!passcodeTimeout || isNaN(passcodeTimeout)) {
      passcodeTimeout = DEFAULT_PASSCODE_TIMEOUT;
    }

    if (passcodeTimeout) {
      this._timeToIdle$.next(Number(passcodeTimeout));
      (window as any)[IFVISIBLE_KEY].setIdleDuration(this._timeToIdle$.value);
    }
  }

  public activateMultiUserMode(
    passcodeRedirectsTo: string,
    passcodeTimeout: number
  ) {
    this._localStorageService.setItem('multiUserMode', true);
    this._localStorageService.setItem(
      'multiUserModeAuthToken',
      this._authenticationService.getAuthToken().token
    );
    this._localStorageService.setItem(
      'passcodeRedirectsTo',
      passcodeRedirectsTo
    );
    this._localStorageService.setItem('passcodeTimeout', passcodeTimeout);

    this.initPasscodeTimeout();

    this._toastService.presentToast('Multi-User Mode Activated!');

    this._authenticationService.logout({ navigateTo: 'pin' });
  }

  public deactivateMultiUserMode() {
    this._localStorageService.clearItem('multiUserMode');
    this._localStorageService.clearItem('multiUserModeAuthToken');
    this._localStorageService.clearItem('passcodeRedirectsTo');
    this._localStorageService.clearItem('passcodeTimeout');

    this._toastService.presentToast('Multi-User Mode Deactivated!');
  }
}
