import { Pipe, PipeTransform } from '@angular/core';
import { localizedWeekDayName } from '../../../utils/localized-week-day-name';

@Pipe({
  name: 'daysOfWeeklySchedule'
})
export class DaysOfWeeklySchedulePipe implements PipeTransform {
  public transform(value: boolean[]): string {
    const weekDays = [0, 1, 2, 3, 4, 5, 6].map(i =>
      localizedWeekDayName(i, 'long')
    );
    const result = weekDays.filter((_, i) => value[i]).join(', ');
    return result;
  }
}
