<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>Skill Matrix</ion-title>

    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" color="primary" appHaptics>
        Done
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="ion-padding-vertical">
    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="selectUsersForGroup(skillMatrixStatus.CANNOT_PERFORM)"
          fill="clear"
          class="ion-no-margin"
        >
          Cannot Perform
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let user of cannotPerformUsers; trackBy: trackItems"
        [label]="user.firstName + ' ' + user.lastName"
        [status]="getUserStatus(user)"
        (statusChanged)="onStatusChange($event, user)"
      />
      <ion-note *ngIf="cannotPerformUsers.length === 0">
        No users with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="
            selectUsersForGroup(skillMatrixStatus.CAN_PERFORM_WITH_SUPERVISON)
          "
          fill="clear"
          class="ion-no-margin"
        >
          Can Perform with Supervision
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let user of canPerformWithSupervisionUsers; trackBy: trackItems"
        [label]="user.firstName + ' ' + user.lastName"
        [status]="getUserStatus(user)"
        (statusChanged)="onStatusChange($event, user)"
      />
      <ion-note *ngIf="canPerformWithSupervisionUsers.length === 0">
        No users with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="
            selectUsersForGroup(skillMatrixStatus.CAN_PERFORM_WITH_REVIEW)
          "
          fill="clear"
          class="ion-no-margin"
        >
          Can Perform with Review
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let user of canPerformWithReviewUsers; trackBy: trackItems"
        [label]="user.firstName + ' ' + user.lastName"
        [status]="getUserStatus(user)"
        (statusChanged)="onStatusChange($event, user)"
      />
      <ion-note *ngIf="canPerformWithReviewUsers.length === 0">
        No users with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="
            selectUsersForGroup(skillMatrixStatus.CAN_PERFORM_WITHOUT_REVIEW)
          "
          fill="clear"
          class="ion-no-margin"
        >
          Can Perform without Review
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let user of canPerformWithoutReviewUsers; trackBy: trackItems"
        [label]="user.firstName + ' ' + user.lastName"
        [status]="getUserStatus(user)"
        (statusChanged)="onStatusChange($event, user)"
      />
      <ion-note *ngIf="canPerformWithoutReviewUsers.length === 0">
        No users with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="selectUsersForGroup(skillMatrixStatus.CAN_PERFORM_AND_TRAIN)"
          fill="clear"
          class="ion-no-margin"
        >
          Can Perform and Train
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let user of canPerformAndTrainUsers; trackBy: trackItems"
        [label]="user.firstName + ' ' + user.lastName"
        [status]="getUserStatus(user)"
        (statusChanged)="onStatusChange($event, user)"
      />
      <ion-note *ngIf="canPerformAndTrainUsers.length === 0">
        No users with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="
            selectUsersForGroup(skillMatrixStatus.MUST_REVIEW_NEW_VERSION)
          "
          fill="clear"
          class="ion-no-margin"
        >
          Must Review New Version
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let user of mustReviewNewVersionUsers; trackBy: trackItems"
        [label]="user.firstName + ' ' + user.lastName"
        [status]="getUserStatus(user)"
        (statusChanged)="onStatusChange($event, user)"
      />
      <ion-note *ngIf="mustReviewNewVersionUsers.length === 0">
        No users with this status
      </ion-note>
    </ion-list>

    <ion-list>
      <ion-item class="group-title">
        <ion-button
          (click)="selectUsersForGroup(skillMatrixStatus.REVOKED)"
          fill="clear"
          class="ion-no-margin"
        >
          Revoked
        </ion-button>
      </ion-item>
      <app-skill-matrix-status-select
        *ngFor="let user of revokedUsers; trackBy: trackItems"
        [label]="user.firstName + ' ' + user.lastName"
        [status]="getUserStatus(user)"
        (statusChanged)="onStatusChange($event, user)"
      />
      <ion-note *ngIf="revokedUsers.length === 0">
        No users with this status
      </ion-note>
    </ion-list>
  </app-width-restricted-container>
</ion-content>

<ion-modal
  #userModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      class="ion-page"
      [title]="selectedStatusTitle"
      [users]="allUsers"
      [recipients]="usersIdsOfSelectedStatus"
      (selectionChange)="selectedIdChanged($event)"
      (selectionCancel)="userModal.dismiss()"
      [multiple]="true"
    ></app-user-selector>
  </ng-template>
</ion-modal>
