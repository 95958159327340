import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';
import { IonicModule } from '@ionic/angular';
import { SlimMenuButtonComponent } from '../slim-menu-button/slim-menu-button.component';
import { UserSelectorComponent } from '../user-selector/user-selector.component';
import { DirectivesModule } from '../directives.module';
import { PositiveFeedbackQuestionComponent } from './positive-feedback-question.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmojiButtonComponent } from '../emoji-button/emoji-button.component';

@NgModule({
  declarations: [PositiveFeedbackQuestionComponent],
  imports: [
    CommonModule,
    IonicModule,
    SlimMenuButtonComponent,
    WidthRestrictedContainerModule,
    UserSelectorComponent,
    DirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    EmojiButtonComponent
  ],
  exports: [PositiveFeedbackQuestionComponent]
})
export class PositiveFeedbackQuestionModule {}
