import { addDays, getDay, lastDayOfMonth } from 'date-fns';
import { DateFlags } from '../modules/task-form/types/date-flags.enum';

export function adjustDateToFlags(date: Date, flags: string | undefined): Date {
  if (flags?.includes(DateFlags.LAST_DAY)) {
    date = lastDayOfMonth(date);
    const dow = getDay(date);
    if (flags.includes(DateFlags.WEEKDAY)) {
      if (dow === 0) {
        date = addDays(date, -2);
      } else if (dow === 6) {
        date = addDays(date, -1);
      }
    }
    return date;
  }

  if (flags?.includes(DateFlags.WEEKDAY)) {
    const dow = getDay(date);
    if (dow === 0) {
      date = addDays(date, 1);
    } else if (dow === 6) {
      date = addDays(date, -1);
    }
  }

  return date;
}
