import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamSettingsComponent } from './team-settings.component';
import { RouterModule, Routes } from '@angular/router';
import { PageWrapperModule } from '@app/modules/page-wrapper/page-wrapper.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TenantService } from '@app/core/service/tenant.service';
import { take } from 'rxjs';
import { DateFormControlModule } from '@app/modules/date-form-control/date-form-control.module';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { DateTransformerPipe } from './date-transformer.pipe';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';
import { AllowedInsightsPipe } from './insights-allowed.pipe';
import { TimezoneSelectorComponent } from '@app/modules/timezone-selector/timezone-selector.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { PasscodesListModalComponent } from '@app/modules/passcodes-list-modal/passcodes-list-modal.component';
import { ReceiveDailyRepotsPipe } from './receive-daily-reports.pipe';
import { TimeFormControlModule } from '@app/modules/time-form-control/time-form-control.module';
import { BehaviorsEditComponent } from './components/behaviors-edit/behaviors-edit.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CompensationPoolModalModule } from '@app/modules/compensation-pool-modal/compensation-pool-modal.module';
import { CompensationFieldModalModule } from '@app/modules/compensation-field-modal/compensation-field-modal.module';
import { InlineInputModule } from '@app/modules/inline-input/inline-input.module';
import { ManagersAndAdminsPipe } from './managers-and-admins.pipe';
import { NormalUsersPipe } from './normal-users.pipe';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { EditSystemEntityModalModule } from '@app/modules/edit-system-entity-modal/edit-system-entity-modal.module';
import { WideButtonComponent } from '@app/modules/wide-button/wide-button.component';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    resolve: {
      tenant: () => inject(TenantService).tenant$.pipe(take(1))
    },
    data: {
      title: 'Behaviors',
      rootComponent: TeamSettingsComponent
    }
  }
];

@NgModule({
  declarations: [
    TeamSettingsComponent,
    BehaviorsEditComponent,
    DateTransformerPipe,
    AllowedInsightsPipe,
    ReceiveDailyRepotsPipe,
    ManagersAndAdminsPipe,
    NormalUsersPipe
  ],
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    PageWrapperModule,
    IonicModule,
    FormsModule,
    DateFormControlModule,
    WidthRestrictedContainerModule,
    SlimMenuButtonComponent,
    UserSelectorComponent,
    PasscodesListModalComponent,
    TimezoneSelectorComponent,
    DirectivesModule,
    TimeFormControlModule,
    GridModule,
    CompensationPoolModalModule,
    CompensationFieldModalModule,
    InlineInputModule,
    DigitOnlyModule,
    EditSystemEntityModalModule,
    WideButtonComponent
  ]
})
export class TeamSettingsModule {}
