import { Component, Input, HostBinding } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { ITask } from '@backend/models/types/task';
import { TaskPointsEarns } from '@backend/models/types/task';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { IAttachment } from '@backend/models/types/attachment';

@Component({
  selector: 'app-performance-row',
  templateUrl: './performance-row.component.html',
  styleUrls: ['./performance-row.component.scss']
})
export class PerformanceRowComponent {
  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  @Input()
  public performance: MongoStoredObject<
    ITaskPerformance & {
      task: MongoStoredObject<ITask>;
      note?: {
        attachments: MongoStoredObject<IAttachment>[];
      };
    }
  >;

  @Input()
  public animationOrder?: number = -1;

  protected readonly taskPointsEarns = TaskPointsEarns;

  public constructor(private readonly _sanitizer: DomSanitizer) {}
}
