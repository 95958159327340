import { Injectable } from '@angular/core';
import { ImageViewerModalComponent } from '@app/modules/image-viewer-modal/image-viewer-modal.component';
import { ModalController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ImageViewerService {
  public constructor(private readonly _modalCtrl: ModalController) {}

  public async viewImage(images: string[], initialIndex: number) {
    const modal = await this._modalCtrl.create({
      id: 'image-viewer-modal',
      component: ImageViewerModalComponent,
      handle: false,
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        images,
        initialIndex
      },
      cssClass: 'fullscreen-modal'
    });
    modal.present();
  }
}
