<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="!parentId">
      {{ suggestion ? 'Edit Suggestion' : 'Add Suggestion' }}
    </ion-title>
    <ion-title *ngIf="parentId">
      {{ suggestion ? 'Edit Comment' : 'Add Comment' }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <ion-grid [formGroup]="mainFormGroup">
        <ion-row>
          <ion-col size="12">
            <app-wysiwyg-editor
              [(value)]="body"
              [disableAttachments]="disableAttachments"
            />
          </ion-col>
        </ion-row>
        <ion-row *ngIf="isAdmin">
          <ion-col size="12">
            <ion-input
              label="Author"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              [value]="formattedAuthor"
              [readonly]="true"
              appHaptics
              (click)="userSelectorModal.present()"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="isAdmin && !parentId">
          <ion-col size="12">
            <ion-select
              class="app-w100 input-with-background"
              label="Status"
              interface="popover"
              placeholder="Select Status"
              aria-label="Status"
              formControlName="status"
              labelPlacement="stacked"
            >
              <ion-select-option
                *ngFor="let option of statuses"
                [value]="option.value"
              >
                {{ option.title }}
              </ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row *ngIf="isAdmin" class="ion-justify-content-center">
          <ion-col>
            <div class="ion-text-center">
              <div class="public-info-label">Publish to Team</div>
              <ion-toggle
                [(ngModel)]="isPublic"
                class="ion-no-padding"
                appHaptics
                aria-label="Publish to Team"
              />
              <div class="public-info-description">
                {{ publicInfo }}
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="4" *ngIf="suggestion">
            <ion-button
              type="button"
              color="danger"
              expand="block"
              fill="outline"
              (click)="onDeleteClick()"
              appHaptics
            >
              Delete
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button
              color="dark"
              expand="block"
              [disabled]="!canSave"
              [disableHaptics]="!canSave"
              appHaptics
              (click)="onFormSubmit()"
            >
              {{ suggestion ? 'Update' : 'Submit' }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-modal
  #userSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Author"
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="onUserChange($event)"
      (selectionCancel)="userSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>
