import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageWrapperComponent } from './page-wrapper.component';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';

@NgModule({
  declarations: [PageWrapperComponent],
  imports: [
    CommonModule,
    IonicModule,
    WidthRestrictedContainerModule,
    SlimMenuButtonComponent
  ],
  exports: [PageWrapperComponent]
})
export class PageWrapperModule {}
