<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Update User</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-width-restricted-container
    class="layout-container"
    [hidden]="!dataIsLoaded"
  >
    <div class="top-content">
      <ion-grid [formGroup]="mainFormGroup">
        <ion-row>
          <ion-col size="6">
            <ion-input
              label="First Name"
              class="app-w100 input-with-background"
              formControlName="firstName"
              labelPlacement="floating"
              fill="solid"
              autocapitalize="on"
            ></ion-input>
            <ion-note color="danger" *ngIf="firstNameError" class="error-note">
              {{ firstNameError }}
            </ion-note>
          </ion-col>
          <ion-col size="6">
            <ion-input
              label="Last Name"
              class="app-w100 input-with-background"
              formControlName="lastName"
              labelPlacement="floating"
              fill="solid"
              autocapitalize="on"
            ></ion-input>
            <ion-note color="danger" *ngIf="lastNameError" class="error-note">
              {{ lastNameError }}
            </ion-note>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Email"
              class="app-w100 input-with-background"
              formControlName="email"
              labelPlacement="floating"
              fill="solid"
              type="email"
            ></ion-input>
            <ion-note color="danger" *ngIf="emailError" class="error-note">
              {{ emailError }}
            </ion-note>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-item class="app-w100 input-with-background" lines="none">
              <ion-input
                label="Supervisor (optional)"
                labelPlacement="floating"
                fill="solid"
                [value]="formattedSupervisor"
                [readonly]="true"
                id="selectSupervisor"
                appHaptics
              ></ion-input>
              <ion-button
                fill="clear"
                (click)="handleSupervisorClearClick()"
                appHaptics
                *ngIf="formattedSupervisor"
                class="ion-no-margin"
              >
                <ion-icon
                  slot="icon-only"
                  name="close-circle"
                  class="input-icon"
                ></ion-icon>
              </ion-button>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              label="Department (optional)"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              formControlName="department"
              autocapitalize="on"
              [spellcheck]="true"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="tenantFeatures.calculateRewards">
          <ion-col size="12">
            <ion-input
              label="Employee ID from Payroll Provider (optional)"
              class="app-w100 input-with-background"
              labelPlacement="floating"
              fill="solid"
              formControlName="compensationEmployeeId"
              autocapitalize="on"
            ></ion-input>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="tenantFeatures.calculateRewards">
          <ion-col size="12">
            <ion-select
              label="Compensation Pools (optional)"
              labelPlacement="floating"
              class="app-w100 input-with-background"
              formControlName="compensationPools"
              appHaptics
              [multiple]="true"
            >
              <ion-select-option
                [value]="compensationPool._id"
                *ngFor="let compensationPool of compensationPools"
              >
                {{ compensationPool.name }}
              </ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-select
              label="Role"
              labelPlacement="floating"
              class="app-w100 input-with-background"
              formControlName="role"
              appHaptics
              interface="popover"
            >
              <ion-select-option
                [value]="role"
                *ngFor="let role of roleOptions"
              >
                {{ role }}
              </ion-select-option>
            </ion-select>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-button
              (click)="openSkillMatrix()"
              expand="block"
              color="light"
              class="ion-no-margin"
            >
              <ion-icon slot="start" name="grid-outline" />
              <ion-label>Skill Matrix</ion-label>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button
              (click)="manageTasks()"
              expand="block"
              color="light"
              class="ion-no-margin"
            >
              <ion-icon slot="start" name="notifications-outline" />
              <ion-label>Notifications</ion-label>
            </ion-button>
          </ion-col>
        </ion-row>
        <app-user-schedule-editor
          *ngIf="userId && tenantFeatures.userSchedule"
          [userId]="userId"
          [scheduleFormGroup]="scheduleFormGroup"
          [weekTimeFormGroup]="weekTimeFormGroup"
        />
      </ion-grid>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-padding-horizontal" *ngIf="isDeleted">
          <ion-col size="6">
            <div class="reactivate-message ion-text-center">
              User has been disabled, click below to Reactivate account
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding actions">
          <ion-col size="6">
            <ion-button
              *ngIf="isDeleted"
              color="dark"
              expand="block"
              appHaptics
              (click)="toggleSoftDelete()"
              fill="outline"
            >
              Reactivate Account
            </ion-button>
            <ion-button
              *ngIf="!isDeleted"
              expand="block"
              appHaptics
              (click)="toggleSoftDelete()"
              color="danger"
              fill="outline"
            >
              Disable User
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button
              color="dark"
              expand="block"
              appHaptics
              [disabled]="!canSave"
              [disableHaptics]="!canSave"
              (click)="updateUser()"
            >
              Update User
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ion-modal
  trigger="selectSupervisor"
  #supervisorSelectorModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      title="Supervisor"
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="onSupervisorChange($event)"
      (selectionCancel)="supervisorSelectorModal.dismiss()"
    ></app-user-selector>
  </ng-template>
</ion-modal>
