<ion-button
  color="light"
  expand="block"
  (click)="onTriggerClick($event)"
  [disabled]="disabled"
  appHaptics
  [disableHaptics]="disabled"
  class="ion-no-margin"
>
  <div class="button-content-wrapper">
    <div class="label">{{ label }}</div>
    <div class="time">
      {{
        value
          | transformerApplicator : dateTransformer
          | date : 'EEEE, MMM d, y'
      }}
    </div>
  </div>
</ion-button>

<ion-popover
  [keepContentsMounted]="true"
  [isOpen]="isOpen"
  (didDismiss)="isOpen = false"
  cssClass="popover-datetime"
  #popover
>
  <ng-template>
    <ion-datetime
      [showDefaultButtons]="true"
      (ionChange)="onDateChange($any($event))"
      (ionCancel)="hapticsImpact()"
      presentation="date"
    />
  </ng-template>
</ion-popover>
