import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../authentication';
import { UserModel, UserPointsRecord } from '../model/user.model';
import { DateInterval } from '@backend/types/date-interval';
import { InsightUserStats } from '@app/types/insight-user-stats';
import { ObjectId } from '@app/types/object-id';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITask } from '@backend/models/types/task';
import { IUserDeviceInfo } from '@backend/models/types/device-info';
import { ILedger } from '@backend/models/types/ledger';

@Injectable()
export class UserApiService {
  tenantId: ObjectId;

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _authenticationService: AuthenticationService
  ) {
    this.tenantId = this._authenticationService.user?.tenant;
  }

  public getAllUsers(
    tenantId?: ObjectId
  ): Observable<
    (UserModel & { deviceInfos: MongoStoredObject<IUserDeviceInfo>[] })[]
  > {
    tenantId ??= this.tenantId;
    return this._httpClient.get<
      (UserModel & { deviceInfos: MongoStoredObject<IUserDeviceInfo>[] })[]
    >(`/tenants/${tenantId}/users`);
  }

  public getAllUsersWithDeleted(): Observable<UserModel[]> {
    return this._httpClient.get<UserModel[]>(
      `/tenants/${this.tenantId}/users-with-deleted`
    );
  }

  public getAllUsersWithPinCodes(): Observable<
    (UserModel & { pin: string })[]
  > {
    return this._httpClient.get<(UserModel & { pin: string })[]>(
      `/tenants/${this.tenantId}/users-with-pincodes`
    );
  }

  public getAllUsersNames(): Observable<UserModel[]> {
    return this._httpClient.get<UserModel[]>(`/users`);
  }

  public getAllRoles(): Observable<any> {
    return this._httpClient.get<any>(`/tenants/${this.tenantId}/user-roles`);
  }

  public inviteUser(user: any): Observable<any> {
    const option = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    const userData = user;
    const updatedData = this.removeEmptyString(userData);
    return this._httpClient.post<any>(`/users/invite`, updatedData, option);
  }

  public editUser(id: string, data: any): Observable<UserModel> {
    const option = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    const updatedData = this.removeEmptyString(data);
    updatedData.tenantId = this.tenantId;

    return this._httpClient.patch(`/users/${id}`, updatedData, option).pipe(
      map(() => {
        if (this._authenticationService.user._id.toString() === id) {
          this._authenticationService.setUser({
            ...this._authenticationService.user,
            ...updatedData
          });

          return this._authenticationService.user;
        }
      })
    );
  }

  deleteUser(id: string): Observable<any> {
    return this._httpClient.delete(`/users/${id}`).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getUser(): Observable<UserModel> {
    return this._httpClient.get<UserModel>('/user');
  }

  getUserById(id: string): Observable<UserModel> {
    return this._httpClient.get<UserModel>(
      `/tenants/${this.tenantId}/users/${id}`
    );
  }

  getUserTotalPoints(id: string): Observable<any> {
    return this._httpClient.get(`/users/${id}/total-points`);
  }

  getUserTaskPerformances(
    userId: string,
    range: DateInterval,
    page: number,
    limit: number
  ): Observable<
    MongoStoredObject<
      ITaskPerformance & {
        task: MongoStoredObject<ITask>;
        ledgers: MongoStoredObject<ILedger>[];
      }
    >[]
  > {
    return this._httpClient.get<
      MongoStoredObject<
        ITaskPerformance & {
          task: MongoStoredObject<ITask>;
          ledgers: MongoStoredObject<ILedger>[];
        }
      >[]
    >(`/users/${userId}/task-performances`, {
      params: {
        from: range.start.toISOString(),
        to: range.end.toISOString(),
        page,
        limit
      }
    });
  }

  getPointsForPeriod(
    direction: 'sender' | 'recipient',
    from: Date,
    to: Date
  ): Observable<UserPointsRecord> {
    return this._httpClient.get<UserPointsRecord>(`/user/points-for-period`, {
      params: {
        direction,
        from: from.toISOString(),
        to: to.toISOString()
      }
    });
  }

  getAverageTeamPointsForPeriod(
    direction: 'sender' | 'recipient',
    from: Date,
    to: Date
  ): Observable<{ teamAveragePoints: number }> {
    return this._httpClient.get<{ teamAveragePoints: number }>(
      `/user/average-team-points-for-period`,
      {
        params: {
          direction,
          from: from.toISOString(),
          to: to.toISOString()
        }
      }
    );
  }

  // removeEmptyString checks if the user data has any empty strings and removes it
  removeEmptyString(userData: object) {
    // Declare my new empty object
    const newObj: any = {};

    // For of lets me loop through the data I have passed in.
    // I am also calling the keys helper function
    for (const property of keys(userData)) {
      // If userData's property doesn't have a value of an empty string, put it in the new object
      if (userData[property] !== '') {
        newObj[property] = userData[property];
      }
    }
    return newObj;
  }

  softDeleteRequest(isDeleted: boolean, id: string): Observable<any> {
    const option = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    const body = {
      isDeleted
    };
    return this._httpClient.put(`/users/${id}/soft-delete`, body, option).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public getInsights(range: DateInterval): Observable<InsightUserStats[]> {
    return this._httpClient.get<InsightUserStats[]>('/users/insights', {
      params: {
        start: range.start.toISOString(),
        end: range.end.toISOString()
      }
    });
  }
}

// Helper function that makes use of TypeScript’s handy keyof operator:
// keyof queries the set of keys for a given type
// https://medium.com/@_achou/dont-give-up-and-use-suppressimplicitanyindexerrors-ca6b208b9365
export function keys<O extends object>(obj: O): Array<keyof O> {
  return Object.keys(obj) as Array<keyof O>;
}
