import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  AfterViewInit,
  ViewChild,
  OnInit
} from '@angular/core';
import { IonicModule, IonModal, IonSearchbar } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { ObjectId } from '@app/types/object-id';
import { CommonModule } from '@angular/common';
import { FilterBehaviorsPipe } from './filter-behaviors.pipe';
import { FormsModule } from '@angular/forms';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { DirectivesModule } from '@app/modules/directives.module';
import { ValuesAccordionModule } from '../culture-accordion/values-accordion.module';

@Component({
  selector: 'app-behavior-selector',
  templateUrl: './behavior-selector.component.html',
  styleUrls: ['./behavior-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    IonicModule,
    WidthRestrictedContainerModule,
    FilterBehaviorsPipe,
    FormsModule,
    DirectivesModule,
    ValuesAccordionModule
  ]
})
export class BehaviorSelectorComponent implements OnInit, AfterViewInit {
  @Input()
  public readonly behaviors: BehaviorModel[];

  @Input()
  public readonly focusSearchbar?: boolean = false;

  @Input()
  public readonly presentingElementSelector?: string;

  @Output()
  public selectionCancel = new EventEmitter<void>();

  @Output()
  public selectionChange = new EventEmitter<ObjectId>();

  @ViewChild('ionSearchbar')
  public ionSearchbar!: IonSearchbar;

  @ViewChild('learnMoreModal')
  public learnMoreModal!: IonModal;

  protected selectedBehaviorId: ObjectId;
  protected filter = '';
  protected presentingElement?: Element;

  public ngOnInit(): void {
    this.presentingElement = this.presentingElementSelector
      ? document.querySelector(this.presentingElementSelector)
      : undefined;
  }

  public ngAfterViewInit(): void {
    if (this.focusSearchbar) {
      setTimeout(() => {
        this.ionSearchbar.setFocus();
      }, 600);
    }
  }

  protected trackItems(_: number, item: BehaviorModel) {
    return item._id;
  }

  protected cancelChanges() {
    this.selectionCancel.emit();
  }

  protected confirmChanges() {
    this.selectionChange.emit(
      this.behaviors.find((t) => t._id === this.selectedBehaviorId)._id
    );
  }

  protected itemClick(value: ObjectId) {
    this.selectedBehaviorId = value;
    this.confirmChanges();
  }

  protected searchbarInput(event: any): void {
    this.filter = event.detail.value;
  }

  protected getFirst() {
    if (!this.filter) return null;

    const behaviors = new FilterBehaviorsPipe().transform(
      this.behaviors,
      this.filter
    );

    if (behaviors.length > 0) {
      return behaviors[0];
    }

    return null;
  }

  protected selectFirst() {
    const first = this.getFirst();

    if (first) {
      this.selectedBehaviorId = first._id;

      this.confirmChanges();
    }
  }
}
