<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <app-slim-menu-button class="ion-hide-lg-up"></app-slim-menu-button>
    </ion-buttons>

    <ion-title>
      <ion-button fill="clear" (click)="showTasksCompletedOfUser()">
        <div class="total-title">
          <span class="toolbar-label">Today</span>
          <span>
            {{ (interval$ | async | intervalStats | async)?.points }} pts
          </span>
        </div>
      </ion-button>
    </ion-title>

    <ion-buttons slot="end" *ngIf="tenantFeatures.dailyReport">
      <ion-button color="primary" (click)="showDailyReport()" appHaptics>
        Completed
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-pull-to-search #pullToSearch (searchInput)="onSearchChange($event)">
  <ion-grid *ngIf="isLoading">
    <ion-row class="ion-justify-content-center">
      <ion-spinner name="lines-sharp-small"></ion-spinner>
    </ion-row>
  </ion-grid>

  <app-width-restricted-container
    *ngIf="checklist$ | async as checklist"
    class="container-with-fab"
  >
    <ng-container *ngIf="filter$ | async as filter">
      <app-checklist-folder
        *ngFor="
          let folder of checklist.folders;
          let i = index;
          trackBy: listFolderTrackBy
        "
        [taskFolderTitle]="folder.title"
        [items]="folder.items"
        [time]="checklist.time"
        (itemClick)="onItemClick($event, folder)"
        [forceOpen]="!!filter.search"
        [animationOrder]="i"
      />

      <ng-container *ngIf="checklist.checklist | notInFolder as nif">
        <app-checklist-folder
          *ngIf="nif.length"
          taskFolderTitle="Ungrouped"
          [items]="nif"
          [time]="checklist.time"
          (itemClick)="onItemClick($event)"
          [forceOpen]="!!filter.search"
          [animationOrder]="checklist.folders.length"
        />
      </ng-container>
    </ng-container>

    <ng-container *ngIf="checklist.checklist.length === 0">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>
  </app-width-restricted-container>

  <ion-fab
    slot="fixed"
    vertical="bottom"
    horizontal="end"
    *ngIf="isAdminOrManager"
    class="main-fab"
  >
    <ion-fab-button color="dark" (click)="onAddTaskClick()" appHaptics>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</app-pull-to-search>

<ng-template #emptyList>
  <app-empty-stub
    [icon]="
      filter$.value.search ? 'search-outline' : 'checkmark-circle-outline'
    "
    [text]="filter$.value.search ? 'Nothing Found' : 'Checklist Is Empty'"
  ></app-empty-stub>
</ng-template>
