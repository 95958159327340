import { Pipe, PipeTransform } from '@angular/core';
import { ChecklistItem } from '../../types/checklist-item';
import { TaskPerformanceStatus } from '@backend/models/types/task-performance';
import { TaskPointsEarns } from '@backend/models/types/task';

@Pipe({
  name: 'hasCompleted'
})
export class hasCompletedPipe implements PipeTransform {
  public transform(item: ChecklistItem): boolean {
    return (
      item.performances.length > 0 &&
      item.task.pointsEarns === TaskPointsEarns.PER_COMPLETION &&
      item.performances.some(
        (p) =>
          p.status === TaskPerformanceStatus.COMPLETE ||
          p.status === TaskPerformanceStatus.IN_PROGRESS
      )
    );
  }
}
