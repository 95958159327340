import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AuthenticationService, LocalStorageService } from '@app/core';
import { UserModel } from '@app/core/model/user.model';
import { UserListService } from '@app/core/service/user-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import {
  INegativeFeedbackParams,
  IPsychologicalSurveyQuestion
} from '@backend/models/types/psychological-survey-question';
import { INegativeFeedbackResult } from '@backend/models/types/psychological-survey-answer';
import { ActionSheetController, IonModal, IonTextarea } from '@ionic/angular';
import { ObjectId } from '@app/types/object-id';
import { PsychologicalSurveyApiService } from '@app/core/service/psychological-survey-api.service';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { nanoid } from 'nanoid/async';

const CONFIDENTIAL_ACCEPTED_KEY = 'pulse_confidential_accepted';

@Component({
  selector: 'app-negative-feedback-question',
  templateUrl: './negative-feedback-question.component.html',
  styleUrls: ['./negative-feedback-question.component.scss']
})
export class NegativeFeedbackQuestionComponent implements OnInit {
  @Input()
  public readonly cancelButton: 'menu' | 'cancel' = 'menu';

  @Input()
  public readonly survey: MongoStoredObject<IPsychologicalSurvey>;

  @Input()
  public readonly question: MongoStoredObject<
    IPsychologicalSurveyQuestion & { params: INegativeFeedbackParams }
  >;

  @Input()
  public readonly previousAnswer?: INegativeFeedbackResult = null;

  @Input()
  public readonly questionTexting: 'TODAY' | 'LAST_SHIFT' = 'TODAY';

  @Output()
  public completed = new EventEmitter<{
    questionId: string;
    result: INegativeFeedbackResult;
  }>();

  @Output()
  public goBack = new EventEmitter<void>();

  @Output()
  public cancelClicked = new EventEmitter<void>();

  @ViewChild('selectIndividualsModal', { static: true })
  public selectIndividualsModal!: IonModal;

  @ViewChild('confidentialModal', { static: true })
  public confidentialModal!: IonModal;

  @ViewChild('suggestionTextarea')
  public suggestionTextarea!: IonTextarea;

  protected allUsers: UserModel[] = [];
  protected result: INegativeFeedbackResult = {
    usersFeedback: [],
    generalFeedback: []
  };
  protected selectedUser: UserModel | null = null;
  protected selectedBehaviors: {
    id: ObjectId;
    name: string;
    checked: boolean;
  }[] = [];
  protected selectedGeneralFeedbackId = '';
  protected isSuggestedOptionChecked = false;
  protected suggestedOption = '';

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _userListService: UserListService,
    private readonly _psychologicaSurveyApiService: PsychologicalSurveyApiService,
    private readonly _actionSheetCtrl: ActionSheetController,
    private readonly _localStorageService: LocalStorageService
  ) {}

  public ngOnInit(): void {
    if (this.previousAnswer) {
      this.result = this.previousAnswer;
    }

    this._userListService.users$.subscribe((users) => {
      this.allUsers = users.filter(
        (user) => user._id.toString() !== this._authenticationService.user._id
      );
    });

    this._psychologicaSurveyApiService
      .getPsychologicalSurveyBehaviors(this.survey._id)
      .subscribe((behaviors) => {
        this.selectedBehaviors = behaviors.map((behavior) => ({
          id: behavior._id,
          name: behavior.futureTense,
          checked: false
        }));
      });

    const confidentialAccepted = this._localStorageService.getItem(
      CONFIDENTIAL_ACCEPTED_KEY
    );

    if (confidentialAccepted !== 'true') {
      this.confidentialModal.present();
    }
  }

  protected get canSave() {
    const hasCheckedBehaviors = !!this.selectedBehaviors.filter(
      (behavior) => behavior.checked
    ).length;

    return this.isSuggestedOptionChecked
      ? !!this.suggestedOption
      : hasCheckedBehaviors;
  }

  protected get hiddenUsers() {
    return this.result.usersFeedback.map((user) => user.userId);
  }

  protected get behaviorsSelectorTitle() {
    return this.question
      ? this.question.params.behaviorsSelectorTitle.replace(
          '${firstName}',
          `<b>${this.selectedUser.firstName}</b>`
        )
      : '';
  }

  protected get behaviorsSelectorNotice() {
    return this.question
      ? this.question.params.behaviorsSelectorNotice.replace(
          '${firstName}',
          this.selectedUser.firstName
        )
      : '';
  }

  protected async askSkipQuestion() {
    if (this.question.params.skipConfirmationTitle) {
      const actionSheet = await this._actionSheetCtrl.create({
        header: this.question.params.skipConfirmationTitle,
        buttons: [
          {
            text: 'Submit',
            role: 'confirm'
          },
          {
            text: 'Cancel',
            role: 'cancel'
          }
        ]
      });
      actionSheet.present();

      const { role } = await actionSheet.onWillDismiss();

      if (role === 'confirm') {
        this._skipQuestion();
      }
    } else {
      this._skipQuestion();
    }
  }

  private _skipQuestion() {
    this.completed.emit({
      questionId: this.question._id,
      result: {
        usersFeedback: [],
        generalFeedback: []
      }
    });
  }

  protected completeQuestion() {
    this.completed.emit({
      questionId: this.question._id,
      result: this.result
    });
  }

  protected getUserName(userId: string) {
    const user = this.allUsers.find((user) => userId === user._id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  }

  protected getUserInitials(userId: string) {
    const user = this.allUsers.find((user) => userId === user._id);
    return `${user.firstName ? user.firstName[0] : ''}${
      user.lastName ? user.lastName[0] : ''
    }`;
  }

  protected editUser(userId: string) {
    const user = this.result.usersFeedback.find(
      (generalFeedback) => generalFeedback.userId === userId
    );

    this.selectedBehaviors = this.selectedBehaviors.map((behavior) => ({
      ...behavior,
      checked: user.surveyBehaviors.includes(behavior.id)
    }));
    this.isSuggestedOptionChecked = !!user.suggestedBehavior;
    this.suggestedOption = user.suggestedBehavior;

    this.selectedUser = this.allUsers.find((user) => user._id === userId);
  }

  protected editGeneralFeedback(generalFeedbackId: string) {
    const generalFeedback = this.result.generalFeedback.find(
      (generalFeedback) =>
        generalFeedback.generalFeedbackId === generalFeedbackId
    );

    this.selectedBehaviors = this.selectedBehaviors.map((behavior) => ({
      ...behavior,
      checked: generalFeedback.surveyBehaviors.includes(behavior.id)
    }));
    this.isSuggestedOptionChecked = !!generalFeedback.suggestedBehavior;
    this.suggestedOption = generalFeedback.suggestedBehavior;

    this.selectedGeneralFeedbackId = generalFeedbackId;
  }

  protected async removeUser(event: any, userId: string) {
    event.stopPropagation();

    const user = this.allUsers.find((user) => user._id === userId);

    const actionSheet = await this._actionSheetCtrl.create({
      header: `Are you sure you want to delete the details for ${user.firstName}?`,
      buttons: [
        {
          text: 'Delete',
          role: 'destructive'
        },
        {
          text: 'Cancel',
          role: 'cancel'
        }
      ]
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    if (role === 'destructive') {
      this.result.usersFeedback = this.result.usersFeedback.filter(
        (user) => user.userId !== userId
      );
    }
  }

  protected async removeGeneralFeedback(event: any, generalFeedbackId: string) {
    event.stopPropagation();

    const actionSheet = await this._actionSheetCtrl.create({
      header: 'Delete general feedback?',
      buttons: [
        {
          text: 'Yes',
          role: 'destructive'
        },
        {
          text: 'No',
          role: 'cancel'
        }
      ]
    });
    actionSheet.present();

    const { role } = await actionSheet.onWillDismiss();

    if (role === 'destructive') {
      this.result.generalFeedback = this.result.generalFeedback.filter(
        (generalFeedback) =>
          generalFeedback.generalFeedbackId !== generalFeedbackId
      );
    }
  }

  protected onUserSelect(value: ObjectId[]): void {
    if (value.length) {
      this.selectedUser = this.allUsers.find((user) => user._id === value[0]);
    }

    this.selectIndividualsModal.dismiss();
  }

  protected async submit() {
    if (this.selectedGeneralFeedbackId) {
      const generalFeedbackExists = this.result.generalFeedback.find(
        (generalFeedback) =>
          generalFeedback.generalFeedbackId === this.selectedGeneralFeedbackId
      );

      if (generalFeedbackExists) {
        generalFeedbackExists.surveyBehaviors = this.selectedBehaviors
          .filter((behavior) => behavior.checked)
          .map((behavior) => behavior.id);
        generalFeedbackExists.suggestedBehavior = this.isSuggestedOptionChecked
          ? this.suggestedOption
          : '';
      } else {
        this.result.generalFeedback.push({
          generalFeedbackId: this.selectedGeneralFeedbackId,
          surveyBehaviors: this.selectedBehaviors
            .filter((behavior) => behavior.checked)
            .map((behavior) => behavior.id),
          suggestedBehavior: this.isSuggestedOptionChecked
            ? this.suggestedOption
            : ''
        });
      }
    } else {
      const userExists = this.result.usersFeedback.find(
        (user) => user.userId === this.selectedUser._id
      );

      if (userExists) {
        userExists.surveyBehaviors = this.selectedBehaviors
          .filter((behavior) => behavior.checked)
          .map((behavior) => behavior.id);
        userExists.suggestedBehavior = this.isSuggestedOptionChecked
          ? this.suggestedOption
          : '';
      } else {
        this.result.usersFeedback.push({
          userId: this.selectedUser._id,
          surveyBehaviors: this.selectedBehaviors
            .filter((behavior) => behavior.checked)
            .map((behavior) => behavior.id),
          suggestedBehavior: this.isSuggestedOptionChecked
            ? this.suggestedOption
            : ''
        });
      }
    }

    this._clearDetails();
  }

  private _clearDetails(): void {
    this.selectedUser = null;
    this.selectedBehaviors = this.selectedBehaviors.map((behavior) => ({
      ...behavior,
      checked: false
    }));
    this.selectedGeneralFeedbackId = '';
    this.isSuggestedOptionChecked = false;
    this.suggestedOption = '';
  }

  protected trackBehaviors(
    _: number,
    item: {
      id: ObjectId;
      name: string;
      checked: boolean;
    }
  ) {
    return item.id;
  }

  protected checkboxClick(event: any, value: ObjectId) {
    event.preventDefault();

    this.selectedBehaviors = this.selectedBehaviors.map((item) => ({
      ...item,
      checked: item.id === value ? !item.checked : item.checked
    }));
  }

  protected suggestedOptionCheckboxClick(event: any) {
    event.preventDefault();

    this.isSuggestedOptionChecked = !this.isSuggestedOptionChecked;

    if (this.isSuggestedOptionChecked) {
      setTimeout(() => {
        this.suggestionTextarea.setFocus();
      });
    }
  }

  protected onSuggestedOptionChange(event: any) {
    this.suggestedOption = event.detail.value;
  }

  protected onSuggestionTextareaKeyPress(event: any) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.suggestionTextarea
        .getInputElement()
        .then((textarea) => textarea.blur());
    }
  }

  protected selectIndividuals() {
    this.selectIndividualsModal.present();
  }

  protected async leaveGeneralFeedback() {
    this.selectedGeneralFeedbackId = await nanoid();
  }

  protected onBackButtonClick() {
    if (this.selectedUser || this.selectedGeneralFeedbackId) {
      this._clearDetails();
    } else {
      this.goBack.emit();
    }
  }

  protected showConfidential() {
    this.confidentialModal.present();
  }

  protected acceptConfidential() {
    this.confidentialModal.dismiss();

    this._localStorageService.setItem(CONFIDENTIAL_ACCEPTED_KEY, true);
  }

  protected onCancelClick() {
    this.cancelClicked.emit();
  }
}
