import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  protected navigateTo = '';
  protected addConnectedAccount = false;

  public constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute
  ) {
    this._route.queryParams.subscribe((params) => {
      this.addConnectedAccount = params.addConnectedAccount === 'true';

      if (params.navigateTo) {
        this.navigateTo = params.navigateTo;
      } else {
        this._router.navigate(['/'], { replaceUrl: true });
      }
    });
  }

  protected logout() {
    let to = `${window.location.origin}/#/${this.navigateTo}`;

    if (this.addConnectedAccount) {
      to += '?addConnectedAccount=true';
    }

    window.location.href = to;
    window.location.reload();
  }

  public ngOnInit(): void {
    setTimeout(() => {
      this.logout();
    }, 250);
  }
}
