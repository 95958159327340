<app-login-page-wrapper [backBtn]="true">
  <form [formGroup]="mainFormGroup" (ngSubmit)="onFormSubmit()" novalidate>
    <ion-row>
      <ion-col size="12">
        <h1>Share Feedback</h1>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="mainFormGroup.controls['author']"
          controlName="Name"
        >
          <ion-input
            appAutofill
            type="text"
            class="login-form-control"
            formControlName="author"
            placeholder="Name (optional)"
            name="author"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-wysiwyg-editor
          [(value)]="body"
          [disableAttachments]="disableAttachments"
          [dark]="true"
          toolbarMode="image-only"
        />
      </ion-col>
    </ion-row>
    <div class="controls">
      <ion-button
        type="submit"
        color="light"
        fill="clear"
        [disabled]="!canSave"
        appHaptics
        [disableHaptics]="!canSave"
      >
        Submit
      </ion-button>

      <!-- this input is the hack to force submit event to fire on the enter press -->
      <input type="submit" class="hidden-control" />
    </div>
  </form>
</app-login-page-wrapper>
