import { Component, Input, OnChanges } from '@angular/core';
import { ERROR_OBJECTS } from '@app/core';

@Component({
  selector: 'app-form-error-wrapper',
  templateUrl: './form-error-wrapper.component.html'
})
export class FormErrorWrapperComponent implements OnChanges {
  @Input()
  public control: any;

  @Input()
  public controlName: string;

  public errorObject = ERROR_OBJECTS;
  public errorKeys: string[];

  ngOnChanges() {
    this.errorKeys = Object.keys(this.errorObject);
  }

  formateError(errorMessage: string, errorObj: any): string {
    const types = ['min', 'max', 'requiredLength'];

    console.log('errorObj', errorObj);

    types.forEach((type) => {
      if (!!errorObj[type]) {
        errorMessage = errorMessage.replace(/{{value}}/g, errorObj[type]);
      }
    });
    return errorMessage.replace(/{{field}}/g, this.controlName);
  }

  hasError() {
    return this.control.errors && this.control.touched;
  }
}
