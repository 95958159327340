import { Types } from 'mongoose';

export enum ECompensationFieldType {
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  HOURS = 'HOURS'
}

export interface ICompensationField {
  name: string;
  tenant: Types.ObjectId;
  isDeleted: boolean;
  type: ECompensationFieldType;
}
