<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">
      Integrations: {{ tenant.name }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading; else airtableIntegrationCard">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>
  </app-width-restricted-container>
</ion-content>

<ng-template #airtableIntegrationCard>
  <ion-card class="integration-card">
    <ion-card-header>
      <ion-card-subtitle>
        Allows you to synchronize all tags with the table in Airtable
      </ion-card-subtitle>
      <ion-card-title>Airtable</ion-card-title>
    </ion-card-header>
    <ion-list
      *ngIf="!isAirtableIntegrationAvailable || isAirtableIntegrationEdit"
      [formGroup]="airtableFormGroup"
    >
      <ion-item-divider>
        <ion-label>Settings</ion-label>
      </ion-item-divider>

      <ion-item>
        <ion-input label="Personal access token" formControlName="apiKey">
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-input label="Base ID" formControlName="baseId"> </ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          label="Table name"
          formControlName="tableName"
          autocapitalize="on"
          [spellcheck]="true"
        >
        </ion-input>
      </ion-item>

      <ion-item-divider>
        <ion-label>Fields</ion-label>
      </ion-item-divider>

      <div formGroupName="fieldNames">
        <ion-item>
          <ion-input
            label="Tag"
            formControlName="tag"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Phone"
            formControlName="phone"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Date"
            formControlName="pickupDate"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Photos"
            formControlName="photos"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Server"
            formControlName="server"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Notes"
            formControlName="notes"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Number of Items"
            formControlName="numberOfItems"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
        <ion-item>
          <ion-input
            label="Status"
            formControlName="status"
            autocapitalize="on"
            [spellcheck]="true"
          >
          </ion-input>
        </ion-item>
      </div>
    </ion-list>

    <div class="actions">
      <ion-button
        *ngIf="!isAirtableIntegrationAvailable || isAirtableIntegrationEdit"
        color="primary"
        (click)="saveAirtableIntegration()"
        [disabled]="airtableFormGroup.invalid"
        fill="clear"
        class="action ion-no-margin"
        appHaptics
      >
        Save
      </ion-button>
      <ion-button
        *ngIf="isAirtableIntegrationAvailable && !isAirtableIntegrationEdit"
        color="primary"
        (click)="editAirtableIntegration()"
        fill="clear"
        class="action ion-no-margin"
        appHaptics
      >
        Edit
      </ion-button>
      <ion-button
        *ngIf="isAirtableIntegrationEdit"
        color="danger"
        (click)="cancelEditingAirtableIntegration()"
        fill="clear"
        class="action ion-no-margin"
        appHaptics
      >
        Cancel
      </ion-button>
      <ion-button
        *ngIf="isAirtableIntegrationAvailable && !isAirtableIntegrationEdit"
        color="danger"
        (click)="deleteAirtableIntegration()"
        fill="clear"
        class="action ion-no-margin"
        appHaptics
      >
        Delete
      </ion-button>
    </div>
  </ion-card>

  <ion-card class="integration-card">
    <ion-card-header>
      <ion-card-subtitle>
        Allows you to synchronize all tags with GoHighLevel subaccount
        (contacts, opportunities and pipelines)
      </ion-card-subtitle>
      <ion-card-title>GoHighLevel</ion-card-title>
    </ion-card-header>
    <ion-list
      *ngIf="!isGoHighLevelIntegrationAvailable || isGoHighLevelIntegrationEdit"
      [formGroup]="goHighLevelFormGroup"
    >
      <ion-item-divider>
        <ion-label>Settings</ion-label>
      </ion-item-divider>
      <ion-item [disabled]="isGoHighLevelIntegrationEdit">
        <ion-input label="Client ID" formControlName="clientId"> </ion-input>
      </ion-item>
      <ion-item [disabled]="isGoHighLevelIntegrationEdit">
        <ion-input label="Client Secret" formControlName="clientSecret">
        </ion-input>
      </ion-item>
      <ion-item [disabled]="isGoHighLevelIntegrationEdit">
        <ion-input label="Pipeline Name" formControlName="pipelineName">
        </ion-input>
      </ion-item>
    </ion-list>
    <ion-list
      *ngIf="!isGoHighLevelIntegrationAvailable || isGoHighLevelIntegrationEdit"
      [formGroup]="tagsSettingsFormGroup"
    >
      <ion-item-divider>
        <ion-label>Mandatory Statuses</ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-input
          label="Default stage name"
          formControlName="defaultStatusName"
        >
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          label="Ready For Pickup stage name"
          formControlName="readyForPickupStatusName"
        >
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          label="Complete stage name"
          formControlName="completedStatusName"
        >
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          label="1st Reminder stage name"
          formControlName="reminder1stStatusName"
        >
        </ion-input>
      </ion-item>
      <ion-item>
        <ion-input
          label="2nd Reminder stage name"
          formControlName="reminder2ndStatusName"
        >
        </ion-input>
      </ion-item>
    </ion-list>

    <div class="actions">
      <ion-button
        *ngIf="
          !isGoHighLevelIntegrationAvailable || isGoHighLevelIntegrationEdit
        "
        color="primary"
        (click)="saveGoHighLevelIntegration()"
        [disabled]="
          isGoHighLevelIntegrationAvailable
            ? tagsSettingsFormGroup.invalid
            : goHighLevelFormGroup.invalid
        "
        fill="clear"
        class="action ion-no-margin"
        appHaptics
      >
        Save
      </ion-button>
      <ion-button
        *ngIf="
          isGoHighLevelIntegrationAvailable && !isGoHighLevelIntegrationEdit
        "
        color="primary"
        (click)="editGoHighLevelIntegration()"
        fill="clear"
        class="action ion-no-margin"
        appHaptics
      >
        Edit
      </ion-button>
      <ion-button
        *ngIf="isGoHighLevelIntegrationEdit"
        color="danger"
        (click)="cancelEditingGoHighLevelIntegration()"
        fill="clear"
        class="action ion-no-margin"
        appHaptics
      >
        Cancel
      </ion-button>
      <ion-button
        *ngIf="
          isGoHighLevelIntegrationAvailable && !isGoHighLevelIntegrationEdit
        "
        color="danger"
        (click)="deleteGoHighLevelIntegration()"
        fill="clear"
        class="action ion-no-margin"
        appHaptics
      >
        Delete
      </ion-button>
    </div>
  </ion-card>
</ng-template>
