<ion-card-header>
  <ion-card-title>
    {{ backdoorInfo.title }}
  </ion-card-title>
</ion-card-header>

<ion-card-content>
  {{ backdoorInfo.message }}
</ion-card-content>

<ion-grid class="app-w100 ion-padding-bottom">
  <ion-row
    *ngFor="let link of backdoorInfo.links"
    class="ion-justify-content-center"
  >
    <ion-col size="8">
      <ion-button
        color="primary"
        (click)="goToUrl(link)"
        appHaptics
        expand="block"
      >
        {{ link.text }}
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row class="ion-justify-content-center">
    <ion-col size="8">
      <ion-button
        expand="block"
        *ngIf="backdoorInfo.canDismiss || preview"
        color="light"
        (click)="dismiss()"
        appHaptics
      >
        {{
          preview && !backdoorInfo.canDismiss
            ? 'Cancel (Only in Preview)'
            : 'Cancel'
        }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
