import { utcToZonedTime } from 'date-fns-tz';
import { getClientTimeZone } from './get-client-time-zone';

export function getZonedTime(date: Date): Date {
  // Get the time zone set on the user's device
  const userTimeZone = getClientTimeZone();

  // Use date-fns-tz to convert from UTC to a zoned time
  return utcToZonedTime(date, userTimeZone);
}
