<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>Review</ion-title>
  </ion-toolbar>
  <ion-toolbar class="app-toolbar">
    <ion-buttons>
      <ion-button
        color="primary"
        class="toolbar-range-button"
        (click)="onRangeClick()"
        appHaptics
        [disabled]="disableSubmit"
        [disableHaptics]="disableSubmit"
      >
        <ng-container *ngIf="!range; else rangeOneDay">
          Select Date Range
        </ng-container>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <div class="layout-container">
    <div class="top-content">
      <div class="scrollable-table" [hidden]="!calculatedUsers.length">
        <div class="users-table">
          <div class="users-table-row users-table-header">
            <div class="users-table-col users-table-col--short">Notify</div>
            <div class="users-table-col">User</div>
            <div class="users-table-col">Hours Worked</div>
            <div class="users-table-col">
              Points
              <ion-button
                id="points-info-btn"
                fill="clear"
                class="info-btn"
                (click)="showPointsPopover = true"
              >
                <ion-icon slot="icon-only" name="help-circle-outline" />
              </ion-button>
            </div>
            <div class="users-table-col">Points/Hour</div>
            <div class="users-table-col" *ngFor="let pool of calculatedPools">
              {{ pool.name }}
            </div>
            <div class="users-table-col">Earnings/Hour</div>
            <div *ngIf="!disableSubmit" class="users-table-col">
              Announcement
            </div>
          </div>
          <div
            class="users-table-row"
            *ngFor="let user of calculatedUsers"
            [ngClass]="{ 'users-table-row--disabled': !user.notify }"
          >
            <div
              class="users-table-col users-table-col--short users-table-col--checkbox"
            >
              <ion-checkbox
                appHaptics
                [(ngModel)]="user.notify"
                appHaptics
                [disabled]="disableSubmit"
                [disableHaptics]="disableSubmit"
              ></ion-checkbox>
            </div>
            <div class="users-table-col">
              {{ user.firstName }} {{ user.lastName }}
            </div>
            <div class="users-table-col">
              {{ user.totalHours.toFixed(2) }} hours
            </div>
            <div class="users-table-col">{{ user.totalPoints }} points</div>
            <div class="users-table-col">
              {{ formatPointsPerHour(user.totalPoints, user.totalHours) }}
            </div>
            <div class="users-table-col" *ngFor="let pool of calculatedPools">
              {{ formatRelativePoolValue(pool, user) }}
            </div>
            <div class="users-table-col">
              {{ formatEarningsPerHour(user) }}
            </div>
            <div class="users-table-col" *ngIf="!disableSubmit">
              <ion-button
                fill="clear"
                color="primary"
                (click)="showAnnouncementPreview(user.userId)"
                class="ion-no-margin row-action-button"
              >
                Preview
              </ion-button>
            </div>
          </div>
          <div class="users-table-row users-table-header">
            <div class="users-table-col users-table-col--short"></div>
            <div class="users-table-col">Total</div>
            <div class="users-table-col">
              {{ sums.totalHours.toFixed(2) }} hours
            </div>
            <div class="users-table-col">
              {{ sums.totalPoints.toFixed(2) }} points
            </div>
            <div class="users-table-col">
              Average: {{ averagePointsPerHour }}
            </div>
            <div class="users-table-col" *ngFor="let pool of calculatedPools">
              {{ formatPoolTotal(pool) }}
            </div>
            <div class="users-table-col"></div>
            <div class="users-table-col" *ngIf="!disableSubmit"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              *ngIf="!disableSubmit"
              color="dark"
              (click)="submit()"
              appHaptics
              expand="block"
              [disabled]="!range"
              [disableHaptics]="!range"
            >
              Submit and Notify
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>

<ng-template #rangeOneDay>
  <ng-container *ngIf="isOneDayRange; else rangeTwoDays">
    {{ range.start | date : 'MMM d, y' }}
  </ng-container>
</ng-template>

<ng-template #rangeTwoDays>
  {{ range.start | date : 'MMM d, y' }} -
  {{ range.end | date : 'MMM d, y' }}
</ng-template>

<ion-popover trigger="points-info-btn">
  <ng-template>
    <ion-content>
      <div class="info-popover">
        <div class="info-popover__title">Includes points from:</div>
        <div *ngFor="let ipf of includePointsFromText">
          {{ ipf }}
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-popover>
