<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>Task History</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="ion-padding-vertical">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <div
      *ngFor="let performance of taskPerformances; trackBy: trackItems"
      class="ion-padding"
    >
      <div class="performance-main">
        <div class="performance-date">
          {{ performance.completeTime | date : 'M/d/yy h:mm a' }}
        </div>
        <div class="performance-user">
          {{ performance.user.firstName }} {{ performance.user.lastName }}
        </div>
      </div>
      <p class="performance-note" *ngIf="performance.note">
        Note: <span>{{ performance.note.body | wysiwygPreview }}</span>
      </p>
    </div>

    <ion-infinite-scroll
      (ionInfinite)="onIonInfinite()"
      #infiniteScroll
      [disabled]="taskPerformances.length === 0 || isLoading || !hasNextPage"
    >
      <ion-infinite-scroll-content></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </app-width-restricted-container>
</ion-content>
