import { NgModule } from '@angular/core';
import { EditSystemEntityModalComponent } from './edit-system-entity-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectivesModule } from '@app/modules/directives.module';
import { DigitOnlyModule } from '@uiowa/digit-only';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    DigitOnlyModule
  ],
  exports: [EditSystemEntityModalComponent],
  declarations: [EditSystemEntityModalComponent]
})
export class EditSystemEntityModalModule {}
