import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  map,
  switchMap,
  take
} from 'rxjs';
import { UserApiService } from '@app/core/service/user-api.service';
import { DateInterval } from '@backend/types/date-interval';
import { InsightUserStatsWithShare } from './insight-user-stats-with-share';
import { InsightUserStats } from '@app/types/insight-user-stats';
import { Sort } from '@angular/material/sort';

type FieldsToSum = keyof Pick<
  InsightUserStats,
  'pointsReceived' | 'pointsSent' | 'tasksCompleted'
>;

interface DisplayOptions {
  lastNameFirst: boolean;
  fieldsToSum: FieldsToSum[];
}

const FIELDS_TO_SUM: FieldsToSum[] = [
  'pointsReceived',
  'pointsSent',
  'tasksCompleted'
];

const DEFAULT_SORT: Sort = {
  active: 'fullName',
  direction: 'asc'
};

function sumField(a: Record<string, any>[], field: string): number {
  return a.reduce((acc, item) => acc + (item[field] as number), 0);
}

function getAscPredicate(field: keyof InsightUserStatsWithShare) {
  return (a: InsightUserStatsWithShare, b: InsightUserStatsWithShare) => {
    if (a[field] < b[field]) {
      return -1;
    }
    if (a[field] > b[field]) {
      return 1;
    }
    return 0;
  };
}

function getDescPredicate(field: keyof InsightUserStatsWithShare) {
  return (a: InsightUserStatsWithShare, b: InsightUserStatsWithShare) => {
    if (a[field] > b[field]) {
      return -1;
    }
    if (a[field] < b[field]) {
      return 1;
    }
    return 0;
  };
}

function sortData(data: InsightUserStatsWithShare[], sort: Sort): void {
  data.sort(
    sort.direction === 'asc'
      ? getAscPredicate(sort.active as any)
      : getDescPredicate(sort.active as any)
  );
}

@Injectable()
export class InsightsDataSourceService extends DataSource<InsightUserStatsWithShare> {
  private _dateRange = new ReplaySubject<DateInterval>(1);
  private _data = new ReplaySubject<InsightUserStatsWithShare[]>(1);
  private _subscription = new Subscription();
  private _options: DisplayOptions = {
    lastNameFirst: false,
    fieldsToSum: ['pointsReceived', 'pointsSent', 'tasksCompleted']
  };
  private _sort = new BehaviorSubject<Sort>(DEFAULT_SORT);

  public constructor(private _api: UserApiService) {
    super();
  }

  public setSort(sort: Sort): void {
    this._sort.next(sort);
  }

  public connect(): Observable<readonly InsightUserStatsWithShare[]> {
    this._subscription.add(
      this._dateRange
        .pipe(
          switchMap((range) => this._api.getInsights(range)),
          switchMap((data) => this._sort.pipe(map((sort) => [data, sort])))
        )
        .subscribe(([data, sort]: [InsightUserStats[], Sort]) =>
          this._putData(data, sort)
        )
    );
    return this._data.asObservable();
  }

  public disconnect(): void {
    this._subscription.unsubscribe();
  }

  public get options() {
    return this._options;
  }

  public set options(v: DisplayOptions) {
    this._options = v;
    this._data
      .pipe(take(1))
      .subscribe((data) => this._putData(data, this._sort.value));
  }

  public setDateRange(range: DateInterval): void {
    this._dateRange.next(range);
  }

  private _putData(originalData: InsightUserStats[], sort: Sort): void {
    const enrichedData = this._enrichData(originalData);
    sortData(enrichedData, sort.direction === '' ? DEFAULT_SORT : sort);
    this._data.next(enrichedData);
  }

  private _enrichData(
    originalData: InsightUserStats[]
  ): InsightUserStatsWithShare[] {
    const data = originalData.map((d) => ({
      ...d,
      fullName: this._options.lastNameFirst
        ? `${d.lastName} ${d.firstName}`
        : `${d.firstName} ${d.lastName}`
    }));

    const dataWithPoints = data.map((item) => {
      const pointsSum: number = FIELDS_TO_SUM.reduce((acc, fieldName) => {
        if (this._options.fieldsToSum.includes(fieldName)) {
          return acc + item[fieldName];
        }
        return acc;
      }, 0);

      const totalPoints: number = FIELDS_TO_SUM.reduce((acc, fieldName) => {
        return acc + item[fieldName];
      }, 0);

      return {
        ...item,
        pointsSum,
        totalPoints
      };
    });

    const tasksCompletedSum = sumField(dataWithPoints, 'tasksCompleted');
    const pointsReceivedSum = sumField(dataWithPoints, 'pointsReceived');
    const pointsSentSum = sumField(dataWithPoints, 'pointsSent');
    const pointsSumTotal = sumField(dataWithPoints, 'pointsSum');
    const totalPointsSum = sumField(dataWithPoints, 'totalPoints');

    const dataWithShare = dataWithPoints.map((item) => {
      const tasksCompletedShareDecimal =
        tasksCompletedSum !== 0 ? item.tasksCompleted / tasksCompletedSum : 0;
      const tasksCompletedShare = tasksCompletedShareDecimal * 100;

      const pointsReceivedShareDecimal =
        pointsReceivedSum !== 0 ? item.pointsReceived / pointsReceivedSum : 0;
      const pointsReceivedShare = pointsReceivedShareDecimal * 100;

      const pointsSentShareDecimal =
        pointsSentSum !== 0 ? item.pointsSent / pointsSentSum : 0;
      const pointsSentShare = pointsSentShareDecimal * 100;

      const pointsSumShare =
        pointsSumTotal !== 0 ? (item.pointsSum / pointsSumTotal) * 100 : 0;

      const totalPointsShareDecimal =
        totalPointsSum !== 0 ? item.totalPoints / totalPointsSum : 0;
      const totalPointsShare = totalPointsShareDecimal * 100;

      return {
        ...item,
        tasksCompletedShare,
        tasksCompletedShareDecimal,
        pointsReceivedShare,
        pointsReceivedShareDecimal,
        pointsSentShare,
        pointsSentShareDecimal,
        pointsSumShare,
        totalPointsShare,
        totalPointsShareDecimal
      };
    });
    return dataWithShare;
  }

  public getCurrentData() {
    return this._data.asObservable();
  }
}
