import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChecklistFolderComponent } from './checklist-folder.component';
import { IonicModule } from '@ionic/angular';
import { ChecklistItemModule } from '../checklist-item/checklist-item.module';
import { DirectivesModule } from '../directives.module';
import { IsOverduePipe } from '@app/pipes/standalone/is-overdue.pipe';

@NgModule({
  declarations: [ChecklistFolderComponent],
  imports: [
    CommonModule,
    IonicModule,
    ChecklistItemModule,
    DirectivesModule,
    IsOverduePipe
  ],
  exports: [ChecklistFolderComponent]
})
export class ChecklistFolderModule {}
