import { Component, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-date-list',
  templateUrl: './date-list.component.html',
  styleUrls: ['./date-list.component.scss']
})
export class DateListComponent {
  @Input()
  public formArray: FormArray<FormControl<string>>;

  protected onDateRemoveClick(index: number): void {
    this.formArray.removeAt(index);
    this.formArray.markAsDirty();
  }
}
