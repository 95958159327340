<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title>Select Group</ion-title>
  </ion-toolbar>
  <ion-toolbar class="app-common-background">
    <ion-searchbar
      (ionInput)="searchbarInput($event)"
      (keyup.enter)="selectFirst()"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list>
    <ion-item
      button
      *ngFor="
        let taskFolder of taskFolders | filterTaskFolders : filter;
        trackBy: trackItems
      "
      (click)="checkboxClick($event, taskFolder._id)"
      appHaptics
      [ngClass]="{ 'highlighted-item': getFirst()?._id === taskFolder._id }"
    >
      <ion-label class="task-folder-name ion-text-capitalize">
        {{ taskFolder.title }}
      </ion-label>
    </ion-item>
  </ion-list>
</ion-content>
