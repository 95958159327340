import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  HostBinding
} from '@angular/core';
import { AuthenticationService } from '@app/core';
import { LibraryDocModel } from '@app/core/model/library-doc.model';
import { IonNav } from '@ionic/angular';
import { LibraryDocViewComponent } from '../library-doc-view/library-doc-view.component';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-library-item',
  templateUrl: './library-item.component.html',
  styleUrls: ['./library-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryItemComponent {
  @Input()
  public readonly doc: {
    originalDoc: LibraryDocModel;
    versions: LibraryDocModel[];
    latestVersion: LibraryDocModel | null;
  };

  @Input()
  public readonly isSelected: boolean = false;

  @Input()
  public animationOrder?: number = -1;

  @Output()
  public selected = new EventEmitter<void>();

  @HostBinding('style')
  get myStyle(): SafeStyle {
    return this._sanitizer.bypassSecurityTrustStyle(
      `--animation-order: ${this.animationOrder};`
    );
  }

  @HostBinding('class.animated')
  public get isAnimated() {
    return this.animationOrder !== -1;
  }

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _ionNav: IonNav,
    private readonly _sanitizer: DomSanitizer
  ) {}

  protected isUnread(doc: LibraryDocModel) {
    return !doc.signed.find(
      (u) => u.user._id === this._authenticationService.user._id
    );
  }

  protected onVersionClick(libraryDoc: LibraryDocModel) {
    this._ionNav.push(LibraryDocViewComponent, { libraryDoc });
  }

  protected expandLibraryDoc(event: any) {
    event.stopPropagation();

    this.selected.emit();
  }
}
