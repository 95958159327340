import { Injectable } from '@angular/core';
import { map, merge, ReplaySubject, switchMap, take, tap, timer } from 'rxjs';
import { repeat, share, Subject } from 'rxjs';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import { ObjectId } from '../../types/object-id';
import { TasksApiService } from './tasks-api.service';
import { TaskDto } from '@app/types/task-dto';
import { TaskInputDto } from '@app/types/task-input-dto';
import { ESkillMatrixStatus } from '@backend/models/types/task';

@Injectable({ providedIn: 'root' })
export class TaskListService {
  private _listRefresher$ = new Subject<void>();
  private _listSource$ = new Subject<MongoStoredObject<TaskDto>[]>();

  public list$ = merge(
    this._tasksApiService.getAll().pipe(
      repeat({
        delay: () => this._listRefresher$
      })
    ),
    this._listSource$
  ).pipe(
    share({
      connector: () => new ReplaySubject(1),
      resetOnRefCountZero: () => timer(1000)
    })
  );

  public constructor(private readonly _tasksApiService: TasksApiService) {}

  public update(): void {
    this._listRefresher$.next();
  }

  public getTaskById(taskId: ObjectId) {
    return this._tasksApiService.getTaskById(taskId).pipe(
      switchMap((taskSaved) =>
        this.list$.pipe(
          take(1),
          map((list) => list.map((t) => (t._id !== taskId ? t : taskSaved))),
          tap((list) => {
            this._listSource$.next(list);
          }),
          map(() => taskSaved)
        )
      )
    );
  }

  public createTask(task: TaskInputDto) {
    return this._tasksApiService.create(task).pipe(
      switchMap((task) =>
        this.list$.pipe(
          take(1),
          map((list) => [...list, task]),
          tap((list) => {
            this._listSource$.next(list);
          }),
          map(() => task)
        )
      )
    );
  }

  public updateTask(taskId: ObjectId, task: TaskDto) {
    return this._tasksApiService.patch(taskId, task).pipe(
      switchMap((taskSaved) =>
        this.list$.pipe(
          take(1),
          map((list) => list.map((t) => (t._id !== taskId ? t : taskSaved))),
          tap((list) => {
            this._listSource$.next(list);
          }),
          map(() => taskSaved)
        )
      )
    );
  }

  public deleteTask(taskId: ObjectId) {
    this._tasksApiService.delete(taskId).subscribe({
      next: () => {
        this.update();
      }
    });
  }

  public restoreTask(taskId: ObjectId) {
    this._tasksApiService.restore(taskId).subscribe({
      next: () => {
        this.update();
      }
    });
  }

  public reorderTasksMultiple(
    taskFolders: {
      taskFolder: string | null;
      tasks: { taskId: string; orderIndex: number }[];
    }[]
  ): void {
    this._tasksApiService.reorderTasksMultiple(taskFolders).subscribe({
      next: (list) => {
        this._listSource$.next(list);
      }
    });
  }

  public refoldTasksMultiple(
    taskFolders: { taskFolder: string | null; tasks: string[] }[]
  ): void {
    this._tasksApiService.refoldTasksMultiple(taskFolders).subscribe({
      next: (list) => {
        this._listSource$.next(list);
      }
    });
  }

  public patchTasks(
    userId: ObjectId,
    tasks: {
      _id: ObjectId;
      isNotificationUponCompletion?: boolean;
      skillMatrixStatus?: ESkillMatrixStatus;
    }[]
  ): void {
    this._tasksApiService.patchTasks(userId, tasks).subscribe({
      next: (list) => {
        this._listSource$.next(list);
      }
    });
  }
}
