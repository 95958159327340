<ion-content color="light" #ionContent>
  <app-width-restricted-container
    class="pull-to-search"
    [ngClass]="{ 'pull-to-search--init': init }"
  >
    <ion-searchbar
      (ionInput)="onSearchInput($event)"
      [debounce]="searchDebounce"
    />
  </app-width-restricted-container>

  <ng-content></ng-content>
</ion-content>
