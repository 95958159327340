import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ObjectId } from '@app/types/object-id';
import {
  EIntegrationProvider,
  IIntegration
} from '@backend/models/types/integration';
import { Observable, Subject, repeat, shareReplay } from 'rxjs';
import { EnvironmentService } from '@app/core/service/environment.service';

@Injectable()
export class IntegrationApiService {
  protected serverUrl = '';

  private readonly _update$ = new Subject<void>();
  public tenantIntegrations$: Observable<MongoStoredObject<IIntegration[]>> =
    this.getIntegrations().pipe(
      repeat({ delay: () => this._update$ }),
      shareReplay(1)
    );

  public constructor(
    private readonly _httpClient: HttpClient,
    private readonly _environmentService: EnvironmentService
  ) {
    this.serverUrl = this._environmentService.serverUrl;
  }

  public getIntegrations(
    tenantId?: ObjectId
  ): Observable<MongoStoredObject<IIntegration[]>> {
    return this._httpClient.get<MongoStoredObject<IIntegration[]>>(
      '/integrations',
      tenantId
        ? {
            params: { tenantId }
          }
        : {}
    );
  }

  public getPublicSettings(provider: EIntegrationProvider): Observable<any> {
    return this._httpClient.get<any>(
      `/integrations/${provider}/public-settings`
    );
  }

  public initIntegration(
    provider: EIntegrationProvider,
    settings: any,
    tenantId?: ObjectId,
    publicSettingsFields?: string[]
  ): Observable<MongoStoredObject<IIntegration> & { initUrl: string }> {
    return this._httpClient.post<
      MongoStoredObject<IIntegration> & { initUrl: string }
    >(
      `/integrations/${provider}/init`,
      tenantId
        ? {
            settings,
            tenantId,
            publicSettingsFields,
            apiBaseUrl: this.serverUrl
          }
        : { settings, publicSettingsFields, apiBaseUrl: this.serverUrl }
    );
  }

  public setIntegration(
    provider: EIntegrationProvider,
    settings: any,
    tenantId?: ObjectId,
    publicSettingsFields?: string[]
  ): Observable<MongoStoredObject<IIntegration>> {
    return this._httpClient.put<MongoStoredObject<IIntegration>>(
      `/integrations/${provider}`,
      tenantId
        ? {
            settings,
            tenantId,
            publicSettingsFields
          }
        : { settings, publicSettingsFields }
    );
  }

  public getIntegration(
    provider: EIntegrationProvider,
    tenantId?: ObjectId
  ): Observable<MongoStoredObject<IIntegration>> {
    return this._httpClient.get<MongoStoredObject<IIntegration>>(
      `/integrations/${provider}`,
      tenantId
        ? {
            params: {
              tenantId
            }
          }
        : {}
    );
  }

  public deleteIntegration(
    provider: EIntegrationProvider,
    tenantId?: ObjectId
  ): Observable<null> {
    return this._httpClient.delete<null>(`/integrations/${provider}`, {
      params: tenantId ? { tenantId } : {}
    });
  }

  public update(): void {
    this._update$.next();
  }
}
