import { NgModule } from '@angular/core';
import { CompensationPoolModalComponent } from './compensation-pool-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserSelectorComponent } from '../user-selector/user-selector.component';
import { WideButtonComponent } from '../wide-button/wide-button.component';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    UserSelectorComponent,
    WideButtonComponent,
    DirectivesModule
  ],
  exports: [CompensationPoolModalComponent],
  declarations: [CompensationPoolModalComponent]
})
export class CompensationPoolModalModule {}
