import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { IonDatetime, IonPopover } from '@ionic/angular';
import { toDate } from 'date-fns-tz';
import { ScheduleFormBaseComponent } from '../shcedule-form-base.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-schedule-specific-dates',
  templateUrl: './schedule-specific-dates.component.html',
  styleUrls: ['./schedule-specific-dates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleSpecificDatesComponent extends ScheduleFormBaseComponent {
  @ViewChild('popover')
  protected popover: IonPopover;
  @ViewChild('datetime')
  protected datetime: IonDatetime;

  protected isOpen = false;

  protected onDateButtonClick(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }

  /**
   *
   * @param string date date in format "YYYY-MM-DD"
   * @returns
   */
  private _addDateToList(date: string): void {
    if (this.form.controls.dates.value.some((v) => v === date)) {
      return;
    }
    const dateTime = toDate(date).getTime();
    const values = this.form.controls.dates.value.map((v) => toDate(v));
    let i = 0;
    for (; i < values.length; i++) {
      if (dateTime <= values[i].getTime()) {
        break;
      }
    }
    this.form.controls.dates.insert(i, new FormControl<string>(date));
    this.form.controls.dates.markAsDirty();
  }

  protected onDateRemoveClick(index: number): void {
    this.form.controls.dates.removeAt(index);
    this.form.controls.dates.markAsDirty();
  }

  protected onCalendarDoneClick(): void {
    this.datetime.confirm().then(() => {
      if (this.datetime.value === undefined) {
        return;
      }
      for (const date of this.datetime.value as string[]) {
        this._addDateToList(date);
      }
      this.datetime.reset();
    });
    this.isOpen = false;
  }
}
