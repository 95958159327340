<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-title>Backdoor Messaging</ion-title>
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content color="light">
  <app-width-restricted-container class="container-with-fab">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ion-card *ngFor="let message of messages" class="message-card">
      <ion-card-header>
        <ion-card-title *ngIf="message.title">
          {{ message.title }}
        </ion-card-title>
        <ion-card-subtitle>
          {{ message.versionRange }}
          ({{ message.platformsToShow | formatPlatforms }})
        </ion-card-subtitle>
      </ion-card-header>

      <ion-list>
        <ion-item>
          <ion-label>Message: {{ message.message }}</ion-label>
        </ion-item>
        <ion-item>
          <ion-label *ngIf="message.showToAllTenants"> Tenants: All </ion-label>
          <ion-label *ngIf="!message.showToAllTenants">
            Tenants: {{ message | backdoorInfoTenants : tenants }}
          </ion-label>
        </ion-item>
        <ion-button
          class="action"
          fill="clear"
          color="primary"
          (click)="updateBackdoorInfo(message)"
          appHaptics
        >
          Edit
        </ion-button>
        <ion-button
          class="action"
          fill="clear"
          color="danger"
          (click)="deleteBackdoorInfo(message)"
          appHaptics
        >
          Delete
        </ion-button>
      </ion-list>
    </ion-card>

    <ng-container *ngIf="messages.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>
  </app-width-restricted-container>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end" class="main-fab">
    <ion-fab-button color="dark" (click)="onFabClick()" appHaptics>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="No Backdoor Messages"></app-empty-stub>
</ng-template>
