<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>{{ announcement.title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="layout-container">
    <div class="top-content">
      <ion-grid>
        <ion-row>
          <ion-col size="12">
            <app-wysiwyg-editor
              [(value)]="body"
              [disableAttachments]="true"
              [bodyPlaceholder]="
                announcement.respondButtonText ===
                announcementRespondButtonText.REQUEST_CLARIFICATION
                  ? 'Please provide a short message to help the sender understand what
        you’d like to better understand'
                  : 'Message'
              "
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              color="dark"
              expand="block"
              [disabled]="!body"
              [disableHaptics]="!body"
              appHaptics
              (click)="onRespondClick()"
            >
              {{ respondButtonText }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
