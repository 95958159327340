<ion-header class="ion-no-border image-viewer-modal__header">
  <ion-toolbar class="image-viewer-modal__toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="close()" appHaptics>
        <ion-icon name="close" />
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="image-viewer-modal__content">
  <swiper-container
    appSwiper
    #swiper
    [config]="swiperConfig"
    class="image-viewer-modal__main-swiper"
    zoom
  >
    <swiper-slide
      *ngFor="let image of images"
      class="image-viewer-modal__main-slide"
    >
      <div class="swiper-zoom-container">
        <img [src]="image" class="image-viewer-modal__main-image" />
      </div>
    </swiper-slide>
  </swiper-container>

  <swiper-container
    appSwiper
    #swiperThumbs
    [config]="swiperThumbsConfig"
    class="image-viewer-modal__thumb-swiper"
    *ngIf="images.length > 1"
  >
    <swiper-slide
      *ngFor="let image of images; let i = index"
      class="image-viewer-modal__thumb-slide"
      (click)="onThumbClick(i)"
    >
      <img [src]="image" class="image-viewer-modal__thumb-image" />
    </swiper-slide>
  </swiper-container>
</ion-content>
