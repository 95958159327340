import { Pipe, PipeTransform } from '@angular/core';
import { parse } from 'date-fns';

@Pipe({
  name: 'transformerApplicator'
})
export class transformerApplicatorPipe implements PipeTransform {
  public transform(
    value: Date | string | null,
    transformer: (value: Date) => Date
  ): Date | null {
    if (value === null) {
      return null;
    }
    if (typeof value === 'string') {
      value = parse(value, 'yyyy-MM-dd', new Date());
    }
    return transformer(value);
  }
}
