import { Pipe, PipeTransform } from '@angular/core';
import { toDate, format } from 'date-fns-tz';
import { DateFlags } from '../types/date-flags.enum';
import { stripeDate } from '../../../utils/stripe-date';

@Pipe({
  name: 'shortDate'
})
export class ShortDatePipe implements PipeTransform {
  public transform(dateString: string): string {
    if (dateString.length > 3) {
      // this part for the particular day calendar
      const [zonedTime, flags] = stripeDate(dateString);
      const date = toDate(zonedTime);
      if ((flags ?? '').includes(DateFlags.LAST_DAY)) {
        return format(date, 'M-') + 'Last day';
      } else {
        return format(date, 'M-dd');
      }
    }
    // this part for the month calendar
    if (dateString.includes(DateFlags.LAST_DAY)) {
      return 'Last day';
    } else {
      const match = dateString.match(/(\d+)/);
      if (match) {
        return match[1];
      }
      throw new Error(`Can't extract month day number part from ${dateString}`);
    }
  }
}
