import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from '@app/core/service/toast.service';
import { TodaysShiftService } from '@app/core/service/todays-shift.service';
import { LoadingController, ModalController } from '@ionic/angular';
import { add } from 'date-fns';

@Component({
  selector: 'app-todays-shift-modal',
  templateUrl: './todays-shift-modal.component.html'
})
export class TodaysShiftModalComponent {
  protected form = new FormGroup({
    start: new FormControl<string>('00:00', Validators.required),
    end: new FormControl<string>('00:00', Validators.required)
  });

  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController,
    private readonly _todaysShiftService: TodaysShiftService,
    private readonly _toastService: ToastService
  ) {
    const start = new Date();
    start.setMinutes(Math.floor(start.getMinutes() / 30) * 30);

    const end = add(start, { hours: 6 });

    this.form.reset({
      start: `${start.getHours()}:${start.getMinutes()}`,
      end: `${end.getHours()}:${end.getMinutes()}`
    });
  }

  protected async iDontWorkToday() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._todaysShiftService.setShiftTime().subscribe({
      next: (res) => {
        loading.dismiss();
        this._modalCtrl.dismiss(res, 'confirm', 'todays-shift-modal');
      },
      error: () => {
        loading.dismiss();
        this._toastService.presentToast('Unable to set the shift time');
      }
    });
  }

  protected async onSubmitClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    const formValue = this.form.value;

    this._todaysShiftService
      .setShiftTime(formValue.start, formValue.end)
      .subscribe({
        next: (res) => {
          loading.dismiss();
          this._modalCtrl.dismiss(res, 'confirm', 'todays-shift-modal');
        },
        error: () => {
          loading.dismiss();
          this._toastService.presentToast('Unable to set the shift time');
        }
      });
  }
}
