import {
  ChangeDetectionStrategy,
  Component,
  Output,
  EventEmitter,
  ViewChild,
  Input,
  ChangeDetectorRef,
  AfterViewInit
} from '@angular/core';
import { hasTouchSupport, isMobile } from '@app/utils/is-mobile';
import { IonContent } from '@ionic/angular';

@Component({
  selector: 'app-pull-to-search',
  templateUrl: './pull-to-search.component.html',
  styleUrls: ['./pull-to-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PullToSearchComponent implements AfterViewInit {
  @Input()
  public readonly searchDebounce?: number = 0;

  @Output()
  public searchInput = new EventEmitter<string>();

  @ViewChild('ionContent')
  public ionContent!: IonContent;

  protected init = false;

  public constructor(private readonly _cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.init = false;
      this._cdr.markForCheck();

      setTimeout(() => {
        this.scrollToTop();
      });
    });
  }

  public initSearch() {
    if (!this.init) {
      setTimeout(() => {
        this.init = true;
        this._cdr.markForCheck();

        setTimeout(() => {
          if (isMobile() || hasTouchSupport()) {
            this.scrollToContent();
          }
        });
      });
    }
  }

  public scrollToContent() {
    this.ionContent && this.ionContent.scrollToPoint(0, 60);
  }

  public scrollToTop() {
    this.ionContent && this.ionContent.scrollToTop();
  }

  protected onSearchInput(e: any) {
    this.searchInput.emit(e.detail.value);
  }
}
