import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IAttachment } from '@backend/models/types/attachment';
import { ITask } from '@backend/models/types/task';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { Observable } from 'rxjs';

@Injectable()
export class DailyReportApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getDailyReport(params: { from: string; to: string }): Observable<{
    completed: {
      userId: string;
      fullName: string;
      points: number;
      performances: MongoStoredObject<
        ITaskPerformance & {
          task: MongoStoredObject<ITask>;
          note?: {
            attachments: MongoStoredObject<IAttachment>[];
          };
        }
      >[];
    }[];
  }> {
    return this._httpClient.get<{
      completed: {
        userId: string;
        fullName: string;
        points: number;
        performances: MongoStoredObject<
          ITaskPerformance & {
            task: MongoStoredObject<ITask>;
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          }
        >[];
      }[];
    }>('/daily-report', {
      params
    });
  }
}
