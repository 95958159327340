import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '@app/core/model/user.model';
import { TenantService } from '@app/core/service/tenant.service';
import { UserListService } from '@app/core/service/user-list.service';
import { ObjectId } from '@app/types/object-id';
import { createDefaultPayroll } from '@app/utils/create-default-payroll';
import { createDefaultPayrollForFrequency } from '@app/utils/create-default-payroll-for-frequency';
import { isSameSet } from '@app/utils/is-same-set';
import { PayFrequency } from '@backend/types/pay-frequency';
import { PayrollDefinition } from '@backend/types/payroll-definition';
import {
  IonModal,
  LoadingController,
  IonContent,
  IonNav,
  PickerButton,
  PickerColumn,
  IonLoading,
  ModalController
} from '@ionic/angular';
import { Observable, map } from 'rxjs';
import { TimezoneApiService } from '@app/core/service/timezone-api.service';
import { ITimezone } from '@backend/types/timezone';
import { LocalStorageService, UserApiService } from '@app/core';
import {
  EPsychologicalFeedbackView,
  ITenantFeatures,
  ITenantPushNotificationsFlags
} from '@backend/models/types/tenant';
import { PasscodeTimeoutService } from '@app/core/service/passcode-timeout.service';
import { BehaviorsEditComponent } from './components/behaviors-edit/behaviors-edit.component';
import { HapticsService } from '@app/core/service/haptics.service';
import { PatchInput } from '@app/core/service/tenant-api.service';
import { CompensationApiService } from '@app/core/service/compensation-api.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ICompensationPool } from '@backend/models/types/compensation-pool';
import { CompensationPoolModalComponent } from '@app/modules/compensation-pool-modal/compensation-pool-modal.component';
import {
  ECompensationFieldType,
  ICompensationField
} from '@backend/models/types/compensation-field';
import { CompensationFieldModalComponent } from '@app/modules/compensation-field-modal/compensation-field-modal.component';
import { ToastService } from '@app/core/service/toast.service';
import {
  CompensationDefinition,
  TIncludePointsFrom
} from '@backend/types/compensation-definition';
import { ChecklistDefinition } from '@backend/types/checklist-definition';
import {
  DailySurveyDefinition,
  EDailySurveyMode
} from '@backend/types/daily-survey-definition';
import { RecognitionDefinition } from '@backend/types/recognition-definition';
import { TagsDefinition } from '@backend/types/tags-definition';
import { AnnouncementsDefinition } from '@backend/types/announcements-definition';
import { EnvironmentService } from '@app/core/service/environment.service';

@Component({
  selector: 'app-team-settings',
  templateUrl: './team-settings.component.html',
  styleUrls: ['./team-settings.component.scss']
})
export class TeamSettingsComponent implements AfterViewInit {
  @ViewChild('content', { static: true })
  public content!: IonContent;

  @ViewChild('selectEncourageManagersAndAdmins', { static: true })
  public selectEncourageManagersAndAdmins!: IonModal;

  @ViewChild('selectNormalUsers', { static: true })
  public selectNormalUsers!: IonModal;

  @ViewChild('selectInsightsUsersModal', { static: true })
  public selectInsightsUsersModal!: IonModal;

  @ViewChild('selectDailyReportUsers', { static: true })
  public selectDailyReportUsers!: IonModal;

  @ViewChild('timezoneSelectorModal', { static: true })
  public timezoneSelectorModal!: IonModal;

  @ViewChild('passcodesListModal', { static: true })
  public passcodesListModal!: IonModal;

  @ViewChild('loading', { static: true })
  public loading!: IonLoading;

  protected readonly feedbackView = EPsychologicalFeedbackView;
  protected readonly dailySurveyMode = EDailySurveyMode;

  protected webAppUrl = '';
  protected tenantId = '';
  protected companyName: string;
  protected frequency: PayFrequency;
  protected date1: string;
  protected date2: string | undefined;
  protected allUsers$: Observable<UserModel[]> = this._usersService.users$.pipe(
    map((list) => list.filter(({ isDeleted }) => !isDeleted))
  );
  protected tenant$ = this._tenantService.tenant$;
  protected payFrequency = PayFrequency;
  protected timezones: ITimezone[] = [];
  protected timezone: string;
  protected loginRedirectsTo: string;
  protected passcodeRedirectsTo = '';
  protected passcodeTimeout: number;
  protected dailyReportTime = '00:00';
  protected dailyReportEnabled = false;
  protected tenantFeatures: Pick<
    ITenantFeatures,
    | 'checklist'
    | 'announcements'
    | 'suggestions'
    | 'multiUserMode'
    | 'survey'
    | 'dailyReport'
    | 'timeAttack'
    | 'surveyFeedback'
    | 'calculateRewards'
    | 'tags'
    | 'auditTasks'
  > = {
    checklist: false,
    announcements: false,
    suggestions: false,
    survey: false,
    dailyReport: false,
    multiUserMode: false,
    timeAttack: false,
    surveyFeedback: false,
    calculateRewards: false,
    tags: false,
    auditTasks: false
  };
  protected pnFlags: ITenantPushNotificationsFlags = {
    isAdminsReceiveNotificationsWhenAnyUserIsRecognized: false,
    isManagersReceiveNotificationsWhenAnyUserIsRecognized: false,
    isUsersReceiveNotificationsForAnnouncements: false
  };
  protected psychologicalFeedbackView: EPsychologicalFeedbackView =
    EPsychologicalFeedbackView.SINGLE_BAR_VIEW;
  protected surveyTimePickerColumns: PickerColumn[] = [];
  protected surveyTimePickerButtons: PickerButton[] = [
    {
      text: 'Cancel',
      role: 'cancel'
    },
    {
      text: 'Confirm',
      handler: (value: { surveyTime: { text: string; value: number } }) => {
        this.dailySurveySettings.dailySurveyPNTime = value.surveyTime.value;
      }
    }
  ];
  protected surveyTimePickerOptions = [
    { text: '15 minutes', value: 15 },
    { text: '30 minutes', value: 30 },
    { text: '45 minutes', value: 45 },
    { text: '1 hour', value: 60 }
  ];
  protected compensationPools: MongoStoredObject<ICompensationPool>[] = [];
  protected compensationFields: MongoStoredObject<ICompensationField>[] = [];
  protected compensationSettings: CompensationDefinition;
  protected checklistSettings: ChecklistDefinition;
  protected dailySurveySettings: DailySurveyDefinition;
  protected recognitionSettings: Omit<
    RecognitionDefinition,
    'encourageManagersAndAdmins' | 'encourageNormalUsers'
  > & {
    encourageManagersAndAdmins: ObjectId[];
    encourageNormalUsers: ObjectId[];
  };
  protected tagsSettings: TagsDefinition;
  protected announcementsSettings: AnnouncementsDefinition;

  protected accordionValue = 'details';
  protected possibleAccordionValues = [
    'details',
    'payroll',
    'permissions',
    'multiUserMode',
    'recognition',
    'checklist',
    'tags',
    'dailyReport',
    'surveyFeedback',
    'announcements',
    'suggestions',
    'pulse',
    'notifications',
    'culture'
  ];

  private _payroll: PayrollDefinition | undefined;

  public constructor(
    private readonly _tenantService: TenantService,
    private readonly _usersService: UserListService,
    private readonly _timezoneApiService: TimezoneApiService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _userApiService: UserApiService,
    private readonly _loadingCtrl: LoadingController,
    private readonly _passcodeTimeoutService: PasscodeTimeoutService,
    private readonly _route: ActivatedRoute,
    private readonly _ionNav: IonNav,
    private readonly _hapticsService: HapticsService,
    private readonly _compensationApiService: CompensationApiService,
    private readonly _modalCtrl: ModalController,
    private readonly _toastService: ToastService,
    private readonly _clipboard: Clipboard,
    private readonly _environmentService: EnvironmentService
  ) {
    this.webAppUrl = this._environmentService.webAppUrl;

    this.tenant$.subscribe((tenant) => {
      if (this.loading) {
        this.loading.dismiss();
      }

      this.surveyTimePickerColumns = [
        {
          name: 'surveyTime',
          options: this.surveyTimePickerOptions,
          selectedIndex: this.surveyTimePickerOptions.reduce<number>(
            (prev, option, index) =>
              tenant.settings.dailySurvey.dailySurveyPNTime === option.value
                ? index
                : prev,
            0
          )
        }
      ];

      this.tenantId = tenant._id;
      this.pnFlags = tenant.pnFlags;
      this.tenantFeatures = tenant.features;
      this.psychologicalFeedbackView = tenant.psychologicalFeedbackView;
      this.dailyReportEnabled = tenant.dailyReportEnabled;
      this.dailyReportTime = tenant.dailyReportTime;
      this.timezone = tenant.timezone;
      this.companyName = tenant.name;
      this.passcodeTimeout = tenant.passcodeTimeout;
      this.compensationSettings = tenant.settings.compensation;
      this.checklistSettings = tenant.settings.checklist;
      this.dailySurveySettings = tenant.settings.dailySurvey;
      this.recognitionSettings = {
        ...tenant.settings.recognition,
        encourageManagersAndAdmins: tenant.settings.recognition
          .encourageManagersAndAdmins as unknown as ObjectId[],
        encourageNormalUsers: tenant.settings.recognition
          .encourageNormalUsers as unknown as ObjectId[]
      };
      this.tagsSettings = tenant.settings.tags;
      this.announcementsSettings = tenant.settings.announcements;
      this._payroll = tenant.settings?.payroll;
      this._setPayrollModel(this._payroll);

      if (
        (tenant.loginRedirectsTo === '/i/checklist' &&
          !this.tenantFeatures.checklist) ||
        (tenant.loginRedirectsTo === '/i/tags' && !this.tenantFeatures.tags) ||
        (tenant.loginRedirectsTo === '/i/announcements' &&
          !this.tenantFeatures.announcements)
      ) {
        this.loginRedirectsTo = '/i/recognition';
      } else {
        this.loginRedirectsTo = tenant.loginRedirectsTo;
      }

      if (
        (tenant.passcodeRedirectsTo === '/i/checklist' &&
          !this.tenantFeatures.checklist) ||
        (tenant.passcodeRedirectsTo === '/i/tags' &&
          !this.tenantFeatures.tags) ||
        (tenant.passcodeRedirectsTo === '/i/announcements' &&
          !this.tenantFeatures.announcements)
      ) {
        this.passcodeRedirectsTo = '/i/recognition';
      } else {
        this.passcodeRedirectsTo = tenant.passcodeRedirectsTo;
      }
    });

    this._timezoneApiService.getTimezones().subscribe((timezones) => {
      this.timezones = timezones;
    });

    this._compensationApiService
      .getCompensationPools()
      .subscribe((compensationPools) => {
        this.compensationPools = compensationPools;
      });
    this._compensationApiService
      .getCompensationFields()
      .subscribe((compensationFields) => {
        this.compensationFields = compensationFields;
      });
  }

  public ngAfterViewInit(): void {
    this._route.queryParams.subscribe((params) => {
      setTimeout(() => {
        if (params && params.scrollTo) {
          this.accordionValue = params.scrollTo;

          const element = document.getElementById(params.scrollTo);
          element.classList.add('highlighted');
          this.content.scrollToPoint(0, element.offsetTop, 1000);
        }
      }, 500);
    });
  }

  protected get onlyHoursCompensationFields() {
    return this.compensationFields.filter(
      (field) => field.type === ECompensationFieldType.HOURS
    );
  }

  protected get compensationEmployeeIdColumnName() {
    const employeeIdColumn = this.compensationFields.find(
      (field) => field.type === ECompensationFieldType.EMPLOYEE_ID
    );

    return employeeIdColumn ? employeeIdColumn.name : '';
  }

  protected onIncludePointsFromChange({
    detail: { value }
  }: CustomEvent<{ value: TIncludePointsFrom[] }>) {
    const compensation = { includePointsFrom: value };

    this._patch({ settings: { compensation } });
  }

  protected onCompensationEmployeeIdColumnNameInput({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    const employeeIdColumn = this.compensationFields.find(
      (field) => field.type === ECompensationFieldType.EMPLOYEE_ID
    );

    if (employeeIdColumn) {
      this._compensationApiService
        .updateCompensationField(employeeIdColumn._id, { name: value })
        .subscribe();
    } else {
      this._compensationApiService
        .addCompensationField({
          name: value,
          type: ECompensationFieldType.EMPLOYEE_ID
        })
        .subscribe();
    }
  }

  protected get isMultiUserModeActive(): boolean {
    return !!this._localStorageService.getItem('multiUserMode');
  }

  protected async activateMultiUserMode() {
    const loading = await this._loadingCtrl.create({
      message: 'Activating...'
    });
    loading.present();

    this._userApiService.getAllUsersWithPinCodes().subscribe(() => {
      loading.dismiss();
      this._passcodeTimeoutService.activateMultiUserMode(
        this.passcodeRedirectsTo,
        this.passcodeTimeout
      );
    });
  }

  protected deactivateMultiUserMode() {
    this._passcodeTimeoutService.deactivateMultiUserMode();
  }

  protected async onTimezoneSelect(timezone: ITimezone) {
    this.timezoneSelectorModal.dismiss();
    this.timezone = timezone.tzCode;
    this._patch({ timezone: timezone.tzCode });
  }

  private _setPayrollModel(payroll: PayrollDefinition | undefined) {
    if (!payroll) {
      payroll = createDefaultPayroll();
    }
    this.frequency = payroll.frequency;
    this._setDatesFromPayroll(payroll);
  }

  private _setDatesFromPayroll(payroll: PayrollDefinition) {
    this.date1 = payroll.date1;
    if (payroll.frequency === PayFrequency.TWICE_PER_MONTH) {
      this.date2 = payroll.date2;
    } else {
      this.date2 = undefined;
    }
  }

  protected onCompanyInput({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    this.companyName = value;
    this._patch({ name: value });
  }

  protected onPickupDateFieldNameInput({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    this.tagsSettings = {
      ...this.tagsSettings,
      pickupDateFieldName: value
    };
    this._patch({ settings: { tags: this.tagsSettings } });
  }

  protected onCustomerNameEnabledChange(event: any) {
    if (event && event.detail) {
      this.tagsSettings.customerNameEnabled = event.detail.checked;
      this._patch({
        settings: {
          tags: this.tagsSettings
        }
      });
    }
  }

  protected onFrequencyChange({
    detail: { value }
  }: CustomEvent<{ value: PayFrequency }>) {
    if (this.frequency === value) {
      return;
    }
    if (value === this._payroll?.frequency) {
      // returning initial dates from the settings
      this._setPayrollModel(this._payroll);
    } else {
      //create defaults for selected frequency
      this.frequency = value;
      const payroll = createDefaultPayrollForFrequency(value);
      this._setDatesFromPayroll(payroll);
    }
    this._savePayroll();
  }

  protected onPsychologicalFeedbackViewChange({
    detail: { value }
  }: CustomEvent<{ value: EPsychologicalFeedbackView }>) {
    if (value) {
      this.psychologicalFeedbackView = value;
      this._patch({ psychologicalFeedbackView: value });
    }
  }

  protected onLoginRedirectsToChange({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    if (value) {
      this.loginRedirectsTo = value;
      this._patch({ loginRedirectsTo: value });
    }
  }

  protected onPasscodeRedirectsToChange({
    detail: { value }
  }: CustomEvent<{ value: string }>) {
    if (value) {
      this.passcodeRedirectsTo = value;
      this._patch({ passcodeRedirectsTo: value });
    }
  }

  protected onPasscodeTimeoutChange({
    detail: { value }
  }: CustomEvent<{ value: number }>) {
    if (value) {
      this.passcodeTimeout = value;
      this._patch({ passcodeTimeout: value });
    }
  }

  protected onDailySurveyModeChange(event: any) {
    if (event && event.detail) {
      this.dailySurveySettings.dailySurveyMode = event.detail.value;
      this._patch({
        settings: {
          dailySurvey: {
            ...this.dailySurveySettings,
            dailySurveyMode: event.detail.value
          }
        }
      });
    }
  }

  protected onDailySurveyPNTimeChange(event: any): void {
    this._hapticsService.hapticsImpact();

    if (event.detail.data) {
      this.dailySurveySettings.dailySurveyPNTime =
        event.detail.data.surveyTime.value;
      this._patch({
        settings: {
          dailySurvey: {
            ...this.dailySurveySettings,
            dailySurveyPNTime: event.detail.data.surveyTime.value
          }
        }
      });
    }
  }

  protected onDailyReportTimeChange(event: any) {
    if (event) {
      this.dailyReportTime = event;
      this._patch({ dailyReportTime: event });
    }
  }

  protected onDailyReportEnabledChange(event: any) {
    if (event && event.detail) {
      this.dailyReportEnabled = event.detail.checked;
      this._patch({ dailyReportEnabled: event.detail.checked });
    }
  }

  protected onChecklistSettingsFieldChange(
    field: keyof ChecklistDefinition,
    event: any
  ) {
    if (event && event.detail) {
      this.checklistSettings[field] = event.detail.checked;
      this._patch({
        settings: {
          checklist: {
            ...this.checklistSettings,
            [field]: event.detail.checked
          }
        }
      });
    }
  }

  protected get whoCanSendAnnouncements() {
    return this.announcementsSettings.whoCanSendAnnouncements.sort().join('+');
  }

  protected onWhoCanSendAnnouncementsChange(event: any) {
    if (event && event.detail) {
      const newValue = event.detail.value.split('+');
      this.announcementsSettings.whoCanSendAnnouncements = newValue;
      this._patch({
        settings: {
          announcements: {
            ...this.announcementsSettings,
            whoCanSendAnnouncements: newValue
          }
        }
      });
    }
  }

  protected onEncourageManagersAndAdminsToRecognizeSomeoneChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageManagersAndAdminsToRecognizeSomeone =
        event.detail.checked;
      this._patch({
        settings: {
          recognition: {
            ...this.recognitionSettings,
            encourageManagersAndAdminsToRecognizeSomeone: event.detail.checked
          }
        }
      });
    }
  }

  protected onEncourageManagersAndAdminsNumberOfDaysChange(event: string) {
    if (event) {
      this.recognitionSettings.encourageManagersAndAdminsNumberOfDays =
        Number(event) || 1;
    }
  }

  protected onEncourageManagersAndAdminsPNChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageManagersAndAdminsPN =
        event.detail.checked;
      this._patch({
        settings: {
          recognition: {
            ...this.recognitionSettings,
            encourageManagersAndAdminsPN: event.detail.checked
          }
        }
      });
    }
  }

  protected onEncourageManagersAndAdminsTimeChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageManagersAndAdminsTime =
        event.detail.checked;
    }
  }

  protected onEncourageNormalUsersToRecognizeSomeoneChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageNormalUsersToRecognizeSomeone =
        event.detail.checked;
      this._patch({
        settings: {
          recognition: {
            ...this.recognitionSettings,
            encourageNormalUsersToRecognizeSomeone: event.detail.checked
          }
        }
      });
    }
  }

  protected onEncourageNormalUsersNumberOfDaysChange(event: string) {
    if (event) {
      this.recognitionSettings.encourageNormalUsersNumberOfDays =
        Number(event) || 1;
    }
  }

  protected onEncourageNormalUsersPNChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageNormalUsersPN = event.detail.checked;
      this._patch({
        settings: {
          recognition: {
            ...this.recognitionSettings,
            encourageNormalUsersPN: event.detail.checked
          }
        }
      });
    }
  }

  protected onEncourageNormalUsersTimeChange(event: any) {
    if (event && event.detail) {
      this.recognitionSettings.encourageNormalUsersTime = event.detail.checked;
    }
  }

  protected onPnFlagChange(
    event: any,
    pnFlag: keyof ITenantPushNotificationsFlags
  ) {
    if (event && event.detail) {
      this.pnFlags[pnFlag] = event.detail.checked;
      this._patch({
        pnFlags: {
          ...this.pnFlags,
          [pnFlag]: event.detail.checked
        }
      });
    }
  }

  protected onDateChange(): void {
    this._savePayroll();
  }

  private _savePayroll() {
    const payroll: PayrollDefinition =
      this.frequency !== PayFrequency.TWICE_PER_MONTH
        ? {
            frequency: this.frequency,
            date1: this.date1
          }
        : {
            frequency: this.frequency,
            date1: this.date1,
            date2: this.date2
          };
    this._patch({ settings: { payroll } });
  }

  protected encourageManagersAndAdminsChanged(selection: ObjectId[]): void {
    this.selectEncourageManagersAndAdmins.dismiss();
    this.recognitionSettings.encourageManagersAndAdmins = selection;
  }

  protected encourageNormalUsersChanged(selection: ObjectId[]): void {
    this.selectNormalUsers.dismiss();
    this.recognitionSettings.encourageNormalUsers = selection;
  }

  protected insightsUsersChanged(
    selection: ObjectId[],
    allUsers: UserModel[]
  ): void {
    this.selectInsightsUsersModal.dismiss();
    const prevSelection = allUsers
      .filter(({ isInsightsHidden }) => !isInsightsHidden)
      .map(({ _id }) => _id);
    const selectionSet = new Set(selection);
    if (isSameSet(selectionSet, prevSelection)) {
      return;
    }
    const prevSelectionSet = new Set(prevSelection);
    const newSelected = selection.filter((s) => !prevSelectionSet.has(s));
    const newRemoved = prevSelection.filter((s) => !selectionSet.has(s));
    if (newRemoved.length > 0) {
      this._patchInsightsHidden(newRemoved, true);
    }
    if (newSelected.length > 0) {
      this._patchInsightsHidden(newSelected, false);
    }
  }

  protected dailyReportUsersChanged(
    selection: ObjectId[],
    allUsers: UserModel[]
  ): void {
    this.selectDailyReportUsers.dismiss();
    const prevSelection = allUsers
      .filter(({ receiveDailyReports }) => receiveDailyReports)
      .map(({ _id }) => _id);
    const selectionSet = new Set(selection);
    if (isSameSet(selectionSet, prevSelection)) {
      return;
    }
    const prevSelectionSet = new Set(prevSelection);
    const newSelected = selection.filter((s) => !prevSelectionSet.has(s));
    const newRemoved = prevSelection.filter((s) => !selectionSet.has(s));
    if (newRemoved.length > 0) {
      this._patchReceiveDailyReports(newRemoved, false);
    }
    if (newSelected.length > 0) {
      this._patchReceiveDailyReports(newSelected, true);
    }
  }

  private _patchInsightsHidden(userIds: ObjectId[], value: boolean) {
    this._usersService
      .patchUsers(userIds, {
        isInsightsHidden: value
      })
      .subscribe({ error: (error) => console.log(error) });
  }

  private _patchReceiveDailyReports(userIds: ObjectId[], value: boolean) {
    this._usersService
      .patchUsers(userIds, {
        receiveDailyReports: value
      })
      .subscribe({ error: (error) => console.log(error) });
  }

  protected manageBehaviors() {
    this._ionNav.push(BehaviorsEditComponent);
  }

  protected get currentSurveyTimeText() {
    return this.surveyTimePickerOptions.find(
      (option) => this.dailySurveySettings.dailySurveyPNTime === option.value,
      0
    ).text;
  }

  private _patch(patch: PatchInput): void {
    this.loading.present();

    this._tenantService.patch(patch);
  }

  protected onAccordionValueChange(event: any) {
    if (
      event.detail.value &&
      typeof event.detail.value === 'string' &&
      this.possibleAccordionValues.includes(event.detail.value)
    ) {
      this.accordionValue = event.detail.value;
    }
    return false;
  }

  protected async addCompensationPool() {
    const compensationPoolModal = await this._modalCtrl.create({
      id: 'compensation-pool-modal',
      component: CompensationPoolModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      handle: false
    });
    compensationPoolModal.present();

    const { role, data } = await compensationPoolModal.onWillDismiss();

    if (role === 'confirm') {
      this._compensationApiService
        .addCompensationPool(data)
        .subscribe((pool) => {
          this.compensationPools.push(pool);
        });
    }
  }

  protected async updateCompensationPool(
    compensationPool: MongoStoredObject<ICompensationPool>
  ) {
    const compensationPoolModal = await this._modalCtrl.create({
      id: 'compensation-pool-modal',
      component: CompensationPoolModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        compensationPool
      },
      handle: false
    });
    compensationPoolModal.present();

    const { role, data } = await compensationPoolModal.onWillDismiss();

    if (role === 'confirm') {
      this._compensationApiService
        .updateCompensationPool(compensationPool._id, data)
        .subscribe((pool) => {
          this.compensationPools = this.compensationPools.map((p) =>
            pool._id === p._id ? pool : p
          );
        });
    }
  }

  protected removeCompensationPool(compensationPoolId: string, event: any) {
    event.stopPropagation();

    this._compensationApiService
      .removeCompensationPool(compensationPoolId)
      .subscribe(() => {
        this.compensationPools = this.compensationPools.filter(
          (pool) => pool._id !== compensationPoolId
        );
      });
  }

  protected trackCompensationPools(_: number, item: { _id: string }) {
    return item._id;
  }

  protected trackCompensationFields(_: number, item: { _id: string }) {
    return item._id;
  }

  protected async addCompensationField() {
    const compensationFieldModal = await this._modalCtrl.create({
      id: 'compensation-field-modal',
      component: CompensationFieldModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      handle: false
    });
    compensationFieldModal.present();

    const { role, data } = await compensationFieldModal.onWillDismiss();

    if (role === 'confirm') {
      this._compensationApiService.addCompensationField(data).subscribe({
        next: (field) => {
          this.compensationFields.push(field);
        },
        error: (e) => {
          this._toastService.presentToast(
            e.error?.message || 'Unable to create compensation column'
          );
        }
      });
    }
  }

  protected async updateCompensationField(
    compensationField: MongoStoredObject<ICompensationField>
  ) {
    const compensationFieldModal = await this._modalCtrl.create({
      id: 'compensation-field-modal',
      component: CompensationFieldModalComponent,
      cssClass: 'modal-auto-height',
      breakpoints: [0, 1],
      initialBreakpoint: 1,
      componentProps: {
        compensationField
      },
      handle: false
    });
    compensationFieldModal.present();

    const { role, data } = await compensationFieldModal.onWillDismiss();

    if (role === 'confirm') {
      this._compensationApiService
        .updateCompensationField(compensationField._id, data)
        .subscribe((field) => {
          this.compensationFields = this.compensationFields.map((f) =>
            field._id === f._id ? field : f
          );
        });
    }
  }

  protected removeCompensationField(compensationFieldId: string, event: any) {
    event.stopPropagation();

    this._compensationApiService
      .removeCompensationField(compensationFieldId)
      .subscribe(() => {
        this.compensationFields = this.compensationFields.filter(
          (field) => field._id !== compensationFieldId
        );
      });
  }

  protected get encourageManagersAndAdmins() {
    return this.recognitionSettings.encourageManagersAndAdmins.map(
      (user) => user.toString() as ObjectId
    );
  }

  protected get encourageNormalUsers() {
    return this.recognitionSettings.encourageNormalUsers.map(
      (user) => user.toString() as ObjectId
    );
  }

  protected onCopySuggestionLinkClick() {
    this._clipboard.copy(`${this.webAppUrl}/#/suggestion/${this.tenantId}`);
    this._toastService.presentToast('Link copied');
  }
}
