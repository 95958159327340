<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="end">
      <ion-back-button text="" icon="close" appHaptics></ion-back-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="taskFolder === null; else updateTask">
        Create Group
      </ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-width-restricted-container>
    <app-task-folder-form
      [taskFolder]="taskFolder"
      (taskFolderSaved)="onTaskFolderSaved()"
      (delete)="onDeleteButtonClick()"
    >
    </app-task-folder-form>
  </app-width-restricted-container>
</ion-content>

<ng-template #updateTask> Edit Group </ng-template>
