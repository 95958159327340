import { Types } from 'mongoose';
import { PayrollDefinition } from '../../types/payroll-definition';
import { CompensationDefinition } from '../../types/compensation-definition';
import { ChecklistDefinition } from '../../types/checklist-definition';
import { DailySurveyDefinition } from '../../types/daily-survey-definition';
import { RecognitionDefinition } from '../../types/recognition-definition';
import { TagsDefinition } from '../../types/tags-definition';
import { AnnouncementsDefinition } from '../../types/announcements-definition';

export interface ITenantFeatures {
  checklist: boolean;
  actionsUponTaskCompletion: boolean;
  requestTaskFeedbackAttachments: boolean;
  leaveTaskNoteAttachments: boolean;
  timeAttack: boolean;
  auditTasks: boolean;
  auditAttachments: boolean;
  tags: boolean;
  announcements: boolean;
  survey: boolean;
  surveyFeedback: boolean;
  insights: boolean;
  dailyReport: boolean;
  multiUserMode: boolean;
  library: boolean;
  announcementAttachments: boolean;
  taskAttachments: boolean;
  libraryDocAttachments: boolean;
  connectedAccounts: boolean;
  calculateRewards: boolean;
  lottery: boolean;
  userSchedule: boolean;
  askTodaysShiftTime: boolean;
  instantFeedback: boolean;
  suggestions: boolean;
  suggestionAttachments: boolean;
}

export interface ITenantPushNotificationsFlags {
  isAdminsReceiveNotificationsWhenAnyUserIsRecognized: boolean;
  isManagersReceiveNotificationsWhenAnyUserIsRecognized: boolean;
  isUsersReceiveNotificationsForAnnouncements: boolean;
}

export enum EPsychologicalFeedbackView {
  SINGLE_BAR_VIEW = 'SINGLE_BAR_VIEW',
  DOUBLE_BAR_VIEW = 'DOUBLE_BAR_VIEW'
}

export interface ITenant {
  name: string;
  dateCreated: Date;
  isActive: boolean;
  deletedUserId: Types.ObjectId;
  deletedDate: Date;
  isDeleted: boolean;
  emailFlags: {
    isSendingSupervisorEmailsRegardingLedger: boolean;
    isSendingLedgerEmailsEnabled: boolean;
    isEmailSendingFeatureEnabled: boolean;
  };
  pnFlags: ITenantPushNotificationsFlags;
  stripe?: {
    session_id: string;
  };
  behaviors: {
    reinforce?: Types.ObjectId;
    checklist?: Types.ObjectId;
    positiveFeedback?: Types.ObjectId;
    empty?: Types.ObjectId;
  };
  tasks: {
    uploadTag?: Types.ObjectId;
    servingTable?: Types.ObjectId;
    readyForPickup?: Types.ObjectId;
    completeTag?: Types.ObjectId;
    reminder1st?: Types.ObjectId;
    reminder2nd?: Types.ObjectId;
    dailySurvey?: Types.ObjectId;
    suggestionSubmit?: Types.ObjectId;
  };
  settings: {
    payroll?: PayrollDefinition;
    compensation?: CompensationDefinition;
    checklist?: ChecklistDefinition;
    dailySurvey?: DailySurveyDefinition;
    recognition?: RecognitionDefinition;
    tags?: TagsDefinition;
    announcements?: AnnouncementsDefinition;
  };
  timezone: string;
  loginRedirectsTo: string;
  passcodeRedirectsTo: string;
  passcodeTimeout: number;
  dailyReportEnabled: boolean;
  dailyReportTime: string;
  features: ITenantFeatures;
  psychologicalFeedbackView: EPsychologicalFeedbackView;
}
