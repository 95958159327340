import { Pipe, PipeTransform } from '@angular/core';
import { formatDuration } from 'date-fns';

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {
  public transform(value: string | null): string {
    if (!value || value === '0:0' || value === '00:00') {
      return 'Not Set';
    }

    const [hours, minutes] = value.split(':');

    return formatDuration({
      hours: Number.parseInt(hours),
      minutes: Number.parseInt(minutes)
    });
  }
}
