import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { RecognitionComponent } from './recognition.component';
import { RecognitionItemModule } from '@app/modules/recognition-item/recognition-item.module';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { EditRecognitionModalModule } from '@app/modules/edit-recognition-modal/edit-recognition-modal.module';
import { DirectivesModule } from '@app/modules/directives.module';
import { PullToSearchModule } from '@app/modules/pull-to-search/pull-to-search.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Recognition',
      rootComponent: RecognitionComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    ReactiveFormsModule,
    FormsModule,
    SlimMenuButtonComponent,
    RecognitionItemModule,
    EmptyStubModule,
    EditRecognitionModalModule,
    DirectivesModule,
    PullToSearchModule
  ],
  declarations: [RecognitionComponent]
})
export class RecognitionModule {}
