<form
  [formGroup]="mainFormGroup"
  (ngSubmit)="onFormSubmit()"
  class="layout-container"
>
  <div class="top-content">
    <ion-grid class="grid">
      <ion-row>
        <ion-col size="12">
          <ion-input
            label="Title"
            class="app-w100 input-with-background"
            formControlName="title"
            labelPlacement="floating"
            fill="solid"
            autocapitalize="on"
            [spellcheck]="true"
          ></ion-input>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12">
          <app-divider>Notification upon completion</app-divider>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="app-notice" size="12">
          Notify selected users whenever all critical tasks have been completed
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-button
            color="light"
            expand="block"
            id="notifyUsersOnAllCriticalTasksCompleted"
            appHaptics
            class="ion-no-margin"
          >
            <ng-container *ngIf="allUsers$ | async as allUsers">
              <ng-container
                *ngIf="
                  mainFormGroup.controls.notifyUsersOnAllCriticalTasksCompleted
                    .value.length === allUsers.length;
                  else notifyUsersOnAllCriticalTasksCompletedCaption
                "
              >
                All Users
              </ng-container>
              <ng-template #notifyUsersOnAllCriticalTasksCompletedCaption>
                <ng-container
                  [ngPlural]="
                    mainFormGroup.controls
                      .notifyUsersOnAllCriticalTasksCompleted.value.length
                  "
                >
                  <ng-template ngPluralCase="0"> Select Users </ng-template>
                  <ng-template ngPluralCase="=1">1 user</ng-template>
                  <ng-template ngPluralCase="other">
                    {{
                      mainFormGroup.controls
                        .notifyUsersOnAllCriticalTasksCompleted.value.length
                    }}
                    users
                  </ng-template>
                </ng-container>
              </ng-template>
            </ng-container>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size="12">
          <ion-toggle
            appHaptics
            justify="space-between"
            class="app-w100"
            formControlName="emailUsersOnAllCriticalTasksCompleted"
          >
            <ion-label>Email selected users</ion-label>
          </ion-toggle>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <div class="bottom-content">
    <ion-grid class="grid">
      <ion-row class="ion-justify-content-center ion-padding">
        <ion-col size="4" *ngIf="taskFolder === null; else updateActions">
          <ion-button
            type="submit"
            color="dark"
            expand="block"
            [disabled]="mainFormGroup.invalid || mainFormGroup.pristine"
            appHaptics
            [disableHaptics]="mainFormGroup.invalid || mainFormGroup.pristine"
          >
            Create
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</form>

<ng-template #updateActions>
  <ion-col size="4">
    <ion-button
      type="button"
      color="danger"
      expand="block"
      fill="outline"
      (click)="onDeleteClick()"
      appHaptics
    >
      Delete
    </ion-button>
  </ion-col>
  <ion-col size="4">
    <ion-button
      type="submit"
      color="dark"
      expand="block"
      [disabled]="
        !hasChanges && (mainFormGroup.invalid || mainFormGroup.pristine)
      "
      appHaptics
      [disableHaptics]="
        !hasChanges && (mainFormGroup.invalid || mainFormGroup.pristine)
      "
    >
      Update
    </ion-button>
  </ion-col>
</ng-template>

<ion-modal
  trigger="notifyUsersOnAllCriticalTasksCompleted"
  #notifyUsersOnAllCriticalTasksCompletedModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <ng-container *ngIf="allUsers$ | async as allUsers">
      <app-user-selector
        title="Email selected users"
        class="ion-page"
        [users]="allUsers"
        [recipients]="
          mainFormGroup.controls.notifyUsersOnAllCriticalTasksCompleted.value
        "
        (selectionChange)="
          notifyUsersOnAllCriticalTasksCompletedChanged($event)
        "
        (selectionCancel)="
          notifyUsersOnAllCriticalTasksCompletedModal.dismiss()
        "
        [multiple]="true"
      ></app-user-selector>
    </ng-container>
  </ng-template>
</ion-modal>
