import { Types } from 'mongoose';

export enum EBackdoorInfoPlatform {
  'web' = 'web',
  'ios' = 'ios',
  'android' = 'android'
}

export interface IBackdoorInfoLink {
  text: string;
  url: string;
}

export interface IBackdoorInfo {
  versionRange: string;
  title: string;
  message: string;
  links: IBackdoorInfoLink[];
  canDismiss: boolean;
  tenantsToShow: Types.ObjectId[];
  showToAllTenants: boolean;
  platformsToShow: EBackdoorInfoPlatform[];
}
