import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { HapticsService } from './haptics.service';

@Injectable({ providedIn: 'root' })
export class ToastService {
  public constructor(
    private readonly _toastController: ToastController,
    private readonly _hapticsService: HapticsService
  ) {}

  public async presentToast(
    message: string,
    options?: {
      duration?: number;
      position?: 'top' | 'bottom';
      okText?: string;
      okCallback?: (role: string) => void;
    }
  ) {
    if (!options) {
      options = {};
    }

    if (!options.duration) {
      options.duration = 5000;
    }

    if (!options.position) {
      options.position = 'bottom';
    }

    if (!options.okText) {
      options.okText = 'OK';
    }

    this.dismissAll();

    const toast = await this._toastController.create({
      message,
      duration: options.duration,
      position: options.position,
      buttons: [{ text: options.okText, role: 'cancel', side: 'end' }]
    });
    toast.present();

    const { role } = await toast.onWillDismiss();

    if (role === 'cancel') {
      this._hapticsService.hapticsImpact();

      options.okCallback && options.okCallback(role);
    }
  }

  public async dismissAll() {
    const topToast = await this._toastController.getTop();
    if (topToast) {
      await topToast.dismiss();
    }
  }
}
