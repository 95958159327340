import { Types } from 'mongoose';

export enum ECompensationPoolType {
  CURRENCY = 'CURRENCY',
  HOURS = 'HOURS',
  OTHER = 'OTHER'
}

export interface ICompensationPool {
  name: string;
  type: ECompensationPoolType;
  users: Types.ObjectId[];
  tenant: Types.ObjectId;
  isDeleted: boolean;
}
