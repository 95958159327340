import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { SlimMenuButtonComponent } from '@app/modules/slim-menu-button/slim-menu-button.component';
import { RouterModule, Routes } from '@angular/router';
import { PsychologicalSurveyFeedbackComponent } from './psychological-survey-feedback.component';
import { DirectivesModule } from '@app/modules/directives.module';
import { PsychologicalSurveyApiService } from '@app/core/service/psychological-survey-api.service';
import { EmptyStubModule } from '@app/modules/empty-stub/empty-stub.module';
import { FeedbackSingleBarComponent } from './components/feedback-single-bar/feedback-single-bar.component';
import { FeedbackDoubleBarComponent } from './components/feedback-double-bar/feedback-double-bar.component';
import { UserSelectorComponent } from '@app/modules/user-selector/user-selector.component';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Psychological Survey Feedback',
      rootComponent: PsychologicalSurveyFeedbackComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    SlimMenuButtonComponent,
    DirectivesModule,
    EmptyStubModule,
    UserSelectorComponent
  ],
  declarations: [
    PsychologicalSurveyFeedbackComponent,
    FeedbackSingleBarComponent,
    FeedbackDoubleBarComponent
  ],
  providers: [PsychologicalSurveyApiService]
})
export class PsychologicalSurveyFeedbackModule {}
