<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button color="primary" id="open-modal" appHaptics>
        Add from
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">Tasks: {{ tenant.name }}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <app-width-restricted-container
    class="layout-container"
    *ngIf="taskList$ | async as taskList"
  >
    <ng-container *ngIf="taskList.length === 0">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <ion-list>
      <ion-item
        *ngFor="let task of taskList; trackBy: taskTrackBy"
        class="task-item opaque-reorder-item"
      >
        <ion-label class="ion-text-capitalize">
          <span *ngIf="task.taskFolder" class="task-folder-title">
            {{ task.taskFolder.title }}
          </span>
          <span class="task-title">{{ task.title }}</span>
        </ion-label>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>

  <ion-modal
    trigger="open-modal"
    [handle]="false"
    [breakpoints]="[0, 1]"
    [initialBreakpoint]="1"
  >
    <ng-template>
      <ion-header>
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-button (click)="cancel()" appHaptics>Cancel</ion-button>
          </ion-buttons>
          <ion-title>Copy tasks</ion-title>
          <ion-buttons slot="end">
            <ion-button
              (click)="confirm()"
              [disabled]="copySource === null"
              [strong]="true"
              appHaptics
              [disableHaptics]="copySource === null"
            >
              Confirm
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <ion-select
                label="Tenant to copy from"
                label-placement="floating"
                fill="solid"
                [(ngModel)]="copySource"
                (ngModelChange)="onTaskSourceChange()"
                appHaptics
                (ionCancel)="hapticsImpact()"
              >
                <ion-select-option
                  *ngFor="let tenant of tenants"
                  [value]="tenant._id"
                >
                  {{ tenant.name }}
                </ion-select-option>
              </ion-select>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <ion-checkbox
                class="app-w100"
                id="terms"
                [(ngModel)]="skipSameNameTasks"
                justify="space-between"
                appHaptics
              >
                Skip tasks with existing name
              </ion-checkbox>
            </ion-col>
          </ion-row>
        </ion-grid>

        <ion-list>
          <ion-list-header>
            <ion-label>Source tenant tasks</ion-label>
          </ion-list-header>
          <ion-item
            *ngFor="let item of sourceTaskList"
            class="task-item opaque-reorder-item"
          >
            <ion-checkbox
              class="ion-text-capitalize"
              [class.deleted]="item.task.isDeleted"
              [checked]="item.checked"
              (ionChange)="item.checked = !item.checked"
              appHaptics
            >
              <span *ngIf="item.task.taskFolder" class="task-folder-title">
                {{ item.task.taskFolder.title }}
              </span>
              <span class="task-title">{{ item.task.title }}</span>
            </ion-checkbox>
          </ion-item>
        </ion-list>
      </ion-content>
    </ng-template>
  </ion-modal>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="No Tasks"></app-empty-stub>
</ng-template>
