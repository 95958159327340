<app-login-page-wrapper>
  <form
    *ngIf="!resetPasswordMode"
    [formGroup]="loginForm"
    (ngSubmit)="login()"
    class="bottom-block"
    novalidate
  >
    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="loginForm.controls['email']"
          [controlName]="'Email'"
        >
          <ion-input
            #emailInput
            appAutofill
            type="email"
            autocomplete="email"
            class="login-form-control"
            formControlName="email"
            placeholder="Email"
            email
            name="email"
            (ionInput)="onInput()"
            (ionChange)="onInput()"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="loginForm.controls['password']"
          [controlName]="'Password'"
        >
          <ion-input
            #passwordInput
            appAutofill
            type="password"
            autocomplete="current-password"
            class="login-form-control"
            formControlName="password"
            placeholder="Password"
            name="password"
            (ionInput)="onInput()"
            (ionChange)="onInput()"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>

    <div class="controls">
      <ion-button
        type="submit"
        color="light"
        fill="clear"
        [disabled]="loginForm.invalid"
        appHaptics
        [disableHaptics]="loginForm.invalid"
      >
        Log in
      </ion-button>
      <ion-button
        type="button"
        color="medium"
        fill="clear"
        size="small"
        (click)="goToResetPassword()"
        appHaptics
      >
        Reset Password
      </ion-button>

      <!-- this input is the hack to force submit event to fire on the enter press -->
      <input type="submit" class="hidden-control" />
    </div>
  </form>
  <form
    *ngIf="resetPasswordMode"
    [formGroup]="resetPasswordForm"
    (ngSubmit)="requestResetPassword()"
    class="bottom-block"
    novalidate
  >
    <div class="error-place error-place-persistent">
      Enter your email to receive a password reset link.
    </div>

    <ion-row>
      <ion-col size="12">
        <app-form-error-wrapper
          [control]="resetPasswordForm.controls['email']"
          [controlName]="'Email'"
        >
          <ion-input
            appAutofill
            type="email"
            autocomplete="email"
            class="login-form-control"
            formControlName="email"
            placeholder="Email"
            email
            name="email"
          />
        </app-form-error-wrapper>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-button
          type="submit"
          color="primary"
          class="ion-no-margin"
          [disabled]="resetPasswordForm.invalid"
          appHaptics
          [disableHaptics]="resetPasswordForm.invalid"
          expand="block"
        >
          Reset Password
        </ion-button>
      </ion-col>
    </ion-row>

    <div class="controls">
      <ion-button
        type="button"
        color="medium"
        fill="clear"
        size="small"
        (click)="backToLogin()"
        appHaptics
      >
        Back to Login
      </ion-button>
    </div>
  </form>
</app-login-page-wrapper>
