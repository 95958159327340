import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IPsychologicalSurvey } from '@backend/models/types/psychological-survey';
import { IPsychologicalSurveyQuestion } from '@backend/models/types/psychological-survey-question';
import { IPsychologicalSurveyBehavior } from '@backend/models/types/psychological-survey-behavior';
import { IPsychologicalSurveyAnswerValue } from '@backend/models/types/psychological-survey-answer';
import { ObjectId } from '@app/types/object-id';

@Injectable()
export class PsychologicalSurveyApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getLatestPsychologicalSurvey(): Observable<{
    survey: MongoStoredObject<IPsychologicalSurvey>;
    questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
  }> {
    return this._httpClient.get<{
      survey: MongoStoredObject<IPsychologicalSurvey>;
      questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
    }>('/psychological-survey/latest');
  }

  public getUnansweredPsychologicalSurveys(): Observable<{
    items: {
      survey: MongoStoredObject<IPsychologicalSurvey>;
      questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
    }[];
    count: number;
    totalCount: number;
  }> {
    return this._httpClient.get<{
      items: {
        survey: MongoStoredObject<IPsychologicalSurvey>;
        questions: MongoStoredObject<IPsychologicalSurveyQuestion>[];
      }[];
      count: number;
      totalCount: number;
    }>('/psychological-survey/unanswered');
  }

  public answerToPsychologicalSurvey(
    surveyId: string,
    questionAnswers: {
      question: string;
      result: IPsychologicalSurveyAnswerValue;
    }[]
  ): Observable<MongoStoredObject<IPsychologicalSurvey>> {
    return this._httpClient.post<MongoStoredObject<IPsychologicalSurvey>>(
      `/psychological-survey/${surveyId}/answer`,
      { questionAnswers }
    );
  }

  public getPsychologicalSurveyBehaviors(
    surveyId: string
  ): Observable<MongoStoredObject<IPsychologicalSurveyBehavior>[]> {
    return this._httpClient.get<
      MongoStoredObject<IPsychologicalSurveyBehavior>[]
    >(`/psychological-survey/${surveyId}/behaviors`);
  }

  public getFeedbackStats(userId?: string | ObjectId): Observable<
    MongoStoredObject<
      IPsychologicalSurveyBehavior & {
        personalFeedback: {
          positiveCount: number;
          negativeCount: number;
          finalValue: number;
        };
        teamFeedback: {
          positiveCount: number;
          negativeCount: number;
          finalValue: number;
        };
      }
    >[]
  > {
    return this._httpClient.get<
      MongoStoredObject<
        IPsychologicalSurveyBehavior & {
          personalFeedback: {
            positiveCount: number;
            negativeCount: number;
            finalValue: number;
          };
          teamFeedback: {
            positiveCount: number;
            negativeCount: number;
            finalValue: number;
          };
        }
      >[]
    >(
      userId
        ? `/psychological-survey/feedback/${userId}`
        : '/psychological-survey/feedback'
    );
  }
}
