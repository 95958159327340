<div class="title">
  <div class="title-left">
    <span class="sender" *ngIf="item.sender">
      {{ item.sender.firstName }} {{ item.sender.lastName }}
    </span>
    <ion-icon
      name="chevron-forward-outline"
      *ngIf="item.sender || item.recipient"
      class="recognition-arrow"
    ></ion-icon>
    <span class="recipient" *ngIf="item.recipient">
      {{ item.recipient.firstName }} {{ item.recipient.lastName }}
    </span>
    <span *ngIf="item.isPrivate">(Sent Privately)</span>
  </div>
  <div class="title-right">
    <div class="points">+{{ item.points }}</div>
  </div>
</div>
<div class="description">
  <div class="inner-message" [innerHTML]="body"></div>
</div>
<div class="footer">
  <div class="behaviors">
    <span class="behavior" *ngIf="item.behavior">
      #{{ item.behavior.name }}
    </span>
  </div>
  <div class="footer-actions">
    <ion-button
      *ngIf="showMarkAsReadButton"
      fill="outline"
      size="small"
      class="ion-no-margin"
      appHaptics
      (click)="onMarkAsReadClick($event)"
    >
      Mark As Read
    </ion-button>
  </div>
</div>

<ion-popover
  [isOpen]="isPopoverOpen"
  (didDismiss)="isPopoverOpen = false"
  #popover
>
  <ng-template>
    <ion-content>
      <ion-list lines="none" class="popover-list">
        <ion-item *ngIf="item.dateCreated">
          <div class="popover-label">
            <ion-label class="popover-label-title ion-no-margin">
              Created
            </ion-label>
            <ion-label
              color="primary"
              class="popover-label-value ion-no-margin"
            >
              {{ item.dateCreated | date : 'MMMM dd, yyyy' }}
            </ion-label>
          </div>
        </ion-item>
        <ion-item *ngIf="item.readDate">
          <div class="popover-label">
            <ion-label class="popover-label-title ion-no-margin">
              Seen
            </ion-label>
            <ion-label
              color="primary"
              class="popover-label-value ion-no-margin"
            >
              {{ item.readDate | date : 'MMMM dd, yyyy' }}
            </ion-label>
          </div>
        </ion-item>
        <ion-item lines="full" *ngIf="item.behavior && showPrivateButton">
          <div class="popover-label">
            <ion-label class="popover-label-title ion-no-margin">
              Private
            </ion-label>
            <ion-toggle
              class="ion-no-padding"
              slot="end"
              [checked]="item.isPrivate"
              (click)="onPrivateClick($event)"
              (ionChange)="onPrivateToggle($event)"
              appHaptics
              aria-label="Private"
            />
          </div>
        </ion-item>
      </ion-list>
      <div class="popover-actions">
        <ion-button
          fill="clear"
          expand="full"
          class="ion-no-margin"
          *ngIf="item.behavior && showEditButton"
          (click)="onEditClick()"
          appHaptics
        >
          Edit
        </ion-button>
        <ion-button
          fill="clear"
          expand="full"
          color="danger"
          class="ion-no-margin"
          *ngIf="item.behavior && showEditButton"
          (click)="onDeleteClick()"
          appHaptics
        >
          Delete
        </ion-button>
      </div>
    </ion-content>
  </ng-template>
</ion-popover>
