import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITaskFolder } from '@backend/models/types/task-folder';
import { Observable } from 'rxjs';

@Injectable()
export class TaskFolderApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getTaskFolders(): Observable<MongoStoredObject<ITaskFolder>[]> {
    return this._httpClient.get<MongoStoredObject<ITaskFolder>[]>(
      '/task-folders'
    );
  }

  public createTaskFolder(
    taskFolderBody: Omit<ITaskFolder, 'orderIndex' | 'tenant'>
  ): Observable<MongoStoredObject<ITaskFolder>> {
    return this._httpClient.post<MongoStoredObject<ITaskFolder>>(
      '/task-folders',
      taskFolderBody
    );
  }

  public updateTaskFolder(
    taskFolderId: string,
    taskFolderBody: Omit<ITaskFolder, 'orderIndex' | 'tenant'>
  ): Observable<MongoStoredObject<ITaskFolder>> {
    return this._httpClient.put<MongoStoredObject<ITaskFolder>>(
      `/task-folders/${taskFolderId}`,
      taskFolderBody
    );
  }

  public reorderTaskFoldersMultiple(
    taskFolders: {
      taskFolderId: string;
      orderIndex: number;
    }[]
  ): Observable<null> {
    return this._httpClient.post<null>(
      `/task-folders/reorder-multiple`,
      taskFolders
    );
  }

  public deleteTaskFolder(taskFolderId: string): Observable<null> {
    return this._httpClient.delete<null>(`/task-folders/${taskFolderId}`);
  }
}
