import { NgModule } from '@angular/core';
import { TaskHistoryModalComponent } from './task-history-modal.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { DirectivesModule } from '@app/modules/directives.module';
import { WidthRestrictedContainerModule } from '../width-restricted-container/width-restricted-container.module';
import { FormatTimeModule } from '../format-time/format-time.module';
import { WysiwygPreviewPipe } from '@app/pipes/standalone/wysiwyg-preview.pipe';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    DirectivesModule,
    WidthRestrictedContainerModule,
    FormatTimeModule,
    WysiwygPreviewPipe
  ],
  exports: [TaskHistoryModalComponent],
  declarations: [TaskHistoryModalComponent]
})
export class TaskHistoryModalModule {}
