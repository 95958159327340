<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="cancelChanges()" color="primary" appHaptics>
        Cancel
      </ion-button>
    </ion-buttons>
    <ion-title>Select Value</ion-title>
    <ion-buttons slot="end">
      <ion-button id="learn-more-btn" color="primary" appHaptics>
        Learn More
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="app-common-background">
    <ion-searchbar
      #ionSearchbar
      (ionInput)="searchbarInput($event)"
      (keyup.enter)="selectFirst()"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container>
    <ion-list>
      <ion-item
        button
        *ngFor="
          let behavior of behaviors | filterBehaviors : filter;
          trackBy: trackItems
        "
        (click)="itemClick(behavior._id)"
        appHaptics
        [ngClass]="{ 'highlighted-item': getFirst()?._id === behavior._id }"
      >
        <ion-label class="behavior-name">
          {{ behavior.name }}
        </ion-label>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>

<ion-modal
  trigger="learn-more-btn"
  #learnMoreModal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
  [presentingElement]="presentingElement"
>
  <ng-template>
    <ion-header class="ion-no-border">
      <ion-toolbar class="app-toolbar">
        <ion-title>Values</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="learnMoreModal.dismiss()" appHaptics>
            <ion-icon slot="icon-only" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content color="light" class="ion-padding">
      <app-values-accordion />
    </ion-content>
  </ng-template>
</ion-modal>
