import { NgModule } from '@angular/core';
import { NavBaseComponent } from '@app/modules/nav-base/nav-base.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { WidthRestrictedContainerModule } from '@app/modules/width-restricted-container/width-restricted-container.module';
import { RouterModule, Routes } from '@angular/router';
import { ViewRecognitionComponent } from './view-recognition.component';
import { RecognitionItemModule } from '@app/modules/recognition-item/recognition-item.module';
import { DirectivesModule } from '@app/modules/directives.module';

const ROUTES: Routes = [
  {
    path: '',
    component: NavBaseComponent,
    data: {
      title: 'Recognition',
      rootComponent: ViewRecognitionComponent
    }
  }
];

@NgModule({
  imports: [
    NavBaseComponent,
    CommonModule,
    RouterModule.forChild(ROUTES),
    IonicModule,
    WidthRestrictedContainerModule,
    RecognitionItemModule,
    DirectivesModule
  ],
  declarations: [ViewRecognitionComponent]
})
export class ViewRecognitionModule {}
