<div
  [ngClass]="performance.isDeleted ? 'pre-line pre-line--deleted' : 'pre-line'"
  *ngIf="performance.completeTime"
>
  <span class="date">
    {{ performance.completeTime | date : 'M/d/yy — h:mma' }}
  </span>
  <span class="count">x{{ performance.quantity }}</span>
</div>

<div
  [ngClass]="{
    'performance-item': true,
    'performance-item--deleted': performance.isDeleted,
    'performance-item--clickable': !disabled
  }"
>
  <div class="avatar">
    <div class="diagram">
      <div class="user-name">{{ userInitials }}</div>
    </div>
  </div>
  <div class="title ion-text-capitalize">
    <span class="title-content">
      {{ performance.task.title }}
    </span>
  </div>
  <div class="points">
    {{ performance.savedTaskPoints || performance.task.points }}
    <div class="note" *ngIf="isPerUnit">ea</div>
  </div>
</div>
