import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormErrorWrapperModule } from '../../modules/form-error-wrapper/form-error-wrapper.module';
import { SignupUserComponent } from './signup-user.component';
import { IonicModule } from '@ionic/angular';
import { LoginPageWrapperModule } from '@app/modules/login-page-wrapper/login-page-wrapper.module';
import { FormErrorPipe } from '@app/modules/form-error-pipe/form-error.pipe';
import { DirectivesModule } from '@app/modules/directives.module';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: SignupUserComponent
      }
    ]),
    CommonModule,
    ReactiveFormsModule,
    FormErrorWrapperModule,
    IonicModule,
    LoginPageWrapperModule,
    FormErrorPipe,
    DirectivesModule
  ],
  declarations: [SignupUserComponent]
})
export class SignupUserModule {}
