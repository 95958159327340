<div class="winner-item-title__left">
  <div class="winner-item-title__head">Winner</div>
  <div class="winner-item-title__value">
    {{ winner.name }}
  </div>
</div>
<div class="winner-item-title__right">
  <div class="winner-item-title__head">Points</div>
  <div class="winner-item-title__value">
    {{ winner.tickets }}
  </div>
</div>
