import { addDays, getDay, isAfter, isSameDay, setDay } from 'date-fns';

export function nextWeeklyPeriodStart(value: Date) {
  const dow = getDay(value);
  const now = new Date();
  let result = setDay(now, dow);

  if (!isSameDay(now, result) && isAfter(now, result)) {
    result = addDays(result, 7);
  }
  return result;
}
