import { TaskScheduleWeekly } from '@backend/models/types/task';
import { eachDayOfInterval, getDay } from 'date-fns';
import { DateInterval } from '../../../../backend/src/types/date-interval';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import { isIntervalsIntersect } from '../../utils/is-intervals-intersect';
import { TaskRenderer } from './task-renderer.class';
import { createTaskInterval } from '../../utils/create-task-interval';
import { TaskDto } from '@app/types/task-dto';

export class TaskWeeklyRenderer extends TaskRenderer {
  public constructor(
    task: MongoStoredObject<TaskDto>,
    allowTasksToBeDisplayedOnlyDuringCertainHours: boolean,
    private readonly _schedule: TaskScheduleWeekly
  ) {
    super(task, allowTasksToBeDisplayedOnlyDuringCertainHours);
  }

  protected createMatchingIntervals(interval: DateInterval): DateInterval[] {
    const result: DateInterval[] = eachDayOfInterval(interval)
      .map((d) =>
        createTaskInterval(
          d,
          this.task.appearsAt,
          this.task.disappearsAt,
          this.allowTasksToBeDisplayedOnlyDuringCertainHours
        )
      )
      .filter((i) => {
        const w = getDay(i.start);
        return this._schedule.weekDays[w] === true;
      })
      .filter((i) => isIntervalsIntersect(i, interval));
    return result;
  }
}
