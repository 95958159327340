<ng-container *ngIf="formArray.controls.length > 0; else zeroState">
  <app-date-chip
    *ngFor="let date of formArray.controls; let i = index"
    [value]="date.value"
    (removed)="onDateRemoveClick(i)"
  ></app-date-chip>
</ng-container>

<ng-template #zeroState>
  <div class="dates-zero-container">
    <span
      class="error-message"
      *ngIf="
        formArray.hasError('minArrayLength') && formArray.dirty;
        else prompt
      "
    >
      At least one date required
    </span>
  </div>
</ng-template>

<ng-template #prompt>
  <span>Add dates to the list</span>
</ng-template>
