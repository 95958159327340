<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="suggestion">{{ suggestion.title }}</ion-title>
    <ion-buttons slot="end" *ngIf="isAdmin || isMine">
      <ion-button color="primary" (click)="editDoc()" appHaptics>
        Edit
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <div class="opportunity-card">
        <app-wysiwyg-editor [value]="body" mode="view" minHeight="56px" />
        <div class="opportunity-card__footer">
          <div class="opportunity-card__footer-item">
            {{ authorName }}
          </div>
          <div class="opportunity-card__footer-item">
            {{ suggestion.dateCreated | date : 'MMM d, y' }}
          </div>
          <div
            *ngIf="suggestion.isPublic"
            class="opportunity-card__footer-item--primary"
          >
            Published to Team
          </div>
        </div>
      </div>
      <div *ngIf="suggestion.solutions.length" class="subtitle">
        <ion-title>Comments</ion-title>
      </div>
      <app-solution-item
        *ngFor="
          let solution of suggestion.solutions;
          trackBy: trackItems;
          let i = index
        "
        [solution]="solution"
        [opportunity]="suggestion"
        [showOptions]="isAdmin"
        (solutionDeleted)="solutionDeleted(solution._id)"
      />
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="6">
            <ion-button
              color="primary"
              fill="outline"
              expand="block"
              appHaptics
              (click)="addSolution()"
            >
              Add Comment
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>
