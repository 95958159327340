import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MongoStoredObject } from '../../types/mongo-stored-object';
import { ObjectId } from '../../types/object-id';
import { TaskPerformanceInputDto } from '../../../../backend/src/models/types/task-performance-input-dto';
import { Observable } from 'rxjs';
import { TaskDto } from '@app/types/task-dto';
import { TaskInputDto } from '@app/types/task-input-dto';
import { Duration } from 'date-fns';
import { UserModel } from '../model/user.model';
import { ITaskFeedback } from '@backend/models/types/task-feedback';
import { IAttachment } from '@backend/models/types/attachment';
import { ITaskPerformance } from '@backend/models/types/task-performance';
import { ESkillMatrixStatus } from '@backend/models/types/task';

@Injectable({ providedIn: 'root' })
export class TasksApiService {
  public constructor(private readonly _httpClient: HttpClient) {}

  public getAll() {
    return this._httpClient.get<MongoStoredObject<TaskDto>[]>(`/tasks`);
  }

  public create(task: TaskInputDto) {
    return this._httpClient.post<MongoStoredObject<TaskDto>>(`/tasks`, task);
  }

  public patch(taskId: ObjectId, task: TaskDto) {
    return this._httpClient.patch<MongoStoredObject<TaskDto>>(
      `/tasks/${taskId}`,
      task
    );
  }

  public getTaskById(taskId: ObjectId) {
    return this._httpClient.get<MongoStoredObject<TaskDto>>(`/tasks/${taskId}`);
  }

  public delete(taskId: ObjectId) {
    return this._httpClient.delete<MongoStoredObject<TaskDto>>(
      `/tasks/${taskId}`
    );
  }

  public restore(taskId: ObjectId) {
    return this._httpClient.post<MongoStoredObject<TaskDto>>(
      `/tasks/${taskId}/restore`,
      {}
    );
  }

  public setPerformance(
    taskId: ObjectId,
    performanceInput: TaskPerformanceInputDto,
    userId: ObjectId,
    isLastCriticalTask?: boolean
  ) {
    return this._httpClient.put<unknown>(`/tasks/${taskId}/performance`, {
      ...performanceInput,
      user: userId,
      isLastCriticalTask: isLastCriticalTask || false
    });
  }

  public getLastTaskPerformance(taskId: ObjectId): Observable<{
    lastPerformance:
      | (MongoStoredObject<ITaskPerformance> & {
          user: Pick<UserModel, 'firstName' | 'lastName'>;
          note?: {
            attachments: MongoStoredObject<IAttachment>[];
          };
        })
      | null;
    lastPerformanceWithNote:
      | (MongoStoredObject<ITaskPerformance> & {
          user: Pick<UserModel, 'firstName' | 'lastName'>;
          note?: {
            attachments: MongoStoredObject<IAttachment>[];
          };
        })
      | null;
    tasksForAudit: {
      task: MongoStoredObject<TaskDto>;
      lastPerformance:
        | (MongoStoredObject<Omit<ITaskPerformance, 'user'>> & {
            user: Pick<UserModel, 'firstName' | 'lastName'> & { _id: ObjectId };
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          })
        | null;
    }[];
  }> {
    return this._httpClient.get<{
      lastPerformance:
        | (MongoStoredObject<ITaskPerformance> & {
            user: Pick<UserModel, 'firstName' | 'lastName'>;
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          })
        | null;
      lastPerformanceWithNote:
        | (MongoStoredObject<ITaskPerformance> & {
            user: Pick<UserModel, 'firstName' | 'lastName'>;
            note?: {
              attachments: MongoStoredObject<IAttachment>[];
            };
          })
        | null;
      tasksForAudit: {
        task: MongoStoredObject<TaskDto>;
        lastPerformance:
          | (MongoStoredObject<Omit<ITaskPerformance, 'user'>> & {
              user: Pick<UserModel, 'firstName' | 'lastName'> & {
                _id: ObjectId;
              };
              note?: {
                attachments: MongoStoredObject<IAttachment>[];
              };
            })
          | null;
      }[];
    }>(`/tasks/${taskId}/last-performance`);
  }

  public getTaskPerformances(
    taskId: ObjectId,
    page: number,
    limit: number
  ): Observable<
    (MongoStoredObject<ITaskPerformance> & {
      user: Pick<UserModel, 'firstName' | 'lastName'>;
      note?: {
        attachments: MongoStoredObject<IAttachment>[];
      };
    })[]
  > {
    return this._httpClient.get<
      (MongoStoredObject<ITaskPerformance> & {
        user: Pick<UserModel, 'firstName' | 'lastName'>;
        note?: {
          attachments: MongoStoredObject<IAttachment>[];
        };
      })[]
    >(`/tasks/${taskId}/performances`, { params: { page, limit } });
  }

  public reorderTasksMultiple(
    taskFolders: {
      taskFolder: string | null;
      tasks: { taskId: string; orderIndex: number }[];
    }[]
  ): Observable<MongoStoredObject<TaskDto>[]> {
    return this._httpClient.post<MongoStoredObject<TaskDto>[]>(
      `/tasks/reorder-multiple`,
      taskFolders
    );
  }

  public refoldTasksMultiple(
    taskFolders: { taskFolder: string | null; tasks: string[] }[]
  ): Observable<MongoStoredObject<TaskDto>[]> {
    return this._httpClient.post<MongoStoredObject<TaskDto>[]>(
      `/tasks/refold-multiple`,
      taskFolders
    );
  }

  public patchTasks(
    userId: ObjectId,
    tasks: {
      _id: ObjectId;
      isNotificationUponCompletion?: boolean;
      skillMatrixStatus?: ESkillMatrixStatus;
    }[]
  ): Observable<MongoStoredObject<TaskDto>[]> {
    return this._httpClient.patch<MongoStoredObject<TaskDto>[]>(`/tasks`, {
      userId,
      tasks
    });
  }

  public getTimeAttackStats(taskId: ObjectId): Observable<{
    averageDuration: Duration;
    currentRecord: {
      user: UserModel;
      duration: Duration;
    };
    rewardForBeatingTheAverage: number;
    rewardForAttempt: number;
  }> {
    return this._httpClient.get<{
      averageDuration: Duration;
      currentRecord: {
        user: UserModel;
        duration: Duration;
      };
      rewardForBeatingTheAverage: number;
      rewardForAttempt: number;
    }>(`/tasks/${taskId}/time-attack-stats`);
  }

  public getSystemGeneratedTasks(): Observable<MongoStoredObject<TaskDto>[]> {
    return this._httpClient.get<MongoStoredObject<TaskDto>[]>('/tasks/system');
  }

  public getTaskFeedbackStats(taskId: ObjectId): Observable<{
    daysSinceLastFeedback: number;
    daysSinceLastFeedbackNeeded: number;
    performancesSinceLastFeedback: number;
    performancesSinceLastFeedbackNeeded: number;
    isFeedbackNeeded: boolean;
    mandatory: boolean;
  }> {
    return this._httpClient.get<{
      daysSinceLastFeedback: number;
      daysSinceLastFeedbackNeeded: number;
      performancesSinceLastFeedback: number;
      performancesSinceLastFeedbackNeeded: number;
      isFeedbackNeeded: boolean;
      mandatory: boolean;
    }>(`/tasks/${taskId}/feedback`);
  }

  public postTaskFeedback(
    taskId: ObjectId,
    input: {
      body: string;
      attachments: string[];
    }
  ): Observable<MongoStoredObject<ITaskFeedback>> {
    return this._httpClient.post<MongoStoredObject<ITaskFeedback>>(
      `/tasks/${taskId}/feedback`,
      input
    );
  }
}
