import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaskFormComponent } from './task-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ScheduleSubformComponent } from './components/schedule-subform/schedule-subform.component';
import { ScheduleNeverFormComponent } from './components/schedule-never-form/schedule-never-form.component';
import { ScheduleSpecificDatesComponent } from './components/schedule-specific-dates/schedule-specific-dates.component';
import { ShortDatePipe } from './pipes/short-date.pipe';
import { IsWorkingDayPipe } from './pipes/is-working-day.pipe';
import { ScheduleMonthlyComponent } from './components/schedule-monthly/schedule-monthly.component';
import { DateListComponent } from './components/date-list/date-list.component';
import { DateChipComponent } from './components/date-chip/date-chip.component';
import { DayToggleButtonModule } from '../day-toggle-button/day-toggle-button.module';
import { MonthDaysPickerModule } from '../month-days-picker/month-days-picker.module';
import { DurationPipe } from './pipes/duration.pipe';
import { PerformanceDurationFormControlComponent } from './components/performance-duration-form-control/performance-duration-form-control.component';
import { DividerModule } from '../divider/divider.module';
import { FormatTimeModule } from '../format-time/format-time.module';
import { UserSelectorComponent } from '../user-selector/user-selector.component';
import { PickPipe } from '../pick-pipe/pick.pipe';
import { DirectivesModule } from '@app/modules/directives.module';
import { TabSwitchComponent } from '../tab-switch/tab-switch.component';
import { TimeFormControlModule } from '../time-form-control/time-form-control.module';
import { ScheduleWeeklyModule } from '../schedule-weekly/schedule-weekly.module';
import { WysiwygModalModule } from '../wysiwyg-modal/wysiwyg-modal.module';
import { TaskFolderSelectorComponent } from '../task-folder-selector/task-folder-selector.component';
import { InlineInputModule } from '../inline-input/inline-input.module';
import { DigitOnlyModule } from '@uiowa/digit-only';
import { WysiwygPreviewPipe } from '@app/pipes/standalone/wysiwyg-preview.pipe';
import { TaskSelectorComponent } from '../task-selector/task-selector.component';
import { SkillMatrixForTaskModalModule } from '../skill-matrix-for-task-modal/skill-matrix-for-task-modal.module';

@NgModule({
  declarations: [
    TaskFormComponent,
    ScheduleSubformComponent,
    ScheduleNeverFormComponent,
    ScheduleSpecificDatesComponent,
    ShortDatePipe,
    IsWorkingDayPipe,
    ScheduleMonthlyComponent,
    DateListComponent,
    DateChipComponent,
    DurationPipe,
    PerformanceDurationFormControlComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    DayToggleButtonModule,
    MonthDaysPickerModule,
    DividerModule,
    FormatTimeModule,
    UserSelectorComponent,
    PickPipe,
    DirectivesModule,
    TabSwitchComponent,
    TimeFormControlModule,
    ScheduleWeeklyModule,
    WysiwygModalModule,
    TaskFolderSelectorComponent,
    InlineInputModule,
    DigitOnlyModule,
    WysiwygPreviewPipe,
    TaskSelectorComponent,
    SkillMatrixForTaskModalModule
  ],
  exports: [TaskFormComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TaskFormModule {}
