<ion-button
  color="light"
  expand="block"
  [disabled]="disabled"
  class="ion-no-margin"
  appHaptics
  [disableHaptics]="disabled"
  (click)="buttonClicked.emit($event)"
>
  <div class="button-content-wrapper">
    <div class="label">{{ label }}</div>
    <div class="text" [ngClass]="{ 'text--no-overflow': !overflow }">
      <ng-content />
    </div>
  </div>
</ion-button>
