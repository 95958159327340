import { TaskScheduleSpecificDates } from '@backend/models/types/task';
import { eachYearOfInterval, getYear, setYear } from 'date-fns';
import { DateInterval } from '@backend/types/date-interval';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { isIntervalsIntersect } from '@app/utils/is-intervals-intersect';
import { TaskRenderer } from './task-renderer.class';
import { createTaskInterval } from '@app/utils/create-task-interval';
import { stripeDate } from '@app/utils/stripe-date';
import { adjustDateToFlags } from '@app/utils/adjust-date-to-flags';
import { DateFlags } from '@app/modules/task-form/types/date-flags.enum';
import { TaskDto } from '@app/types/task-dto';
import { getZonedTime } from '@app/utils/get-zoned-time';

export class TaskSpecificDatesRenderer extends TaskRenderer {
  public constructor(
    task: MongoStoredObject<TaskDto>,
    allowTasksToBeDisplayedOnlyDuringCertainHours: boolean,
    private readonly _schedule: TaskScheduleSpecificDates
  ) {
    super(task, allowTasksToBeDisplayedOnlyDuringCertainHours);
  }

  protected createMatchingIntervals(interval: DateInterval): DateInterval[] {
    let dates: Date[];
    if (this._schedule.repeatEveryYear) {
      dates = this._schedule.dates
        .map((dateString) =>
          this._createScheduleDatesInInterval(dateString, interval)
        )
        .flat();
    } else {
      dates = this._schedule.dates.map((dateString) =>
        this._createScheduleDate(dateString)
      );
    }
    const result = dates
      .map((d) =>
        createTaskInterval(
          d,
          this.task.appearsAt,
          this.task.disappearsAt,
          this.allowTasksToBeDisplayedOnlyDuringCertainHours
        )
      )
      .filter((i) => isIntervalsIntersect(i, interval));
    return result;
  }

  private _createScheduleDatesInInterval(
    dateStringWithFlags: string,
    interval: DateInterval
  ): Date[] {
    // TODO:
    // using flags now a bit complicated solution left from the period
    // when flags were a part of the date string
    // consider to simpplify code having date string without DateFlags
    const [dateString, flags] = stripeDate(dateStringWithFlags);
    const result = eachYearOfInterval(interval).map((d) => {
      const year = getYear(d);
      return adjustDateToFlags(
        setYear(getZonedTime(new Date(dateString)), year),
        `${flags}${this._schedule.adjustWeekdays ? DateFlags.WEEKDAY : ''}`
      );
    });
    return result;
  }

  private _createScheduleDate(dateStringWithFlags: string): Date {
    const [dateString, flags] = stripeDate(dateStringWithFlags);
    return adjustDateToFlags(
      getZonedTime(new Date(dateString)),
      `${flags}${this._schedule.adjustWeekdays ? DateFlags.WEEKDAY : ''}`
    );
  }
}
