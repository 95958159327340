import { setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

export function setTimeForDate(date: number | Date, time: string): Date {
  const [hours, minutes] = time.split(':');
  const nHours = Number.parseInt(hours);
  const nMinutes = Number.parseInt(minutes);

  return setMilliseconds(
    setSeconds(setMinutes(setHours(date, nHours), nMinutes), 0),
    0
  );
}
