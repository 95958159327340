<ion-grid [formGroup]="form">
  <ion-row>
    <ion-col size="10">
      <app-date-list [formArray]="form.controls.daysOfMonth"></app-date-list>
    </ion-col>
    <ion-col size="2" class="ion-text-right">
      <button
        mat-icon-button
        (click)="onDateButtonClick($event)"
        aria-label="Add date"
        appHaptics
      >
        <ion-icon name="add"></ion-icon>
      </button>
      <ion-popover
        [keepContentsMounted]="true"
        [isOpen]="isOpen"
        (didDismiss)="onPopovedDismiss()"
        cssClass="popover-datetime"
        #popover
      >
        <ng-template>
          <app-month-days-picker
            [(ngModel)]="daysOfMonth"
            [ngModelOptions]="{ standalone: true }"
          ></app-month-days-picker>
          <ion-buttons class="ion-justify-content-between action-buttons">
            <ion-button
              color="primary"
              fill="clear"
              (click)="isOpen = false"
              appHaptics
            >
              Cancel
            </ion-button>
            <ion-button
              color="primary"
              fill="clear"
              (click)="onDoneClick()"
              appHaptics
            >
              Done
            </ion-button>
          </ion-buttons>
        </ng-template>
      </ion-popover>
    </ion-col>
  </ion-row>
</ion-grid>
