<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="end">
      <ion-button (click)="askGoBack()">
        <ion-icon name="close" />
      </ion-button>
    </ion-buttons>
    <ion-title>
      <ng-container *ngIf="this.announcement; else newAnnouncement">
        Edit Announcement
      </ng-container>
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="layout-container">
    <div class="top-content">
      <ion-grid class="top-content-grid">
        <ion-row>
          <ion-col size="12">
            <ion-input
              class="app-w100 input-with-background"
              labelPlacement="floating"
              label="Recipients"
              id="selectRecipients"
              appHaptics
              [value]="formattedRecipients"
              [readonly]="true"
            />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-input
              class="app-w100 input-with-background"
              labelPlacement="floating"
              label="Subject"
              [(ngModel)]="subject"
              autocapitalize="on"
              [spellcheck]="true"
            />
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <app-wysiwyg-editor
              [(value)]="body"
              [disableAttachments]="disableAttachments"
            />
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div class="bottom-content">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col>
            <div class="ion-text-center">
              <div class="critical-info-label">Critical</div>
              <ion-toggle
                class="ion-no-padding"
                [(ngModel)]="isCritical"
                appHaptics
                aria-label="Critical"
              />
              <div class="critical-info-description">
                Require to acknowledge the announcement prior to gaining access
                to the app
              </div>
            </div>
          </ion-col>
        </ion-row>
        <ion-row class="ion-justify-content-center ion-padding">
          <ion-col size="4">
            <ion-button
              color="dark"
              (click)="askPublish()"
              [disabled]="!canNextStep"
              appHaptics
              [disableHaptics]="!canNextStep"
              expand="block"
            >
              {{ announcement ? 'Save' : 'Publish' }}
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </app-width-restricted-container>
</ion-content>

<ng-template #newAnnouncement>Create Announcement</ng-template>

<ion-modal
  trigger="selectRecipients"
  #modal
  [handle]="false"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
>
  <ng-template>
    <app-user-selector
      class="ion-page"
      [users]="allUsers"
      [recipients]="selectedUserIds"
      (selectionChange)="selectedIdChanged($event)"
      (selectionCancel)="modal.dismiss()"
      [multiple]="true"
    ></app-user-selector>
  </ng-template>
</ion-modal>
