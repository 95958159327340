import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, ROLES } from '@app/core';
import { BehaviorModel } from '@app/core/model/behavior.model';
import { IonNav } from '@ionic/angular';

@Component({
  selector: 'app-culture',
  templateUrl: './culture.component.html',
  styleUrls: ['./culture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CultureComponent {
  protected valueBehaviors: BehaviorModel[] = [];
  protected isAdmin = false;

  public constructor(
    private readonly _authenticationService: AuthenticationService,
    private readonly _router: Router,
    private readonly _ionNav: IonNav
  ) {
    this.isAdmin = this._authenticationService.user
      ? this._authenticationService.user.roles.includes(ROLES.adminName)
      : false;
  }

  protected onSettingsClick() {
    this._router.navigate(['/i/team-settings'], {
      queryParams: {
        scrollTo: 'culture'
      }
    });
  }

  protected onBackButtonClick() {
    this._ionNav.pop();
  }
}
