import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthenticationService } from '../authentication';
import { isSameDay } from 'date-fns';
import { TodaysShiftModalComponent } from '@app/modules/todays-shift-modal/todays-shift-modal.component';
import { EUserScheduleType } from '@backend/models/types/user';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { IUserShiftTime } from '@backend/models/types/user-shift-time';

@Injectable()
export class TodaysShiftService {
  public constructor(
    private readonly _modalCtrl: ModalController,
    private readonly _authenticationService: AuthenticationService,
    private readonly _httpClient: HttpClient
  ) {}

  public async askTodaysShift() {
    const user = this._authenticationService.user;

    if (user && user.schedule) {
      this.getMyLatestShiftTime().subscribe(async (lastShift) => {
        if (
          user.schedule.scheduleType === EUserScheduleType.VARIABLE &&
          (!lastShift || !isSameDay(new Date(lastShift.date), new Date()))
        ) {
          const todaysShiftModal =
            document.getElementById('todays-shift-modal');
          if (!todaysShiftModal) {
            const modal = await this._modalCtrl.create({
              id: 'todays-shift-modal',
              component: TodaysShiftModalComponent,
              cssClass: 'modal-auto-height todays-shift-modal',
              breakpoints: [0, 1],
              initialBreakpoint: 1,
              handle: false,
              backdropDismiss: false,
              canDismiss: async (_, role) => role === 'confirm'
            });

            modal.present();
            await modal.onWillDismiss();
          }
        }
      });
    }
  }

  public getMyLatestShiftTime(): Observable<MongoStoredObject<IUserShiftTime> | null> {
    return this._httpClient.get<MongoStoredObject<IUserShiftTime> | null>(
      '/user/latest-shift-time'
    );
  }

  public getUserLatestShiftTime(
    userId: string
  ): Observable<MongoStoredObject<IUserShiftTime> | null> {
    return this._httpClient.get<MongoStoredObject<IUserShiftTime> | null>(
      `/users/${userId}/latest-shift-time`
    );
  }

  public setShiftTime(
    start?: string,
    end?: string
  ): Observable<MongoStoredObject<IUserShiftTime>> {
    return this._httpClient.post<MongoStoredObject<IUserShiftTime>>(
      '/user/shift-time',
      {
        start,
        end
      }
    );
  }
}
