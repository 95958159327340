import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from '@app/modules/directives.module';

@Component({
  selector: 'app-emoji-button',
  templateUrl: './emoji-button.component.html',
  styleUrls: ['./emoji-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, IonicModule, DirectivesModule]
})
export class EmojiButtonComponent {
  @Input()
  public readonly label: string;

  @Input()
  public readonly emoji: string;
}
