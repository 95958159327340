import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PostRecognitionInput } from '../../types/post-recognition-input';

@Injectable({ providedIn: 'root' })
export class RecognitionApiService {
  public constructor(private _httpClient: HttpClient) {}

  public postRecognition(data: PostRecognitionInput): Observable<unknown> {
    return this._httpClient.post<unknown>('/recognition', data);
  }

  public getRecognitionReminders(): Observable<{
    items: { title: string }[];
    count: number;
    totalCount: number;
  }> {
    return this._httpClient.get<{
      items: { title: string }[];
      count: number;
      totalCount: number;
    }>('/recognition/reminders');
  }

  public dismissRecognitionReminder(): Observable<null> {
    return this._httpClient.post<null>('/recognition/reminder/dismiss', {});
  }
}
