<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-title>{{ taskPerformance.task.title }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="onCloseClick()" appHaptics>
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div>
  <ion-list class="ion-padding" [formGroup]="form">
    <ion-item>
      <ion-label>Status</ion-label>
      <ion-note slot="end">
        {{
          taskPerformance.status === taskPerformanceStatus.WAITING_FOR_APPROVAL
            ? 'Waiting for Approval'
            : 'Waiting to Start'
        }}
      </ion-note>
    </ion-item>
    <ion-item>
      <ion-label>User</ion-label>
      <ion-note slot="end">
        {{ user }}
      </ion-note>
    </ion-item>
    <div
      *ngIf="
        taskPerformance.status === taskPerformanceStatus.WAITING_FOR_APPROVAL
      "
    >
      <ion-item lines="none">
        <ion-label>Start</ion-label>
      </ion-item>
      <ion-row>
        <ion-col size="6">
          <app-date-selector
            formControlName="startDate"
            [max]="completeDate"
            [endOfDay]="true"
          />
        </ion-col>
        <ion-col size="6">
          <app-time-form-control
            class="app-w100"
            formControlName="startTime"
            [minuteStep]="1"
          />
        </ion-col>
      </ion-row>
      <ion-item lines="none">
        <ion-label>End</ion-label>
      </ion-item>
      <ion-row>
        <ion-col size="6">
          <app-date-selector
            formControlName="completeDate"
            [min]="startDate"
            [endOfDay]="true"
          />
        </ion-col>
        <ion-col size="6">
          <app-time-form-control
            class="app-w100"
            formControlName="completeTime"
            [minuteStep]="1"
          />
        </ion-col>
      </ion-row>
      <ion-item *ngIf="taskPerformance.quantity > 1">
        <ion-label> Time Spent (each) </ion-label>
        <ion-note slot="end">
          {{ timeSpentEach }} (x{{ taskPerformance.quantity }})
        </ion-note>
      </ion-item>
      <ion-item>
        <ion-label>
          {{
            taskPerformance.quantity === 1 ? 'Time Spent' : 'Time Spent (total)'
          }}
        </ion-label>
        <ion-note slot="end">
          {{ timeSpentTotal }} (Average: {{ averageTime }})
        </ion-note>
      </ion-item>
      <ion-item>
        <ion-select
          formControlName="bonus"
          label="Quality"
          interface="action-sheet"
        >
          <ion-select-option [value]="taskPerformanceBonus.NEEDS_IMPROVEMENT">
            Needs Improvement (no bonus points)
          </ion-select-option>
          <ion-select-option [value]="taskPerformanceBonus.ACCEPTABLE">
            Acceptable (full bonus points)
          </ion-select-option>
          <ion-select-option [value]="taskPerformanceBonus.EXCELLENT">
            Excellent (full bonus points)
          </ion-select-option>
        </ion-select>
      </ion-item>
      <ion-item
        *ngIf="
          form.get('bonus').value !== taskPerformanceBonus.NEEDS_IMPROVEMENT
        "
      >
        <ion-label>Bonus</ion-label>
        <ion-note slot="end">
          <div class="ion-text-right">
            <ion-text color="primary">
              +{{ taskPerformance.task.timeAttackAttemptPoints }}
              points
            </ion-text>
            for attempt
          </div>
          <div class="ion-text-right" *ngIf="isFasterThanAverage">
            <ion-text color="primary">
              +{{ taskPerformance.task.timeAttackRewardPoints }}
              points
            </ion-text>
            for beating the average
          </div>
        </ion-note>
      </ion-item>
    </div>
  </ion-list>
  <ion-grid>
    <ion-row class="ion-justify-content-center ion-padding">
      <ion-col size="6">
        <ion-button
          color="dark"
          fill="outline"
          expand="block"
          (click)="onRejectClick()"
          appHaptics
          [disabled]="!timeAttackStats"
          [disableHaptics]="!timeAttackStats"
        >
          Reject
        </ion-button>
      </ion-col>
      <ion-col size="6">
        <ion-button
          color="dark"
          expand="block"
          (click)="onApproveClick()"
          appHaptics
          [disabled]="!timeAttackStats"
          [disableHaptics]="!timeAttackStats"
        >
          {{
            taskPerformance.status ===
            taskPerformanceStatus.WAITING_FOR_APPROVAL
              ? 'Approve'
              : 'Start'
          }}
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
