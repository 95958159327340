import { NgModule } from '@angular/core';
import { PerformanceItemComponent } from './performance-item.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [PerformanceItemComponent],
  declarations: [PerformanceItemComponent]
})
export class PerformanceItemModule {}
