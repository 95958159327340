import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-compensation-announcement-preview-modal',
  templateUrl: './compensation-announcement-preview-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompensationAnnouncementPreviewModalComponent {
  @Input()
  public readonly title: string = '';

  @Input()
  public readonly body: string = '';

  public constructor(private readonly _modalCtrl: ModalController) {}

  protected onCloseClick(): void {
    this._modalCtrl.dismiss(
      null,
      'cancel',
      'compensation-announcement-preview-modal'
    );
  }
}
