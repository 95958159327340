<app-wysiwyg-editor [value]="body" mode="view" minHeight="56px" />
<div class="solution-item__footer">
  <div class="solution-item__footer-item">
    {{ solution.author.firstName }} {{ solution.author.lastName }}
  </div>
  <div class="solution-item__footer-item">
    {{ solution.dateCreated | date : 'MMM d, y' }}
  </div>
  <div *ngIf="solution.isPublic" class="solution-item__footer-item--primary">
    Published to Team
  </div>
</div>

<ion-popover
  [isOpen]="isPopoverOpen"
  (didDismiss)="isPopoverOpen = false"
  #popover
>
  <ng-template>
    <ion-content>
      <ion-list lines="none" class="solution-item__popover-list">
        <ion-item>
          <div class="solution-item__popover-label">
            <ion-label class="solution-item__popover-label-title ion-no-margin">
              Author
            </ion-label>
            <ion-label
              color="primary"
              class="solution-item__popover-label-value ion-no-margin"
            >
              {{ solution.author.firstName }} {{ solution.author.lastName }}
            </ion-label>
          </div>
        </ion-item>
        <ion-item>
          <div class="solution-item__popover-label">
            <ion-label class="solution-item__popover-label-title ion-no-margin">
              Created
            </ion-label>
            <ion-label
              color="primary"
              class="solution-item__popover-label-value ion-no-margin"
            >
              {{ solution.dateCreated | date : 'MMM d, y' }}
            </ion-label>
          </div>
        </ion-item>
        <ion-item lines="full">
          <div class="solution-item__popover-label">
            <ion-label class="solution-item__popover-label-title ion-no-margin">
              Publish to Team
            </ion-label>
            <ion-toggle
              class="ion-no-padding"
              slot="end"
              [checked]="solution.isPublic"
              (ionChange)="onIsPublicChange($event)"
              appHaptics
              aria-label="Publish to Team"
            />
          </div>
        </ion-item>
      </ion-list>
      <div class="solution-item__popover-actions">
        <ion-button
          fill="clear"
          expand="full"
          color="danger"
          class="ion-no-margin"
          (click)="onDeleteClick()"
          appHaptics
        >
          Delete
        </ion-button>
      </div>
    </ion-content>
  </ng-template>
</ion-popover>
