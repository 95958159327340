import { NgModule } from '@angular/core';
import { AnnouncementItemComponent } from './announcement-item.component';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ReadEyeComponent } from '../read-eye/read-eye.component';

@NgModule({
  imports: [CommonModule, IonicModule, ReadEyeComponent],
  exports: [AnnouncementItemComponent],
  declarations: [AnnouncementItemComponent]
})
export class AnnouncementItemModule {}
