import { Pipe, PipeTransform } from '@angular/core';
import { ObjectId } from '@app/types/object-id';
import { ROLES } from '@app/core';
import FuzzySearch from 'fuzzy-search';

@Pipe({
  name: 'filterUsers',
  standalone: true
})
export class FilterUsersPipe implements PipeTransform {
  public transform(
    items: {
      id: ObjectId;
      name: string;
      checked: boolean;
      roles: ROLES[];
    }[],
    filter: string
  ): {
    id: ObjectId;
    name: string;
    checked: boolean;
    roles: ROLES[];
  }[] {
    if (filter.length === 0) {
      return items;
    }
    const searcher = new FuzzySearch(items, ['name'], {
      caseSensitive: false
    });
    return searcher.search(filter);
  }
}
