import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TenantListService } from '@app/core/service/tenant-list.service';
import { MongoStoredObject } from '@app/types/mongo-stored-object';
import { ITenant } from '@backend/models/types/tenant';
import { IonNav } from '@ionic/angular';
import { Observable } from 'rxjs';
import { TenantEditComponent } from './components/tenant-edit/tenant-edit.component';
import { TenantTasksComponent } from './components/tenant-tasks/tenant-tasks.component';
import { TenantUsersComponent } from './components/tenant-users/tenant-users.component';
import { TenantIntegrationsComponent } from './components/tenant-integrations/tenant-integrations.component';
import { IIntegration } from '@backend/models/types/integration';
import { TenantFeaturesComponent } from './components/tenant-features/tenant-features.component';
import { AuthenticationService } from '@app/core';

@Component({
  selector: 'app-tenants',
  templateUrl: './tenants.component.html',
  styleUrls: ['./tenants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TenantsComponent {
  protected tenantList$: Observable<
    MongoStoredObject<ITenant & { integrations: IIntegration }>[]
  > = this._tenantService.tenants$;
  protected currentTenantId: string = null;

  public constructor(
    private readonly _tenantService: TenantListService,
    private readonly _ionNav: IonNav,
    private readonly _authenticationService: AuthenticationService
  ) {
    this.currentTenantId = this._authenticationService.user.tenant;
  }

  protected itemTrackBy(_index: number, item: MongoStoredObject<ITenant>) {
    return item._id;
  }

  protected onTenantNameInput(
    { detail: { value } }: { detail: { value: string } },
    tenant: MongoStoredObject<ITenant>
  ): void {
    value = value.trim();
    if (value.length === 0) {
      return;
    }
    this._tenantService
      .patchTenantById(tenant._id, { name: value })
      .subscribe();
  }

  protected onEnabledChange(
    { detail: { checked } }: { detail: { checked: boolean } },
    tenant: MongoStoredObject<ITenant>
  ) {
    this._tenantService
      .patchTenantById(tenant._id, { isDeleted: !checked })
      .subscribe();
  }

  protected onFabClick() {
    this._ionNav.push(TenantEditComponent);
  }

  protected onTasksClick(
    tenant: MongoStoredObject<ITenant>,
    tenants: MongoStoredObject<ITenant>[]
  ) {
    this._ionNav.push(TenantTasksComponent, { tenant, tenants });
  }

  protected onUsersClick(tenant: MongoStoredObject<ITenant>) {
    this._ionNav.push(TenantUsersComponent, { tenant });
  }

  protected onIntegrationsClick(tenant: MongoStoredObject<ITenant>) {
    this._ionNav.push(TenantIntegrationsComponent, { tenant });
  }

  protected onFeaturesClick(tenant: MongoStoredObject<ITenant>) {
    this._ionNav.push(TenantFeaturesComponent, { tenant });
  }
}
