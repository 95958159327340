import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TaskPerformanceService } from '@app/core/service/task-performance.service';
import { TaskPerformanceStatus } from '@backend/models/types/task-performance';
import { LoadingController, ModalController } from '@ionic/angular';
import { Duration } from 'date-fns';
import { ToastService } from '@app/core/service/toast.service';
import { UserModel } from '@app/core/model/user.model';
import { TenantService } from '@app/core/service/tenant.service';
import { ApprovalsListService } from '@app/core/service/approvals-list.service';
import { ChecklistItem } from '@app/types/checklist-item';
import { formatDurationShort } from '@app/utils/format-duration';

@Component({
  selector: 'app-time-attack-modal',
  templateUrl: './time-attack-modal.component.html',
  styleUrls: ['./time-attack-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeAttackModalComponent {
  @Input()
  public checklistItem: ChecklistItem;

  @Input()
  protected timeAttackStats: {
    averageDuration: Duration;
    currentRecord: {
      user: UserModel;
      duration: Duration;
    };
    rewardForBeatingTheAverage: number;
    rewardForAttempt: number;
  } = null;
  protected requireManagerToInitiateTimeAttack = false;

  public constructor(
    private readonly _taskPerformanceService: TaskPerformanceService,
    private readonly _modalCtrl: ModalController,
    private readonly _loadingCtrl: LoadingController,
    private readonly _toastService: ToastService,
    private readonly _tenantService: TenantService,
    private readonly _approvalsListService: ApprovalsListService
  ) {
    this._tenantService.tenant$.subscribe((tenant) => {
      this.requireManagerToInitiateTimeAttack =
        tenant.settings.checklist.requireManagerToInitiateTimeAttack;
    });
  }

  protected async onStartClick() {
    const loading = await this._loadingCtrl.create({
      message: 'Loading...'
    });
    loading.present();

    this._taskPerformanceService
      .setTaskPerformance(
        this.checklistItem.task._id,
        this.requireManagerToInitiateTimeAttack
          ? {
              status: TaskPerformanceStatus.NOT_STARTED,
              taskAppearTime: this.checklistItem.interval.start.toISOString()
            }
          : {
              status: TaskPerformanceStatus.IN_PROGRESS,
              taskAppearTime: this.checklistItem.interval.start.toISOString()
            }
      )
      .subscribe({
        next: () => {
          this._approvalsListService.update();
          loading.dismiss();
          this.onCloseClick('confirm');
        },
        error: (e: { error: { message: string } }) => {
          loading.dismiss();
          this._toastService.presentToast(
            e.error?.message || 'Unable to start task performance'
          );
        }
      });
  }

  protected onCloseClick(role = 'cancel') {
    this._modalCtrl.dismiss(null, role, 'time-attack-modal');
  }

  protected get averageTime() {
    return formatDurationShort(this.timeAttackStats.averageDuration);
  }

  protected get currentRecordTime() {
    return formatDurationShort(this.timeAttackStats.currentRecord.duration);
  }

  protected get currentRecordUser() {
    return `${this.timeAttackStats.currentRecord.user.firstName} ${this.timeAttackStats.currentRecord.user.lastName}`;
  }
}
