<ion-header class="ion-no-border">
  <ion-toolbar class="app-common-background">
    <ion-buttons slot="start">
      <ion-button (click)="onBackButtonClick()" appHaptics>
        <ion-icon slot="icon-only" name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-capitalize">
      Features: {{ tenant.name }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <app-width-restricted-container class="container-with-fab">
    <ion-list [formGroup]="mainFormGroup">
      <div class="feature-group-title">Users</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="people-circle-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="connectedAccounts"
          (ionChange)="onFeatureToggle($any($event), 'connectedAccounts')"
        >
          Connected Accounts
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="apps-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="multiUserMode"
          (ionChange)="onFeatureToggle($any($event), 'multiUserMode')"
        >
          Multi-User Mode
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Checklist</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="checkmark-circle-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="checklist"
          (ionChange)="onFeatureToggle($any($event), 'checklist')"
        >
          Checklist
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="attach-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="taskAttachments"
          (ionChange)="onFeatureToggle($any($event), 'taskAttachments')"
        >
          Task Attachments
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="stopwatch-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="timeAttack"
          (ionChange)="onFeatureToggle($any($event), 'timeAttack')"
        >
          Time Attack
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="caret-forward-circle-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="actionsUponTaskCompletion"
          (ionChange)="
            onFeatureToggle($any($event), 'actionsUponTaskCompletion')
          "
        >
          Actions Upon Task-Completion
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="attach-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="requestTaskFeedbackAttachments"
          (ionChange)="
            onFeatureToggle($any($event), 'requestTaskFeedbackAttachments')
          "
        >
          Task Feedback Attachments
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="attach-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="leaveTaskNoteAttachments"
          (ionChange)="
            onFeatureToggle($any($event), 'leaveTaskNoteAttachments')
          "
        >
          Task Note Attachments
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="checkmark-done-circle-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="auditTasks"
          (ionChange)="onFeatureToggle($any($event), 'auditTasks')"
        >
          Audit
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="attach-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="auditAttachments"
          (ionChange)="onFeatureToggle($any($event), 'auditAttachments')"
        >
          Audit Attachments
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Tags</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="pricetags-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="tags"
          (ionChange)="onFeatureToggle($any($event), 'tags')"
        >
          Tags
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Insights</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="pie-chart-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="insights"
          (ionChange)="onFeatureToggle($any($event), 'insights')"
        >
          Performance
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="calculator-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="calculateRewards"
          (ionChange)="onFeatureToggle($any($event), 'calculateRewards')"
        >
          Calculate Rewards
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="ticket-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="lottery"
          (ionChange)="onFeatureToggle($any($event), 'lottery')"
        >
          Lottery
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="today-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="dailyReport"
          (ionChange)="onFeatureToggle($any($event), 'dailyReport')"
        >
          Daily Report
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="star-half-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="surveyFeedback"
          (ionChange)="onFeatureToggle($any($event), 'surveyFeedback')"
        >
          Feedback
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Announcements</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="mail-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="announcements"
          (ionChange)="onFeatureToggle($any($event), 'announcements')"
        >
          Announcements
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="attach-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="announcementAttachments"
          (ionChange)="onFeatureToggle($any($event), 'announcementAttachments')"
        >
          Announcements / Attachments
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Pulse</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="leaf-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="survey"
          (ionChange)="onFeatureToggle($any($event), 'survey')"
        >
          Pulse
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Library</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="library-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="library"
          (ionChange)="onFeatureToggle($any($event), 'library')"
        >
          Library
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="attach-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="libraryDocAttachments"
          (ionChange)="onFeatureToggle($any($event), 'libraryDocAttachments')"
        >
          Attachments
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Suggestions</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="chatbubbles-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="suggestions"
          (ionChange)="onFeatureToggle($any($event), 'suggestions')"
        >
          Suggestions
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="attach-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="suggestionAttachments"
          (ionChange)="onFeatureToggle($any($event), 'suggestionAttachments')"
        >
          Attachments
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Schedule</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="calendar-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="userSchedule"
          (ionChange)="onFeatureToggle($any($event), 'userSchedule')"
        >
          Schedule
        </ion-toggle>
      </ion-item>
      <ion-item>
        <ion-icon
          slot="start"
          name="time-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="askTodaysShiftTime"
          (ionChange)="onFeatureToggle($any($event), 'askTodaysShiftTime')"
        >
          Ask for Shift End Time
        </ion-toggle>
      </ion-item>

      <div class="feature-group-title">Other</div>
      <ion-item>
        <ion-icon
          slot="start"
          name="flash-outline"
          class="feature-icon"
        ></ion-icon>
        <ion-toggle
          formControlName="instantFeedback"
          (ionChange)="onFeatureToggle($any($event), 'instantFeedback')"
        >
          App Feedback Button
        </ion-toggle>
      </ion-item>
    </ion-list>
  </app-width-restricted-container>
</ion-content>
