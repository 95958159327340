<ion-header class="ion-no-border">
  <ion-toolbar class="app-toolbar">
    <ion-buttons slot="start" class="ion-hide-lg-up">
      <app-slim-menu-button></app-slim-menu-button>
    </ion-buttons>
    <ion-title> Suggestions </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <app-width-restricted-container class="container-with-fab ion-padding">
    <ion-grid *ngIf="isLoading">
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="lines-sharp-small"></ion-spinner>
      </ion-row>
    </ion-grid>

    <ng-container *ngIf="opportunities.length === 0 && !isLoading">
      <ng-container *ngTemplateOutlet="emptyList"></ng-container>
    </ng-container>

    <div
      class="suggestion-item"
      *ngFor="let opp of opportunities; trackBy: trackItems"
      (click)="viewDoc(opp)"
    >
      <div class="suggestion-item__header">
        <div class="suggestion-item__title">{{ opp.title }}</div>
        <ion-chip
          class="ion-text-capitalize suggestion-item__status"
          [color]="getStatusColor(opp.status)"
        >
          {{ opp.status.toLowerCase().replace('_', ' ') }}
        </ion-chip>
      </div>
      <div class="suggestion-item__footer">
        <div class="suggestion-item__footer-item">
          {{ getAuthorName(opp) }}
        </div>
        <div class="suggestion-item__footer-item">
          {{ opp.dateCreated | date : 'MMM d, y' }}
        </div>
        <div *ngIf="opp.solutions.length" class="suggestion-item__footer-item">
          {{ getSolutionsText(opp) }}
        </div>
        <div *ngIf="opp.isPublic" class="suggestion-item__footer-item--primary">
          Published to Team
        </div>
      </div>
    </div>
  </app-width-restricted-container>

  <ion-fab slot="fixed" vertical="bottom" horizontal="end" class="main-fab">
    <ion-fab-button color="dark" (click)="onAddClick()" appHaptics>
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
</ion-content>

<ng-template #emptyList>
  <app-empty-stub text="No Suggestions Found"></app-empty-stub>
</ng-template>
