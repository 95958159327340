import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ContentTitleComponent } from './content-title.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  exports: [ContentTitleComponent],
  declarations: [ContentTitleComponent]
})
export class ContentTitleModule {}
